import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopup from '../../common/CustomPopup';
import { Button, Divider, Heading, Icon, ActionIcon, Text } from '../../common';
import { InputText, TextArea } from '../../components/form';
import { CustomButton } from '../../common/commonStyled';
import DesignQuestion from './DesignQuestion';
import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../StrictModeDroppable';
import { faL } from '@fortawesome/free-solid-svg-icons';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SortableTest from './SortableTest';
import { PiDotsSixVerticalBold } from 'react-icons/pi';

import 'react-toastify/dist/ReactToastify.css';
import './DeleteQuestionToast.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const CustomButtont = styled(Button)`
  padding: 2px 1rem;
  color: var(--white);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
`;

class PointerSensorWithoutPreventDefault extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent }) => {
        if (!nativeEvent.isPrimary || nativeEvent.button !== 0) {
          return false;
        }
        return shouldHandleEvent(nativeEvent.target);
      }
    }
  ];
}

// Custom Focus Sensor to prevent drag on focusable elements
class FocusSensor extends KeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown',
      handler: ({ nativeEvent }) => {
        return shouldHandleEvent(nativeEvent.target);
      }
    }
  ];
}

// Helper function to check if an event should activate dragging
function shouldHandleEvent(element) {
  let cur = element;
  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false; // Prevent drag if `data-no-dnd="true"` is present
    }
    cur = cur.parentElement;
  }
  return true;
}

// Custom SmartPointerSensor that ignores interactive elements
class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (!event.isPrimary || event.button !== 0 || isInteractiveElement(event.target)) {
          return false;
        }
        return true;
      }
    }
  ];
}

// Function to check if an element is interactive (prevents dragging)
function isInteractiveElement(element) {
  const interactiveElements = ['button', 'input', 'textarea', 'select', 'option'];
  return element?.tagName && interactiveElements.includes(element.tagName.toLowerCase());
}

const CustomDivider = () => <Divider width="100%" height="1px" margin="1.5rem 0" background="var(--greyBlue)" />;

export default function DesignFormTab({
  task,
  nextTask,
  taskList,
  questionTypeList,
  handleTaskTab,
  sectionIndex,
  ValueTypeChoices,
  addQuestionDisable,
  setAddQuestionDisable,
  questionIndex
}) {
  const loggedUser = useSelector(state => state.LoginReducer);
  const projectTask_ID = task.projectTask_ID;
  const [index, setIndex] = useState(null);
  const { projectId } = useParams();
  const [popup, setPopup] = useState(false);
  const [formTask, setFormTask] = useState(task);
  const [formTaskSections, setFormTaskSections] = useState(taskList);
  const dispatch = useDispatch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  const [popupColor, setPopupColor] = useState('');
  const [message, setMessage] = useState(false);
  const addSec = useSelector(state => state.ProjectReducer?.addSection);
  const editSec = useSelector(state => state.ProjectReducer?.editSection);
  const editQues = useSelector(state => state.TaskReducer?.editQuestion);
  const [editSectionIndex, seteditSectionIndex] = useState(null);
  const addQues = useSelector(state => state.TaskReducer?.addQuestion);
  const deleteSec = useSelector(state => state.ProjectReducer?.deleteSec);
  const deleteQues = useSelector(state => state.TaskReducer?.deleteQues);

  const [sectionmessage, setsectionMessage] = useState(null);
  const [newQuestionIndex, setNewQuestionIndex] = useState(null);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  const [questionmessage, setquestionMessage] = useState(null);
  const [sectionNameError, setSectionNameError] = useState(false);
  const [sectionSavedStatus, setSectionSavedStatus] = useState([]);
  const [switchvalue, setSwitchValue] = useState(0);
  const [QuestionIndex, setQuestionIndex] = useState(null);
  const [isNewSectionForm, setisNewSectionForm] = useState(false);
  const [addSectionDisable, setAddSectionDisbale] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [deletesectionmessage, setdeletesectionMessage] = useState(null);
  const [deleteSectionIndex, setDeleteSectionIndex] = useState(null);
  const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(null);
  const [isAddingNewSection, setIsAddingNewSection] = useState(false);
  const initialLoad = useRef(true);
  const [isEditing, setIsEditing] = useState(false);

  const handleSetEditing = () => {
    setIsEditing(false);
  };

  const [openSections, setOpenSections] = useState(new Set(formTaskSections.map(s => s.questionPaperSection_ID)));

  const [draggingItem, setDraggingItem] = useState(null);

  const toggleOpen = sectionId => {
    setOpenSections(prev => {
      const newSet = new Set(prev);
      if (newSet.has(sectionId)) {
        newSet.delete(sectionId);
      } else {
        newSet.add(sectionId);
      }
      return newSet;
    });
  };

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 0, tolerance: 0 } }));

  const isSectionBeingDragged = activeId => {
    return formTaskSections.some(s => s.questionPaperSection_ID === activeId);
  };

  useEffect(() => {
    if (addSec?.StatusCode == 1) {
      setPopupColor('var(--sapGreen)');
      setsectionMessage('Section Added Successfully!');
    }
    if (addSec?.StatusCode == -1) {
      setPopupColor('red');
      setsectionMessage('Error While Adding Section');
    }

    const timeout = setTimeout(() => {
      setsectionMessage(null);
      seteditSectionIndex(null);
      dispatch({ type: 'CLEAR_ADD_SECTION' });
      dispatch({ type: 'CLEAR_EDIT_SECTION' });
    }, 3000);
  }, [addSec, editSec]);

  useEffect(() => {
    if (addQues && addQues !== undefined) {
      if (addQues?.StatusCode == 1) {
        setIsPopup(true);
        setPopupColor('var(--sapGreen)');
        setquestionMessage('Question Added Successfully!');
      }
      if (addQues?.StatusCode == -1) {
        setIsPopup(true);
        setPopupColor('red');
        setquestionMessage('Error While Adding Question');
      }

      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getProjectTask.methodType
        )
        .then(response => {
          let taskData = response.data.Response;

          setFormTaskSections(taskData?.GetProjectTaskQuestions);
        });

      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        seteditSectionIndex(null);
        dispatch({ type: 'CLEAR_ADD_QUESTION' });
      }, 3000);
    }
  }, [addQues]);

  useEffect(() => {
    if (editSec?.StatusCode == 1) {
      setPopupColor('var(--sapGreen)');
      setsectionMessage('Section Edited Successfully!');
    }
    if (editSec?.StatusCode == -1) {
      setPopupColor('red');
      setsectionMessage('Error While Editing The Section');
    }

    const timeout = setTimeout(() => {
      setsectionMessage(null);
      dispatch({ type: 'CLEAR_EDIT_SECTION' });
    }, 3000);
  }, [editSec]);

  useEffect(() => {
    if (editQues && editQues !== undefined) {
      if (editQues?.StatusCode == 1) {
        setIsPopup(true);
        setPopupColor('var(--sapGreen)');
        // setquestionMessage('Question Edited Successfully!');
        setquestionMessage(editQues?.StatusDesc);
      }
      if (editQues?.StatusCode == -1) {
        setIsPopup(true);
        setPopupColor('red');
        // setquestionMessage('Question response is already saved. You can not Edit question.');
        setquestionMessage(editQues?.StatusDesc);
      }

      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getProjectTask.methodType
        )
        .then(response => {
          let taskData = response.data.Response;

          setFormTaskSections(taskData?.GetProjectTaskQuestions);
        });

      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_EDIT_QUESTION' });
      }, 3000);
    }
  }, [editQues]);

  const toastDisplayed = useRef(false);
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (deleteSec?.StatusCode && !toastDisplayed.current) {
      const message = deleteSec?.StatusDesc;

      if (deleteSec?.StatusCode === '-1') {
        toast.error(<>{message}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
        setIsPopup(true);
      } else if (deleteSec?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{message}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
        setIsPopup(true);
      }

      toastDisplayed.current = true;

      setTimeout(() => {
        setIsPopup(false);
        dispatch({ type: 'CLEAR_DELETE_SECTION' });
        toastDisplayed.current = false;
      }, 4500);

      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getProjectTask.methodType
        )
        .then(response => {
          let taskData = response.data.Response;

          setFormTaskSections(taskData?.GetProjectTaskQuestions);
        });
    }
  }, [deleteSec]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (deleteQues?.StatusCode && !toastDisplayed.current) {
      const message = deleteQues?.StatusDesc;

      if (deleteQues?.StatusCode === '-1') {
        toast.error(<>{message}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
        setIsPopup(true);
      } else if (deleteQues?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{message}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
        setIsPopup(true);
      }

      toastDisplayed.current = true;

      setTimeout(() => {
        setIsPopup(false);
        dispatch({ type: 'CLEAR_DELETE_QUESTION' });
        toastDisplayed.current = false;
      }, 4500);

      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getProjectTask.methodType
        )
        .then(response => {
          let taskData = response.data.Response;

          setFormTaskSections(taskData?.GetProjectTaskQuestions);
        });

      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_DELETE_QUESTION' });
        // getCriteriaBudgetQuestion();
      }, 3000);
    }
  }, [deleteQues]);

  let questionTypes = questionTypeList?.map(questionType => {
    return questionType.questionType_ID != 9 && questionType.questionType_ID != 10
      ? { ...questionType, label: questionType.questionTypeName, value: questionType.questionType_ID }
      : null;
  });

  questionTypes = questionTypes?.filter(function(questionType) {
    return questionType != null;
  });

  useEffect(() => {
    setFormTaskSections(taskList);
    setOpenSections(new Set(taskList.map(s => s.questionPaperSection_ID)));
  }, [taskList]);

  useEffect(() => {
    setTimeout(() => {
      setisNewSectionForm(false);
    }, 3000);
  }, [isNewSectionForm]);

  const [showSaveButton, setShowSaveButton] = useState(false);

  const createSection = () => {
    setIsAddingNewSection(true);
    // let tempFormTaskSections = formTaskSections;
    // tempFormTaskSections = [
    //   ...tempFormTaskSections,
    //   { sectionName: '', sectionDescription: '', questionName_array: [], isNew: true }
    // ];

    // setFormTaskSections(tempFormTaskSections);
    // setShowSaveButton(true);
    // setSectionNameError(false);

    setFormTaskSections(prevList => [
      ...prevList,
      { sectionName: '', sectionDescription: '', questionName_array: [], isNew: true }
    ]);
    setShowSaveButton(true);
    setAddSectionDisbale(true);
    setSectionNameError(false);
    setSectionSavedStatus(prevStatus => [...prevStatus, false]);
  };
  const closeHandler = e => {
    setPopup(e);
  };

  const handleDragStart = event => {
    const { active } = event;
    setDraggingItem(active.id);

    // Collapse all sections ONLY if a SECTION is being dragged
    if (isSectionBeingDragged(active.id)) {
      setOpenSections(new Set());
    }
  };

  const moveQuestionWithinSection = (sections, sectionId, sourceIndex, destinationIndex) => {
    const updatedSections = [...sections];
    const section = updatedSections.find(s => s.questionPaperSection_ID === sectionId);

    if (!section) return sections;

    const [movedQuestion] = section.questionName_array.splice(sourceIndex, 1);
    section.questionName_array.splice(destinationIndex, 0, movedQuestion);

    return updatedSections;
  };

  const moveQuestionAcrossSections = (
    sections,
    sourceSectionId,
    destinationSectionId,
    sourceIndex,
    destinationIndex
  ) => {
    const updatedSections = [...sections];

    const sourceSection = updatedSections.find(s => s.questionPaperSection_ID === sourceSectionId);
    const destinationSection = updatedSections.find(s => s.questionPaperSection_ID === destinationSectionId);

    if (!sourceSection || !destinationSection) return sections;

    // Remove question from source section
    const [movedQuestion] = sourceSection.questionName_array.splice(sourceIndex, 1);

    // Add question to destination section
    destinationSection.questionName_array.splice(destinationIndex, 0, movedQuestion);

    return updatedSections;
  };

  const moveSection = (sections, activeId, overId) => {
    const sectionIndex = sections.findIndex(s => s.questionPaperSection_ID === activeId);
    const newSectionIndex = sections.findIndex(s => s.questionPaperSection_ID === overId);

    if (sectionIndex !== -1 && newSectionIndex !== -1) {
      return arrayMove(sections, sectionIndex, newSectionIndex);
    }

    return sections;
  };

  const handleDragEnd = event => {
    setDraggingItem(null);
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setFormTaskSections(prevSections => {
      let updatedSections = [...prevSections];

      // Check if the dragged item is a section
      const isSectionDrag = prevSections.some(s => s.questionPaperSection_ID === active.id);

      if (isSectionDrag) {
        // Moving sections
        updatedSections = moveSection(updatedSections, active.id, over.id);

        // API call for section drag and drop
        dispatch({
          type: 'DRAG_SECTION',
          payload: {
            user_ID: loggedUser.userId,
            sections: updatedSections.map((section, index) => ({
              questionPaperSection_ID: section.questionPaperSection_ID.toString(),
              sectionOrder: (index + 1).toString()
            }))
          }
        });
      } else {
        // Find the source and destination sections
        let sourceSection = updatedSections.find(s => s.questionName_array.some(q => q.question_ID === active.id));

        let destinationSection = updatedSections.find(s => s.questionName_array.some(q => q.question_ID === over.id));

        if (!sourceSection || !destinationSection) return updatedSections;

        const sourceIndex = sourceSection.questionName_array.findIndex(q => q.question_ID === active.id);
        const destinationIndex = destinationSection.questionName_array.findIndex(q => q.question_ID === over.id);

        if (sourceSection === destinationSection) {
          // Moving within the same section
          updatedSections = moveQuestionWithinSection(
            updatedSections,
            sourceSection.questionPaperSection_ID,
            sourceIndex,
            destinationIndex
          );

          dispatch({
            type: 'DRAG_QUESTION',
            payload: {
              user_ID: loggedUser.userId,
              questions: updatedSections
                .find(s => s.questionPaperSection_ID === sourceSection.questionPaperSection_ID)
                ?.questionName_array.map((q, index) => ({
                  question_ID: q.question_ID,
                  questionOrder: index + 1
                }))
            }
          });
        } else {
          // Moving across sections
          updatedSections = moveQuestionAcrossSections(
            updatedSections,
            sourceSection.questionPaperSection_ID,
            destinationSection.questionPaperSection_ID,
            sourceIndex,
            destinationIndex
          );

          dispatch({
            type: 'DRAG_QUESTION_ACROSS_SECTIONS',
            payload: {
              user_ID: loggedUser.userId,
              questionsPaperSections: [
                {
                  section_ID: sourceSection.questionPaperSection_ID,
                  questions: updatedSections
                    .find(s => s.questionPaperSection_ID === sourceSection.questionPaperSection_ID)
                    ?.questionName_array.map((q, index) => ({
                      question_ID: q.question_ID,
                      questionOrder: index + 1
                    }))
                },
                {
                  section_ID: destinationSection.questionPaperSection_ID,
                  questions: updatedSections
                    .find(s => s.questionPaperSection_ID === destinationSection.questionPaperSection_ID)
                    ?.questionName_array.map((q, index) => ({
                      question_ID: q.question_ID,
                      questionOrder: index + 1
                    }))
                }
              ]
            }
          });
        }
      }

      return updatedSections;
    });

    setOpenSections(new Set(formTaskSections.map(s => s.questionPaperSection_ID)));
  };

  const storeSection = (sectionIndex, _field, _value) => {
    setIsAddingNewSection(false);
    // let tempFormTaskSections = formTaskSections;
    // tempFormTaskSections[sectionIndex] = {
    //   ...tempFormTaskSections[sectionIndex],
    //   _field: _value,
    //   isNew: _field != 'sectionDescription'
    // };

    // setFormTaskSections(tempFormTaskSections);

    // const data = {
    //   user_ID: loggedUser.userId,
    //   projectsTask_ID: formTask.projectTask_ID,
    //   project_ID: projectId,
    //   projectScreener_ID: null,
    //   parentType: 'task',
    //   sectionName: _field == 'sectionName' ? _value : tempFormTaskSections[sectionIndex].sectionName,
    //   sectionDescription:
    //     _field == 'sectionDescription' ? _value : tempFormTaskSections[sectionIndex].sectionDescription
    // };
    // dispatch({ type: 'ADD_SECTION', payload: data });

    setFormTaskSections(prevList => {
      return prevList.map((section, i) => (i === sectionIndex ? { ...sectionIndex, [_field]: _value } : section));
    });

    const section = formTaskSections[sectionIndex];
    if (section?.sectionName || section?.sectionDescription) {
      const data = {
        user_ID: loggedUser.userId,
        projectsTask_ID: formTask.projectTask_ID,
        project_ID: projectId,
        projectScreener_ID: null,
        parentType: 'task',
        sectionName: section.sectionName,
        sectionDescription: section.sectionDescription,
        sectionOrder: sectionIndex
      };

      dispatch({ type: 'ADD_SECTION', payload: data });

      const updatedStatus = [...sectionSavedStatus];
      updatedStatus[sectionIndex] = true; // Mark the section at index as saved
      setSectionSavedStatus(updatedStatus);
    } else {
      console.log('error');
    }

    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
        maverickPortalApiEndPoint.getProjectTask.methodType
      )
      .then(response => {
        let taskData = response.data.Response;

        setFormTaskSections(taskData?.GetProjectTaskQuestions);
      });
    setShowSaveButton(false);
  };

  const updateSection = (sectionIndex, _field, _value) => {
    let tempFormTaskSections = formTaskSections;
    tempFormTaskSections[sectionIndex] = { ...tempFormTaskSections[sectionIndex], _field: _value };
    if (_field == 'sectionName') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }
    if (_field == 'sectionDescription') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }

    setFormTaskSections(tempFormTaskSections);

    const data = {
      user_ID: loggedUser.userId,
      QuestionPaperSection_ID: tempFormTaskSections[sectionIndex].questionPaperSection_ID,
      sectionName: _field == 'sectionName' ? _value : tempFormTaskSections[sectionIndex].sectionName,
      sectionDescription:
        _field == 'sectionDescription' ? _value : tempFormTaskSections[sectionIndex].sectionDescription
    };
    if (data.sectionName != '') {
      dispatch({ type: 'EDIT_SECTION', payload: data });
    }

    setShowSaveButton(false);
    // setEditedSectionIndex(null);
  };

  const createQuestion = sectionIndex => {
    setIsEditing(true);
    let tempFormTaskSections = formTaskSections.slice();

    !tempFormTaskSections[sectionIndex].hasOwnProperty('questionName_array') &&
      (tempFormTaskSections[sectionIndex] = { ...tempFormTaskSections[sectionIndex], questionName_array: [] });

    let tempQuestionArray = tempFormTaskSections[sectionIndex].questionName_array;
    // console.log('switchValue', switchvalue);
    tempQuestionArray.push({
      question_ID: null,
      questionName: null,
      questionDescription: null,
      5: switchvalue,
      isEdit: true,
      questionType_ID: 1,
      oldQuestionType_ID: 1,
      characterLimit: 500,
      maxFileSizeInMB: 10,
      numberOfFiles: 1,
      savedOption: null,
      choiceName_array: [],
      ValueTypeChoices: [],
      likertScaleName_array: [
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Dislike Very Much'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Dislike'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Moderately Dislike'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Neutral'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Moderately Like'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Like'
        },
        {
          questionLikertScale_ID: null,
          questionLikertScaleName: 'Like Very Much'
        }
      ]
    });
    tempFormTaskSections[sectionIndex].questionName_array = tempQuestionArray;
    setNewQuestionIndex(sectionIndex);
    setEditQuestionIndex(null);
    setDeleteQuestionIndex(null);
    setAddQuestionDisable(true);
    setFormTaskSections(tempFormTaskSections);
  };

  const handleChangeQuestionType = (sectionIndex, questionIndex, questionTypeId, question) => {
    let tempFormTaskSections = formTaskSections.slice();

    question = {
      ...question,
      questionType_ID: questionTypeId,
      oldQuestionType_ID: question.oldQuestionType_ID,
      isEdit: true
    };
    tempFormTaskSections[sectionIndex].questionName_array[questionIndex] = question;

    setFormTaskSections(tempFormTaskSections);
  };

  const handleDuplicateQuestion = (sectionIndex, questionIndex, setCheckBoxRequired) => {
    let tempFormTaskSections = formTaskSections.slice();
    let question = tempFormTaskSections[sectionIndex]?.questionName_array[questionIndex];
    question = { ...question, isEdit: true, question_ID: null };

    tempFormTaskSections[sectionIndex].questionName_array.splice(questionIndex + 1, 0, question);
    setFormTaskSections(tempFormTaskSections);
    setIsEditing(true);

    setCheckBoxRequired(question.isRequired);
  };

  const handleEditQuestion = (sectionIndex, questionIndex) => {
    let tempFormTaskSections = formTaskSections.slice();
    console.log('SECTIONS', tempFormTaskSections);
    let question = tempFormTaskSections[sectionIndex]?.questionName_array[questionIndex];
    question = { ...question, isEdit: true };

    tempFormTaskSections[sectionIndex].questionName_array[questionIndex] = question;
    setFormTaskSections(tempFormTaskSections);
    setIsEditing(true);
  };

  const handleDeleteQuestion = (sectionIndex, questionIndex) => {
    let tempFormTaskSections = formTaskSections.slice();
    let question = tempFormTaskSections[sectionIndex]?.questionName_array[questionIndex];

    // Remove the question from the array
    tempFormTaskSections[sectionIndex].questionName_array.splice(questionIndex, 1);
    setFormTaskSections(tempFormTaskSections);
    setAddQuestionDisable(false);
    // Dispatch only if question_ID is not null
    if (question?.question_ID) {
      setQuestionIndex(questionIndex);
      setDeleteQuestionIndex(sectionIndex);
      setNewQuestionIndex(null);
      let payload = {
        user_ID: loggedUser.userId,
        questions_ID: question.question_ID
      };

      dispatch({
        type: 'REQUEST_DELETE_QUESITON',
        formData: payload
      });
    }
  };

  const handleDeleteSection = sectionIndex => {
    setDeleteSectionIndex(sectionIndex);
    let tempFormTaskSections = formTaskSections.slice();
    let section = tempFormTaskSections[sectionIndex];
    let payload = {
      user_ID: loggedUser.userId,
      QuestionPaperSection_ID: section.questionPaperSection_ID
    };

    tempFormTaskSections.splice(sectionIndex, 1);
    setFormTaskSections(tempFormTaskSections);
    dispatch({
      type: 'REQUEST_DELETE_SECTION',
      formData: payload
    });

    setAddSectionDisbale(false);
    setPopup(false);
  };

  const handleProjectTaskTab = () => {
    handleTaskTab(task.taskType, task.projectTask_ID, task.IsVisited);
  };
  const handleProjectTaskNext = () => {
    handleTaskTab(nextTask.taskType, nextTask.projectTask_ID, nextTask.IsVisited);
  };

  const SortableSection = React.memo(({ section, isOpen, toggleOpen, isDragging, sIndex }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
      id: section.questionPaperSection_ID
    });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      border: isDragging ? '4px solid #83A600' : ''
    };

    return (
      <div ref={setNodeRef} style={style}>
        {/* Child Questions (Do Not Trigger Toggle) */}
        <React.Fragment key={section.questionPaperSection_ID}>
          <CustomDivider />
          <div className="row">
            <div className="col-md-8">
              {isPopupVisible && deletesectionmessage && sIndex === 0 && (
                <center>
                  <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                    <div>{deletesectionmessage}</div>
                  </div>
                </center>
              )}
              <CustomButton
                variant="outlined"
                buttonColor="var(--greyBlue)"
                className=""
                onClick={e => {
                  setPopup(!popup), setIndex(sIndex);
                }}
                style={{ float: 'right', color: 'var(--greyBlue)', backgroundColor: 'white', fontSize: '15px' }}
              >
                <Icon className="material-icons-round" style={{ color: 'var(--greyBlue)', marginTop: '1.5px' }}>
                  delete
                </Icon>
                Delete Section
                <CustomPopup
                  onClose={closeHandler}
                  showed={popup}
                  heading="Confirm delete"
                  secondheading="Are you sure you want to delete this Section?"
                  secondheading2="Once deleted, it cant be retrieved."
                >
                  <CustomButtont
                    fontSize="14px"
                    fontWeight="300"
                    color="black"
                    size="medium"
                    style={{ justifyContent: 'space-between', marginRight: '20px' }}
                    onClick={() => {
                      handleDeleteSection(index);
                    }}
                  >
                    Delete
                  </CustomButtont>
                </CustomPopup>
              </CustomButton>

              <div className="section-wrapper ">
                <div className="row" style={{ cursor: 'pointer' }}>
                  <div className="col-md-3">
                    {/* Drag Handle (Only This is Draggable) */}
                    <div className="d-flex">
                      <button
                        ref={setActivatorNodeRef}
                        {...listeners}
                        {...attributes}
                        style={{
                          cursor: 'grab',
                          padding: 0,
                          margin: 0,
                          border: 0
                        }}
                      >
                        <PiDotsSixVerticalBold />
                      </button>
                      <Heading
                        type="5"
                        className="mt-2"
                        color="var(--seaBlue)"
                        onClick={() => toggleOpen(section.questionPaperSection_ID)}
                      >
                        Section {+sIndex + +1} {isOpen ? '▼' : '▶'}
                      </Heading>
                    </div>
                  </div>
                  {formTaskSections[sIndex].sectionName == '' && formTaskSections[sIndex].sectionDescription == '' ? (
                    <div className="col-md-9">
                      <TextArea
                        showCharacters={false}
                        fontSize="1.125rem"
                        value={section.sectionName}
                        placeholder="Add a section name"
                        width="250px"
                        style={{
                          color: 'var(--deepBlue)'
                        }}
                        onChange={e => {
                          let tempFormTaskSections = formTaskSections;
                          tempFormTaskSections[sIndex].sectionName = e.target.value;
                          setFormTaskSections(tempFormTaskSections);
                        }}
                      />
                      {sectionNameError && (
                        <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                      )}
                      <TextArea
                        fontSize="0.75rem"
                        showCharacters={false}
                        value={section.sectionDescription}
                        placeholder="Add a section description"
                        className="mt-1"
                        style={{
                          color: 'var(--deepBlue)',
                          fontWeight: '500'
                        }}
                        onChange={e => {
                          let tempFormTaskSections = formTaskSections;
                          tempFormTaskSections[sIndex].sectionDescription = e.target.value;
                          setFormTaskSections(tempFormTaskSections);
                        }}
                      />

                      {showSaveButton && (
                        <>
                          <CustomButton
                            variant="outlined"
                            buttonColor="var(--sapGreen)"
                            className="mt-3"
                            onClick={e => {
                              e.stopPropagation();
                              if (!section.sectionName.trim()) {
                                // alert('Please fill in the section name.')
                                setSectionNameError(true);
                              } else {
                                setSectionNameError(false);
                                storeSection(sIndex, 'sectionDescription', e.target.value);
                                // storeSection(index, 'sectionName', e.target.value)
                                setisNewSectionForm(true);
                                setAddSectionDisbale(false);
                                setShowSaveButton(false);
                              }
                            }}
                          >
                            <Icon>save</Icon>
                            Save Section
                          </CustomButton>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-9">
                      <TextArea
                        showCharacters={false}
                        fontSize="1.125rem"
                        value={section.sectionName}
                        placeholder="Add a section name"
                        width="250px"
                        style={{
                          color: 'var(--deepBlue)'
                        }}
                        onChange={e => {
                          let tempFormTaskSections = formTaskSections;
                          tempFormTaskSections[sIndex].sectionName = e.target.value;
                          setFormTaskSections(tempFormTaskSections);
                          seteditSectionIndex(section.questionPaperSection_ID);
                        }}
                      />
                      {editSectionIndex === section.questionPaperSection_ID && sectionNameError && (
                        <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                      )}
                      <TextArea
                        fontSize="0.75rem"
                        showCharacters={false}
                        value={section.sectionDescription}
                        className="mt-1"
                        style={{
                          color: 'var(--deepBlue)',
                          fontWeight: '500'
                        }}
                        onChange={e => {
                          let tempFormTaskSections = formTaskSections;
                          tempFormTaskSections[sIndex].sectionDescription = e.target.value;

                          setFormTaskSections(tempFormTaskSections);
                          seteditSectionIndex(section.questionPaperSection_ID);
                        }}
                      />
                      {editSectionIndex === section.questionPaperSection_ID && (
                        <>
                          <CustomButton
                            variant="outlined"
                            buttonColor="var(--sapGreen)"
                            className="mt-3"
                            onClick={e => {
                              e.stopPropagation();
                              // storeSection(index, 'sectionDescription', e.target.value)
                              if (!section.sectionName.trim()) {
                                setSectionNameError(true);
                              } else {
                                setSectionNameError(false);
                                updateSection(sIndex, 'sectionName', section.sectionName);
                                updateSection(sIndex, 'sectionDescription', section.sectionDescription);
                                setShowSaveButton(false);
                              }
                            }}
                          >
                            <Icon>save</Icon>
                            Save Section
                          </CustomButton>
                        </>
                      )}
                      {editSectionIndex === section.questionPaperSection_ID && sectionmessage && (
                        <center>
                          <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                            <div>{sectionmessage}</div>
                          </div>
                        </center>
                      )}
                    </div>
                  )}
                </div>
                {isOpen && !isDragging && (
                  <>
                    <div className="questions-list mt-3">
                      <SortableContext
                        items={section.questionName_array.map(q => q.question_ID).filter(id => id !== null)}
                      >
                        <React.Fragment key={section.questionPaperSection_ID}>
                          <div className="questions-list mt-3">
                            {section.questionName_array.map((question, qIndex) => (
                              <SortableQuestion
                                key={question.question_ID}
                                question={question}
                                qIndex={qIndex}
                                section={section}
                                sIndex={sIndex}
                                QuestionIndex={QuestionIndex}
                              />
                            ))}
                          </div>
                        </React.Fragment>
                      </SortableContext>
                    </div>

                    {newQuestionIndex === sIndex && questionmessage && (
                      <center>
                        <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                          <div>{questionmessage}</div>
                        </div>
                      </center>
                    )}

                    <CustomButton
                      variant="outlined"
                      buttonColor="var(--sapGreen)"
                      color="var(--sapGreen)"
                      className="mt-5"
                      onClick={() => createQuestion(sIndex)}
                      disabled={section.isNew || addQuestionDisable}
                    >
                      <Icon>add</Icon>
                      Add Question
                    </CustomButton>

                    {isPopupVisible && deletesectionmessage && deleteSectionIndex == index + 1 && (
                      <center>
                        <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                          <div>{deletesectionmessage}</div>
                        </div>
                      </center>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  });

  const SortableQuestion = React.memo(({ question, qIndex, section, sIndex, QuestionIndex }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
      id: question.question_ID,
      animateLayoutChanges: () => false
    });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,

      background: 'white'
    };

    return (
      <>
        <div ref={setNodeRef} style={style}>
          <div
            key={question.question_ID}
            index={qIndex}
            onPointerDown={e => {
              e.stopPropagation(); // Prevent drag from interfering
              e.preventDefault(); // Fix double-click issue
            }}
            style={{ position: 'relative' }}
          >
            <button
              ref={setActivatorNodeRef}
              {...listeners}
              {...attributes}
              style={{
                cursor: 'grab',
                padding: 0,
                margin: 0,
                border: 0,
                position: 'absolute',
                zIndex: 100,
                top: '50%'
              }}
            >
              <PiDotsSixVerticalBold />
            </button>
            <DesignQuestion
              key={qIndex}
              sectionIndex={sIndex}
              setSwitchValue={setSwitchValue}
              switchvalue={switchvalue}
              questionIndex={qIndex}
              sectionId={section.questionPaperSection_ID}
              questionArrayLength={section?.questionName_array?.length}
              question={question}
              questionTypes={questionTypes}
              ValueTypeChoices={ValueTypeChoices}
              handleChangeQuestionType={handleChangeQuestionType}
              handleDuplicateQuestion={handleDuplicateQuestion}
              handleEditQuestion={handleEditQuestion}
              handleSetEditing={handleSetEditing}
              handleDeleteQuestion={handleDeleteQuestion}
              handleProjectTaskTab={handleProjectTaskTab}
              isInterview={task.taskType !== 'Form'}
              setEditQuestionIndex={setEditQuestionIndex}
              setNewQuestionIndex={setNewQuestionIndex}
              setDeleteQuestionIndex={setDeleteQuestionIndex}
              enableEdit={editQuestionId == question?.question_ID}
              setEditQuestionId={setEditQuestionId}
              setAddQuestionDisable={setAddQuestionDisable}
              setQuestionIndex={setQuestionIndex}
              questionmessage={questionmessage}
              popupColor={popupColor}
              showCheckbox={true}
              setFormTaskSections={setFormTaskSections}
              formTaskSections={formTaskSections}
              task={task}
            />

            {questionmessage && (
              <center>
                <div
                  style={{
                    color: popupColor,
                    fontWeight: 'bold',
                    textAlign: 'left'
                  }}
                >
                  <div>{questionmessage}</div>
                </div>
              </center>
            )}
          </div>
        </div>
      </>
    );
  });

  return (
    <>
      <section className="form-notification-section ">
        <div className="row py-2">
          <div className="col-md-6">
            <Heading color={'var(--deepBlue)'} fontSize="1.125rem" fontWeight="800">
              {formTask.taskName}
            </Heading>

            <TextArea
              showCharacters={false}
              fontSize="0.75rem"
              fontWeight="500"
              placeholder="Add section description"
              value={formTask.taskDescription}
              className="mt-1"
            />
          </div>
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          // disabled={isEditingQuestion}
        >
          {draggingItem !== null || isEditing === false ? (
            <SortableContext items={formTaskSections?.map(s => s.questionPaperSection_ID).filter(id => id !== null)}>
              {formTaskSections.map((section, sIndex) => (
                <SortableSection
                  key={section.questionPaperSection_ID}
                  section={section}
                  isOpen={openSections.has(section.questionPaperSection_ID)}
                  toggleOpen={toggleOpen}
                  isDragging={draggingItem === section.questionPaperSection_ID}
                  sIndex={sIndex}
                />
              ))}
            </SortableContext>
          ) : (
            <>
              {formTaskSections?.map((section, sIndex) => {
                return (
                  <React.Fragment key={section.questionPaperSection_ID}>
                    <CustomDivider />
                    <div className="row">
                      <div className="col-md-8">
                        {isPopupVisible && deletesectionmessage && sIndex === 0 && (
                          <center>
                            <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                              <div>{deletesectionmessage}</div>
                            </div>
                          </center>
                        )}
                        <CustomButton
                          variant="outlined"
                          buttonColor="var(--greyBlue)"
                          className=""
                          onClick={e => {
                            setPopup(!popup), setIndex(sIndex);
                          }}
                          style={{
                            float: 'right',
                            color: 'var(--greyBlue)',
                            backgroundColor: 'white',
                            fontSize: '15px'
                          }}
                        >
                          <Icon
                            className="material-icons-round"
                            style={{ color: 'var(--greyBlue)', marginTop: '1.5px' }}
                          >
                            delete
                          </Icon>
                          Delete Section
                          <CustomPopup
                            onClose={closeHandler}
                            showed={popup}
                            heading="Confirm delete"
                            secondheading="Are you sure you want to delete this Section?"
                            secondheading2="Once deleted, it cant be retrieved."
                          >
                            <CustomButtont
                              fontSize="14px"
                              fontWeight="300"
                              color="black"
                              size="medium"
                              style={{ justifyContent: 'space-between', marginRight: '20px' }}
                              onClick={() => {
                                handleDeleteSection(index);
                              }}
                            >
                              Delete
                            </CustomButtont>
                          </CustomPopup>
                        </CustomButton>

                        <div className="section-wrapper ">
                          <div className="row">
                            <div className="col-md-3">
                              {/* Drag Handle (Only This is Draggable) */}
                              <div className="d-flex">
                                <Heading type="5" className="mt-2" color="var(--seaBlue)">
                                  Section {+sIndex + +1}
                                </Heading>
                              </div>
                            </div>
                            {formTaskSections[sIndex].sectionName == '' &&
                            formTaskSections[sIndex].sectionDescription == '' ? (
                              <div className="col-md-9">
                                <TextArea
                                  showCharacters={false}
                                  fontSize="1.125rem"
                                  value={section.sectionName}
                                  placeholder="Add a section name"
                                  width="250px"
                                  style={{
                                    color: 'var(--deepBlue)'
                                  }}
                                  onChange={e => {
                                    let tempFormTaskSections = formTaskSections;
                                    tempFormTaskSections[sIndex].sectionName = e.target.value;
                                    setFormTaskSections(tempFormTaskSections);
                                  }}
                                />
                                {sectionNameError && (
                                  <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                                )}
                                <TextArea
                                  fontSize="0.75rem"
                                  showCharacters={false}
                                  value={section.sectionDescription}
                                  placeholder="Add a section description"
                                  className="mt-1"
                                  style={{
                                    color: 'var(--deepBlue)',
                                    fontWeight: '500'
                                  }}
                                  onChange={e => {
                                    let tempFormTaskSections = formTaskSections;
                                    tempFormTaskSections[sIndex].sectionDescription = e.target.value;
                                    setFormTaskSections(tempFormTaskSections);
                                  }}
                                />

                                {showSaveButton && (
                                  <>
                                    <CustomButton
                                      variant="outlined"
                                      buttonColor="var(--sapGreen)"
                                      className="mt-3"
                                      onClick={e => {
                                        e.stopPropagation();
                                        if (!section.sectionName.trim()) {
                                          // alert('Please fill in the section name.')
                                          setSectionNameError(true);
                                        } else {
                                          setSectionNameError(false);
                                          storeSection(sIndex, 'sectionDescription', e.target.value);
                                          // storeSection(index, 'sectionName', e.target.value)
                                          setisNewSectionForm(true);
                                          setAddSectionDisbale(false);
                                          setShowSaveButton(false);
                                        }
                                      }}
                                    >
                                      <Icon>save</Icon>
                                      Save Section
                                    </CustomButton>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div className="col-md-9">
                                <TextArea
                                  showCharacters={false}
                                  fontSize="1.125rem"
                                  value={section.sectionName}
                                  placeholder="Add a section name"
                                  width="250px"
                                  style={{
                                    color: 'var(--deepBlue)'
                                  }}
                                  onChange={e => {
                                    let tempFormTaskSections = formTaskSections;
                                    tempFormTaskSections[sIndex].sectionName = e.target.value;
                                    setFormTaskSections(tempFormTaskSections);
                                    seteditSectionIndex(section.questionPaperSection_ID);
                                  }}
                                  // onBlur={e =>
                                  //   section.isNew
                                  //     ? storeSection(sIndex, 'sectionName', e.target.value)
                                  //     : updateSection(sIndex, 'sectionName', e.target.value)
                                  // }
                                />
                                {editSectionIndex === section.questionPaperSection_ID && sectionNameError && (
                                  <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                                )}
                                <TextArea
                                  fontSize="0.75rem"
                                  showCharacters={false}
                                  value={section.sectionDescription}
                                  className="mt-1"
                                  style={{
                                    color: 'var(--deepBlue)',
                                    fontWeight: '500'
                                  }}
                                  onChange={e => {
                                    let tempFormTaskSections = formTaskSections;
                                    tempFormTaskSections[sIndex].sectionDescription = e.target.value;

                                    setFormTaskSections(tempFormTaskSections);
                                    seteditSectionIndex(section.questionPaperSection_ID);
                                  }}
                                  // onBlur={e =>
                                  //   section.isNew
                                  //     ? storeSection(sIndex, 'sectionDescription', e.target.value)
                                  //     : updateSection(sIndex, 'sectionDescription', e.target.value)

                                  // }
                                />
                                {editSectionIndex === section.questionPaperSection_ID && (
                                  <>
                                    {/* {sectionNameError && <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>} */}
                                    <CustomButton
                                      variant="outlined"
                                      buttonColor="var(--sapGreen)"
                                      className="mt-3"
                                      onClick={e => {
                                        e.stopPropagation();
                                        // storeSection(index, 'sectionDescription', e.target.value)
                                        if (!section.sectionName.trim()) {
                                          setSectionNameError(true);
                                        } else {
                                          setSectionNameError(false);
                                          updateSection(sIndex, 'sectionName', section.sectionName);
                                          updateSection(sIndex, 'sectionDescription', section.sectionDescription);
                                          setShowSaveButton(false);
                                        }
                                      }}
                                    >
                                      <Icon>save</Icon>
                                      Save Section
                                    </CustomButton>
                                  </>
                                )}
                                {editSectionIndex === section.questionPaperSection_ID && sectionmessage && (
                                  <center>
                                    <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                      <div>{sectionmessage}</div>
                                    </div>
                                  </center>
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            className="questions-list mt-3"
                            // style={{ borderLeft: '3px solid var(--greyBlue) !important' }}
                          >
                            <React.Fragment key={section.questionPaperSection_ID}>
                              {section?.questionName_array?.map((question, qIndex) => (
                                <div>
                                  <DesignQuestion
                                    key={qIndex}
                                    sectionIndex={sIndex}
                                    setSwitchValue={setSwitchValue}
                                    switchvalue={switchvalue}
                                    questionIndex={qIndex}
                                    sectionId={section.questionPaperSection_ID}
                                    questionArrayLength={section?.questionName_array?.length}
                                    question={question}
                                    questionTypes={questionTypes}
                                    ValueTypeChoices={ValueTypeChoices}
                                    handleChangeQuestionType={handleChangeQuestionType}
                                    handleDuplicateQuestion={handleDuplicateQuestion}
                                    handleEditQuestion={handleEditQuestion}
                                    handleSetEditing={handleSetEditing}
                                    handleDeleteQuestion={handleDeleteQuestion}
                                    handleProjectTaskTab={handleProjectTaskTab}
                                    isInterview={task.taskType !== 'Form'}
                                    setEditQuestionIndex={setEditQuestionIndex}
                                    setNewQuestionIndex={setNewQuestionIndex}
                                    setDeleteQuestionIndex={setDeleteQuestionIndex}
                                    enableEdit={editQuestionId == question?.question_ID}
                                    setEditQuestionId={setEditQuestionId}
                                    setAddQuestionDisable={setAddQuestionDisable}
                                    setQuestionIndex={setQuestionIndex}
                                    questionmessage={questionmessage}
                                    popupColor={popupColor}
                                    showCheckbox={true}
                                    setFormTaskSections={setFormTaskSections}
                                    formTaskSections={formTaskSections}
                                    task={task}
                                  />

                                  {questionmessage && (
                                    <center>
                                      <div
                                        style={{
                                          color: popupColor,
                                          fontWeight: 'bold',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <div>{questionmessage}</div>
                                      </div>
                                    </center>
                                  )}
                                </div>
                              ))}
                            </React.Fragment>
                          </div>

                          {newQuestionIndex === sIndex && questionmessage && (
                            <center>
                              <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                <div>{questionmessage}</div>
                              </div>
                            </center>
                          )}

                          <CustomButton
                            variant="outlined"
                            buttonColor="var(--sapGreen)"
                            color="var(--sapGreen)"
                            className="mt-5"
                            onClick={() => createQuestion(sIndex)}
                            disabled={section.isNew || addQuestionDisable}
                          >
                            <Icon>add</Icon>
                            Add Question
                          </CustomButton>

                          {isPopupVisible && deletesectionmessage && deleteSectionIndex == index + 1 && (
                            <center>
                              <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                <div>{deletesectionmessage}</div>
                              </div>
                            </center>
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          )}

          <CustomDivider />

          <CustomButton
            variant="outlined"
            buttonColor="var(--sapGreen)"
            className="mt-3"
            onClick={e => createSection()}
            disabled={addSectionDisable}
          >
            <Icon>add</Icon>
            Add New Section
          </CustomButton>
          {isNewSectionForm && sectionmessage && (
            <center>
              <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                <div>{sectionmessage}</div>
              </div>
            </center>
          )}
        </DndContext>

        <div className="d-flex flex-column align-items-center justify-content-center mt-5">
          <Heading type="4">Well done!</Heading>
          <Heading type="5">Your progress so far has been saved</Heading>
          <div className="d-flex ">
            <Link to={`/project/${projectId}/invite-recruit`}>
              <Button size="mid" className="mt-4 mr-2" buttonColor="var(--sapGreen)">
                Recruit Respondents
              </Button>
            </Link>

            <Button size="mid" className="mt-4 ml-2" buttonColor="var(--sapGreen)" onClick={handleProjectTaskNext}>
              Design {nextTask.taskType != 'Video' ? nextTask.taskType : 'Interview'}
            </Button>
            <Link to={{ pathname: '/project/' + projectId + '/field' }} style={{ marginLeft: '15px' }}>
              <Button size="mid" className="mt-4 mr-2" buttonColor="var(--sapGreen)">
                Go To Field
              </Button>
            </Link>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
}
