import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DownIndicator from '../../common/DownIndicator';
import Question from '../../common/Question';
import QuickNote from '../../common/QuickNote';
import PlusGreen from '../../images/plus-green.svg';
import { Dropdown, InputText, Radio, TextArea, Checkbox } from '../../components/form';
import { Heading } from '../../common';
import Accordion from '../../common/Accordion';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { map } from 'jquery';
import { json } from 'd3';
import { ButtonGroup, Button, Box, Typography } from '@mui/material';

const DownIndicatorCustom = ({ active, ...props }) => {
  return (
    <DownIndicator
      color={active ? 'var(--middleGreen)' : 'var(--middleGrey)'}
      iconStyle={{ fontSize: 16 }}
      {...props}
    />
  );
};

export default function GuidedScreen({
  step,
  callOn,
  id,
  respondantInfo,
  setSelectedOptionData,
  selectedOptionData,
  setSelectedTextValue,
  selectedTextValue,
  fullScreen,
  transcriptedData,
  scrollToBottom,
  setUnansweredCount,
  unansweredCount
}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [rankingItems, setRankingItems] = useState({});
  const [dropDownData, setdropDownData] = useState([]);
  const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);

  const [sectionData, setSectionData] = useState(captureguideData?.captureGuide[step - +1]);
  const projectRespondent_ID = localStorage.getItem('projectRespondent_ID') || '';
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);

  useEffect(() => {
    const checkUnansweredQuestions = () => {
      let allUnansweredQuestions = [];
      let totalUnansweredCount = 0;
      // Iterate through each section in captureGuide
      captureguideData?.captureGuide?.forEach(section => {
        const sectionUnansweredQuestions = [];
        let sectionUnansweredCount = 0;

        section?.questionName_array?.forEach(question => {
          if (question.isRequired === 1) {
            let isAnswerSet = false;

            if (question.questionType_ID === 5 || question.questionType_ID === 6) {
              isAnswerSet = selectedTextValue[question.question_ID]?.answerText;
            } else if (question.questionType_ID === 1 || question.questionType_ID === 7) {
              isAnswerSet = selectedOptionData[question.question_ID]?.choiceName_ID;
            } else if (question.questionType_ID === 2) {
              const selectedOptions = Object.values(selectedOptionData).filter(
                option => option.question_ID === question.question_ID
              );
              isAnswerSet = selectedOptions.length > 0;
            } else if (question.questionType_ID === 10) {
              isAnswerSet = question.counter > 0;
            } else if (question.questionType_ID === 4) {
              const allChoicesAnswered = question.choiceName_array.every(opt => {
                const selectedOption = selectedOptionData[opt.choiceName_ID];
                return selectedOption && selectedOption.questionLikertScale_ID !== undefined;
              });
              isAnswerSet = allChoicesAnswered;
            } else if (question.questionType_ID === 11) {
              isAnswerSet = selectedTextValue[question.question_ID]?.questionAnswer;
            } else if (question.questionType_ID === 14) {
              // Check if all ranking options have been assigned a rank
              const allOptionsRanked = question.choiceName_array.every(choice => {
                const ranking = selectedOptionData[choice.choiceName_ID];
                return ranking && ranking.questionRankScale_ID !== undefined;
              });
              isAnswerSet = allOptionsRanked;
            }

            if (!isAnswerSet) {
              sectionUnansweredQuestions.push(question);
              sectionUnansweredCount++; // Increment section-specific unanswered count
            }
          }
        });

        // Accumulate data from this section
        allUnansweredQuestions = allUnansweredQuestions.concat(sectionUnansweredQuestions);
        totalUnansweredCount += sectionUnansweredCount;
      });

      // Update states with aggregated data
      setUnansweredQuestions(allUnansweredQuestions);
      setUnansweredCount(totalUnansweredCount);
    };

    checkUnansweredQuestions();
  }, [selectedTextValue, selectedOptionData, captureguideData]);

  useEffect(() => {
    const newRankingItems = {};
    sectionData?.questionName_array?.forEach(question => {
      if (question.questionType_ID === 14) {
        // Get all choices for this question
        const choices = [...(question.choiceName_array || [])];

        // Create a map of existing rankings
        const rankingsMap = {};
        choices.forEach(choice => {
          const ranking = selectedOptionData[choice.choiceName_ID];
          if (ranking) {
            rankingsMap[choice.choiceName_ID] = ranking.questionRankScale_ID;
          }
        });

        // Sort choices based on their rankings
        const sortedChoices = choices.sort((a, b) => {
          const rankA = rankingsMap[a.choiceName_ID] || 999;
          const rankB = rankingsMap[b.choiceName_ID] || 999;
          return rankA - rankB;
        });

        newRankingItems[question.question_ID] = sortedChoices;
      }
    });
    setRankingItems(newRankingItems);
  }, [sectionData, selectedOptionData]);

  useEffect(() => {}, [selectedOption]);
  useEffect(() => {}, [dropDownData]);

  useEffect(() => {
    setSectionData(captureguideData?.captureGuide[step - 1]);
  }, [step, captureguideData]);

  useEffect(() => {
    scrollToBottom();
  }, [transcriptedData]);

  let dropDownQuestionType =
    (sectionData &&
      sectionData.questionName_array.map(item => {
        return (
          item.screenerQuestion &&
          item.screenerQuestion.map(data => {
            return { ...data, label: data.groupFilterLable, value: data.question_ID };
          })
        );
      })) ||
    [];

  const handleChange = e => {
    setdropDownData([...dropDownData, e]);
  };

  const { userId } = useSelector(state => state.LoginReducer);

  const { projectId } = useParams();
  const respondentUser_ID = respondantInfo?.Respondent_ID;

  const dispatch = useDispatch();
  const value = [];

  useEffect(() => {}, [selectedOptionData, selectedTextValue]);

  const saveQuestionAnswer = (e, question_ID, questionType_ID, questionLikertScale_ID) => {
    let payload = {};
    if (questionType_ID == 5 || questionType_ID == 6) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        answerText: e.target.value
      };
      setSelectedTextValue({
        ...selectedTextValue,
        [question_ID]: { question_ID: question_ID, answerText: e.target.value }
      });
    } else if (questionType_ID == 11) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionAnswer: e.target.value
      };
      setSelectedTextValue({
        ...selectedTextValue,
        [question_ID]: { question_ID: question_ID, questionAnswer: e.target.value }
      });
    } else if (questionType_ID == 1 || questionType_ID == 7) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionChoice_ID: e
      };
      setSelectedOptionData({
        ...selectedOptionData,
        [question_ID]: { question_ID: question_ID, choiceName_ID: e, questionLikertScale_ID: questionLikertScale_ID }
      });
    } else if (questionType_ID == 4) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionChoice_ID: e,
        questionLikertScale_ID: questionLikertScale_ID
      };
      setSelectedOptionData({
        ...selectedOptionData,
        [e]: { question_ID: question_ID, choiceName_ID: e, questionLikertScale_ID: questionLikertScale_ID }
      });
    } else if (questionType_ID == 2) {
      value.push(questionLikertScale_ID);
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionChoice_ID: value
      };
      if (e.target.checked == true) {
        setSelectedOptionData({
          ...selectedOptionData,
          [questionLikertScale_ID]: {
            question_ID: question_ID,
            choiceName_ID: questionLikertScale_ID,
            questionLikertScale_ID: questionLikertScale_ID
          }
        });
      } else {
        for (let key in selectedOptionData) {
          if (key == questionLikertScale_ID) {
            delete selectedOptionData[key];
            setSelectedOptionData({ ...selectedOptionData });
          }
        }
      }
    } else if (questionType_ID == 8) {
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append('user_ID', userId);
      formData.append('projectId', id);
      formData.append('respondentUser_ID', respondentUser_ID);
      formData.append('question_ID', question_ID);
      formData.append('questionType_ID', questionType_ID);
      formData.append('questionAnswerImage', file);

      payload = formData;
    }

    dispatch({
      type: 'REQUEST_ADDRESPONDENTANSWER',
      payload: payload
    });
  };
  const handleDragEnd = (result, questionId) => {
    if (!result.destination) return;

    const currentItems = rankingItems[questionId];
    if (!currentItems?.length) return;

    const reorderedItems = Array.from(currentItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    // Find the current question data
    const currentQuestion = sectionData?.questionName_array?.find(q => q.question_ID === questionId);

    // Create a batch of updates for all affected items
    const updates = {};
    reorderedItems.forEach((item, index) => {
      const rankScaleID = currentQuestion?.RankScaleName_array?.[index]?.questionRankScale_ID;

      // Add to batch updates
      updates[item.choiceName_ID] = {
        question_ID: questionId,
        choiceName_ID: item.choiceName_ID,
        questionRankScale_ID: rankScaleID
      };

      // Send individual update to backend
      const payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: questionId,
        questionType_ID: 14,
        questionChoice_ID: item.choiceName_ID,
        questionRankScale_ID: rankScaleID
      };

      dispatch({
        type: 'REQUEST_ADDRESPONDENTANSWER',
        payload: payload
      });
    });

    // Update local states
    setRankingItems({
      ...rankingItems,
      [questionId]: reorderedItems
    });

    // Update selected options with all changes at once
    setSelectedOptionData(prevState => ({
      ...prevState,
      ...updates
    }));
  };

  const CustomTextArea = styled(TextArea)`
    // ::placeholder,
    // ::-webkit-input-placeholder,
    // ::-moz-placeholder,
    // :-ms-input-placeholder {
    // 	color: var(--grey20);
    // 	font-weight: 400;
    // 	font-size: 10px;
    // }
  `;

  return (
    <>
      {sectionData?.questionName_array.map((question, index) =>
        question.screenerQuestion ? (
          <Question key={index} question={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}

            <Dropdown
              selectedFontSize="0.75rem"
              options={dropDownQuestionType[index]}
              selectedColor="var(--deepBlue)"
              selectedFontWeight="400"
              controlStyle={{
                minHeight: 'auto',
                width: '180px',
                '&:hover': {
                  borderColor: 'var(--deepBlue)'
                }
              }}
              valueContainerStyle={{
                padding: 0
              }}
              DropdownIndicator={props => <DownIndicatorCustom active={callOn} {...props} />}
              selectedBorderBottom={callOn ? '1px solid var(--darkGreen)' : '1px solid var(--middleGrey)'}
              onChange={e => {
                handleChange(e);
              }}
              defaultValue={dropDownData[index]}
            />
          </Question>
        ) : question.questionType_ID == 10 ? (
          <Question question={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}

            <QuickNote
              ClipsData={
                Array.isArray(question.clips) && question.counter > 0 ? question.clips[question.counter - 1] : null
              }
              // ClipsData={question.clips}
              title={question.buttonName}
              callOn={callOn}
              badge={question.counter == 0 ? '' : question.counter}
              active={
                (callOn && selectedOption == '') ||
                (callOn && selectedOption?.toLowerCase() == question?.buttonName?.toLowerCase())
              }
              questionId={question.question_ID}
              questionPaperSection_ID={sectionData.questionPaperSection_ID}
              projectRepondentVideo_ID={captureguideData?.projectRepondentVideo_ID}
              projectRespondent_ID={projectRespondent_ID}
              setSelectedOption={setSelectedOption}
            />
          </Question>
        ) : question.questionType_ID == 11 ? (
          <Question
            key={index + question.questionName}
            question={question.questionName}
            isRequired={question.isRequired}
          >
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            {callOn && respondantInfo?.isInterviewStart == 1 ? (
              <InputText
                key={index + question.question_ID}
                type="number"
                showCharacters={false}
                fontSize="1.125rem"
                value={selectedTextValue[question.question_ID]?.questionAnswer}
                placeholder="Add a value"
                width="250px"
                style={{
                  color: 'var(--deepBlue)',
                  fontSize: '0.8rem',
                  fontWeight: '400'
                }}
                onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
              />
            ) : (
              <InputText
                key={index + question.question_ID}
                type="number"
                showCharacters={false}
                fontSize="1.125rem"
                value={selectedTextValue[question.question_ID]?.questionAnswer}
                disabled
                placeholder="Add a value"
                width="250px"
                style={{
                  color: 'var(--deepBlue)',
                  fontSize: '0.8rem',
                  fontWeight: '400'
                }}
                onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
              />
            )}
          </Question>
        ) : question.questionType_ID == 4 ? (
          <Accordion title={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}

            <div className="row">
              <div className="col-md-12">
                {/* Headings Positioned Above Scale */}
                {question.likertScaleName_array?.length > 0 && (
                  <div className="d-flex justify-content-between px-3">
                    <Heading type="6" fontWeight="200" color="var(--headingColor)" fontSize="12px">
                      {`1 = ${question.likertScaleName_array[0]?.questionLikertScaleName}`}
                    </Heading>
                    <Heading type="6" fontWeight="200" color="var(--headingColor)" fontSize="12px">
                      {`${question.likertScaleName_array.length} = ${
                        question.likertScaleName_array[question.likertScaleName_array.length - 1]
                          ?.questionLikertScaleName
                      }`}
                    </Heading>
                  </div>
                )}

                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className="d-flex align-items-center mt-2 flex-wrap">
                    {/* Left-aligned choice name with word wrapping */}
                    <p
                      style={{
                        fontSize: '12px',
                        fontWeight: 200,
                        color: 'var(--headingColor)',
                        minWidth: '120px',
                        maxWidth: '150px', // Ensures wrapping
                        marginRight: '10px',
                        wordBreak: 'break-word', // Breaks long words
                        whiteSpace: 'normal' // Allows multi-line wrapping
                      }}
                    >
                      {opt.choiceName}
                    </p>

                    {/* Button Group - Responsive */}
                    <div className="d-flex justify-content-center flex-grow-1 overflow-auto">
                      <ButtonGroup variant="contained" className="flex-wrap">
                        {question.likertScaleName_array?.map((likerTOption, ltoIndex) => {
                          const isSelected =
                            likerTOption.questionLikertScale_ID ===
                            (selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID || '');

                          return (
                            <Button
                              key={ltoIndex}
                              sx={{
                                backgroundColor: isSelected ? '#B3DB1A' : 'white',
                                color: isSelected ? 'white' : '#8A8A8A',
                                minWidth: '30px',
                                padding: '6px 8px',
                                border: '1px solid #8A8A8A',
                                fontSize: '12px',
                                '&:hover': { backgroundColor: isSelected ? '#A3C710' : 'white' },
                                '&.MuiButton-root': { border: '1px solid #8A8A8A' },
                                '&:not(:first-of-type)': { borderLeft: 'none' }
                              }}
                              onClick={() =>
                                saveQuestionAnswer(
                                  opt.choiceName_ID,
                                  question.question_ID,
                                  question.questionType_ID,
                                  likerTOption.questionLikertScale_ID
                                )
                              }
                            >
                              {ltoIndex + 1}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 1 ? (
          <Accordion title={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className={`col-md-12 d-flex align-items-center my-1`}>
                    {callOn && respondantInfo?.isInterviewStart == 1 ? (
                      <Radio
                        name={question.questionName}
                        id={oIndex}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        // checked={opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID}
                        checked={opt.choiceName_ID == selectedOptionData[question.question_ID]?.choiceName_ID}
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                        onChange={e =>
                          saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID)
                        }
                      >
                        {opt.choiceName}
                      </Radio>
                    ) : (
                      <Radio
                        name={question.questionName}
                        id={oIndex}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        checked=""
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                      >
                        {opt.choiceName}
                      </Radio>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 5 || question.questionType_ID == 6 ? (
          <Question question={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {callOn && respondantInfo?.isInterviewStart == 1 ? (
                  <InputText
                    key={index + question.question_ID}
                    fontSize="0.75rem"
                    showCharacters={false}
                    placeholder="Add a description"
                    className="mt-1"
                    width={'400px'}
                    value={selectedTextValue[question.question_ID]?.answerText}
                    onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
                  />
                ) : (
                  <InputText
                    key={index + question.question_ID}
                    fontSize="0.75rem"
                    showCharacters={false}
                    placeholder="Add a description"
                    className="mt-1"
                    disabled
                    width={'400px'}
                    value={selectedTextValue[question.question_ID]?.answerText}
                    onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
                  />
                )}
              </div>
            </div>
          </Question>
        ) : question.questionType_ID == 2 ? (
          <Accordion className=" py-2 " title={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className={`col-md-12 d-flex align-items-center my-1`}>
                    {callOn && respondantInfo?.isInterviewStart == 1 ? (
                      <Checkbox
                        id={oIndex}
                        fontSize="0.875rem"
                        className="mb-0 pb-0"
                        checked={opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID}
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                        onChange={e =>
                          saveQuestionAnswer(e, question.question_ID, question.questionType_ID, opt.choiceName_ID)
                        }
                      >
                        {opt.choiceName}
                      </Checkbox>
                    ) : (
                      <Checkbox
                        id={oIndex}
                        fontSize="0.875rem"
                        className="mb-0 pb-0"
                        checked=""
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                      >
                        {opt.choiceName}
                      </Checkbox>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 7 ? (
          <Accordion title={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className={`col-md-12 d-flex align-items-center my-1`}>
                    {callOn && respondantInfo?.isInterviewStart == 1 ? (
                      <Radio
                        id={oIndex}
                        name={question.questionName}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        // checked={opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID}
                        checked={opt.choiceName_ID == selectedOptionData[question.question_ID]?.choiceName_ID}
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                        onChange={e =>
                          saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID)
                        }
                      >
                        {opt.choiceName}
                      </Radio>
                    ) : (
                      <Radio
                        id={oIndex}
                        name={question.questionName}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        checked=""
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                      >
                        {opt.choiceName}
                      </Radio>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 8 ? (
          <Question question={question.questionName} isRequired={question.isRequired}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                <img src={PlusGreen} alt="Plus Green" className="plus-green mr-2" style={{ width: 15 }} />
                <label style={{ fontWeight: 600, fontSize: 12, color: 'var(--snapGreen)', cursor: 'pointer' }}>
                  Select a file to upload
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
                  />
                </label>
              </div>
            </div>
          </Question>
        ) : question.questionType_ID == 14 ? (
          <Accordion
            title={question.questionName || 'Please rank these factors in order of importance'}
            isRequired={question.isRequired}
          >
            {question.questionDescription && question.questionDescription !== 'null' && (
              <div className="note-text">{`Note: ${question.questionDescription}`}</div>
            )}

            <DragDropContext onDragEnd={result => handleDragEnd(result, question.question_ID)}>
              <Droppable droppableId={`ranking-${question.question_ID}`}>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={{ maxWidth: '400px' }}>
                    {(rankingItems[question.question_ID] || []).map((option, optionIndex) => (
                      <Draggable
                        key={option.choiceName_ID}
                        draggableId={option.choiceName_ID.toString()}
                        index={optionIndex}
                        isDragDisabled={!callOn || respondantInfo?.isInterviewStart !== 1}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              padding: '8px 12px',
                              marginBottom: '4px',
                              background: snapshot.isDragging ? '#f3f4f6' : 'white',
                              border: '1px solid #e5e7eb',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              cursor: callOn && respondantInfo?.isInterviewStart === 1 ? 'grab' : 'default',
                              ...provided.draggableProps.style
                            }}
                          >
                            <span style={{ marginRight: '8px', color: '#666' }}>{optionIndex + 1}.</span>
                            <span style={{ flex: 1, color: 'var(--grey60)' }}>{option.choiceName}</span>
                            <span style={{ color: '#ccc' }}>⋮⋮</span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Accordion>
        ) : (
          <Question question={question.questionName} isRequired={question.isRequired}>
            {' '}
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
          </Question>
        )
      )}
      {step == 2000 && transcriptedData?.length > 0 && (
        <>
          <div>
            {transcriptedData.map((item, index) => (
              <div key={index} className="d-flex mt-2 w-100 border-bottom" style={{ fontSize: '14px' }}>
                {item && item.text != '' && (
                  <>
                    <div className="p-2 w-25" style={{ color: '#38454F', width: '15%', fontWeight: '600' }}>
                      {item.name + ' :'}
                    </div>
                    <div className="p-2 w-100" style={{ color: '#38454F' }}>
                      {item.text}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
