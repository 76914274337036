import React, { useEffect, useRef } from "react";
import { select, scaleLinear } from "d3";

export default function Likert(props) {
  const { datasets, valueType } = props;
  const svgRef = useRef();
  const wrapperRef = useRef();
  const canvasRef = useRef(null);

  // Function to generate color array based on the number of options
  const generateColors = (numOptions) => {
    const startColor = [201, 4, 3, 1.0]; // Red
    const middleColor = [166, 166, 166, 1.0]; // Gray
    const endColor = [22, 62, 102, 1.0]; // Blue

    const colors = [];
    for (let i = 0; i < numOptions; i++) {
      let factor = i / (numOptions - 1);
      if (factor <= 0.5) {
        // Interpolate between start and middle
        const interpolated = startColor.map((channel, index) =>
          Math.round(channel + factor * 2 * (middleColor[index] - channel))
        );
        colors.push(`rgba(${interpolated.join(",")})`);
      } else {
        // Interpolate between middle and end
        const interpolated = middleColor.map((channel, index) =>
          Math.round(channel + (factor * 2 - 1) * (endColor[index] - channel))
        );
        colors.push(`rgba(${interpolated.join(",")})`);
      }
    }
    return colors;
  };

  useEffect(() => {
    const svg = select(svgRef.current);
    const { width, height } = wrapperRef.current.getBoundingClientRect();

    // Extract data from datasets
    let dk = Object.keys(datasets);
    let data = [];
    for (let i = 0; i < dk.length; i++) {
      if (dk[i] !== "id" && dk[i] !== "choiceName" && dk[i] !== "mean") {
        data.push(parseInt(datasets[dk[i]]));
      }
    }

    // Calculate percentages if valueType is '%'
    let dataTemp = [];
    if (valueType === "%") {
      const sum = data.reduce((a, b) => a + b, 0);
      dataTemp = data.map((value) => Math.floor((value / sum) * 100));
    } else {
      dataTemp = data;
    }
    data = dataTemp?.reverse();

    // Calculate total for scaling
    const total = data.reduce((acc, val) => acc + val, 0);

    // Generate colors based on the number of options
    const numOptions = data.length;
    const colors = generateColors(numOptions);

    // Clear previous SVG content
    svg.selectAll("*").remove();

    // Draw bars
    svg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("fill", (d, i) => colors[i])
      .attr("class", "barreact")
      .attr("x", (d, i) => {
        if (i === 0) return 0;
        let prevSum = data.slice(0, i).reduce((acc, val) => acc + val, 0);
        return (width * prevSum) / total;
      })
      .attr("width", (d) => (width * d) / total)
      .attr("y", 0)
      .attr("height", 30);

    // Add text labels
    const gr = svg.selectAll("rect");
    const ngroup = svg.append("g");
    gr.each(function (p, j) {
      ngroup
        .append("text")
        .text(p !== 0 ? p : "")
        .attr("x", () => {
          let x = 0;
          if (j !== 0) {
            let prevSum = data.slice(0, j).reduce((acc, val) => acc + val, 0);
            x = (width * prevSum) / total;
          }
          return x + (width * p) / total / 2;
        })
        .attr("y", 18)
        .attr("text-anchor", "middle")
        .attr("font-family", "Noto Sans")
        .attr("font-size", "11px")
        .attr("fill", "white");
    });

    // Convert SVG to PNG
    let doctype =
      '<?xml version="1.0" standalone="no"?>' +
      '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
    let source = new XMLSerializer().serializeToString(
      window.document.getElementById("svg_" + props.choice)
    );

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let img = new Image({ width: width, height: height });
    img.src = "data:image/svg+xml;base64," + window.btoa(doctype + source);
    canvas.width = width;
    canvas.height = height;
    context.drawImage(img, 0, 0, width, height);

    img.onload = function () {
      context.drawImage(img, 0, 0, width, height);
      if (props.onReady != undefined) {
        props.onReady(props.index, canvas.toDataURL("image/png"), props.choice, props.datasets.mean);
      }
    };
  }, [datasets, valueType]);

  return (
    <>
      <div
        ref={wrapperRef}
        style={{ width: "100%", height: "30px", marginBottom: "1rem" }}
      >
        <svg ref={svgRef} style={{ width: "100%", height: "110%" }} id={"svg_" + props.choice}>
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="content" />
        </svg>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </>
  );
}