import styled from '@emotion/styled';
import React, { useEffect, useState, useRef } from 'react';

import AnalysisImage from './AnalysisImage';
import AnalysisVideo from './AnalysisVideo';
import { REQUEST_ANALYSIS_CLIPS_DATA } from '../../constants/analysis/analysisConstants';
import { Card, Heading, Button } from '../../common';
import copy_active from '../../images/copy_active.svg';
import copy from '../../images/copy.svg';
import AnalysisAudio from './AnalysisAudio';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import loading from '../../assets/images/loading.svg';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_CHECK_SUMMARY } from '../../constants/analysis/checkSummaryConstants';
import { useParams } from 'react-router-dom';
import { REQUEST_GENERATE_SUMMARY_DATA } from '../../constants/analysis/generateSummaryConstants';
import { ActionIcon, Icon } from '../../common';
import { Checkbox, InputText, Radio, TextArea, Switch } from '../../components/form';
//import { REQUEST_SUMMARYDATA } from '../constants/field/GetSummaryDataConstant';
import { REQUEST_SUMMARYDATA, EDIT_SUMMARYDATA } from '../../constants/field/GetSummaryDataConstant';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useInView } from 'react-intersection-observer';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import { Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const SeeButton1 = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;
  padding: 0 !important;
  padding-top: 2px !important;
  .reverse {
    transform: rotate(-180deg);
  }
`;
const SeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;

  .reverse {
    transform: rotate(-180deg);
  }
`;
const CustomTextArea = styled(TextArea)`
  ::placeholder,
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    color: var(--grey20);
    font-weight: 400;
    font-size: 10px;
  }
  .characters {
    display: none !important;
  }
`;
const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--midWhite);
  color: var(--sapGreen);
  border-radius: 3px;
  padding: 0.4rem 0.9rem;
  img {
    padding-right: 5px;
  }
  :hover {
    background: var(--midWhite);
    color: ${props => props.buttonColor};
  }

  :disabled,
  [disabled] {
    background: var(--midWhite);
    border: none;
    color: var(--middleGrey);

    :hover {
      background: var(--midWhite);
      border: none;
      color: var(--middleGrey);
      cursor: not-allowed;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  justify-content: space-between;

  // For screens smaller than or equal to 1350px
  @media (max-width: 1350px) {
    grid-template-columns: repeat(4, 1fr);
  }

  // For screens larger than 1350px
  @media (min-width: 1351px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const StyledAccordion = styled(Accordion)`
  display: block;
  border: none;
  background-color: transparent;

  .accordion-item {
    border: none;
    background-color: transparent;
  }

  .accordion-header {
    button {
      background-color: transparent;
      border: none;
    white-space: nowrap;
  }
`;

function InlineWrapperWithMargin({ children }) {
  return <span style={{ marginRight: '0.5rem' }}>{children}</span>;
}

function AnalysisQuestion({
  questionObject,
  question,
  information,
  onCopyClick,
  isSelectAll = true,
  type = 'image',
  handleCopyClick,
  data,
  dataWithoutUrl,
  tag,
  description,
  note,
  qid,
  isMp4 = false,
  filters,
  cTag,
  vType,
  // isSummary,
  preAnalysisData,
  tagsFilterdata = {},
  preSelectedGenerateSummary,
  summaryWithQid,
  isLoadingView,
  isLoadingVideo,
  setIsLoadingView,
  interviewsCompleted,
  isLoadingAudio,
  isHitsOn,
  questionPaperSection_ID
}) {
  const [selectedItems, setSelectedItems] = useState(false);
  const [addReport, setAddReport] = useState({});
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);
  // setSelectedGenerateSummary([...selectedGenerateSummary, preSelectedGenerateSummary]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let { id } = useParams();
  const loggedUser = useSelector(state => state.LoginReducer);
  const [summaryData, setSummaryData] = useState('');
  const [seeMorePrimary, setSeeMorePrimary] = useState(false);
  // var summaryData = ''
  const summaryData2 = useSelector(state => state.getSummaryReducer.Analysisdata);
  const [summary, setSummary] = useState('');
  const [sliceSummary, setSliceSummary] = useState('');
  const [answers, setAnswers] = useState([]);
  const isLoadingClips = useSelector(state => state.analysisReducer?.isLoadingVideo?.[qid]);

  const [isEditing, setIsEditing] = useState(false); // Define isEditing state
  const [editedSummary, setEditedSummary] = useState(summary);
  const [matchedAnswers, setMatchedAnswers] = useState([]);
  const questionsAnalysisData = useSelector(state => state.analysisReducer?.clipsData?.questionsAnalysisData || {});

  const questionData = questionsAnalysisData[qid];

  useEffect(() => {
    if (questionData && questionData.questionsAnalysisData?.length > 0) {
      const extractedAnswers = questionData.questionsAnalysisData
        .flatMap(q => q.questionName_array?.map(qItem => qItem.answer) || [])
        .flat();

      setAnswers(extractedAnswers);
    } else {
      setAnswers([]);
    }
  }, [questionData]);

  var isSummary = 0;
  const isGenerated = useSelector(state => state.checkSummaryReducer?.Analysisdata);
  if (isGenerated && isGenerated[qid]) {
    isSummary = isGenerated[qid].isSummary;
  } else {
    isSummary = 0;
  }

  console.log("answers",answers , data)

  useEffect(() => {
    const computedAnswers =
      questionObject.answer?.map(answerItem => {
        const matchingTags =
          questionObject.ansGetChoice
            ?.filter(choice => choice?.mediaAnswerID === answerItem?.questionAnswerImage_ID)
            ?.map(choice => ({ tag_ID: choice?.tag_ID, tagName: choice?.tag, table_ID: choice?.table_ID })) || [];
        return {
          ...answerItem,
          tags: matchingTags
        };
      }) || [];

    setMatchedAnswers(computedAnswers);
  }, [questionObject]);

  const [showAllItems, setShowAllItems] = useState(false);

  const toggleShowAllItems = () => {
    setShowAllItems(!showAllItems);
  };
  const [showMore, setShowMore] = useState(false);
  const currentAudioRef = useRef(null);
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  const [itemsToShow, setItemsToShow] = useState(10);
  const [showLess, setShowLess] = useState(false);

  const handleShowAllClick = () => {
    if (itemsToShow + 10 >= answers.length) {
      setItemsToShow(answers.length);
      setShowLess(true);
    } else {
      setItemsToShow(itemsToShow + 10);
    }
  };

  const handleShowLessClick = () => {
    setItemsToShow(10);
    setShowLess(false);
  };
  const [activeKey, setActiveKey] = useState(null);

  const handlePlay = (index, playerRef) => {
    // Pause the previous video if it's different from the current
    if (currentPlaying !== null && currentPlaying !== playerRef) {
      currentPlaying.load(); // pause the previous video
    }

    setCurrentPlaying(playerRef); // Set the currently playing video ref
  };

  const handleAudioPlay = event => {
    if (currentAudioRef.current && currentAudioRef.current !== event.target) {
      currentAudioRef.current.pause();
    }

    currentAudioRef.current = event.target;
  };
  const [showMoreResponses, setShowMoreResponses] = useState(false);

  const toggleShowMoreResponses = () => {
    setShowMoreResponses(!showMoreResponses);
  };
  const SeeLess = () => <>Show Less</>;
  const SeeMore = () => <>Show More</>;

  var mp3Flag = 0;

  const handleSave = () => {
    dispatch({
      type: EDIT_SUMMARYDATA,
      payload: {
        user_ID: loggedUser.userId,
        project_ID: id,
        question_ID: qid,
        analysis: editedSummary
      }
    });
    setSummary(editedSummary);
    dispatch({
      type: REQUEST_SUMMARYDATA,
      payload: {
        project_ID: id,
        question_ID: qid
      }
    });

    setSeeMorePrimary(false);

    setIsEditing(false);
  };

  if (questionObject?.answer?.length === 1) {
    if (
      questionObject?.answer[0]?.MP3UpdateSummaryStatus == '1' ||
      questionObject?.answer[0]?.MP3UpdateSummaryStatus === null
    ) {
      mp3Flag = 1;
    }
  } else {
    for (var i = 0; i < questionObject?.answer?.length; i++) {
      if (questionObject?.answer[i]?.MP3UpdateSummaryStatus == '1') {
        mp3Flag = 1;
      }
    }
  }

  useEffect(() => {
    if (seeMorePrimary === false) {
      var sliced = summary.slice(0, 250);
      var lastIndex = sliced.lastIndexOf(' ');

      setSliceSummary(sliced.slice(0, lastIndex));
    }

    if (seeMorePrimary === true) {
      setSliceSummary(summary);
    }
  }, [seeMorePrimary]);

  useEffect(() => {
    if (seeMorePrimary === false) {
      var sliced = editedSummary.slice(0, 250);
      var lastIndex = sliced.lastIndexOf(' ');
      setSliceSummary(sliced.slice(0, lastIndex));
    } else {
      setSliceSummary(editedSummary);
    }
  }, [editedSummary]);

  const triggerShowMore = () => {
    setShowMore(!showMore);
    // setShowMore(!showMore)
  };

  var flag = 0;
  for (var i = 0; i < answers?.length; i++) {
    if (answers[i]?.ClipUpdateStatusSummary !== '0' && answers[i]?.ClipUpdateStatusSummary !== null) {
      flag = 1;
    }
  }
  const setSummaryInChild = analysisData => {
    setSummaryData(analysisData);
  };

  const handleCheckbox = index => {
    if (selectedItems.includes(index)) {
      const tempSelectItems = [...selectedItems];
      const filterItems = tempSelectItems.filter(item => item != index);
      setSelectedItems([...filterItems]);
    } else {
      setSelectedItems(prev => [...prev, index]);
    }
  };

  useEffect(() => {}, [addReport]);

  useEffect(() => {}, [summaryData]);

  useEffect(() => {
    setSelectedGenerateSummary([...selectedGenerateSummary, preSelectedGenerateSummary]);
  }, [preSelectedGenerateSummary]);

  return (
    <div className=" question" style={{ borderBottom: '1px solid var(--greyBlue30)' }} ref={ref}>
      {inView ? (
        <StyledAccordion>
          <Accordion.Item eventKey="0">
            <div className="d-flex justify-content-between my-2" style={{ alignItems: 'center', gap: '10px' }}>
              <Accordion.Header style={{ flex: '1 1 auto', minWidth: '60%' }}>
                <Heading
                  fontSize="14px"
                  fontWeight="700"
                  color="var(--deepBlue)"
                  className="mb-0 pb-0"
                  breakHeading={false}
                  style={{
                    justifyContent: 'space-between',
                    whiteSpace: 'normal',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word'
                  }}
                  // onClick={() => setActiveKey(activeKey === '0' ? null : '0')}
                  onClick={() => {
                    const isOpening = activeKey !== '0'; // Check if accordion is opening

                    setActiveKey(isOpening ? '0' : null);

                    if (isOpening) {
                      dispatch({
                        type: REQUEST_ANALYSIS_CLIPS_DATA,
                        payload: {
                          project_ID: id,
                          AgeGroup: filters?.AgeGroup,
                          MaritalStatus: filters?.MaritalStatus,
                          Gender: filters?.Gender,
                          classificationData: filters?.classificationData,
                          UserType: filters?.UserType,
                          Geography: filters?.Geography,
                          Ownership: filters?.Ownership,
                          CustomTags: filters?.CustomTags ?? null,
                          CustomTags1: filters?.CustomTags ?? null,
                          question_ID: qid
                        }
                      });
                    }
                  }}
                >
                  {question}
                  {activeKey === '0' ? (
                    <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
                  ) : (
                    <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
                  )}
                  <span>
                    {answers.length > 0 && (
                      <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                        {answers?.length + ' Videos'}
                      </Heading>
                    )}

                    {information && type === 'audio' && (
                      <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                        {data?.length + ' Audios'}
                      </Heading>
                    )}
                    {information && type === 'image' && (
                      <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                        {data?.length + ' Photos'}
                      </Heading>
                    )}
                    {/* {information && vType && vType.length === 0 && (
                      <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                        {information}
                      </Heading>
                    )} */}
                  </span>
                </Heading>
              </Accordion.Header>
              <div style={{ flex: '0 1 auto', width: '30%', marginTop: '10px' }}>
                {answers?.length > 0 && type == 'video' && isMp4 == false && information.split('')[0] !== '0' ? (
                  <SummaryTextContainer
                    type={'video'}
                    interviewsCompleted={interviewsCompleted}
                    isLoading={isLoading}
                    summary={summary}
                    isMp4={false}
                    setIsLoadingView={setIsLoadingView}
                    isLoadingVideo={isLoadingVideo}
                    question_ID={qid}
                    id={answers[answers?.length - 1]?.projectRepondentVideo_ID}
                    project_id={id}
                    setSelectedGenerateSummary={setSelectedGenerateSummary}
                    preSelectedGenerateSummary={preSelectedGenerateSummary}
                    selectedGenerateSummary={selectedGenerateSummary}
                    setIsLoading={setIsLoading}
                    flag={flag}
                    isSummary={isSummary}
                    setSummary={setSummary}
                    setSliceSummary={setSliceSummary}
                  />
                ) : (
                  ' '
                )}

                {type == 'video' && isMp4 == true && information.split('')[0] !== '0' ? (
                  <SummaryTextContainer
                    type={'video'}
                    mp3Flag={mp3Flag}
                    interviewsCompleted={interviewsCompleted}
                    isLoading={isLoading}
                    summary={summary}
                    isMp4={true}
                    setIsLoadingView={setIsLoadingView}
                    isLoadingVideo={isLoadingVideo}
                    question_ID={qid}
                    id={data ? data[data?.length - 1]?.projectRepondentVideo_ID : 0}
                    project_id={id}
                    setSelectedGenerateSummary={setSelectedGenerateSummary}
                    preSelectedGenerateSummary={preSelectedGenerateSummary}
                    selectedGenerateSummary={selectedGenerateSummary}
                    setIsLoading={setIsLoading}
                    flag={flag}
                    isSummary={isSummary}
                    setSummary={setSummary}
                    setSliceSummary={setSliceSummary}
                  />
                ) : (
                  ' '
                )}

                {type == 'audio' && information.split('')[0] !== '0' ? (
                  <SummaryTextContainer
                    type={'audio'}
                    mp3Flag={mp3Flag}
                    interviewsCompleted={interviewsCompleted}
                    isLoading={isLoading}
                    summary={summary}
                    isMp4={false}
                    setIsLoadingView={setIsLoadingView}
                    isLoadingVideo={isLoadingVideo}
                    question_ID={qid}
                    id={data ? data[data?.length - 1]?.projectRepondentVideo_ID : 0}
                    project_id={id}
                    setSelectedGenerateSummary={setSelectedGenerateSummary}
                    preSelectedGenerateSummary={preSelectedGenerateSummary}
                    selectedGenerateSummary={selectedGenerateSummary}
                    setIsLoading={setIsLoading}
                    flag={flag}
                    isSummary={isSummary}
                    setSummary={setSummary}
                    setSliceSummary={setSliceSummary}
                  />
                ) : (
                  ' '
                )}

                {/* {console.log(summary)} */}
                {/* {isLoadingVideo == true ? (
                  <div style={{ display: 'inline' }} className="pb-0">
                    <Heading fontSize="12px" fontWeight="500" color="var(--deepBlue)" className="mb-0 pb-0 mt-0">
                      Clips Loading...
                    </Heading>
                    <img src={loading} className="loading" alt="loading" />
                  </div>
                ) : (
                  ''
                )} */}

                {/* {isLoadingAudio == true && type == 'audio' ? (
                  <div style={{ display: 'inline' }} className="pb-0">
                    <Heading fontSize="12px" fontWeight="500" color="var(--deepBlue)" className="mb-0 pb-0 mt-0">
                      Audio Notes Loading...
                    </Heading>
                    <img src={loading} className="loading" alt="loading" />
                  </div>
                ) : (
                  ''
                )} */}
              </div>

              <div className="d-flex align-items-center" style={{ flex: '0 0 auto', whiteSpace: 'nowrap' }}>
                {selectedItems == false ? (
                  <CustomButton
                    fontWeight="400"
                    fontSize="10px"
                    className="ml-0"
                    size="small"
                    variant="outlined"
                    buttonColor="var(--sapGreen)"
                    disabled={true}
                    onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
                  >
                    {true ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
                  </CustomButton>
                ) : (
                  <CustomButton
                    fontWeight="400"
                    fontSize="10px"
                    className="ml-2"
                    size="small"
                    variant="outlined"
                    buttonColor="var(--sapGreen)"
                    disabled={false}
                    onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
                  >
                    {false ? <img src={copy} /> : <img src={copy_active} />} Copy to Report
                  </CustomButton>
                )}
              </div>
            </div>

            <Accordion.Body>
              {isLoading === false && summary && selectedGenerateSummary.includes(qid) ? (
                <div>
                  <Heading
                    fontSize="14px"
                    fontWeight="700"
                    fontFamily="Noto Sans"
                    fontStyle="normal"
                    lineHeight="normal"
                    color="#38454F"
                    className="mb-0 pb-2 mt-2"
                  >
                    <b>Summary</b>
                  </Heading>

                  <div
                    style={{
                      background: 'rgb(242, 244, 244)',
                      marginBottom: '2%',
                      padding: '1%',
                      marginTop: '1 %',
                      borderRadius: '5px'
                    }}
                  >
                    {isEditing ? ( // Conditionally render textarea for editing
                      <>
                        <CustomTextArea
                          value={editedSummary}
                          onChange={e => setEditedSummary(e.target.value)}
                          rows={4}
                          showCharacters={false}
                          characterLimit={524288}
                          cols={50}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Icon onClick={handleSave} style={{ cursor: 'pointer' }}>
                            save
                          </Icon>
                        </div>
                      </>
                    ) : (
                      <>
                        {summary.length > 250 ? sliceSummary : summary}
                        {summary.length > 250 ? (
                          <SeeButton
                            style={{ display: 'inline-block' }}
                            className="mt-0"
                            simple
                            color="var(--primaryColor)"
                            fontSize="0.875rem"
                            fontWeight="500"
                            onClick={() => setSeeMorePrimary(!seeMorePrimary)}
                          >
                            {seeMorePrimary ? SeeLess() : SeeMore()}
                          </SeeButton>
                        ) : (
                          ''
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <ActionIcon
                            onClick={() => {
                              setIsEditing(true);
                              setEditedSummary(summary);
                            }}
                          >
                            create
                          </ActionIcon>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}

              {type === 'audio' && isLoadingAudio !== false ? (
                <Skeleton count={5} wrapper={InlineWrapperWithMargin} inline width={220} height={70} />
              ) : type == 'video' && isLoadingVideo !== false ? (
                <Skeleton count={5} wrapper={InlineWrapperWithMargin} inline width={220} height={70} />
              ) : type == 'image' && isLoadingView !== false ? (
                <Skeleton count={5} wrapper={InlineWrapperWithMargin} inline width={220} height={70} />
              ) : (
                <GridContainer>
                  {isLoadingClips  ? (
                    <div style={{ marginBottom: '1.5rem', width: '1200px' }}>
                      <Skeleton count={4} wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                    </div>
                  ) : answers?.length > 0 ||  data?.length > 0  ? (
                    <>
                      {type === 'video'
                        ? answers?.slice(0, itemsToShow).map((item, index) => {
                            let tagH = [];
                            item?.tags?.map(a => {
                              if (a.tag === 'Hits') {
                                tagH.push('Hits');
                              }
                              if (a.tag === 'Misses') {
                                tagH.push('Misses');
                              }
                            });

                            if (!cTag && isLoadingVideo == false && isMp4 == true && vType.length == 0) {
                              return (
                                <AnalysisVideo
                                  questionType_ID={questionObject.questionType_ID}
                                  clipDetails={item}
                                  clipWithoutUrl={dataWithoutUrl?.[index] || {}}
                                  addtag={matchedAnswers[index]}
                                  tag={item?.tags}
                                  description={description}
                                  note={item.VideoNote}
                                  imgLink={item.preAssingedImageUrl}
                                  // thumbLink={item.preAssingedImageUrl}
                                  thumbLink={item.mp4ThumbnailFilePath}
                                  dataLength={answers.length}
                                  filters={filters}
                                  tagsFilterdata={tagsFilterdata}
                                  ref={el => (videoRefs.current[index] = el)}
                                  // isPlaying={playingVideo == index}
                                  onPlay={playerRef => handlePlay(index, playerRef)}
                                  keepCheckBox={true}
                                  checkBoxValue={item.videoPresignedUrl}
                                  selectCheckBox={val => {
                                    let d = addReport;
                                    if (d.hasOwnProperty(val.vidId.toString())) {
                                      delete d[val.vidId.toString()];
                                      if (Object.keys(d).length == 0) {
                                        setSelectedItems(false);
                                      }
                                    } else {
                                      if (Object.keys(addReport).length >= 8) {
                                        console.log('Maximum 8 Videos can be exported.');
                                        return;
                                      }
                                      (d[val.vidId.toString()] = val), setSelectedItems(true);
                                    }

                                    setAddReport({ ...d });
                                  }}
                                  name={item.name}
                                  button={item.buttonName}
                                  vidId={item.questionAnswerImage_ID}
                                  qid={qid}
                                  selection={addReport}
                                  clipUpdateStatus={item.MP3UpdateSummaryStatus}
                                  Skeleton={
                                    <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                  }
                                />
                              );
                            }

                            if (!cTag && isLoadingVideo == false && isMp4 == false && vType.length == 0) {
                              return (
                                <AnalysisVideo
                                  questionType_ID={questionObject.questionType_ID}
                                  clipDetails={item}
                                  clipWithoutUrl={dataWithoutUrl?.[index] || {}}
                                  addtag={matchedAnswers[index]}
                                  tag={item?.tags}
                                  description={description}
                                  note={item.VideoNote}
                                  imgLink={item.videoPresignedUrl}
                                  thumbLink={item.thumbnailPresignedUrl}
                                  filters={filters}
                                  tagsFilterdata={tagsFilterdata}
                                  ref={el => (videoRefs.current[index] = el)}
                                  // isPlaying={playingVideo == index}
                                  onPlay={playerRef => handlePlay(index, playerRef)}
                                  checkBoxValue={item.videoPresignedUrl}
                                  dataLength={answers.length}
                                  selectCheckBox={val => {
                                    let d = addReport;

                                    if (d.hasOwnProperty(val.vidId.toString())) {
                                      delete d[val.vidId.toString()];
                                      if (Object.keys(d).length == 0) {
                                        setSelectedItems(false);
                                      }
                                    } else {
                                      (d[val.vidId.toString()] = val), setSelectedItems(true);
                                    }

                                    setAddReport({ ...d });
                                  }}
                                  name={item.name}
                                  button={item.buttonName}
                                  vidId={item.projectRepondentVideo_ID}
                                  qid={qid}
                                  selection={addReport}
                                  clipUpdateStatus={item.ClipUpdateStatus}
                                  Skeleton={
                                    <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                  }
                                />
                              );
                            }

                            if (cTag && isLoadingVideo == false && vType.length == 0) {
                              if (item?.tags?.indexOf(cTag) != -1) {
                                return (
                                  <AnalysisVideo
                                    questionType_ID={questionObject.questionType_ID}
                                    clipDetails={item}
                                    clipWithoutUrl={dataWithoutUrl?.[index] || {}}
                                    addtag={matchedAnswers[index]}
                                    tag={item?.tags}
                                    description={description}
                                    // hoverList={item.tags}
                                    note={item.VideoNote}
                                    imgLink={item.videoPresignedUrl}
                                    filters={filters}
                                    thumbLink={item.thumbnailPresignedUrl}
                                    tagsFilterdata={tagsFilterdata}
                                    ref={el => (videoRefs.current[index] = el)}
                                    // isPlaying={playingVideo == index}
                                    onPlay={playerRef => handlePlay(index, playerRef)}
                                    checkBoxValue={item.videoPresignedUrl}
                                    dataLength={answers.length}
                                    selectCheckBox={val => {
                                      let d = addReport;
                                      if (d.hasOwnProperty(val.vidId.toString())) {
                                        delete d[val.vidId.toString()];
                                        if (Object.keys(d).length == 0) {
                                          setSelectedItems(false);
                                        }
                                      } else {
                                        (d[val.vidId.toString()] = val), setSelectedItems(true);
                                      }

                                      setAddReport({ ...d });
                                    }}
                                    name={item.name}
                                    button={item.buttonName}
                                    vidId={item.projectRepondentVideo_ID}
                                    qid={qid}
                                    selection={addReport}
                                    Skeleton={
                                      <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                    }
                                  />
                                );
                              }
                            }

                            if (!cTag && isLoadingVideo == false && vType.length > 0) {
                              if (vType.indexOf(item.buttonName) != -1 || tagH.some(val => vType.includes(val))) {
                                console.log('dataWithoutUrl[index] 4', dataWithoutUrl[index]);
                                return (
                                  <AnalysisVideo
                                    questionType_ID={questionObject.questionType_ID}
                                    clipDetails={item}
                                    clipWithoutUrl={dataWithoutUrl?.[index] || {}}
                                    addtag={matchedAnswers[index]}
                                    tag={item?.tags}
                                    description={description}
                                    note={item.VideoNote}
                                    imgLink={item.videoPresignedUrl}
                                    filters={filters}
                                    thumbLink={item.thumbnailPresignedUrl}
                                    tagsFilterdata={tagsFilterdata}
                                    ref={el => (videoRefs.current[index] = el)}
                                    // isPlaying={playingVideo == index}
                                    onPlay={playerRef => handlePlay(index, playerRef)}
                                    dataLength={answers.length}
                                    checkBoxValue={item.videoPresignedUrl}
                                    selectCheckBox={val => {
                                      let d = addReport;
                                      if (d.hasOwnProperty(val.vidId.toString())) {
                                        delete d[val.vidId.toString()];
                                        if (Object.keys(d).length == 0) {
                                          setSelectedItems(false);
                                        }
                                      } else {
                                        (d[val.vidId.toString()] = val), setSelectedItems(true);
                                      }

                                      setAddReport({ ...d });
                                    }}
                                    name={item.name}
                                    button={item.buttonName}
                                    vidId={item.projectRepondentVideo_ID}
                                    qid={qid}
                                    selection={addReport}
                                    Skeleton={
                                      <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                    }
                                  />
                                );
                              }
                            }

                            if (cTag && isLoadingVideo == false && vType.length > 0) {
                              if (item.tags.indexOf(cTag) != -1 && vType.indexOf(item.buttonName) != -1) {
                                return (
                                  <AnalysisVideo
                                    questionType_ID={questionObject.questionType_ID}
                                    clipDetails={item}
                                    clipWithoutUrl={dataWithoutUrl?.[index] || {}}
                                    addtag={matchedAnswers[index]}
                                    tag={item?.tags}
                                    description={description}
                                    note={item.VideoNote}
                                    imgLink={item.videoPresignedUrl}
                                    filters={filters}
                                    thumbLink={item.thumbnailPresignedUrl}
                                    tagsFilterdata={tagsFilterdata}
                                    ref={el => (videoRefs.current[index] = el)}
                                    // isPlaying={playingVideo == index}
                                    onPlay={playerRef => handlePlay(index, playerRef)}
                                    dataLength={answers.length}
                                    checkBoxValue={item.videoPresignedUrl}
                                    selectCheckBox={val => {
                                      let d = addReport;
                                      if (d.hasOwnProperty(val.vidId.toString())) {
                                        delete d[val.vidId.toString()];
                                        if (Object.keys(d).length == 0) {
                                          setSelectedItems(false);
                                        }
                                      } else {
                                        (d[val.vidId.toString()] = val), setSelectedItems(true);
                                      }

                                      setAddReport({ ...d });
                                    }}
                                    name={item.name}
                                    button={item.buttonName}
                                    vidId={item.projectRepondentVideo_ID}
                                    qid={qid}
                                    selection={addReport}
                                    Skeleton={
                                      <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                    }
                                  />
                                );
                              }
                            }
                          })
                        : data.slice(0, itemsToShow).map((item, index) => {
                            if (isLoadingAudio === false && type === 'audio') {
                              return (
                                <AnalysisAudio
                                  key={index}
                                  imgLink={item.preAssingedImageUrl}
                                  checkBoxValue={item.preAssingedImageUrl}
                                  onPlay={handleAudioPlay}
                                  selectCheckBox={val => {
                                    let d = addReport;

                                    if (d.hasOwnProperty(val.imgId.toString())) {
                                      delete d[val.imgId.toString()];
                                      if (Object.keys(d).length == 0) {
                                        setSelectedItems(false);
                                      }
                                    } else {
                                      if (Object.keys(addReport).length >= 8) {
                                        console.log('Maximum 8 Audios can be exported.');
                                        return;
                                      }
                                      (d[val.imgId.toString()] = val), setSelectedItems(true);
                                    }

                                    setAddReport({ ...d });
                                  }}
                                  name={item.name}
                                  imgId={item.questionAnswerImage_ID}
                                  qid={qid}
                                  selection={addReport}
                                  Skeleton={
                                    <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                  }
                                />
                              );
                            }

                            return (
                              <AnalysisImage
                                key={index}
                                imgLink={item.preAssingedImageUrl}
                                checkBoxValue={item.preAssingedImageUrl}
                                selectCheckBox={val => {
                                  let d = addReport;
                                  if (d.hasOwnProperty(val.imgId.toString())) {
                                    delete d[val.imgId.toString()];
                                    if (Object.keys(d).length == 0) {
                                      setSelectedItems(false);
                                    }
                                  } else {
                                    if (Object.keys(addReport).length >= 8) {
                                      console.log('Maximum 8 photos can be exported.');
                                      return;
                                    }
                                    (d[val.imgId.toString()] = val), setSelectedItems(true);
                                  }

                                  setAddReport({ ...d });
                                }}
                                name={item.name}
                                imgId={item.questionAnswerImage_ID}
                                qid={43051}
                                selection={addReport}
                                Skeleton={
                                  <Skeleton wrapper={InlineWrapperWithMargin} inline width={220} height={140} />
                                }
                              />
                            );
                          })}
                      {/* <div>
    {data.length > 10 && (
    <SeeButton1
      className="mb-0 pb-0 ml-2"
      simple
      color="var(--primaryColor)"
      fontSize="0.750rem"
      fontWeight="500"
      onClick={toggleShowAllItems}
    >
      {showAllItems ? (
        <>
          See Less <img src={GreenDropdown} className="ml-2" />
        </>
      ) : (
        <>
          See all <img src={GreenDropdown} className="ml-2 reverse" />
        </>
      )}
    </SeeButton1>
  )}</div> */}
                    </>
                  ) : (
                    <p>No Videos/Images/Audios Found</p>
                  )}
                </GridContainer>
              )}

              <center>
                {' '}
                <br />{' '}
                <div style={{ marginLeft: '50%' }}>
                  {answers?.length > 10 && !showLess && (
                    <SeeButton1
                      className="mb-0 pb-0 ml-2"
                      simple
                      color="var(--primaryColor)"
                      fontSize="0.750rem"
                      fontWeight="500"
                      onClick={handleShowAllClick}
                    >
                      See more <img src={GreenDropdown} className="ml-2 reverse" />
                    </SeeButton1>
                  )}
                  {showLess && (
                    <SeeButton1
                      className="mb-0 pb-0 ml-2"
                      simple
                      color="var(--primaryColor)"
                      fontSize="0.750rem"
                      fontWeight="500"
                      onClick={handleShowLessClick}
                    >
                      See Less <img src={GreenDropdown} className="ml-2" />
                    </SeeButton1>
                  )}
                </div>
              </center>
            </Accordion.Body>
          </Accordion.Item>
        </StyledAccordion>
      ) : (
        <div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AnalysisQuestion;
