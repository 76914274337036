import { useState, useRef, useEffect } from "react";
import { select, scaleBand, axisBottom, axisLeft, scaleLinear, map, max, range } from "d3";
import { toPng } from "html-to-image";
import { AiOutlineSwap, AiOutlineDownload, AiOutlineCamera } from "react-icons/ai";

export default function BarChart(props) {
  const svgRef = useRef();
  const wrapperRef = useRef();

  // State for editable labels and axis direction
  const [xLabel, setXLabel] = useState("X Axis Label");
  const [yLabel, setYLabel] = useState("Y Axis Label");
  const [isEditing, setIsEditing] = useState(false);
  const [swapAxes, setSwapAxes] = useState(false); // New state for toggling axes
  const [sortDirection, setSortDirection] = useState(null); // null, 'asc', or 'desc'

  useEffect(() => {
    const svg = select(svgRef.current);

    // Determine which axes to use based on the toggle
    const x = swapAxes ? (d) => d.y : (d) => d.x;
    const y = swapAxes ? (d) => d.x : (d) => d.y;

    // Sort data if sortDirection is set
    let data = [...props.datasets];
    if (sortDirection) {
        data.sort((a, b) => {
            // Always sort by the numeric value (y) regardless of orientation
            const valA = Number(a.y);
            const valB = Number(b.y);
            
            // Apply sort direction
            if (sortDirection === 'asc') {
                return valA - valB;
            } else {
                return valB - valA;
            }
        });
    }

    const X = map(data, x);
    const Y = map(data, y);
    const marginTop = 20;
    const marginRight = 20;
    const marginBottom = 50;
    const marginLeft = 60;
    const { width, height } = wrapperRef.current.getBoundingClientRect();

    // Update domains after sorting
    const xDomain = swapAxes ? [0, max(X)] : Array.from(new Set(X));
    const yDomain = swapAxes ? Array.from(new Set(Y)) : [0, max(Y)];
    const xRange = [marginLeft, width - marginRight];
    const yRange = [height - marginBottom, marginTop];
    const xPadding = 0.1;

    // Scales and Axes
    const xScale = swapAxes ? scaleLinear(xDomain, xRange) : scaleBand(xDomain, xRange).padding(xPadding);
    const yScale = swapAxes ? scaleBand(yDomain, yRange).padding(xPadding) : scaleLinear(yDomain, yRange);
    const xAxis = swapAxes ? axisBottom(xScale).ticks(width / 80) : axisBottom(xScale).tickSizeOuter(0);
    const yAxis = swapAxes ? axisLeft(yScale).tickSizeOuter(0) : axisLeft(yScale);

    // Clear previous content
    svg.selectAll(".x-axis > *").remove();
    svg.selectAll(".y-axis > *").remove();
    svg.selectAll(".content > *").remove();

    // Render Y Axis
    svg
      .select(".y-axis")
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .append("text")
          .attr("x", -marginLeft + 10)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr("font-family", "Noto Sans")
          .text(swapAxes ? xLabel : yLabel) // Swap labels
      );

    // Render X Axis
    svg
      .select(".x-axis")
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis);

    // Render X-Axis Label
    svg
      .select(".x-axis")
      .append("text")
      .attr("x", (width - marginLeft - marginRight) / 2 + marginLeft)
      .attr("y", height - 10) // positioned below the ticks
      .attr("text-anchor", "middle")
      .attr("font-family", "Noto Sans")
      .attr("font-size", "12px")
      .attr("fill", "currentColor")
      .text(swapAxes ? yLabel : xLabel); // Swap labels

    // Render Bars
    const I = range(X.length);
    const bar = svg
      .select(".content")
      .append("g")
      .attr("fill", "rgb(35,97,160)")
      .selectAll("rect")
      .data(I)
      .join("rect")
      .attr("x", (i) => (swapAxes ? xScale(0) : xScale(X[i]) + xScale.bandwidth() / 2 - 50))
      .attr("y", (i) => (swapAxes ? yScale(Y[i]) : yScale(Y[i])))
      .attr("width", (i) => (swapAxes ? xScale(X[i]) - xScale(0) : Math.min(xScale.bandwidth(), 100)))
      .attr("height", (i) => (swapAxes ? Math.min(yScale.bandwidth(), 30) : yScale(0) - yScale(Y[i])));

    // Add value labels
    svg.select(".content")
        .append("g")
        .selectAll("text")
        .data(I)
        .join("text")
        .attr("x", (i) => {
            if (swapAxes) {
                return xScale(X[i]) + 5; // Position after the bar end for horizontal
            } else {
                return xScale(X[i]) + xScale.bandwidth() / 2; // Center on bar for vertical
            }
        })
        .attr("y", (i) => {
            if (swapAxes) {
                return yScale(Y[i]) + yScale.bandwidth() / 2 + 5; // Center on bar for horizontal
            } else {
                return yScale(Y[i]) - 5; // Position above bar for vertical
            }
        })
        .attr("text-anchor", swapAxes ? "start" : "middle")
        .attr("font-family", "Noto Sans")
        .attr("font-size", "11px")
        .attr("fill", "black")
        .text(i => Y[i]);

  }, [props, xLabel, yLabel, swapAxes, sortDirection]);

  // Handle Edit Toggle
  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  // Handle Save
  const handleSave = () => {
    setIsEditing(false);
  };

  // Handle Export
  const handleExport = () => {
    const chart = wrapperRef.current;

    toPng(chart, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "chart.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error("Error exporting chart as image:", err);
      });
  };

  // Handle Axis Swap
  const handleSwapAxes = () => {
    setSwapAxes((prev) => !prev);
  };

  // Add sort handler
  const handleSort = () => {
    setSortDirection(current => {
      if (!current) return 'asc';
      if (current === 'asc') return 'desc';
      return null;
    });
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <button
          onClick={handleEditToggle}
          style={{
            padding: "0.5rem 1rem",
            backgroundColor: "#2361a0",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {isEditing ? "Cancel" : "Edit Axis Labels"}
        </button>
        <button
          onClick={handleSort}
          style={{
            padding: "0.5rem 1rem",
            backgroundColor: "#2361a0",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {sortDirection === 'asc' ? '↑ Sort' : sortDirection === 'desc' ? '↓ Sort' : '⇅ Sort'}
        </button>
        {!isEditing && (
          <button
            onClick={handleExport}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: "#2361a0",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "48px",
              height: "38px"
            }}
          >
            <AiOutlineCamera style={{ fontSize: "1.5rem" }} />
          </button>
        )}
      </div>
      {isEditing && (
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <label style={{ fontFamily: "Noto Sans", fontSize: "0.9rem" }}>
            X Axis Label:
            <input
              type="text"
              value={xLabel}
              onChange={(e) => setXLabel(e.target.value)}
              style={{
                marginLeft: "0.5rem",
                padding: "0.3rem",
                fontSize: "0.9rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
            {/* Swap Axes Icon */}
            <AiOutlineSwap
              onClick={handleSwapAxes}
              style={{
                cursor: "pointer",
                fontSize: "1.5rem",
                color: "#2361a0",
                marginLeft:"2rem"
              }}
              title="Swap Axes"
            />
          </label>
          <label style={{ fontFamily: "Noto Sans", fontSize: "0.9rem" }}>
            Y Axis Label:
            <input
              type="text"
              value={yLabel}
              onChange={(e) => setYLabel(e.target.value)}
              style={{
                marginLeft: "0.5rem",
                padding: "0.3rem",
                fontSize: "0.9rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </label>
          <button
            onClick={handleSave}
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: "#2361a0",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Save
          </button>
        </div>
      )}
      <div
        ref={wrapperRef}
        style={{
          width: "100%",
          height: "300px",
          marginBottom: "2rem",
          margin: "0 auto",
          backgroundColor: "white"
        }}
      >
        <svg ref={svgRef} style={{ width: "100%", height: "110%" }}>
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="content" />
        </svg>
      </div>
    </>
  );
}