import React, { useState, useEffect } from 'react';
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryTooltip,
  VictoryLabel,
  VictoryLegend,
  VictoryGroup,
} from 'victory';
import { Heading, Icon, Button } from '../../common';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from '@emotion/styled';
import 'react-loading-skeleton/dist/skeleton.css'
import copy_active from '../../images/copy_active.svg';
import { useInView } from 'react-intersection-observer';
import { Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const StyledAccordion = styled(Accordion)`
  display: block;
  border: none;
  background-color: transparent;

  .accordion-item {
    border: none;
    background-color: transparent;
  }

  .accordion-header {
    button {
      background-color: transparent;
      border: none;
      white-space: nowrap;
    }
  }
`;

const VerticalStackedRankedBarChart = ({ questionObject, qid, question, information, handleCopyClick, data, filters, options, questionID, isLoadingQuestion }) => {
  const colors = [
    'rgb(8, 48, 107)',    // Darkest blue
    'rgb(10, 72, 143)',
    'rgb(20, 96, 179)',
    'rgb(40, 120, 204)',
    'rgb(70, 145, 219)',
    'rgb(100, 170, 230)',
    'rgb(135, 195, 240)',
    'rgb(170, 215, 245)',
    'rgb(200, 230, 250)',
    'rgb(230, 245, 255)'  // Lightest blue
  ];
  const CustomButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: var(--midWhite);
	color: var(--sapGreen);
	border-radius: 3px;
	padding: 0.4rem 0.9rem;
	img {
		padding-right: 5px;
	}
	:hover {
		background: var(--midWhite);
		color: ${(props) => props.buttonColor};
	}

	:disabled,
	[disabled] {
		background: var(--midWhite);
		border: none;
		color: var(--middleGrey);

		:hover {
			background: var(--midWhite);
			border: none;
			color: var(--middleGrey);
			cursor: not-allowed;
		}
	}
`;
const [addReport,setAddReport] = React.useState({
  contribution: null, graphItem: null
})
const updateReport = (selection)=>{
  setAddReport(selection)
}
const [ref, inView] = useInView({
  triggerOnce: true, 
  threshold: 0.5,    
});

  // Update legend data to include composite scores
  const legendData = data.map((choice, index) => {
    const compositeScore = questionObject?.Composite?.find(c => c.id === choice.id)?.compositeScore || 'N/A';
    return {
      name: `Score: ${compositeScore} - ${choice.choiceName}`,
      symbol: { fill: colors[index] }
    };
  });

  // Restructure data for grouped bar chart
  const transformedData = [];
  
  // First, create one category per rank (for x-axis)
  if (data.length > 0 && data[0].rankings) {
    for (let rankIndex = 0; rankIndex < data[0].rankings.length; rankIndex++) {
      data.forEach((choice, choiceIndex) => {
        transformedData.push({
          x: `Rank ${rankIndex + 1}`,  // Now using rank as x value
          y: parseInt(choice.rankings[rankIndex]?.anscnt || 0, 10),
          choiceName: choice.choiceName,
          rankNumber: rankIndex + 1,
          label: `${choice.choiceName}: ${choice.rankings[rankIndex]?.anscnt || 0}`
        });
      });
    }
  }

  // Update legend data to show choices with composite scores
  const choiceLegendData = data.map((choice, index) => {
    const compositeScore = questionObject?.Composite?.find(c => c.id === choice.id)?.compositeScore || 'N/A';
    return {
      name: `Score: ${compositeScore} - ${choice.choiceName}`,
      symbol: { fill: colors[index] }
    };
  });

  const [activeKey, setActiveKey] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [forceRender, setForceRender] = useState(0);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(prev => !prev);
    setTimeout(() => {
      setForceRender(prev => prev + 1);
    }, 100);
  };

  useEffect(() => {
    if (isAccordionOpen) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isAccordionOpen]);

  return (
    <div style={{ marginTop: '1rem' }}>
      <StyledAccordion>
        <Accordion.Item eventKey="0">
          <div className='d-flex justify-content-between align-items-start'>
            <Accordion.Header>
              <div>
                <Heading
                  fontSize='14px'
                  fontWeight='700'
                  color='var(--deepBlue)'
                  className='mb-0 pb-0'
                  breakHeading={false}
                  onClick={() => {
                    setActiveKey(activeKey === '0' ? null : '0');
                    handleAccordionToggle();
                  }}
                >
                  {question}
                  {activeKey === '0' ? (
                    <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
                  ) : (
                    <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
                  )}
                  {information && (
                    <span>
                      <Heading fontSize='12px' fontWeight='400' color='var(--deepBlue)' className='mb-0 pb-0 pl-1'>
                        {information}
                      </Heading>
                    </span>
                  )}
                </Heading>
              </div>
            </Accordion.Header>
            <div>
              <CustomButton
                fontWeight='400'
                fontSize='10px'
                size='small'
                variant='outlined'
                buttonColor='var(--sapGreen)'
                onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
              >
                <img src={copy_active} />
                {' '}
                Copy to Report
              </CustomButton>
            </div>
          </div>

          <Accordion.Body>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ maxWidth: '1600px', width: '100%' }} key={`chart-${forceRender}`}>
                <VictoryChart
                  domainPadding={{ x: 50, y: 10 }}
                  height={450}
                  width={1100}
                  padding={{ top: 50, bottom: 200, left: 60, right: 60 }}
                >
                  <VictoryLegend
                    x={50}
                    y={350}
                    orientation="horizontal"
                    gutter={20}
                    symbolSpacer={5}
                    itemsPerRow={3}
                    style={{ 
                      labels: { 
                        fontSize: 10, 
                        dy: -1,
                        fill: 'var(--paraColor)'
                      },
                      fontFamily: 'Noto Sans'
                    }}
                    data={choiceLegendData}
                  />
                  <VictoryAxis
                    label="Ranks"
                    style={{
                      axis: { stroke: 'var(--grey20)' },
                      grid: { stroke: 'var(--grey20)', strokeDasharray: '5,5' },
                      tickLabels: { 
                        fontSize: 11, 
                        padding: 8,
                        fill: 'var(--paraColor)',
                        angle: -45,
                        textAnchor: 'end'
                      },
                      axisLabel: { 
                        fontSize: 12, 
                        padding: 85,
                        fill: 'var(--paraColor)'
                      },
                      fontFamily: 'Noto Sans'
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    label="Number of Users"
                    style={{
                      axis: { stroke: 'var(--grey20)' },
                      grid: { stroke: 'var(--grey20)', strokeDasharray: '5,5' },
                      tickLabels: { 
                        fontSize: 11, 
                        padding: 8,
                        fill: 'var(--paraColor)'
                      },
                      axisLabel: { 
                        fontSize: 12, 
                        padding: 40,
                        fill: 'var(--paraColor)'
                      },
                      fontFamily: 'Noto Sans'
                    }}
                  />
                  {/* Secondary axis for composite scores */}
                  <VictoryAxis
                    tickValues={data.map((_, i) => i)}
                    tickFormat={(t) => {
                      const choice = data[t];
                      if (!choice) return "";
                      const compositeScore = questionObject?.Composite?.find(c => c.id === choice.id)?.compositeScore || 'N/A';
                      return `Score: ${compositeScore}`;
                    }}
                    style={{
                      axis: { stroke: "transparent" },
                      tickLabels: { 
                        fontSize: 10, 
                        padding: 15,
                        fill: 'var(--deepBlue)',
                        fontWeight: 'bold'
                      }
                    }}
                    orientation="bottom"
                    offsetY={110}
                  />
                  {/* Grouped bar chart with joined bars */}
                  <VictoryGroup
                    offset={8}
                    alignment="start"
                    colorScale={colors.slice(0, data.length)}
                    style={{
                      data: {
                        stroke: "var(--white)",
                        strokeWidth: 1,
                        cursor: "pointer",
                      }
                    }}
                  >
                    {data.map((choice, choiceIndex) => (
                      <VictoryBar
                        key={choiceIndex}
                        data={transformedData.filter(d => d.choiceName === choice.choiceName)}
                        labels={({ datum }) => datum.label}
                        labelComponent={
                          <VictoryTooltip
                            style={{ fontSize: 10, fill: "var(--white)", fontFamily: 'Noto Sans' }}
                            flyoutStyle={{ 
                              fill: "var(--mediumGrey)", 
                              stroke: "var(--white)", 
                              strokeWidth: 1 
                            }}
                            cornerRadius={5}
                            pointerLength={10}
                          />
                        }
                        barWidth={8}
                        barRatio={0.9}
                      />
                    ))}
                  </VictoryGroup>
                </VictoryChart>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
      <div style={{ borderBottom: '1px solid var(--greyBlue30)' }}></div>
    </div>
  );
};

export default VerticalStackedRankedBarChart;