import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import video_play from '../../images/video_play.svg';
import analysis_video from '../../images/analysis_video.png';
import note_blue from '../../images/note_blue.svg';
import tag_grey from '../../images/tag_grey.svg';
import { Player, ControlBar, BigPlayButton, VolumeMenuButton, PlayToggle } from 'video-react';
import 'video-react/dist/video-react.css';

import editIcon from '../../images/editDisable.svg';
import editGreem from '../../images/edit-green.svg';
import deleteGreen from '../../images/delete-green.svg';
import { Button, Icon } from '../../common';
import EditingTag from '../Project/EditingTagAnalysis';

import styled from '@emotion/styled';
import { Heading } from '../../common';
import { Checkbox } from '../../components/form';
import useWindowDimensions from '../../common/useWindowDimensions';
import { useInView } from 'react-intersection-observer';

const Container = styled.div`
  background: var(--midWhite);
  // margin: 0.5rem;
  margin-left: 0;
  // margin: 0.5rem 0;
  // margin-bottom: 1rem;
  // margin-right: 0.5rem;
  .image {
    // width: 14rem;
  }

  .video-react.video-react-fluid {
    padding-top: 70% !important;
  }

  .video-react .video-react-video {
    height: 100%;
  }
  label {
    margin-bottom: 0 !important;
  }
  .img {
    position: relative;
  }
  .video_play {
    position: absolute;
    left: 40%;
    top: 38%;
  }
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .imgContainer {
      position: relative;
      background: var(--darkWhite);
      margin-left: 5px;
      padding: 10px;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .popup {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18);
        border-radius: 5px;
        background: var(--white);
        font-size: 10px;
        font-weight: 500;
        color: var(--deepBlue);
        padding: 0.5rem;
        position: absolute;
        width: 180px;
        top: 29px;
        right: -10px;
        z-index: 1080;
        display: block;
      }
    }
  }
`;

const PrimaryButton = styled(Button)`
  padding: 2px 4px;
  &:hover {
    color: var(--darkGrey);
    span {
      color: var(--darkGrey);
    }
  }
  &:focus {
    bakground: red;
    box-shadow: none;
    background: transparent !important;
    color: var(--middleGreen);

    :hover {
      background: var(--middleGreen) !important;
      color: var(--darkGrey);
      span {
        color: var(--darkGrey);
      }
    }
  }

  &.time {
    color: var(--middleGrey);
    background: #101010;
    border: 0.5px solid #8a8a8a;
  }

  :disabled,
  [disabled] {
    color: var(--mediumGrey);
    border: 1px solid var(--mediumGrey);
    background: transparent;
    span {
      color: var(--mediumGrey);
    }
    :hover {
      box-shadow: none;
      background: transparent;
      color: var(--mediumGrey);
      cursor: not-allowed;
    }
  }
`;

const CustomButton = styled(Button)`
  color: ${props => (props.disabled ? 'var(--lightGrey)' : props.buttonColor)};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};

  &:focus {
    background: transparent !important;
    &:hover {
      color: var(--white);
    }
  }
  &:hover {
    color: var(--quadColor);
    background: transparent !important;
    box-shadow: none;
  }
  &.time {
    background: #101010;
    border: 0.5px solid #8a8a8a;
  }

  :disabled,
  [disabled] {
    color: var(--mediumGrey);
    border: 1px solid var(--mediumGrey);
    background: transparent;
    :hover {
      box-shadow: none;
      background: transparent;
      color: var(--mediumGrey);
      cursor: not-allowed;
    }
  }
`;

const TextArea = styled.textarea`
  border: 0;
  background: transparent;
  resize: none;
  font-size: 11px;
  font-weight: 500;
  width: 90%;
  max-width: 90%;  
  min-width: 150px;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word; 
  white-space: pre-wrap;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  &:focus-visible {
    border: 0;

    outline: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  border-bottom: ${props => (props.disabled ? '1px solid transparent' : '1px solid var(--middleGreen)')};
  textarea {
    min-width: 150px;
    flex: 1;
  }
  span {
    align-self: flex-end;
    margin-bottom: 0.5rem;
  }
  .icon {
    cursor: pointer;
    flex-shrink: 0;
  }
`;

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  margin-top: 5px;
`;

const TagButton = styled(CustomButton)`
  border: none;
  display: flex;
  align-items: center;
  color: #8a8a8a;
  padding: 4px 8px;

  span {
    font-size: 17px;
    color: #8a8a8a;
    padding-right: 3px;
  }
`;

const EditInputForm = ({
  className,
  disabled,
  value,
  filters,
  projectId,
  enableDisableNoteEdit,
  noteUpdated,
  clipData
}) => {
  const [text, setText] = useState(value);
  const noteForm = useRef();
  const dispatch = useDispatch();
  const loggedUser = useSelector(state => state.LoginReducer);

  useEffect(() => {
    noteForm.current.value = clipData.VideoNote ? clipData.VideoNote : '';
  }, [clipData]);

  const handleSubmit = e => {
    e.preventDefault();
    const data1 = {
      user_ID: loggedUser.userId,
      videoNote: noteForm.current.value,
      ...(clipData?.projectRepondentVideo_ID
        ? { clip_ID: clipData.projectRepondentVideo_ID, questionType_ID: 10 }
        : { clip_ID: clipData.questionAnswerImage_ID, questionType_ID: 13 }),
      ...(clipData?.projectRepondentVideo_ID
        ? { clip_ID: clipData.questionAnswerImage_ID, questionType_ID: 15 }
        : {})
    };
    
    dispatch({ type: 'REQUEST_ADDVIDEONOTE', payload: data1 });
    setTimeout(() => {
      dispatch({
        type: 'REQUEST_ANALYSIS_CLIPS_WITHOUT_URL',
        payload: {
          project_ID: projectId,
          AgeGroup: filters['AgeGroup'],
          MaritalStatus: filters['MaritalStatus'],
          Gender: filters['Gender'],
          classificationData: filters['classificationData'],
          UserType: filters['UserType'],
          Geography: filters['Geography'],
          Ownership: filters['Ownership'],
          CustomTags: filters['CustomTags']
        }
      });
    }, 500);
    noteUpdated(clipData, noteForm.current.value);
    enableDisableNoteEdit();
  };

  return (
    <div>
      {
        <FormContainer className={`${className} ${!disabled && 'mb-2'}`} disabled={disabled}>
          <form onSubmit={handleSubmit} style={{ width: '90%' }}>
            <TextArea
              disabled={disabled}
              defaultValue={value}
              rows={!disabled && 1}
              placeholder="Add a new note"
              onChange={e => setText(e.target.value)}
              ref={noteForm}
            />
          </form>
          {!disabled && (
            <div onClick={handleSubmit} className="icon">
              <IconCustom color="var(--sapGreen)" style={{ height: '8px' }}>
                subdirectory_arrow_left
              </IconCustom>
            </div>
          )}
        </FormContainer>
      }
    </div>
  );
};

function AnalysisVideo({
  
  addtag,
  clipWithoutUrl,
  imgLink,
  filters,
  tagsFilterdata,
  tag,
  onPlay,
  note,
  selectCheckBox,
  thumbLink,
  name,
  button,
  vidId,
  selection,
  clipUpdateStatus,
  keepCheckBox = true,
  Skeleton,
  questionType_ID
}) {
  const { width } = useWindowDimensions();
  const playerRef = useRef(null);
  const PlayToggleRef = useRef(null);
  const VolumeMenuButtonRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  const [isTagPopupVisible, setIsTagPopupVisible] = useState(false);
  const [isNotePopupVisible, setIsNotePopupVisible] = useState(false);
  const [clipData, setClipData] = useState({ tags: [] });

  useEffect(() => {
    setClipData(prev => ({
      ...(clipWithoutUrl || {}),
      ...(addtag || {}),
      tags: [
        ...(clipWithoutUrl?.tags
          ? clipWithoutUrl.tags.map(tag => ({
              tag_ID: tag.tag_ID,
              tagName: tag.tag || tag.tagName,
              table_ID: tag.table_ID
            }))
          : []),
        ...(addtag?.tags?.map(tag => ({
          ...tag,
          table_ID: tag.table_ID || null
        })) || [])
      ]
    }));
  }, [clipWithoutUrl, addtag]);

  const [enableNoteEdit, setEnableNoteEdit] = useState(true);
  const [addNewNote, setAddNewNote] = useState(false);
  const loggedUser = useSelector(state => state.LoginReducer);
  const projectId = useSelector(state => state.ProjectReducer.project_ID);
  const dispatch = useDispatch();

  const handlePlay = () => {
    if (playerRef.current) {
      onPlay(playerRef.current.video.video);
    }
  };
  const handleAddNote = () => {
    setAddNewNote(true);
    enableDisableNoteEdit();
  };

  useEffect(() => {
    if (PlayToggleRef.current?.button) {
      const playControlButton = PlayToggleRef.current.button;

      playControlButton.style.width = '2em';
    }
  }, []);
  const enableDisableNoteEdit = () => {
    setEnableNoteEdit(!enableNoteEdit);
  };

  const noteUpdated = (clipData, note, type) => {
    if (type && type === 'delete') {
      setClipData({ ...clipData, VideoNote: null });
    } else {
      setClipData({ ...clipData, VideoNote: note });
    }
  };

  const updateTagList = updatedTagList => {
    const updatedClipData = { ...clipData, tags: updatedTagList };
    window.isTagAdded = true;
    window.dispatchEvent(new Event('tagAdded'));
    setClipData(updatedClipData);
  };

  return (
    <div ref={ref}>
      {inView ? (
        <Container
          id={clipUpdateStatus}
          // style={{ marginRight: width < 1344 ? 0 : width / 70  }}
        >
          {/* <div className='img'>
          <img src={video_play} className='video_play' />
          <img
            src={imgLink}
            className='image'
            style={{ width: '100%' }}
          />
        </div> */}
          <div className="img">
            <Player
              // src={'https://maverickapi.estonetech.in/'+imgLink}
              src={imgLink}
              ref={playerRef}
              onPlay={handlePlay}
              poster={thumbLink}
              preload="none"
              fluid="false"
            >
              <BigPlayButton position="center" />
              <ControlBar autoHide={false}>
                <PlayToggle ref={PlayToggleRef} order={1} />
                <VolumeMenuButton vertical order={2} />
              </ControlBar>
            </Player>
          </div>

          <div className="d-flex p-2 justify-content-between">
            <div className="d-flex align-items-center">
              {keepCheckBox ? (
                <Checkbox
                  color="var(--deepBlue)"
                  fontSize="10px"
                  iconSize="10px"
                  inactiveColor="var(--white)"
                  value={vidId}
                  checked={Object.keys(selection)?.indexOf(vidId?.toString()) != -1}
                  onChange={ev => {
                    selectCheckBox({
                      path: imgLink,
                      thumb: thumbLink,
                      name: name,
                      vidId: vidId,
                      qtype: 10
                    });
                  }}
                />
              ) : (
                ''
              )}
              <div className="pl-1">
                <Heading fontSize="10px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0">
                  {name}
                </Heading>
                <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0">
                  {button}
                </Heading>
              </div>
            </div>

            <div className="iconContainer">
              <div
                className="iconPopupWrapper"
                onMouseEnter={() => {
                  setIsTagPopupVisible(true);
                  setIsNotePopupVisible(false);
                }}
                onMouseLeave={() => {
                  setIsTagPopupVisible(false);
                }}
              >
                <div className="imgContainer">
                  <img src={tag_grey} />
                  {isTagPopupVisible && (
                    <div className="popup">
                      <EditingTag
                        singleValueStyle={{
                          fontSize: '10px',
                          fontWeight: '700',
                          color: 'var(--greyBlue)'
                        }}
                        tagColor="var(--greyBlue)"
                        addTagButtonColor="#8A8A8A"
                        tagList={clipData?.tags}
                        onUpdateTagList={updateTagList}
                        filters={filters}
                        clipData={clipData}
                        Options={tagsFilterdata?.CustomTags}
                        questionType_ID={questionType_ID}
                        AddTagButton={() => (
                          <TagButton
                            fontSize="12px"
                            fontWeight="300"
                            variant="outlined"
                            buttonColor="#8A8A8A"
                            color="#8A8A8A"
                            // className='ml-3'
                          >
                            <Icon>add</Icon>
                          </TagButton>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                className="iconPopupWrapper"
                onMouseEnter={() => {
                  setIsNotePopupVisible(true);
                }}
                onMouseLeave={() => {
                  setIsNotePopupVisible(false);
                }}
              >
                <div className="imgContainer">
                  <img src={note_blue} />
                  {isNotePopupVisible && (
                    <div className="popup">
                      {clipData && !clipData.VideoNote && (
                        <PrimaryButton
                          fontWeight="700"
                          fontSize="10px"
                          size="small"
                          variant="outlined"
                          className="px-0 py-0 pr-1  d-inline-flex align-items-center "
                          borderRadius="3px"
                          buttonColor="var(--middleGreen)"
                          onClick={handleAddNote}
                        >
                          <Icon style={{ fontSize: '16px', marginRight: '3px' }} color="var(--middleGreen)">
                            add
                          </Icon>{' '}
                          Add Note
                        </PrimaryButton>
                      )}
                      {clipData && clipData.VideoNote !== null && (
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <Heading
                              type={3}
                              fontSize="9px"
                              fontWeight="500"
                              color="var(--middleGrey)"
                              className="mb-0 pb-0"
                            >
                              Note
                            </Heading>
                            <div className="d-flex align-items-center">
                              <img
                                src={enableNoteEdit ? editIcon : editGreem}
                                style={{ cursor: 'pointer', marginRight: '5px' }}
                                onClick={() => {
                                  enableDisableNoteEdit();
                                }}
                              />
                              <img
                                style={{ cursor: 'pointer' }}
                                src={deleteGreen}
                                onClick={
                                  () => {
                                    dispatch({
                                      type: 'REQUEST_DELETEVIDEONOTE',
                                      payload: {
                                        user_ID: loggedUser.userId,
                                        ...(clipData?.projectRepondentVideo_ID
                                          ? { videoNotes_ID: clipData.projectRepondentVideo_ID, questionType_ID: 10 }
                                          : clipData?.questionAnswerImage_ID
                                            ? { videoNotes_ID: clipData.questionAnswerImage_ID, questionType_ID: 13 }
                                            : { videoNotes_ID: clipData.questionAnswerImage_ID, questionType_ID: 15 })
                                      }
                                    });

                                    setTimeout(() => {
                                      dispatch({
                                        type: 'REQUEST_ANALYSIS_CLIPS_WITHOUT_URL',
                                        payload: {
                                          project_ID: projectId,
                                          AgeGroup: filters['AgeGroup'],
                                          MaritalStatus: filters['MaritalStatus'],
                                          Gender: filters['Gender'],
                                          classificationData: filters['classificationData'],
                                          UserType: filters['UserType'],
                                          Geography: filters['Geography'],
                                          Ownership: filters['Ownership'],
                                          CustomTags: filters['CustomTags']
                                        }
                                      });
                                    }, 2500);
                                
                                    noteUpdated(clipData, null, 'delete');
                                  }
                                  
                                }
                              />
                            </div>
                          </div>
                          <EditInputForm
                            className="mb-0 pb-0 mt-2"
                            disabled={enableNoteEdit}
                            value={clipData.VideoNote}
                            clipData={clipData}
                            filters={filters}
                            projectId={projectId}
                            enableDisableNoteEdit={enableDisableNoteEdit}
                            noteUpdated={noteUpdated}
                           
                          />
                        </div>
                      )}
                      {addNewNote && !clipData?.VideoNote && (
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                          
                          </div>
                          <EditInputForm
                            className="mb-0 pb-0 mt-2"
                            disabled={enableNoteEdit}
                            value={clipData.VideoNote}
                            filters={filters}
                            projectId={projectId}
                            enableDisableNoteEdit={enableDisableNoteEdit}
                            noteUpdated={noteUpdated}
                            clipData={clipData}
                           
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        Skeleton
      )}
    </div>
  );
}

export default AnalysisVideo;
