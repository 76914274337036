export const REQUEST_CREATE_PROJECT = 'REQUEST_CREATE_PROJECT';
export const CREATE_PROJECT_RECEIVED = 'CREATE_PROJECT_RECEIVED';
export const REQUEST_EDIT_PROJECT = 'REQUEST_EDIT_PROJECT';
export const CLEAR_EDIT_PROJECT = 'CLEAR_EDIT_PROJECT';
export const EDIT_PROJECT_RECEIVED = 'EDIT_PROJECT_RECEIVED';

export const REQUEST_DELETE_REFERRAL = 'REQUEST_DELETE_REFERRAL';
export const DELETE_REFERRAL_RECEIVED = 'DELETE_REFERRAL_RECEIVED';

export const PRE_PROJECT_DATA = 'PRE_PROJECT_DATA';
export const PRE_PROJECT_DATA_RECIEVED = 'PRE_PROJECT_DATA_RECIEVED';
export const DELETE_PROJECT_IMAGE = 'DELETE_PROJECT_IMAGE';
export const REQUEST_GET_PROJECT_TASK = 'REQUEST_GET_PROJECT_TASK';
export const PROJECT_TASK_RECEIVED = 'PROJECT_TASK_RECEIVED';
export const DELETE_PROJECT_TASK = 'DELETE_PROJECT_TASK';
export const EDIT_PROJECT_TASK = 'EDIT_PROJECT_TASK';

export const ADD_PROJECT_TASK = 'ADD_PROJECT_TASK';
export const CREATE_PROJECT_LEAD = 'CREATE_PROJECT_LEAD';
export const ADD_PROJECT_RESEARCHER = 'ADD_PROJECT_RESEARCHER';
export const ADD_PROJECT_OBSERVER = 'ADD_PROJECT_OBSERVER';
export const GET_CRITERIA_AND_BUDGET = 'GET_CRITERIA_AND_BUDGET';
export const RECIEVED_GET_CRITERIA_AND_BUDGET = 'RECIEVED_GET_CRITERIA_AND_BUDGET';
export const CLEAR_CRITERIS_AND_BUDGET_STATUS = 'CLEAR_CRITERIS_AND_BUDGET_STATUS';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const GET_RESOLUTION = 'GET_RESOLUTION';
export const RECIEVED_GET_RESOLUTION = 'RECIEVED_GET_RESOLUTION';
export const GET_PARTICIPANTS_LIST = 'GET_PARTICIPANTS_LIST';
export const RECIEVED_GET_PARTICIPANTS_LIST = 'RECIEVED_GET_PARTICIPANTS_LIST';
export const SEND_PARTICIPANTS_LIST_ONEND = 'SEND_PARTICIPANTS_LIST_ONEND';
export const SEND_PARTICIPANTS_LIST_RECIEVED_ONEND = 'SEND_PARTICIPANTS_LIST_RECIEVED_ONEND';
export const REMOVE_VIDEO_CALL = 'REMOVE_VIDEO_CALL';
export const RECIEVED_REMOVE_VIDEO_CALL = 'RECIEVED_REMOVE_VIDEO_CALL';

export const EDIT_SECTION = 'EDIT_SECTION';
export const RECIEVED_EDIT_SECTION = 'RECIEVED_EDIT_SECTION';

export const ADD_SECTION = 'ADD_SECTION';
export const RECIEVED_ADD_SECTION = 'RECIEVED_ADD_SECTION';

export const DRAG_QUESTION = 'DRAG_QUESTION';
export const RECIEVED_DRAG_QUESTION = 'RECIEVED_DRAG_QUESTION';

export const RECIEVED_DRAG_SECTION = 'RECIEVED_DRAG_SECTION';
export const DRAG_SECTION = 'DRAG_SECTION';

export const DRAG_QUESTION_ACROSS_SECTIONS = 'DRAG_QUESTION_ACROSS_SECTIONS';
export const RECEIVED_DRAG_QUESTION_ACROSS_SECTIONS = 'RECEIVED_DRAG_QUESTION_ACROSS_SECTIONS';

export const COUNT_NO_OF_PARTICIPANTS = 'ssCOUNT_NO_OF_PARTICIPANTS';
export const RECIEVED_COUNT_NO_OF_PARTICIPANTS = 'RECIEVED_COUNT_NO_OF_PARTICIPANTS';

RECIEVED_COUNT_NO_OF_PARTICIPANTS;

export const VIDEO_RECORDING = 'VIDEO_RECORDING';
export const RECIEVED_VIDEO_RECORDING = 'RECIEVED_VIDEO_RECORDING';

export const VIDEO_RECORDING_RESOURCE = 'VIDEO_RECORDING_RESOURCE';
export const RECIEVED_VIDEO_RECORDING_RESOURCE = 'RECIEVED_VIDEO_RECORDING_RESOURCE';

export const QUERY_VIDEO_RECORDING = 'QUERY_VIDEO_RECORDING';
export const RECIEVED_QUERY_VIDEO_RECORDING = 'RECIEVED_QUERY_VIDEO_RECORDING';

export const UPDATELAYOUT_VIDEO_RECORDING = 'UPDATELAYOUT_VIDEO_RECORDING';
export const RECIEVED_UPDATELAYOUT_VIDEO_RECORDING = 'RECIEVED_UPDATELAYOUT_VIDEO_RECORDING';

export const STOP_VIDEO_RECORDING = 'STOP_VIDEO_RECORDING';
export const RECIEVED_STOP_VIDEO_RECORDING = 'RECIEVED_STOP_VIDEO_RECORDING';

export const RESUME_DATA = 'RESUME_DATA';
export const RECIEVED_RESUME_DATA = 'RECIEVED_RESUME_DATA';

export const SCHEDULE_CALL = 'SCHEDULE_CALL';
export const RECIEVED_SCHEDULE_CALL = 'RECIEVED_SCHEDULE_CALL';

export const REQUEST_QUESTION_EDIT = 'REQUEST_QUESTION_EDIT';
export const QUESTION_EDIT_RECEIVED = 'QUESTION_EDIT_RECEIVED';

export const REQUEST_UPDATE_PROJECT_SCREENER = 'REQUEST_UPDATE_PROJECT_SCREENER';

export const REQUEST_ADD_SCREENER_VALIDATION_QUESTION = 'REQUEST_ADD_SCREENER_VALIDATION_QUESTION';
export const REQUEST_EDIT_SCREENER_ANSWER = 'REQUEST_EDIT_SCREENER_ANSWER';
export const EDIT_SCREENER_ANSWER_RECEIVED = 'EDIT_SCREENER_ANSWER_RECEIVED';
export const REQUEST_ADD_QUESTION_TAGS = 'REQUEST_ADD_QUESTION_TAGS';
export const REQUEST_DELETE_QUESTION_TAG = 'REQUEST_DELETE_QUESTION_TAG';

export const REQUEST_VIDEO_QUESTION_EDIT = 'REQUEST_VIDEO_QUESTION_EDIT';
export const REQUEST_VIDEO_QUESTION_RECEIVED = 'REQUEST_VIDEO_QUESTION_RECEIVED';

export const REQUEST_GET_VIDEO_TASK = 'REQUEST_GET_VIDEO_TASK';
export const VIDEO_TASK_RECEIVED = 'VIDEO_TASK_RECEIVED';

export const REQUEST_DELETE_QUESITON = 'REQUEST_DELETE_QUESITON';
export const DELETE_QUESITON_RECEIVED = 'DELETE_QUESITON_RECEIVED';
export const REQUEST_DELETE_SECTION = 'REQUEST_DELETE_SECTION';
export const DELETE_SECTION_RECEIVED = 'DELETE_SECTION_RECEIVED';

export const REQUEST_ADD_QUESITON = 'REQUEST_ADD_QUESITON';
export const REQUEST_SHOW_ScreenerValidation = 'REQUEST_SHOW_ScreenerValidation';
export const REQUEST_EDIT_AUTOMATED_SCREENER = 'REQUEST_EDIT_AUTOMATED_SCREENER';
export const EDIT_AUTOMATED_SCREENER_RECEIVED = 'EDIT_AUTOMATED_SCREENER_RECEIVED';
export const SHOW_ScreenerValidation_Recieved = 'SHOW_ScreenerValidation_Recieved';
export const ADD_QUESITON_RECEIVED = 'ADD_QUESITON_RECEIVED';

export const ADD_NEW_PROJECT_TASK = 'ADD_NEW_PROJECT_TASK';
export const NEW_PROJECT_TASK_RECIEVED = 'NEW_PROJECT_TASK_RECIEVED';

export const REQUEST_SCHEDULE_DATES = 'REQUEST_SCHEDULE_DATES';
export const REQUEST_SCHEDULE_DATES_RECEIVED = 'REQUEST_SCHEDULE_DATES_RECEIVED';

export const ADD_SCHEDULE_DATE = 'ADD_SCHEDULE_DATE';
export const ADD_SCHEDULE_DATE_RECEIVED = 'ADD_SCHEDULE_DATE_RECEIVED';
export const RESET_ADD_SLOT_STATUS = 'RESET_ADD_SLOT_STATUS';
export const RESET_DELETE_SLOT_STATUS = 'RESET_DELETE_SLOT_STATUS';

export const EDIT_SCHEDULE_DATE = 'EDIT_SCHEDULE_DATE';
export const EDIT_SCHEDULE_DATE_RECEIVED = 'EDIT_SCHEDULE_DATE_RECEIVED';

export const DELETE_SCHEDULE_DATE = 'DELETE_SCHEDULE_DATE';
export const DELETE_SCHEDULE_DATE_RECEIVED = 'DELETE_SCHEDULE_DATE_RECEIVED';

export const UPDATE_DURATION = 'UPDATE_DURATION';
export const RECIEVED_UPDATE_DURATION = 'RECIEVED_UPDATE_DURATION';

export const ADD_PROJECT_GROUP = 'ADD_PROJECT_GROUP';
export const EDIT_PROJECT_GROUP = 'EDIT_PROJECT_GROUP';
export const DELETE_PROJECT_GROUP = 'DELETE_PROJECT_GROUP';
export const PROJECT_GROUP_RECEIVED = 'PROJECT_GROUP_RECEIVED';
export const CREATE_BUDGET = 'CREATE_BUDGET';
export const SAMPLE_AND_BUDGET_RECEIVED = 'SAMPLE_AND_BUDGET_RECEIVED';
export const ASSIGN_SAMPLE_AND_BUDGET = 'ASSIGN_SAMPLE_AND_BUDGET';
export const DEASSIGN_SAMPLE_AND_BUDGET = 'DEASSIGN_SAMPLE_AND_BUDGET';

export const VIDEO_USERLIST = 'VIDEO_USERLIST';
export const RECIEVED_VIDEO_USERLIST = 'RECIEVED_VIDEO_USERLIST';

export const REQUEST_GETUIDDETAILS = 'REQUEST_GETUIDDETAILS';
export const GETUIDDETAILS_RECEIVED = 'GETUIDDETAILS_RECEIVED';

export const VIDEO_RECORDING_UPDATE = 'VIDEO_RECORDING_UPDATE';
export const RECIEVED_VIDEO_RECORDING_UPDATE = 'RECIEVED_VIDEO_RECORDING_UPDATE';

export const RTC_TOKEN_BUILDER = 'RTC_TOKEN_BUILDER';
export const RECIEVED_RTC_TOKEN_BUILDER = 'RECIEVED_RTC_TOKEN_BUILDER';
export const DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER';

export const RTM_TRANSCRIPT_DATA = 'RTM_TRANSCRIPT_DATA';
export const SAVE_RTM_TRANSCRIPT_DATA = 'SAVE_RTM_TRANSCRIPT_DATA';
export const GET_RTM_TRANSCRIPT_DATA = 'GET_RTM_TRANSCRIPT_DATA';
export const UPDATE_RTM_TRANSCRIPT_DATA = 'UPDATE_RTM_TRANSCRIPT_DATA';
export const UPDATE_GUEST_DATA = 'UPDATE_GUEST_DATA';
export const UPDATE_DATA_DATA = 'UPDATE_DATA_DATA';
export const RTM_TRANSCRIPT_LOGS = 'RTM_TRANSCRIPT_LOGS';
export const STORE_RTM_TRANSCRIPT_LOGS = 'STORE_RTM_TRANSCRIPT_LOGS';
export const GET_RTM_TRANSCRIPT_LOGS = 'GET_RTM_TRANSCRIPT_LOGS';

export const CLEAR_ADD_SECTION = 'CLEAR_ADD_SECTION';

export const CLEAR_DELETE_SECTION = 'CLEAR_DELETE_SECTION';
export const CLEAR_EDIT_SECTION = 'CLEAR_EDIT_SECTION';
export const CLEAR_ADD_QUESTION = 'CLEAR_ADD_QUESTION';
export const CLEAR_DELETE_QUESTION = 'CLEAR_DELETE_QUESTION';
export const CLEAR_EDIT_QUESTION = 'CLEAR_EDIT_QUESTION';
export const REQUEST_DELETEFORMTAG = 'REQUEST_DELETEFORMTAG';
export const DELETEFORMTAG_RECEIVED = 'DELETEFORMTAG_RECEIVED';
export const UPDATE_RTM_TRANSCRIPT_DATA_RECEIVED = 'UPDATE_RTM_TRANSCRIPT_DATA_RECEIVED';

export const RTM_TRANSCRIPT_MESSAGE_LOGS = 'RTM_TRANSCRIPT_LOGS';
export const STORE_RTM_TRANSCRIPT_MESSAGE_LOGS = 'STORE_RTM_TRANSCRIPT_LOGS';
export const GET_RTM_TRANSCRIPT_MESSAGE_LOGS = 'GET_RTM_TRANSCRIPT_LOGS';

export const UPDATE_SID_DATA = 'UPDATE_SID_DATA';
