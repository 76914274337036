import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CustomButton } from '../../common/commonStyled';
import CustomPopup from '../../common/CustomPopup';
import {
  ActionIcon,
  Card,
  ChoiceButton,
  Heading,
  Icon,
  QuestionWrapper,
  SideNav,
  Text,
  Button as MuiButton
} from '../../common';
import { Checkbox, InputText, Radio, TextArea, Switch } from '../../components/form';

import Dropdown from '../../components/form/TaskTypeOption';
import EditingTag from '../Project/EditingTag';
import DesignDropdown from './DesignDropdown';
import { ButtonGroup, Button, Box, Typography } from '@mui/material';
import { index } from 'd3';
import { faL } from '@fortawesome/free-solid-svg-icons';

const StyledCheckbox = styled.label`
  display: flex;
  align-items: center;

  .outer {
    height: ${props => props.outerHeight || '13px'};
    width: ${props => props.outerWidth || '13px'};
    border-radius: ${props => props.borderRadius || '1px'};
    border: ${props => props.border || '1px solid #e2e2e2'};
    border-color: ${props => props.color || '#e2e2e2'};
    background: ${props => props.inactiveColor || 'transparent'};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 1px;
    margin-right: 5px;
    // margin-left: 5px;
    // margin-top: 3px;
    cursor: pointer; // Set default cursor style to pointer
  }

  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-54%, -48%);
    z-index: 1;
    opacity: 0;
    color: var(--white);
    font-size: ${props => props.iconSize || '12px'};
  }

  .text {
    font-size: ${props => props.fontSize || '12px'};
    font-weight: ${props => props.fontWeight || '500'};
    color: ${props => props.color || 'var(--quadColor)'};
  }

  input[disabled] + .outer {
    cursor: not-allowed; // Change cursor style to not-allowed when checkbox is disabled
  }

  input {
    display: none;

    :checked ~ .outer {
      border: 1px solid var(--primaryColor);
      border-color: ${props => props.color || 'var(--primaryColor)'};
      background: ${props => props.color || 'var(--primaryColor)'};
    }

    :checked ~ .outer .inner {
      opacity: 1;
    }

    :checked ~ .text {
      color: ${props => props.color || 'var(--primaryColor)'};
      font-weight: ${props => props.fontWeight || '800'};
    }
  }
`;

const Tag = styled.div`
  border: 1px solid var(--greyBlue);
  padding: 3px 10px;
  display: flex;
  align-items: center;
  border-radius: 15px;

  p {
    font-size: 10px;
    font-weight: 700;
    color: var(--greyBlue);
    padding: 0;
    margin: 0;
    padding-right: 3px;
  }
  span {
    font-size: 12px;
    padding: 0;
    margin: 0;
    color: var(--greyBlue);
  }
`;
const TagButton = styled(CustomButton)`
  border: none;
  display: flex;
  align-items: center;
  color: #8a8a8a;
  padding: 4px 8px;

  span {
    font-size: 17px;
    color: #8a8a8a;
    padding-right: 3px;
  }
`;
const CustomButtont = styled(MuiButton)`
  padding: 2px 1rem;
  color: var(--white);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
`;

const LikertDiv = styled.div`
  margin-top: 2rem;

  @media (max-width: 1355px) {
    margin-top: 5rem;
  }
`;

const TooltipCard = styled.div`
  z-index: 10;
  background: #ffffff;
  color: var(--greyBlue);
  font-size: smaller;
  font-weight: 600;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.15);
  margin-left: -20px;
  padding: 5px 10px;
  position: absolute;
  transform: translateX(-4%) translateY(calc(-100% - 1px)); /* Move up including the triangle height */

  &::after {
    content: '';
    position: absolute;
    bottom: -10px; /* Position triangle at the bottom of CustomCard */
    left: 50%;
    margin-left: -10px; /* Center the triangle */
    border-width: 5px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
  }
`;

const Tooltip = ({ active, children }) => {
  return active ? <TooltipCard>{children}</TooltipCard> : null;
};

const generateUniqueID = () =>
  `${Date.now()}-${Math.random()
    .toString(36)
    .substr(2, 9)}`;

const SingleChoiceQuestion = ({ options, edit = false, handleOptions }) => {
  const [choices, setChoices] = useState(options);
  const [selectedOption, setSelectedOption] = useState(null);

  let className = 'col-md-12';
  // if (choices?.length > 4) {
  //   className = 'col-md-4';
  // }

  // Sageer's code
  const handleRadioChange = index => {
    setSelectedOption(index);
  };

  const addChoice = () => {
    let tempChoices = choices?.slice();
    if (tempChoices == null) {
      tempChoices = [{ choiceName_ID: generateUniqueID(), choiceName: '' }];
    } else {
      tempChoices = [...tempChoices, { choiceName_ID: generateUniqueID(), choiceName: '' }];
    }
    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleSingleChoiceOption = (index, value) => {
    let tempChoices = choices;
    tempChoices[index].choiceName = value;

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleDeleteOption = index => {
    let tempChoices = choices;
    tempChoices.splice(index, 1);
    // delete tempChoices[index];

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  return (
    <div className="options-list mt-2">
      <div className="row">
        <div className="col-md-6 px-0">
          {choices?.map((opt, oIndex) => (
            <div key={opt.choiceName_ID} className={`${className} d-flex align-items-center my-1`}>
              <Radio
                checked={selectedOption === oIndex}
                onChange={() => handleRadioChange(oIndex)}
                className="mb-0 pb-0"
                fontSize="0.875rem"
              >
                {!edit && opt.choiceName}
              </Radio>
              {edit && (
                <EditOption
                  optionIndex={oIndex}
                  value={opt.choiceName}
                  handleOption={handleSingleChoiceOption}
                  handleDeleteOption={handleDeleteOption}
                />
              )}
            </div>
          ))}
        </div>

        {edit && (
          <div className={className}>
            <ChoiceButton onClick={e => addChoice()}>
              <Icon className="material-icons-round">add</Icon> Add choice
            </ChoiceButton>
          </div>
        )}
      </div>
    </div>
  );
};

const MultipleChoiceQuestion = ({ options, edit, handleOptions }) => {
  const [choices, setChoices] = useState(options);

  let className = 'col-md-12';
  // if (choices?.length > 4) {
  //   className = 'col-md-4';
  // }

  const addChoice = () => {
    let tempChoices = choices?.slice();
    if (tempChoices == null) {
      tempChoices = [{ choiceName_ID: generateUniqueID(), choiceName: '' }];
    } else {
      tempChoices = [...tempChoices, { choiceName_ID: generateUniqueID(), choiceName: '' }];
    }
    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleMultipleChoiceOption = (index, value) => {
    let tempChoices = choices;
    tempChoices[index].choiceName = value;

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleDeleteOption = index => {
    let tempChoices = choices;
    tempChoices.splice(index, 1);
    // delete tempChoices[index];

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  return (
    <div className="options-list mt-3">
      <div className="row">
        <div className="col-md-6 px-0">
          {choices?.map((opt, oIndex) => (
            <div key={opt.choiceName_ID} className={`${className} d-flex align-items-center my-1`}>
              <Checkbox fontSize="0.875rem" className="mb-0 pb-0">
                {!edit && opt.choiceName}
              </Checkbox>
              {edit && (
                <EditOption
                  optionIndex={oIndex}
                  value={opt.choiceName}
                  handleOption={handleMultipleChoiceOption}
                  handleDeleteOption={handleDeleteOption}
                />
              )}
            </div>
          ))}
        </div>
        {edit && (
          <div className={className}>
            <ChoiceButton className="mt-3" onClick={e => addChoice()}>
              <Icon className="material-icons-round">add</Icon> Add choice
            </ChoiceButton>
          </div>
        )}
      </div>
    </div>
  );
};

const SliderQuestion = ({ options, edit = false, handleOptions }) => {
  const [choices, setChoices] = useState(options);

  let className = 'col-md-12';
  if (choices?.length > 4) {
    className = 'col-md-4';
  }

  const handleSliderOption = (index, value) => {
    let tempChoices = choices;
    tempChoices[index].choiceName = value;

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  return (
    <div className="options-list mt-3">
      <div className="row">
        <div className={choices?.length > 4 ? 'col-md-12' : 'col-md-7'}>
          <div className="d-flex" style={{ flex: 1, paddingTop: 10, justifyContent: 'center', paddingBottom: 10 }}>
            <div style={{ flex: 0.3, paddingTop: 10 }}>
              <Text type="4" fontWeight="800" color="var(--headingColor)">
                Minimum Value
              </Text>
            </div>
            <div style={{ flex: 0.7 }}>
              <InputText
                type="number"
                disabled={edit ? false : true}
                showCharacters={false}
                placeholder="Add Minimum Value"
                defaultValue={choices[0].choiceName}
                onChange={e => handleSliderOption(0, e.target.value)}
                data-no-dnd="true"
              />
            </div>
          </div>
          <div className="d-flex" style={{ flex: 1, justifyContent: 'center' }}>
            <div style={{ flex: 0.3, paddingTop: 10 }}>
              <Text type="4" fontWeight="800" color="var(--headingColor)">
                Maximum Value
              </Text>
            </div>
            <div style={{ flex: 0.7 }}>
              <InputText
                type="number"
                disabled={edit ? false : true}
                showCharacters={false}
                placeholder="Add Maximum Value"
                defaultValue={choices[1].choiceName}
                onChange={e => handleSliderOption(1, e.target.value)}
                data-no-dnd="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const tempLikerTOptions = [
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Dislike Very Much'
  },
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Dislike'
  },
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Moderately Dislike'
  },
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Neutral'
  },
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Moderately Like'
  },
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Like'
  },
  {
    questionLikertScale_ID: null,
    questionLikertScaleName: 'Like Very Much'
  }
];

const getLikertScaleOptions = (scaleSize, type = 'agree-disagree') => {
  if (type === 'important-not-important') {
    switch (scaleSize) {
      case 5:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Not at all Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Not so Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely Important' }
        ];
      case 7:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Not at all Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Not so Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Quite Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely Important' }
        ];
      case 10:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Not at all Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Not so Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Unimportant' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neither Important nor Unimportant' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Quite Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Important' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely Important' }
        ];
      default:
        return [];
    }
  }

  if (type === 'satisfied-dissatisfied') {
    switch (scaleSize) {
      case 5:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Satisfied' }
        ];
      case 7:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Satisfied' }
        ];
      case 10:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Dissatisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Satisfied' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Completely Satisfied' }
        ];
      default:
        return [];
    }
  }

  if (type === 'likely-unlikely') {
    switch (scaleSize) {
      case 5:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neither Likely nor Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Likely' }
        ];
      case 7:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neither Likely nor Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Likely' }
        ];
      case 10:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Unlikely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Quite Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Likely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely Likely' }
        ];
      default:
        return [];
    }
  }
  if (type === 'like-dislike') {
    switch (scaleSize) {
      case 5:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dislike very much' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dislike' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like very much' }
        ];
      case 7:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dislike Very Much' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dislike' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately Dislike' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately Like' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like Very Much' }
        ];
      case 10:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dislike Very Much' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Dislike' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Dislike' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Midly Dislike' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Midly Like' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Like' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like considerably' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Like Very Much' }
        ];
      default:
        return [];
    }
  }
  if (type === 'always-never') {
    switch (scaleSize) {
      case 5:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Never' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Rarely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Sometimes' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Usually' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Always' }
        ];
      case 7:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Never' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Rarely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Occasionally' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Sometimes' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Usually' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Almost Always' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Always' }
        ];
      case 10:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Never' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Almost Never' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Rarely' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Occasionally' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Sometimes' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Usually' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Frequently' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Frequently' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Almost Always' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Always' }
        ];
      default:
        return [];
    }
  }
  if (type === 'positive-negative') {
    switch (scaleSize) {
      case 5:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Positive' }
        ];
      case 7:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely positive' }
        ];
      case 10:
        return [
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Quite negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Negative' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Moderately positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Quite positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Very Positive' },
          { questionLikertScale_ID: null, questionLikertScaleName: 'Extremely positive' }
        ];
      default:
        return [];
    }
  }

  switch (scaleSize) {
    case 5:
      return [
        { questionLikertScale_ID: null, questionLikertScaleName: 'Strongly Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Strongly Agree' }
      ];
    case 7:
      return [
        { questionLikertScale_ID: null, questionLikertScaleName: 'Strongly Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Strongly Agree' }
      ];
    case 10:
      return [
        { questionLikertScale_ID: null, questionLikertScaleName: 'Strongly Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Disagree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Neutral' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Slightly Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Somewhat Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Strongly Agree' },
        { questionLikertScale_ID: null, questionLikertScaleName: 'Completely Agree' }
      ];

    default:
      return [];
  }
};

const SCALE_TYPES = [
  { value: 'agree-disagree', label: 'Agree-Disagree' },
  { value: 'satisfied-dissatisfied', label: 'Satisfied-Dissatisfied' },
  { value: 'like-dislike', label: 'Like-Dislike' },
  { value: 'positive-negative', label: 'Positive-Negative' },
  { value: 'always-never', label: 'Always-Never' },
  { value: 'likely-unlikely', label: 'Likely-Unlikely' },
  { value: 'important-not-important', label: 'Important-Not Important' }
];

const LikerTQuestion = ({
  options,
  likerTOptions,
  edit = false,
  handleOptions,
  handleScaleOptions,
  initialScaleType
}) => {
  const [scaleSize, setScaleSize] = useState();
  const [scaleType, setScaleType] = useState(initialScaleType || null);
  const [choices, setChoices] = useState(options || []);
  const [scaleOptions, setScaleOptions] = useState(likerTOptions || []);

  useEffect(() => {
    if (likerTOptions?.length && initialScaleType) {
      setScaleSize(likerTOptions.length);
      setScaleType(initialScaleType);
      handleScaleOptions(likerTOptions, initialScaleType);
    }
  }, [likerTOptions, initialScaleType]);

  const handleScaleChange = size => {
    setScaleSize(size);
    if (scaleType) {
      const newScaleOptions = getLikertScaleOptions(size, scaleType);
      setScaleOptions(newScaleOptions);
      handleScaleOptions(newScaleOptions, scaleType);
    }
  };

  const handleTypeChange = type => {
    setScaleType(type);
    if (scaleSize) {
      const newScaleOptions = getLikertScaleOptions(scaleSize, type);
      setScaleOptions(newScaleOptions);
      handleScaleOptions(newScaleOptions, type);
    }
  };

  const addChoice = () => {
    let tempChoices = choices.slice();
    tempChoices = [...tempChoices, { choiceName_ID: '', choiceName: '' }];
    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleLikerTOption = (index, value) => {
    let tempChoices = choices;
    tempChoices[index].choiceName = value;
    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleDeleteOption = index => {
    let tempChoices = choices;
    tempChoices.splice(index, 1);
    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const buttonStyle = {
    border: '1px solid #8A8A8A',
    backgroundColor: 'white',
    color: 'rgba(137, 137, 137, 1)',
    marginLeft: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#B3DB1A',
    color: 'white',
    border: '1px solid #B3DB1A'
  };

  return (
    <div className="options-list mt-2">
      <div className="row">
        {edit && (
          <>
            <div className="d-flex flex-column gap-3 ">
              <div className="d-flex align-items-center">
                <Text type="6" style={{ marginRight: '16px', minWidth: '100px' }}>
                  Select Scale:
                </Text>
                <div className="d-flex mt-2">
                  {[5, 7, 10].map(size => (
                    <button
                      key={size}
                      style={scaleSize === size ? selectedButtonStyle : buttonStyle}
                      onClick={() => handleScaleChange(size)}
                    >
                      {size}
                    </button>
                  ))}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <Text type="6" style={{ marginRight: '16px', width: '200px' }}>
                  Select Text Value:
                </Text>
                <div
                  className="d-flex flex-wrap mt-2"
                  style={{
                    gap: '8px',
                    justifyContent: 'flex-start'
                  }}
                >
                  {SCALE_TYPES.map(type => (
                    <button
                      key={type.value}
                      style={{
                        ...buttonStyle,
                        ...(scaleType === type.value ? selectedButtonStyle : {}),
                        flex: '0 1 auto',
                        minWidth: '100px',
                        textAlign: 'center'
                      }}
                      onClick={() => handleTypeChange(type.value)}
                    >
                      {type.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-4">
              {choices?.map((opt, oIndex) => (
                <div key={oIndex} className="d-flex align-items-center my-1">
                  <EditOption
                    optionIndex={oIndex}
                    value={opt.choiceName}
                    handleOption={handleLikerTOption}
                    handleDeleteOption={handleDeleteOption}
                  />
                </div>
              ))}
            </div>

            <div className=" col-md-12 mt-3">
              <ButtonGroup>
                <ChoiceButton onClick={addChoice}>
                  <Icon className="material-icons-round">add</Icon> Add choice
                </ChoiceButton>
              </ButtonGroup>
            </div>
          </>
        )}
        {!edit && (
          <div className="col-md-12">
            <Box className="d-flex flex-column w-100">
              <Box className="d-flex flex-row w-100" sx={{ mb: 2, alignItems: 'center', gap: 5 }}>
                <Typography variant="body2" sx={{ color: '#8A8A8A', fontWeight: 400 }}>
                  {`Scale Size: ${likerTOptions?.length || 'Not Set'}`}
                </Typography>
                <Typography variant="body2" sx={{ color: '#8A8A8A', fontWeight: 400 }}>
                  {`Scale Type: ${
                    initialScaleType
                      ? initialScaleType
                          .split('-')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join('-')
                      : 'Not Set'
                  }`}
                </Typography>
              </Box>

              <Box className="d-flex flex-column w-100" sx={{ mb: 1, alignItems: 'start' }}>
                <Typography variant="body2" sx={{ color: '#8A8A8A', fontWeight: 400, textAlign: 'start' }}>
                  {`1 = ${likerTOptions?.[0]?.questionLikertScaleName || 'Strongly Disagree'}`}
                </Typography>
                <Typography variant="body2" sx={{ color: '#8A8A8A', fontWeight: 400, textAlign: 'start', mt: 1 }}>
                  {`${likerTOptions?.length} = ${likerTOptions?.[likerTOptions.length - 1]?.questionLikertScaleName ||
                    'Strongly Agree'}`}
                </Typography>
              </Box>

              {choices?.map((opt, oIndex) => (
                <Box key={oIndex} className="d-flex align-items-center w-100" sx={{ mb: 2 }}>
                  <Typography variant="h5" sx={{ color: '#8A8A8A', fontSize: '13px', textAlign: 'right', pr: 2 }}>
                    {opt.choiceName}
                  </Typography>

                  <ButtonGroup variant="contained" aria-label="Likert scale">
                    {Array.from({ length: likerTOptions?.length }, (_, index) => (
                      <Button
                        key={index}
                        sx={{
                          backgroundColor: 'white',
                          color: '#8A8A8A',
                          minWidth: '40px',
                          padding: '6px 12px',
                          border: '1px solid #8A8A8A',
                          '&:hover': { backgroundColor: 'white' },
                          '&.MuiButton-root': { border: '1px solid #8A8A8A' },
                          '&:not(:first-of-type)': { borderLeft: 'none' }
                        }}
                      >
                        {index + 1}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              ))}
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};
const CustomTextArea = styled(TextArea)`
  ::placeholder,
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    color: var(--grey20);
    font-weight: 400;
    font-size: 10px;
  }
`;
const TextAnswerQuestion = ({ edit, description = '', characterLimit = 500, handleTextFields }) => {
  return (
    <div className="options-list mt-3">
      <div className="d-flex align-items-center mt-3">
        <Text className=" mr-2  mb-0 pb-0" type="6" fontSize={'12px'}>
          Character Limit :
        </Text>

        {!edit ? (
          <p
            className="mb-0 pb-0"
            style={{
              fontSize: '12px',
              fontWeight: '700',
              color: 'var(--greyBlue)'
            }}
          >
            {characterLimit}
          </p>
        ) : (
          <InputText
            type="number"
            showCharacters={false}
            value={characterLimit}
            width="50px"
            style={{
              fontSize: '12px',
              fontWeight: '700',
              color: 'var(--greyBlue)'
            }}
            onChange={e => handleTextFields('characterLimit', e.target.value)}
            data-no-dnd="true"
          />
        )}
      </div>
    </div>
  );
};

export const SingleSevenQuestion = ({ options, edit = false, handleOptions }) => {
  const [choices, setChoices] = useState(options);
  const [selectedOption, setSelectedOption] = useState(null);

  let className = 'col-md-12';
  if (choices?.length > 4) {
    className = 'col-md-4';
  }

  const handleRadioChange = index => {
    setSelectedOption(index);
  };

  const handleSingleSevenOption = (index, value) => {
    let tempChoices = choices;
    tempChoices[index].choiceName = value;

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  return (
    <div className="options-list mt-2">
      <div className="row">
        <div className="col-md-6 px-0">
          {choices?.length > 0 &&
            [...Array(7)].map((opt, oIndex) => (
              <div key={oIndex} className={`d-flex align-items-center my-1`}>
                <Radio
                  // Sageer's code
                  checked={selectedOption === oIndex}
                  onChange={() => handleRadioChange(oIndex)}
                  className="mb-0 pb-0"
                  fontSize="0.875rem"
                >
                  {!edit && choices[oIndex] && choices[oIndex].choiceName}
                </Radio>
                {edit && (
                  <EditOption
                    optionIndex={oIndex}
                    value={choices[oIndex] && choices[oIndex].choiceName}
                    handleOption={handleSingleSevenOption}
                    handleDeleteOption={null}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const tempOptionsNumber = [
  {
    value: 1,
    label: 1
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 4,
    label: 4
  }
];
const tempOptionsNumberSize = [
  {
    value: 5,
    label: '5 Min'
  },
  {
    value: 10,
    label: '10 Min'
  }
];

const tempOptionsNumberSizeForImage = [
  {
    value: 10,
    label: '10 MB'
  },
  {
    value: 20,
    label: '20 MB'
  },
  {
    value: 30,
    label: '30 MB'
  }
];

const singleValueStyle = {
  fontSize: '14px',
  fontWeight: '300',
  color: 'var(--mediumGrey)'
};

const ImageUploadQuestion = ({ edit, numberOfFiles, maxFileSizeInMB, handleTextFields }) => {
  // let numberOfFilesIndex = tempOptionsNumber.findIndex(ton => ton.value == numberOfFiles);
  // let numberOfFilesDefaultValue =
  //   numberOfFilesIndex >= 0 ? tempOptionsNumber[numberOfFilesIndex] : { value: 1, label: 1 };

  let maxFileSizeInMBIndex = tempOptionsNumberSizeForImage.findIndex(tons => tons.value == maxFileSizeInMB);
  let maxFileSizeInMBDefaultValue =
    maxFileSizeInMBIndex >= 0 ? tempOptionsNumberSizeForImage[maxFileSizeInMBIndex] : { value: 10, label: '10 MB' };

  return (
    <div className="options-list mt-3">
      {/* <div className="d-flex align-items-center">
        <div style={{ width: edit ? '300px' : '220px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <Text color="var(--mediumGrey)" type="6">
              Maximum number of files
            </Text>
            {edit ? (
              <Dropdown
                singleValueStyle={singleValueStyle}
                options={tempOptionsNumber}
                defaultValue={numberOfFilesDefaultValue}
                onChange={e => handleTextFields('numberOfFiles', e.value)}
              />
            ) : (
              <Text color="var(--mediumGrey)" type="6">
                {numberOfFiles}
              </Text>
            )}
          </div>
        </div>
      </div> */}

      <div className="d-flex align-items-center mt-2">
        <div style={{ width: edit ? '300px' : '220px' }}>
          <div className="d-flex align-items-center">
            <Text color="var(--mediumGrey)" type="6">
              Maximum size of files
            </Text>
            {edit ? (
              <Dropdown
                singleValueStyle={singleValueStyle}
                options={tempOptionsNumberSizeForImage}
                defaultValue={maxFileSizeInMBDefaultValue}
                onChange={e => handleTextFields('maxFileSizeInMB', e.value)}
                className="ml-2"
              />
            ) : (
              <Text color="var(--mediumGrey)" type="6" className="ml-2">
                {maxFileSizeInMB} MB
              </Text>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ScreenerTypeQuestion = ({ options, edit, savedOption, handleTextFields }) => {
  const [selectedOption, setSelectedOption] = useState(savedOption);
  let screenerQuestionIndex = options.findIndex(op => op.label == selectedOption);
  let screenerQuestionDefaultValue =
    screenerQuestionIndex >= 0 ? options[screenerQuestionIndex] : { value: null, label: 'Select one' };

  return (
    <div className="options-list ">
      <div className="d-flex align-items-center my-4">
        <div style={{ width: '120px' }}>
          <Text type="6">Screener Question:</Text>
        </div>
        {edit ? (
          <Dropdown
            options={options}
            defaultValue={screenerQuestionDefaultValue}
            singleValueStyle={{
              fontSize: '12px',
              fontWeight: '700',
              color: 'var(--greyBlue)'
            }}
            optionStyle={{
              fontSize: '12px',
              fontWeight: '500',
              color: 'var(--greyBlue)'
            }}
            controlStyle={{
              borderBottom: '1.5px solid var(--grey20)'
            }}
            onChange={e => {
              handleTextFields('screenerQuestion_ID', e.value);
              setSelectedOption(e.label);
            }}
          />
        ) : (
          <Heading fontSize="12px" fontWeight="700" color="var(--greyBlue)" className="mb-0 pb-0">
            {selectedOption}
          </Heading>
        )}
      </div>
    </div>
  );
};

const RecordingQuestion = ({ edit, buttonName, tagList, options, handleTextFields, handleTags, handleDeleteTag }) => {
  const [recordingButtonName, setRecordingButtonName] = useState(buttonName);

  const onSelectTag = tag => {
    let tempTagLists = tagList;
    if (tag.tag != '') {
      tempTagLists = [...tempTagLists, tag];
      handleTags(tempTagLists);
    }
  };

  const onChangeTag = (index, tag) => {
    if (tag.tag == '') {
      let tempTagLists = tagList;
      tempTagLists.splice(index, 1);
      handleTags(tempTagLists);
    } else {
      let tempTagLists = tagList;
      tempTagLists[index] = tag;
      handleTags(tempTagLists);
    }
  };

  // const onSelectTag = tag => {
  //   let tempTagLists = tagList;
  //   tempTagLists = [...tempTagLists, tag];

  //   handleTags(tempTagLists);
  // };

  // const onChangeTag = (index, tag) => {
  //   let tempTagLists = tagList;
  //   tempTagLists[index] = tag;

  //   handleTags(tempTagLists);
  // };

  const onDeleteTag = tag => {
    let index = tagList.findIndex(o => o.tag == tag);
    let tempTagLists = tagList;
    if (index !== -1) {
      tempTagLists.splice(index, 1);
    }
    // handleDeleteTag(tempTagLists);
    handleTags(tempTagLists);
  };

  return (
    <div className="options-list ">
      <div className="d-flex align-items-center my-2">
        <div style={{ width: '120px' }}>
          <Text type="6">Button Name:</Text>
        </div>
        {edit ? (
          <InputText
            showCharacters={false}
            fontSize="12px"
            value={recordingButtonName}
            width="100px"
            style={{
              color: 'var(--greyBlue)'
            }}
            onChange={e => {
              handleTextFields('buttonName', e.target.value);
              setRecordingButtonName(e.target.value);
            }}
            data-no-dnd="true"
          />
        ) : (
          <Heading fontSize="12px" fontWeight="" color="var(--greyBlue)" className="mb-0 pb-0">
            {recordingButtonName}
          </Heading>
        )}
      </div>

      <div className="d-flex align-items-center mt-2">
        <div style={{ width: '120px' }}>
          <Text type="6">Tags:</Text>
        </div>
        {edit ? (
          <EditingTag
            tagList={tagList}
            options={options}
            singleValueStyle={{
              fontSize: '10px',
              fontWeight: '700',
              color: 'var(--greyBlue)'
            }}
            tagColor="var(--greyBlue)"
            addTagButtonColor="#8A8A8A"
            AddTagButton={() => (
              <TagButton
                fontSize="12px"
                fontWeight="300"
                variant="outlined"
                buttonColor="#8A8A8A"
                color="#8A8A8A"
                // className='ml-3'
              >
                <Icon>add</Icon>Add Tag
              </TagButton>
            )}
            onSelectTag={onSelectTag}
            onChangeTag={onChangeTag}
            onDeleteTag={onDeleteTag}
          />
        ) : (
          <>
            {tagList &&
              tagList.map(tag => (
                <Tag className="mr-3 ">
                  <div className="d-flex align-items-center ">
                    <p>{tag.tag}</p> <Icon>close</Icon>
                  </div>
                </Tag>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

const InputTextContainer = styled.div`
  .iconContainer {
    position: absolute;
    top: 30%;
    right: 0;
  }
  span {
    font-size: 18px;
    color: var(--greyBlue);
  }
`;
const EditOption = ({ optionIndex, value, handleOption, handleDeleteOption }) => {
  const changeOption = e => {
    handleOption(optionIndex, e.target.value);
  };
  const removeOption = () => {
    handleDeleteOption(optionIndex);
  };

  return (
    <div style={{ position: 'relative' }}>
      <InputTextContainer>
        <InputText
          showCharacters={false}
          fontSize="14px"
          value={value}
          width="180px"
          style={{
            color: 'var(--greyBlue)',
            fontWeight: '500',
            marginLeft: '3px'
          }}
          onChange={e => changeOption(e)}
          data-no-dnd="true"
        />
        {handleDeleteOption != null && (
          <div className="iconContainer" style={{ cursor: 'pointer' }} onClick={() => removeOption()}>
            <Icon>close</Icon>
          </div>
        )}
      </InputTextContainer>
    </div>
  );
};

const CustomCard = styled(Card)`
  border: 1px solid #ffffff;
  box-sizing: border-box;
  /* Selected Box in Box */

  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

const ValueQuestion = ({ options, edit, savedOption, handleTextFields }) => {
  let savedOpt = savedOption == 'Percentage Value' ? 'Percentage value' : savedOption;
  const [selectedOption, setSelectedOption] = useState(savedOpt);
  let screenerQuestionIndex = options.findIndex(op => op.label == selectedOption);
  let screenerQuestionDefaultValue =
    screenerQuestionIndex >= 0 ? options[screenerQuestionIndex] : { value: null, label: 'Select one' };

  return (
    <div className="options-list ">
      <div className="d-flex align-items-center my-4">
        <div style={{ width: '120px' }}>
          <Text type="6">Ask for amount in:</Text>
        </div>
        {edit ? (
          <Dropdown
            options={options}
            defaultValue={screenerQuestionDefaultValue}
            singleValueStyle={{
              fontSize: '12px',
              fontWeight: '700',
              color: 'var(--greyBlue)'
            }}
            optionStyle={{
              fontSize: '12px',
              fontWeight: '500',
              color: 'var(--greyBlue)'
            }}
            controlStyle={{
              borderBottom: '1.5px solid var(--grey20)'
            }}
            onChange={e => {
              handleTextFields('valueQuestion_ID', e.value);
              setSelectedOption(e.label);
            }}
          />
        ) : (
          <Heading fontSize="12px" fontWeight="700" color="var(--greyBlue)" className="mb-0 pb-0">
            {selectedOption}
          </Heading>
        )}
      </div>
    </div>
  );
};
const AudioQuestion = ({ edit, numberOfFiles, maxFileSizeInMB, handleTextFields }) => {
  // let numberOfFilesIndex = tempOptionsNumber.findIndex(ton => ton.value == numberOfFiles);
  // let numberOfFilesDefaultValue =
  //   numberOfFilesIndex >= 0 ? tempOptionsNumber[numberOfFilesIndex] : { value: 1, label: 1 };

  let maxFileSizeInMBIndex = tempOptionsNumberSize.findIndex(tons => tons.value == maxFileSizeInMB);
  let maxFileSizeInMBDefaultValue =
    maxFileSizeInMBIndex >= 0 ? tempOptionsNumberSize[maxFileSizeInMBIndex] : { value: 5, label: '5 Min' };

  return (
    <div className="options-list mt-3">
      {/* <div className="d-flex align-items-center">
        <div style={{ width: edit ? '300px' : '220px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <Text color="var(--mediumGrey)" type="6">
              Maximum number of files
            </Text>
            {edit ? (
              <Dropdown
                singleValueStyle={singleValueStyle}
                options={tempOptionsNumber}
                defaultValue={numberOfFilesDefaultValue}
                onChange={e => handleTextFields('numberOfFiles', e.value)}
              />
            ) : (
              <Text color="var(--mediumGrey)" type="6">
                {numberOfFiles}
              </Text>
            )}
          </div>
        </div>
      </div> */}

      <div className="d-flex align-items-center mt-2">
        <div style={{ width: edit ? '300px' : '220px' }}>
          <div className="d-flex align-items-center">
            <Text color="var(--mediumGrey)" type="6">
              Maximum duration of files :
            </Text>
            <Text color="var(--mediumGrey)" type="6" className="ml-2">
              {maxFileSizeInMB} Min
            </Text>
            {/* {edit ? (
              <Dropdown
                singleValueStyle={singleValueStyle}
                options={tempOptionsNumberSize}
                defaultValue={maxFileSizeInMBDefaultValue}
                onChange={e => handleTextFields('maxFileSizeInMB', e.value)}
              />
            ) : (
              <Text color="var(--mediumGrey)" type="6">
                {maxFileSizeInMB} Min
              </Text>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
const VideoQuestion = ({ edit, numberOfFiles, maxFileSizeInMB, handleTextFields }) => {
  // let numberOfFilesIndex = tempOptionsNumber.findIndex(ton => ton.value == numberOfFiles);
  // let numberOfFilesDefaultValue =
  //   numberOfFilesIndex >= 0 ? tempOptionsNumber[numberOfFilesIndex] : { value: 1, label: 1 };

  let maxFileSizeInMBIndex = tempOptionsNumberSize.findIndex(tons => tons.value == maxFileSizeInMB);
  let maxFileSizeInMBDefaultValue =
    maxFileSizeInMBIndex >= 0 ? tempOptionsNumberSize[maxFileSizeInMBIndex] : { value: 5, label: '5 Min' };

  return (
    <div className="options-list mt-3">
      {/* <div className="d-flex align-items-center">
        <div style={{ width: edit ? '300px' : '220px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <Text color="var(--mediumGrey)" type="6">
              Maximum number of files
            </Text>
            {edit ? (
              <Dropdown
                singleValueStyle={singleValueStyle}
                options={tempOptionsNumber}
                defaultValue={numberOfFilesDefaultValue}
                onChange={e => handleTextFields('numberOfFiles', e.value)}
              />
            ) : (
              <Text color="var(--mediumGrey)" type="6">
                {numberOfFiles}
              </Text>
            )}
          </div>
        </div>
      </div> */}

      <div className="d-flex align-items-center mt-2">
        <div style={{ width: edit ? '300px' : '220px' }}>
          <div className="d-flex align-items-center">
            <Text color="var(--mediumGrey)" type="6">
              Maximum duration of files :
            </Text>
            <Text color="var(--mediumGrey)" type="6" className="ml-2">
              {maxFileSizeInMB} Min
            </Text>
            {/* {edit ? (
              <Dropdown
                singleValueStyle={singleValueStyle}
                options={tempOptionsNumberSize}
                defaultValue={maxFileSizeInMBDefaultValue}
                onChange={e => handleTextFields('maxFileSizeInMB', e.value)}
              />
            ) : (
              <Text color="var(--mediumGrey)" type="6">
                {maxFileSizeInMB} Min
              </Text>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

//Ranking Question
const RankingQuestion = ({ options, edit = false, handleOptions }) => {
  const [choices, setChoices] = useState(options);
  const [selectedOption, setSelectedOption] = useState(null);

  let className = 'col-md-12';
  // if (choices?.length > 4) {
  //   className = 'col-md-4';
  // }

  // Sageer's code
  const handleRadioChange = index => {
    setSelectedOption(index);
  };

  const addChoice = () => {
    let tempChoices = choices?.slice();
    if (tempChoices == null) {
      tempChoices = [{ choiceName_ID: generateUniqueID(), choiceName: '' }];
    } else {
      tempChoices = [...tempChoices, { choiceName_ID: generateUniqueID(), choiceName: '' }];
    }
    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleRankingChoiceOption = (index, value) => {
    let tempChoices = choices;
    tempChoices[index].choiceName = value;

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  const handleDeleteOption = index => {
    let tempChoices = choices;
    tempChoices.splice(index, 1);
    // delete tempChoices[index];

    setChoices(tempChoices);
    handleOptions(tempChoices);
  };

  return (
    <div className="options-list mt-2">
      <div className="row">
        <div className="col-md-6 px-0">
          {choices?.map((opt, oIndex) => (
            <div key={opt.choiceName_ID} className={`${className} d-flex align-items-center my-1`}>
              <Radio
                checked={selectedOption === oIndex}
                onChange={() => handleRadioChange(oIndex)}
                className="mb-0 pb-0"
                fontSize="0.875rem"
              >
                {!edit && opt.choiceName}
              </Radio>
              {edit && (
                <EditOption
                  optionIndex={oIndex}
                  value={opt.choiceName}
                  handleOption={handleRankingChoiceOption}
                  handleDeleteOption={handleDeleteOption}
                />
              )}
            </div>
          ))}
        </div>

        {edit && (
          <div className={className}>
            <ChoiceButton onClick={e => addChoice()}>
              <Icon className="material-icons-round">add</Icon> Add choice
            </ChoiceButton>
          </div>
        )}
      </div>
    </div>
  );
};

// const RankingQuestion = ({ options, edit = false, handleOptions }) => {
//   const [choices, setChoices] = useState(options);
//   const [selectedOption, setSelectedOption] = useState(null);

//   let className = 'col-md-12';
//   // if (choices?.length > 4) {
//   //   className = 'col-md-4';
//   // }

//   // Sageer's code
//   const handleRadioChange = index => {
//     setSelectedOption(index);
//   };

//   const handleRankingChoiceOption = () => {
//     console.log("This is getting calledddddddd",handleRankingChoiceOption)
//     let tempChoices = choices?.slice();
//     if (tempChoices == null) {
//       tempChoices = [{ choiceName_ID: generateUniqueID(), choiceName: '' }];
//     } else {
//       tempChoices = [...tempChoices, { choiceName_ID: generateUniqueID(), choiceName: '' }];
//     }
//     setChoices(tempChoices);
//     handleOptions(tempChoices);
//   };

//   const handleSingleChoiceOption = (index, value) => {
//     let tempChoices = choices;
//     tempChoices[index].choiceName = value;

//     setChoices(tempChoices);
//     handleOptions(tempChoices);
//   };

//   const handleDeleteOption = index => {
//     let tempChoices = choices;
//     tempChoices.splice(index, 1);
//     // delete tempChoices[index];

//     setChoices(tempChoices);
//     handleOptions(tempChoices);
//   };

//   return (
//     <div className="options-list mt-2">
//       <div className="row">
//         <div className="col-md-6 px-0">
//           {choices?.map((opt, oIndex) => (
//             <div key={opt.choiceName_ID} className={`${className} d-flex align-items-center my-1`}>
//               {/* <Radio
//                 checked={selectedOption === oIndex}
//                 onChange={() => handleRadioChange(oIndex)}
//                 className="mb-0 pb-0"
//                 fontSize="0.875rem"
//               >
//                 {!edit && opt.choiceName}
//               </Radio> */}
//               {edit && (
//                 <EditOption
//                   optionIndex={oIndex}
//                   value={opt.choiceName}
//                   handleOption={handleSingleChoiceOption}
//                   handleDeleteOption={handleDeleteOption}
//                 />
//               )}
//             </div>
//           ))}
//         </div>

//         {edit && (
//           <div className={className}>
//             <ChoiceButton onClick={e => handleRankingChoiceOption()}>
//               <Icon className="material-icons-round">add</Icon> Add choice
//             </ChoiceButton>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

function DesignQuestion({
  sectionIndex,
  questionIndex,
  questionArrayLength,
  sectionId,
  question,
  questionTypes,
  screenerTypeOption,
  masterTags,
  handleChangeQuestionType,
  handleDuplicateQuestion,
  handleEditQuestion,
  handleDeleteQuestion,
  ValueTypeChoices,
  handleProjectTaskTab,
  isInterview,
  setEditQuestionIndex,
  setNewQuestionIndex,
  setDeleteQuestionIndex,
  switchvalue,
  setSwitchValue,
  setQuestionIndex,
  enableEdit,
  setEditQuestionId,
  setAddQuestionDisable,
  questionmessage,
  popupColor,
  showCheckbox,
  handleSetEditing,
  setFormTaskSections,
  formTaskSections,
  setInterviewTaskSections,
  interviewTaskSections,
  task
}) {
  const { userId } = useSelector(state => state.LoginReducer);

  const [taskQuestion, setTaskQuestion] = useState(question);
  const [desc, setDesc] = useState(question.questionDescription || '');
  const projectId = useSelector(state => state.ProjectReducer.project_ID);
  const [visible, setVisible] = useState(false);
  const [optionVisible, setOptionVisible] = useState(false);

  const [scaletypeVisible, setScaleTypeVisible] = useState(false);
  let [saveQuestion, setSaveQuestion] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [popup, setPopup] = useState(false);
  const [checkBoxRequired, setCheckBoxRequired] = useState(
    taskQuestion.isRequired == 0 || taskQuestion.isRequired == null ? 0 : 1
  );
  const [shuffleEnabled, setShuffleEnabled] = useState(
    taskQuestion.toShuffle == 0 || taskQuestion.toShuffle == null ? 0 : 1
  );
  const [showTooltipisdResponded, setShowTooltipisResponded] = useState(false);

  // const [isQuestionRequired, setIsQuestionRequired] = useState(false);
  // const [isEditing, setIsEditing] = useState('false')
  const dispatch = useDispatch();

  // console.log('taskQuestion', question);

  useEffect(() => {
    setTaskQuestion(taskQuestion);
  }, [taskQuestion]);

  // console.log("taskQuestion",taskQuestion);

  useEffect(() => {
    setTaskQuestion({ ...taskQuestion, questionDescription: desc });
  }, [desc]);

  useEffect(() => {
    setTaskQuestion(question);
  }, [question]);

  useEffect(() => {
    console.log('IS EDIT CHANGES', isEdit);
    setTimeout(() => {
      setIsEdit(false);
    }, 10000);
  }, [isEdit]);

  useEffect(() => {
    setTaskQuestion(taskQuestion);
    if (taskQuestion?.questionName != null && taskQuestion?.questionName != '') {
      setVisible(false);
    }
    if (
      taskQuestion.choiceName_array &&
      taskQuestion.choiceName_array.every(obj => {
        return obj.choiceName != '';
      })
    ) {
      setOptionVisible(false);
      setScaleTypeVisible(false);
    }
  }, [taskQuestion]);

  let options = taskQuestion?.choiceName_array;
  let tagList = [];
  if (
    question.questionType_ID == 1 ||
    question.questionType_ID == 2 ||
    question.questionType_ID == 3 ||
    question.questionType_ID == 14
  ) {
    {
      [...Array(2)].map((opt, oIndex) => {
        options?.length <= oIndex && options.push({ choiceName: '', choiceName_ID: '' });
      });
    }
  }
  if (question.questionType_ID == 7) {
    {
      [...Array(7)].map((opt, oIndex) => {
        options?.length <= oIndex && options.push({ choiceName: '', choiceName_ID: '' });
      });
    }
  }

  if (question.questionType_ID == 9) {
    options =
      screenerTypeOption?.map(option => {
        return { ...option, label: option.groupFilterLable, value: option.question_ID };
      }) || [];

    options.unshift({ value: null, label: 'Select one', question_ID: null, groupFilterLable: null });

    options = options.filter(function(option) {
      return option != null;
    });
  }
  if (question.questionType_ID == 10) {
    options = masterTags?.map(option => {
      return { ...option, label: option.tag, value: option.tag_ID };
    });

    options = options.filter(function(option) {
      return option != null;
    });

    tagList =
      taskQuestion?.tags?.length > 0
        ? taskQuestion?.tags?.map(tag => {
            return { ...tag };
          })
        : [];

    tagList = tagList.filter(function(option) {
      return option != null;
    });
  }
  if (question.questionType_ID == 11) {
    options =
      ValueTypeChoices?.length > 0
        ? ValueTypeChoices?.map(val => {
            return { ...val, label: val.valueChoice, value: val.id };
          })
        : [];
  }

  let likerTOptions =
    question.questionType_ID == 4
      ? taskQuestion?.likertScaleName_array?.length > 0
        ? taskQuestion?.likertScaleName_array
        : []
      : [];

  let LikertScaleType = question.questionType_ID == 4 ? taskQuestion?.LikertScaleType : [];

  let questionTypesDefaultValue =
    questionTypes != undefined
      ? questionTypes[questionTypes?.findIndex(qt => qt.questionType_ID == question.questionType_ID)]
      : {};

  const handleQuestionFields = (_field, _value, _scaleType) => {
    let tempTaskQuestion = taskQuestion;

    if (_field === 'likertScaleOptions') {
      tempTaskQuestion = {
        ...tempTaskQuestion,
        likertScaleOptions: _value,
        scaleType: _scaleType
      };
    } else {
      tempTaskQuestion = { ...tempTaskQuestion, [_field]: _value };
    }

    if (_field == 'screenerQuestion_ID') {
      let index = screenerTypeOption.findIndex(option => option.question_ID == _value);
      tempTaskQuestion = { ...tempTaskQuestion, savedOption: screenerTypeOption[index].groupFilterLable };
    }
    if (_field == 'valueQuestion_ID') {
      let index = ValueTypeChoices.findIndex(option => option.id == _value);
      tempTaskQuestion = { ...tempTaskQuestion, savedOption: ValueTypeChoices[index]?.valueChoice };
    }
    setVisible(false);

    setTaskQuestion(tempTaskQuestion);
  };

  const handleOptions = choices => {
    let tempTaskQuestion = { ...taskQuestion, isEdit: true };
    tempTaskQuestion.choiceName_array = choices;

    setTaskQuestion(tempTaskQuestion);
  };

  const handleTags = tags => {
    let tempTaskQuestion = { ...taskQuestion, isEdit: true };
    tempTaskQuestion.tags = tags;

    setTaskQuestion(tempTaskQuestion);
  };

  const handleDeleteTag = tags => {
    handleTags(tags);
    storeOrUpdateQuestion(taskQuestion?.question_ID);
    handleTags(tags);
  };

  const closeHandler = e => {
    setPopup(e);
  };

  const changeQuestionType = e => {
    let tempTaskQuestion = taskQuestion;

    if ($.inArray(e.value, [1, 2, 7]) == -1) {
      tempTaskQuestion = { ...tempTaskQuestion, choiceName_array: [] };
    }
    tempTaskQuestion = {
      ...tempTaskQuestion,
      questionType_ID: e.value,
      oldQuestionType_ID: tempTaskQuestion.questionType_ID
    };

    setTaskQuestion(tempTaskQuestion);

    handleChangeQuestionType(+sectionIndex, +questionIndex, e.value, tempTaskQuestion);
  };

  const handleCheckboxrequired = e => {
    // setCheckBoxRequired(!checkBoxRequired);
    const newChecked = checkBoxRequired == 0 ? 1 : 0;
    setTaskQuestion(prevState => ({
      ...prevState,
      isRequired: newChecked
    }));
    setCheckBoxRequired(newChecked);
  };

  const handleShuffleToggle = e => {
    const newShuffleValue = shuffleEnabled == 0 ? 1 : 0;
    setTaskQuestion(prevState => ({
      ...prevState,
      toShuffle: newShuffleValue
    }));
    setShuffleEnabled(newShuffleValue);
  };

  const storeOrUpdateQuestion = (questionId, questionIndex) => {
    console.log('TASKK', task);
    let isAutomatedAnswer_array = [];
    if (
      taskQuestion.questionType_ID == 1 &&
      taskQuestion.choiceName_array.some(obj => {
        return obj.choiceName == '';
      })
    ) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (
      taskQuestion.questionType_ID == 2 &&
      taskQuestion.choiceName_array.some(obj => {
        return obj.choiceName == '';
      })
    ) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (
      taskQuestion.questionType_ID == 3 &&
      taskQuestion.choiceName_array.some(obj => {
        return obj.choiceName == '';
      })
    ) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (
      taskQuestion.questionType_ID == 7 &&
      taskQuestion.choiceName_array.some(obj => {
        return obj.choiceName == '';
      })
    ) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 4 &&
      !taskQuestion?.scaleType && 
      !taskQuestion?.LikertScaleType) {
      setScaleTypeVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 4 && taskQuestion.choiceName_array.length == 0) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (
      taskQuestion.questionType_ID == 4 &&
      taskQuestion.choiceName_array.some(obj => {
        return obj.choiceName == '';
      })
    ) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (
      taskQuestion.questionType_ID == 14 &&
      taskQuestion.choiceName_array.some(obj => {
        return obj.choiceName == '';
      })
    ) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 5 && taskQuestion.characterLimit == '') {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 6 && taskQuestion.characterLimit == '') {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 9 && taskQuestion.savedOption == null) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 10 && taskQuestion.buttonName == null) {
      setOptionVisible(true);
      saveQuestion = false;
    } else if (taskQuestion.questionType_ID == 11 && taskQuestion.savedOption == '') {
      setOptionVisible(true);
      saveQuestion = false;
    } else {
      saveQuestion = true;
    }
    if (taskQuestion.questionName == null || taskQuestion.questionName == '') {
      setVisible(true);
    }
    console.log('EASY0', taskQuestion.questionName != null && taskQuestion.questionName != '');

    if (taskQuestion.questionName != null && taskQuestion.questionName != '' && saveQuestion) {
      setOptionVisible(false);

      const formData = new FormData();
      formData.append('user_ID', userId);
      formData.append('questionPaperSection_ID', sectionId);
      formData.append('questionType_ID', taskQuestion.questionType_ID);
      formData.append('questionDescription', desc);
      formData.append('questionOrder', questionIndex + 1);
      formData.append('NewQuestionType_ID', taskQuestion.questionType_ID);
      formData.append(
        'OldQuestionType_ID',
        taskQuestion.oldQuestionType_ID != null ? taskQuestion.oldQuestionType_ID : taskQuestion.questionType_ID
      );
      formData.append('questionName', taskQuestion.questionName);
      if (taskQuestion?.tags) {
        formData.append('tags', JSON.stringify(taskQuestion.tags));
      }

      if (taskQuestion?.questionImage) {
        formData.append('questionImage', taskQuestion?.questionImage);
      } else {
        formData.append('questionImage', '');
      }
      //   formData.append('questionDescription', taskQuestion.questionDescription);
      formData.append('isRequired', checkBoxRequired);
      formData.append('toShuffle', shuffleEnabled);
      formData.append('isAutomatedQuestion', 0);

      let screenerTypePayload = {};
      let tagsPayload = {};

      switch (taskQuestion.questionType_ID) {
        case 1:
          isAutomatedAnswer_array = taskQuestion?.choiceName_array?.map((choice, index) => 0);

        case 2:
          isAutomatedAnswer_array = taskQuestion?.choiceName_array?.map((choice, index) => 0);

        case 3:
          console.log('Inside Case 3');
        case 4:
          const likertOptions = taskQuestion?.likertScaleOptions || taskQuestion?.likertScaleName_array || [];
          const scaleType = taskQuestion?.scaleType || taskQuestion?.LikertScaleType;

          formData.append(
            'choices',
            JSON.stringify(
              taskQuestion?.choiceName_array?.map(tq => {
                return tq.choiceName;
              })
            )
          );

          formData.append(
            'questionLikertScaleName',
            likertOptions
              ?.map(lt => lt.questionLikertScaleName)
              .toString()
              .replace(/[\[\]"]/g, '') || ''
          );

          formData.append('LikertScaleType', scaleType || '');
          break;
        case 5:
          console.log('Inside Case 5 ');

        case 6:
          // payload = { ...payload, "characterLimit": taskQuestion?.characterLimit };

          formData.append('characterLimit', taskQuestion?.characterLimit);
          break;
        case 7:
          // payload = {
          // 	...payload, "choices": taskQuestion?.choiceName_array?.map((tq) => {
          // 		return tq.choiceName
          // 	})
          // }

          isAutomatedAnswer_array = taskQuestion?.choiceName_array?.map((choice, index) => 0);

          formData.append(
            'choices',
            JSON.stringify(
              taskQuestion?.choiceName_array?.map(tq => {
                return tq.choiceName;
              })
            )
          );
          break;
        case 8:
          // payload = { ...payload, "maxFileSizeInMB": taskQuestion?.maxFileSizeInMB, "numberOfFiles": taskQuestion?.numberOfFiles };

          formData.append('maxFileSizeInMB', taskQuestion?.maxFileSizeInMB);
          formData.append('numberOfFiles', taskQuestion?.numberOfFiles);
          break;
        case 9:
          // payload = { ...payload, "screenerQuestion_ID": taskQuestion?.screenerQuestion_ID };
          const matchingScreenerQuestion = taskQuestion?.screenerQuestion?.find(
            question => question.groupFilterLable === taskQuestion?.savedOption
          );

          const screenerQuestion_ID = matchingScreenerQuestion ? matchingScreenerQuestion.question_ID : null;

          formData.append('screenerQuestion_ID', screenerQuestion_ID);

          screenerTypePayload = {
            user_ID: userId,
            screenerQuestion_ID: screenerQuestion_ID,
            question_ID: questionId
          };

          break;
        case 10:
          // payload = { ...payload, "buttonName": taskQuestion?.buttonName }

          formData.append('buttonName', taskQuestion?.buttonName);
          formData.append('project_ID', projectId);

          tagsPayload = {
            user_ID: userId,
            question_ID: questionId,
            project_ID: projectId,
            tags: taskQuestion?.tags ? taskQuestion?.tags : [],
            buttonName: taskQuestion?.buttonName
          };
          break;
        case 11:
          // payload = { ...payload, "valueQuestion_ID": taskQuestion?.valueQuestion_ID };

          formData.append(
            'valueQuestion_ID',
            taskQuestion?.valueQuestion_ID == undefined ? 1 : taskQuestion?.valueQuestion_ID
          );
          formData.append('SavedOption', taskQuestion?.savedOption);
          break;
        case 12:
          // payload = { ...payload, "maxFileSizeInMB": taskQuestion?.maxFileSizeInMB, "numberOfFiles": taskQuestion?.numberOfFiles };

          formData.append('maxFileSizeInMB', 5);
          formData.append('numberOfFiles', taskQuestion?.numberOfFiles);
          break;
        case 13:
          // payload = { ...payload, "maxFileSizeInMB": taskQuestion?.maxFileSizeInMB, "numberOfFiles": taskQuestion?.numberOfFiles };

          formData.append('maxFileSizeInMB', 10);
          formData.append('numberOfFiles', taskQuestion?.numberOfFiles);
          break;

        case 14:
          isAutomatedAnswer_array = taskQuestion?.choiceName_array?.map((choice, index) => 0);

          formData.append(
            'choices',
            JSON.stringify(
              taskQuestion?.choiceName_array?.map(tq => {
                return tq.choiceName;
              })
            )
          );
          formData.append(
            'questionLikertScaleName',
            tempLikerTOptions?.map(lt => {
              return lt.questionLikertScaleName;
            })
          );

          

          break;
          case 15:
            // payload = { ...payload, "maxFileSizeInMB": taskQuestion?.maxFileSizeInMB, "numberOfFiles": taskQuestion?.numberOfFiles };
  
            formData.append('maxFileSizeInMB', 10);
            formData.append('numberOfFiles', taskQuestion?.numberOfFiles);
            break;
        default:
          break;
      }
      formData.append('isAutomatedAnswer', isAutomatedAnswer_array);
      formData.append('mediaDisclaimer', null);
      if (formData.get('NewQuestionType_ID') == formData.get('OldQuestionType_ID')) {
        formData.delete('NewQuestionType_ID');
        formData.delete('OldQuestionType_ID');
      }

      if (questionId === null) {
        dispatch({
          type: 'REQUEST_ADD_QUESITON',
          formData: formData
        });

        handleProjectTaskTab();

        // dispatch({
        // 	type: 'REQUEST_ADD_QUESITON',
        // 	formData: payload
        // });
      } else {
        // payload = { ...payload, "questions_ID": questionId };
        console.log('WELP ELSE!!');

        formData.append('questions_ID', questionId);
        dispatch({
          type: 'REQUEST_QUESTION_EDIT',
          formData: formData
        });

        if (taskQuestion.questionType_ID == 9) {
          dispatch({
            type: 'REQUEST_ADD_SCREENER_VALIDATION_QUESTION',
            formData: screenerTypePayload
          });
        }
        if (taskQuestion.questionType_ID == 10) {
          dispatch({
            type: 'REQUEST_ADD_QUESTION_TAGS',
            formData: tagsPayload
          });
        }
      }

      let tempTaskQuestion = taskQuestion;
      tempTaskQuestion = { ...tempTaskQuestion, isEdit: false };

      setAddQuestionDisable(false);
      setTaskQuestion(tempTaskQuestion);

      if (task.taskType === 'Form') {
        formTaskSections[sectionIndex].questionName_array[questionIndex] = tempTaskQuestion;
        setFormTaskSections(formTaskSections);
        console.log('----------------Triggerrrrrrrrrrrrrrrrrrrrrrrrrrrr-------------');
      } else {
        interviewTaskSections[sectionIndex].questionName_array[questionIndex] = tempTaskQuestion;
        setInterviewTaskSections(interviewTaskSections);
      }
      handleSetEditing();
    }
  };

  const editquest = () => {
    setEditQuestionId(taskQuestion?.question_ID);
    setQuestionIndex(questionIndex);
    setNewQuestionIndex(null);
    setDeleteQuestionIndex(null);
    setEditQuestionIndex(sectionIndex);
    setIsEdit(true);
  };
  const editQuestion = () => {
    let tempTaskQuestion = taskQuestion;
    tempTaskQuestion = { ...tempTaskQuestion, isEdit: true };
    console.log('quess', tempTaskQuestion);

    setTaskQuestion(tempTaskQuestion);
    setEditQuestionId(taskQuestion?.question_ID);
    setQuestionIndex(questionIndex);
    setNewQuestionIndex(null);
    setDeleteQuestionIndex(null);
    setEditQuestionIndex(sectionIndex);
    setIsEdit(true);

    console.log('SUM ISSUE', isEdit);
  };

  const duplicateQuestion = (sIndex, qIndex, setCheckBoxRequired) => {
    handleDuplicateQuestion(sIndex, qIndex, setCheckBoxRequired);
  };

  const deleteQuestion = (sIndex, qIndex) => {
    handleDeleteQuestion(sIndex, qIndex);
  };

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // handleQuestionFields("questionImage", objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  // console.log(preview);
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    handleQuestionFields('questionImage', e.target.files[0]);
  };
  // console.warn(taskQuestion.questionImage);

  return (
    question != null && (
      <CustomCard className="mb-4">
        <QuestionWrapper className="question-wrapper">
          <Heading type="6" fontWeight="500" color="var(--quadColor)" className="q-no">
            {+sectionIndex + +1 + '.' + (+questionIndex + +1)}
          </Heading>
          {/* {console.log("questionIndex", questionIndex)} */}
          <div className="row">
            <div className="col-md-8 col-xs-6 d-flex flex-column">
              {!taskQuestion?.isEdit ? (
                <>
                  <Heading type="6" color="var(--greyBlue)" fontSize="16px" fontWeight="700" className="mb-0 pb-0 mt-2">
                    {taskQuestion.questionName}
                  </Heading>

                  {taskQuestion.questionDescription !== 'null' && taskQuestion.questionDescription?.length > 0 ? (
                    <Heading
                      type="6"
                      color="var(--greyBlue)"
                      fontSize="16px"
                      fontWeight="700"
                      className="mb-0 pb-0 mt-2"
                      style={{ display: 'flex' }}
                    >
                      <Heading
                        type="4"
                        color="var(--greyBlue)"
                        fontSize="13px"
                        fontWeight="500"
                        className="mb-0 pb-0 mt-0"
                        style={{ display: 'inline-block' }}
                      >
                        Description:
                      </Heading>
                      <Heading
                        type="5"
                        color="var(--greyBlue)"
                        fontSize="13px"
                        fontWeight="700"
                        className="mb-0 pb-0 mt-0 pl-2"
                        style={{ display: 'inline-block' }}
                      >
                        {'  '} {taskQuestion.questionDescription}
                      </Heading>
                    </Heading>
                  ) : (
                    ''
                  )}

                  {(taskQuestion.presignedUrlQuestionImage || selectedFile) && (
                    <img
                      src={preview ? preview : taskQuestion.presignedUrlQuestionImage}
                      width="250"
                      height="150"
                      style={{ objectFit: 'contain', marginTop: '5%', marginBottom: '5%' }}
                    />
                  )}
                </>
              ) : (
                <>
                  <InputText
                    showCharacters={false}
                    fontSize="14px"
                    value={taskQuestion.questionName}
                    placeholder={'Add question'}
                    width="390px"
                    style={{
                      color: 'var(--greyBlue)',
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                    onChange={e => handleQuestionFields('questionName', e.target.value)}
                  />

                  <InputText
                    showCharacters={false}
                    fontSize="14px"
                    value={taskQuestion.questionDescription}
                    placeholder={'Add a description'}
                    width="390px"
                    style={{
                      color: 'var(--greyBlue)',
                      fontWeight: '700',
                      fontSize: '16px'
                    }}
                    onChange={e => setDesc(e.target.value)}
                  />

                  {/* <CustomTextArea
                    fontSize="0.75rem"
                    showCharacters={false}
                    placeholder="Add a description"
                    className="mt-1"
                    value={desc}
                    width={'400px'}
                    onChange={e => setDesc(e.target.value)}
                  /> */}

                  <Text type="2">
                    {visible ? (
                      <p className="mt-2" style={{ color: 'red', fontSize: '11px' }}>
                        Please enter the question
                      </p>
                    ) : null}
                  </Text>
                  {isInterview === false ? (
                    <div>
                      <input
                        type="file"
                        onChange={e => onSelectFile(e)}
                        style={{ margin: '3% 0' }}
                        // onChange={(e) =>  handleQuestionFields("questionImage", e.target.files[0])}
                        // onChange={e => { handleQuestionFields("questionImage", e.target.files[0]); onSelectFile(e)}}
                      />
                      <div>
                        {/* {taskQuestion.presignedUrlQuestionImage && <img src={taskQuestion.presignedUrlQuestionImage} width='150' height='150' style={{ objectFit: 'cover' }} />} */}
                        {/* {selectedFile && <img
											src={preview}
											width='150'
											height='150'
											style={{ objectFit: 'cover' }}
										/>} */}
                        <img
                          src={preview ? preview : taskQuestion.presignedUrlQuestionImage}
                          width="250"
                          height="150"
                          style={{ objectFit: 'contain', float: 'left' }}
                        />
                      </div>
                    </div>
                  ) : (
                    ' '
                  )}
                </>
              )}
              <br />
              <div>
                {
                  {
                    1: (
                      <SingleChoiceQuestion
                        edit={taskQuestion?.isEdit}
                        options={options}
                        handleOptions={handleOptions}
                      />
                    ),
                    2: (
                      <MultipleChoiceQuestion
                        edit={taskQuestion?.isEdit}
                        options={options}
                        handleOptions={handleOptions}
                      />
                    ),
                    3: <SliderQuestion edit={taskQuestion?.isEdit} options={options} handleOptions={handleOptions} />,
                    4: (
                      <LikerTQuestion
                        options={options}
                        LikertScaleType={LikertScaleType}
                        likerTOptions={likerTOptions}
                        edit={taskQuestion?.isEdit}
                        handleOptions={handleOptions}
                        handleScaleOptions={(scaleOptions, scaleType) =>
                          handleQuestionFields('likertScaleOptions', scaleOptions, scaleType)
                        }
                        initialScaleType={taskQuestion?.LikertScaleType}
                      />
                    ),
                    5: (
                      <TextAnswerQuestion
                        edit={taskQuestion?.isEdit}
                        // description={taskQuestion?.questionDescription}
                        characterLimit={taskQuestion?.characterLimit}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    6: (
                      <TextAnswerQuestion
                        edit={taskQuestion?.isEdit}
                        // description={taskQuestion?.questionDescription}
                        characterLimit={taskQuestion?.characterLimit}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    7: (
                      <SingleSevenQuestion
                        edit={taskQuestion?.isEdit}
                        options={options}
                        handleOptions={handleOptions}
                      />
                    ),
                    8: (
                      <ImageUploadQuestion
                        edit={taskQuestion?.isEdit}
                        numberOfFiles={taskQuestion?.numberOfFiles}
                        maxFileSizeInMB={taskQuestion?.maxFileSizeInMB}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    9: (
                      <ScreenerTypeQuestion
                        edit={taskQuestion?.isEdit}
                        options={options}
                        savedOption={taskQuestion?.savedOption}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    10: (
                      <RecordingQuestion
                        edit={taskQuestion?.isEdit}
                        buttonName={taskQuestion?.buttonName}
                        tagList={tagList}
                        options={options}
                        handleTextFields={handleQuestionFields}
                        handleTags={handleTags}
                        handleDeleteTag={handleDeleteTag}
                      />
                    ),
                    11: (
                      <ValueQuestion
                        edit={taskQuestion?.isEdit}
                        options={options}
                        savedOption={taskQuestion?.savedOption}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    12: (
                      <AudioQuestion
                        edit={taskQuestion?.isEdit}
                        numberOfFiles={taskQuestion?.numberOfFiles}
                        maxFileSizeInMB={5}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    13: (
                      <VideoQuestion
                        edit={taskQuestion?.isEdit}
                        numberOfFiles={taskQuestion?.numberOfFiles}
                        maxFileSizeInMB={10}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    15: (
                      <VideoQuestion
                        edit={taskQuestion?.isEdit}
                        numberOfFiles={taskQuestion?.numberOfFiles}
                        maxFileSizeInMB={10}
                        handleTextFields={handleQuestionFields}
                      />
                    ),
                    14: <RankingQuestion edit={taskQuestion?.isEdit} options={options} handleOptions={handleOptions} />
                  }[question.questionType_ID]
                }
              </div>
            </div>
            <div className="col-md-3 offset-md-1 col-xs-6 d-flex flex-column justify-content-start">
              {/* <DesignDropdown
                options={questionTypes}
                value={questionTypesDefaultValue}
                isDisabled={!taskQuestion?.isEdit}
                onChange={e => changeQuestionType(e)}
              /> */}
              <Tooltip
                active={showTooltipisdResponded && taskQuestion?.isResponded === 1 && taskQuestion?.isEdit}
              >{`You Can't Change Question, Response is already submitted.`}</Tooltip>

              <div
                onMouseEnter={() => setShowTooltipisResponded(true)}
                onMouseLeave={() => setShowTooltipisResponded(false)}
                onClick={() => setShowTooltipisResponded(true)}
              >
                <DesignDropdown
                  options={questionTypes}
                  value={questionTypesDefaultValue}
                  isDisabled={!taskQuestion?.isEdit || taskQuestion?.isResponded === 1}
                  onChange={e => changeQuestionType(e)}
                />
              </div>

              <div className="d-flex mt-2 mb-3">
                {showCheckbox && !taskQuestion.isEdit ? (
                  <>
                    <StyledCheckbox
                      fontSize="0.875rem"
                      className="mt-2 pb-0 "
                      outerHeight="22px"
                      outerWidth="22px"
                      borderRadius="5px"
                      border="1px solid #9EBE21"
                    >
                      <input
                        type="checkbox"
                        checked={taskQuestion?.question_ID && checkBoxRequired}
                        onChange={handleCheckboxrequired}
                        disabled={true}
                      />
                      <span className="outer">
                        <Icon className="material-icons-round inner">done</Icon>
                      </span>
                    </StyledCheckbox>
                    <span
                      style={{
                        fontSize: '13px',
                        fontWeight: 'lighter',
                        color: 'var(--textColor)',
                        marginTop: '0.625rem'
                      }}
                    >
                      {' Required Question'}
                    </span>
                  </>
                ) : (
                  showCheckbox && (
                    <>
                      <StyledCheckbox
                        fontSize="0.875rem"
                        className="mt-2 pb-0 "
                        outerHeight="22px"
                        outerWidth="22px"
                        borderRadius="5px"
                        border="1px solid #9EBE21"
                      >
                        <input type="checkbox" checked={checkBoxRequired} onChange={handleCheckboxrequired} />
                        <span className="outer">
                          <Icon className="material-icons-round inner">done</Icon>
                        </span>
                      </StyledCheckbox>
                      <span
                        style={{
                          fontSize: '13px',
                          fontWeight: 'lighter',
                          color: 'var(--textColor)',
                          marginTop: '0.625rem'
                        }}
                      >
                        {' '}
                        Required Question
                      </span>
                    </>
                  )
                )}
              </div>
              <div className="d-flex mt-2 mb-3">
                {showCheckbox &&
                  taskQuestion.questionType_ID === 14 &&
                  (!taskQuestion.isEdit ? (
                    <>
                      <StyledCheckbox
                        fontSize="0.875rem"
                        className="mt-2 pb-0"
                        outerHeight="22px"
                        outerWidth="22px"
                        borderRadius="5px"
                        border="1px solid #9EBE21"
                      >
                        <input
                          type="checkbox"
                          checked={taskQuestion?.question_ID && shuffleEnabled}
                          onChange={handleShuffleToggle}
                          disabled={true}
                        />
                        <span className="outer">
                          <Icon className="material-icons-round inner">done</Icon>
                        </span>
                      </StyledCheckbox>
                      <span
                        style={{
                          fontSize: '13px',
                          fontWeight: 'lighter',
                          color: 'var(--textColor)',
                          marginTop: '0.625rem'
                        }}
                      >
                        {' Shuffle Options'}
                      </span>
                    </>
                  ) : (
                    <>
                      <StyledCheckbox
                        fontSize="0.875rem"
                        className="mt-2 pb-0"
                        outerHeight="22px"
                        outerWidth="22px"
                        borderRadius="5px"
                        border="1px solid #9EBE21"
                      >
                        <input
                          type="checkbox"
                          checked={taskQuestion?.question_ID && shuffleEnabled}
                          onChange={handleShuffleToggle}
                        />
                        <span className="outer">
                          <Icon className="material-icons-round inner">done</Icon>
                        </span>
                      </StyledCheckbox>
                      <span
                        style={{
                          fontSize: '13px',
                          fontWeight: 'lighter',
                          color: 'var(--textColor)',
                          marginTop: '0.625rem'
                        }}
                      >
                        {' Shuffle Options'}
                      </span>
                    </>
                  ))}
              </div>
            </div>
          </div>

          <Text type="2">
            {optionVisible ? (
              <p className="mt-2" style={{ color: 'red', fontSize: '11px' }}>
                Fill in all details
              </p>
            ) : null}
          </Text>
          <Text type="2">
            {scaletypeVisible ? (
              <p className="mt-2" style={{ color: 'red', fontSize: '11px' }}>
                Fill all scale details
              </p>
            ) : null}
          </Text>

          {taskQuestion?.isEdit ? (
            <>
              {console.log(' IS EDIT TASK QUESTION ', taskQuestion)}
              {taskQuestion.question_ID == null ? (
                <div
                  className="iconContainer"
                  style={{ position: 'absolute', right: '-20px', top: '-20px' }}
                  onClick={() => handleDeleteQuestion(sectionIndex, questionIndex)}
                >
                  <Icon style={{ cursor: 'pointer' }}>close</Icon>
                </div>
              ) : (
                ''
              )}

              <div className="question-action d-flex align-items-center">
                <CustomButton
                  variant="outlined"
                  buttonColor="var(--sapGreen)"
                  onClick={() => storeOrUpdateQuestion(taskQuestion?.question_ID, questionIndex, task)}
                  value={switchvalue}
                  className="mb-0"
                >
                  <Icon>done</Icon>
                  Save Changes
                </CustomButton>
              </div>
            </>
          ) : (
            <div className="question-action d-flex align-items-center">
              <div onClick={() => handleEditQuestion(sectionIndex, questionIndex)}>
                <ActionIcon>create</ActionIcon>
              </div>
              <div onClick={() => duplicateQuestion(sectionIndex, questionIndex, setCheckBoxRequired)}>
                <ActionIcon>content_copy</ActionIcon>
              </div>
              <div onClick={() => setPopup(!popup)}>
                <ActionIcon>delete</ActionIcon>
                <CustomPopup
                  onClose={closeHandler}
                  showed={popup}
                  heading="Confirm delete"
                  secondheading="Are you sure you want to delete this Question?"
                  secondheading2="Once deleted, it cant be retrieved."
                >
                  <CustomButtont
                    fontSize="14px"
                    fontWeight="300"
                    color="black"
                    size="medium"
                    style={{ justifyContent: 'space-between', marginRight: '20px' }}
                    onClick={() => {
                      handleDeleteQuestion(sectionIndex, questionIndex);
                      setPopup(false);
                    }}
                  >
                    Delete
                  </CustomButtont>
                </CustomPopup>
              </div>
            </div>
          )}
          <br />
          <div></div>
          {enableEdit && questionmessage && (
            <center>
              <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                <div>{questionmessage}</div>
              </div>
            </center>
          )}
        </QuestionWrapper>
      </CustomCard>
    )
  );
}

export default DesignQuestion;
