import {useState, useRef, useEffect} from 'react'
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  max,
  schemeTableau10,
  scaleOrdinal
} from "d3";
import { AiOutlineSwap, AiOutlineSortAscending, AiOutlineSortDescending, AiOutlineCamera } from "react-icons/ai";
import { toPng } from "html-to-image";

export default function MultiBarGraph(props){
    const svgRef = useRef();
    const wrapperRef = useRef();
    const chartRef = useRef();
    
    const colors = schemeTableau10;
    const [dkeys, setDkeys] = useState([]);
    const [dcolors, setDcolors] = useState({});
    const [swapAxes, setSwapAxes] = useState(false);
    const [sortOrder, setSortOrder] = useState('none');
    const [isDataReady, setIsDataReady] = useState(false);

    // Define margin as a constant at component level
    const margin = { 
        top: 30,
        right: 30,
        bottom: 50,
        left: 60
    };

    useEffect(() => {
        // Check if data is available
        if (!props?.data || !props?.data.length || !props.secondary) {
            setIsDataReady(false);
            return;
        }

        const svg = select(svgRef.current);
        const { width, height } = wrapperRef.current.getBoundingClientRect();
        
        // Check if dimensions are valid
        if (!width || !height) {
            return;
        }

        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Clear only the content group, not the entire SVG
        svg.select('.content').selectAll('*').remove();

        // Process data for multi-bar graph
        let categories = [...new Set(props.data.map(d => d.cn1))];
        let subgroups = [...new Set(props.data.map(d => d[props.secondary]))];
        
        // Validate data structure
        if (!categories.length || !subgroups.length) {
            return;
        }

        // Create processed data structure
        let processedData = categories.map(cat => {
            let obj = { category: cat };
            subgroups.forEach(sub => {
                const matchingItem = props.data.find(d => d.cn1 === cat && d[props.secondary] === sub);
                obj[sub] = matchingItem ? matchingItem.anscnt : 0;
            });
            return obj;
        });

        // Add sorting logic after processedData creation
        if (sortOrder !== 'none') {
            processedData.sort((a, b) => {
                const sumA = subgroups.reduce((acc, key) => acc + a[key], 0);
                const sumB = subgroups.reduce((acc, key) => acc + b[key], 0);
                return sortOrder === 'asc' ? sumA - sumB : sumB - sumA;
            });
            // Update categories order after sorting
            categories = processedData.map(d => d.category);
        }

        setDkeys(subgroups);

        // Set up scales
        const xScale = swapAxes
            ? scaleLinear()
                .domain([0, max(processedData, d => max(subgroups, key => d[key]))])
                .range([0, innerWidth])
            : scaleBand()
                .domain(categories)
                .range([0, innerWidth])
                .padding(0.2);

        const yScale = swapAxes
            ? scaleBand()
                .domain(categories)
                .range([innerHeight, 0])
                .padding(0.2)
            : scaleLinear()
                .domain([0, max(processedData, d => max(subgroups, key => d[key]))])
                .range([innerHeight, 0]);

        // Create color scale
        const color = scaleOrdinal()
            .domain(subgroups)
            .range(colors.slice(0, subgroups.length));

        let cd = {};
        subgroups.forEach((key) => {
            cd[key] = color(key);
        });
        setDcolors(cd);

        // Set up inner scale for grouped bars
        const xSubScale = swapAxes
            ? null
            : scaleBand()
                .domain(subgroups)
                .range([0, xScale.bandwidth()])
                .padding(0.05);

        const ySubScale = swapAxes
            ? scaleBand()
                .domain(subgroups)
                .range([0, yScale.bandwidth()])
                .padding(0.05)
            : null;

        // Update axes
        svg
            .select(".x-axis")
            .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
            .call(swapAxes ? axisBottom(xScale) : axisBottom(xScale))
            .selectAll("text")
            .style("font-size", "12px")
            .style("font-family", "Noto Sans");

        svg
            .select(".y-axis")
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .call(swapAxes ? axisLeft(yScale) : axisLeft(yScale))
            .selectAll("text")
            .style("font-size", "12px")
            .style("font-family", "Noto Sans");

        // Create or update content group
        const contentGroup = svg
            .select(".content")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // Create groups for each category
        const categoryGroups = contentGroup
            .selectAll(".category")
            .data(processedData)
            .join("g")
            .attr("class", "category")
            .attr("transform", d => 
                swapAxes 
                    ? `translate(0, ${yScale(d.category)})` 
                    : `translate(${xScale(d.category)}, 0)`
            );

        // Create bars
        categoryGroups.selectAll("rect")
            .data(d => subgroups.map(key => ({
                key,
                value: d[key],
                category: d.category
            })))
            .join("rect")
            .attr("x", d => 
                swapAxes 
                    ? 0 
                    : xSubScale(d.key)
            )
            .attr("y", d => 
                swapAxes 
                    ? ySubScale(d.key)
                    : yScale(d.value)
            )
            .attr("width", d => 
                swapAxes 
                    ? xScale(d.value)
                    : xSubScale.bandwidth()
            )
            .attr("height", d => 
                swapAxes 
                    ? ySubScale.bandwidth()
                    : innerHeight - yScale(d.value)
            )
            .attr("fill", d => cd[d.key]);

        // Add value labels
        categoryGroups.selectAll(".value-label")
            .data(d => subgroups.map(key => ({
                key,
                value: d[key],
                category: d.category
            })))
            .join("text")
            .attr("class", "value-label")
            .text(d => d.value || '')
            .attr("x", d => 
                swapAxes 
                    ? xScale(d.value) + 5
                    : xSubScale(d.key) + xSubScale.bandwidth() / 2
            )
            .attr("y", d => 
                swapAxes 
                    ? ySubScale(d.key) + ySubScale.bandwidth() / 2
                    : yScale(d.value) - 5
            )
            .attr("text-anchor", swapAxes ? "start" : "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-family", "Noto Sans")
            .attr("font-size", "11px")
            .attr("fill", "black");

        setIsDataReady(true);
    }, [props, swapAxes, sortOrder]);

    const handleSort = () => {
        setSortOrder(current => {
            if (current === 'none') return 'asc';
            if (current === 'asc') return 'desc';
            return 'none';
        });
    };

    const handleExport = () => {
        const chartContainer = chartRef.current;

        if (chartContainer) {
            toPng(chartContainer, { 
                cacheBust: true,
                height: chartContainer.offsetHeight + 80, // Add extra height for legends
                style: {
                    padding: '10px' // Add some padding to prevent cropping
                }
            })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "chart.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                console.error("Error exporting chart as image:", err);
            });
        }
    };

    // Early return if data isn't ready
    if (!props?.data || !props?.data.length) {
        return (
            <div style={{ 
                width: "100%", 
                height: "250px", 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center" 
            }}>
                Loading...
            </div>
        );
    }

    return (
        <>
          <div
            ref={wrapperRef}
            style={{ 
              width: "100%", 
              minHeight: "300px",
              marginBottom: "1rem",
              position: "relative"
            }}
          >
            <div style={{ 
              marginBottom: "1rem",
              display: "flex",
              gap: "10px"
            }}>
              <button 
                onClick={() => setSwapAxes(!swapAxes)}
                disabled={!isDataReady}
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "#2361a0",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: isDataReady ? "pointer" : "not-allowed",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  opacity: isDataReady ? 1 : 0.7
                }}
              >
                <AiOutlineSwap /> Swap Axes
              </button>
              <button 
                onClick={handleSort}
                disabled={!isDataReady}
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: sortOrder === 'none' ? "#6c757d" : "#2361a0",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: isDataReady ? "pointer" : "not-allowed",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  opacity: isDataReady ? 1 : 0.7
                }}
              >
                {sortOrder === 'asc' ? <AiOutlineSortAscending /> : 
                 sortOrder === 'desc' ? <AiOutlineSortDescending /> : 
                 <AiOutlineSortAscending />}
                Sort {sortOrder === 'asc' ? '↑' : sortOrder === 'desc' ? '↓' : ''}
              </button>
              <button
                onClick={handleExport}
                disabled={!isDataReady}
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "#2361a0",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: isDataReady ? "pointer" : "not-allowed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "48px",
                  height: "38px",
                  opacity: isDataReady ? 1 : 0.7
                }}
              >
                <AiOutlineCamera style={{ fontSize: "1.5rem" }} />
              </button>
            </div>
            
            <div 
              ref={chartRef}
              style={{ 
                position: 'relative', 
                height: "250px",
                marginBottom: "80px",
                backgroundColor: "white"
              }}
            >
              <svg 
                ref={svgRef} 
                style={{ 
                  width: "100%", 
                  height: "100%",
                  overflow: "visible",
                  marginLeft: "10px",
                  marginBottom: "40px"
                }}
              >
                <g className="x-axis" />
                <g className="y-axis" />
                <g className="content" />
              </svg>

              {isDataReady && (
                <div style={{ 
                  position: 'absolute',
                  bottom: "-80px",
                  left: "0",
                  right: "0",
                  paddingLeft: margin.left + "px"
                }}>
                  <div style={{fontSize:'12px', marginBottom:'4px'}}>Contribution</div>
                  <div style={{ 
                    width: "100%", 
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px'
                  }}>
                    {dkeys.map((d,i) => (
                      <div 
                        style={{
                          display:'flex', 
                          alignItems:'center',
                          marginRight: '12px'
                        }} 
                        key={'color_'+d}
                      >
                        <div style={{
                          width:'12px', 
                          height:'12px', 
                          backgroundColor:dcolors[d]
                        }}>&nbsp;</div>
                        <div style={{
                          marginLeft:'4px', 
                          fontSize:'11px'
                        }}>{d}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
    );
}