import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Divider, Heading, Icon, ActionButton, Text } from '../../common';
import ProjectWrapper from '../Project/ProjectWrapper';
import contact_search from '../../images/contact_search.svg';
import refresh from '../../images/refresh.svg';
import corner from '../../images/corner.svg';
import location from '../../images/location.svg';
import heart from '../../images/heart.svg';
import video from '../../images/video.svg';
import images from '../../images/images.svg';
import audio from '../../images/audioNew.svg';
import bar_chart from '../../images/bar_chart.svg';
import star from '../../images/star.svg';
import comment from '../../images/comment.svg';
import like from '../../images/like.svg';
import dislike from '../../images/dislike.svg';
import DropdownBlue from '../../images/dropdown_blue.svg';
import whiteDropdown from '../../images/white-dropdown.svg';
import save from '../../images/save.svg';
import download_csv from '../../images/download_csv.svg';
import report_expand from '../../images/report_expand.svg';
import report_expand_active from '../../images/report_expand_active.svg';
import empty_report from '../../images/empty_report.svg';
import edit_darkgreen from '../../images/edit_darkgreen.svg';
import delete_darkGreen from '../../images/delete_darkGreen.svg';
import eye_darkGreen from '../../images/eye_darkGreen.svg';
import tag_blue from '../../images/tag_blue.svg';
import { Checkbox, Dropdown, TaskMenu, DeletableDropdown } from '../../components/form';
import CustomPopup from '../../common/CustomPopup';
import { components } from 'react-select';
import AnalysisList from './AnalysisList';
import EditableInput from '../../common/EditableInput';
import EditDeepBlue from '../../images/editDeepBlue.svg';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import PageLoader from '../PageLoader';
import {
  ADD_TO_REPORT,
  REQUEST_ADD_COHORT,
  REQUEST_ANALYSIS_DATA,
  REQUEST_FILTER_DATA,
  REQUEST_STICH_VIDEO,
  REQUEST_ANALYSIS_CLIPS_DATA,
  REQUEST_ANALYSIS_CSV,
  REQUEST_ANALYSIS_CSV_WITH_CODE,
  REQUEST_ANALYSIS_CLIPS_WITHOUT_URL
} from '../../constants/analysis/analysisConstants';
import { REQUEST_CHECK_SUMMARY } from '../../constants/analysis/checkSummaryConstants';
import { selection } from 'd3-selection';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RescheduleToast.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
window.isTagAdded = false;

const FilterContainer = styled(Card)`
  background: var(--darkWhite);
  padding: 0;
  display: flex;
  margin: 0;
  padding: 0.5rem 0;
  justify-content: center;
  border-radius: 5px;
  box-shadow: none;
  width: 82%;
  display: flex;
  justify-content: space-between;

  .filter {
    width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    position: 'fixed';

    // padding: 0 0.5rem;
    // .filter_icon {
    // 	margin: 0 0.4rem;
    // }
    // &:first-of-type {
    // 	padding-left: 0.4rem;
    // }

    // .filter_icon:first-of-type {
    // 	margin-right: 0.4rem;
    // 	margin-left: 0;
    // }
    // .filter_icon:last-child {
    // 	margin-left: 0.4rem;
    // 	margin-right: 0;
    // }
  }

  .filter:last-child {
    border-right: none;
  }
`;

const BtnAssignGroup = styled.a`
  background: transparent none repeat scroll 0% 0%;
  border: 0px none;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--quadColor);
  padding: 0.25rem;
  text-align: center;
`;

const ResultContainer = styled(Card)`
  // min-width: 825px;
  border: 1px solid var(--greyBlue30);
  padding: 0;
  // display: flex;
  margin: 0;
  padding: 0.2rem 0.5rem;
  // justify-content: center;
  border-radius: 5px;
  box-shadow: none;

  .ResultCount {
    margin: 0 1rem;
  }
  .ResultCount:first-of-type {
    margin-left: 0;
  }
`;

const Title = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: var(--seaBlue);
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0;

  // padding-left: 5px;

  &.icon_title {
    margin-left: 1rem;

    @media (min-width: 1424px) {
      margin-left: 1.35rem;
    }
    @media (min-width: 1624px) {
      margin-left: 1.85rem;
    }
  }
`;
const DropdownContainer = styled(Card)`
  background: var(--darkWhite);
  margin: 0;
  padding: 0;
  padding: 0.5rem 1.25rem;
  box-shadow: none;
  border-radius: 5px;
  width: 18%;
  .icon {
    margin-left: 5px;
  }
`;
const FilterIconContainer = styled.div`
  // padding: 2px;
  // margin: 2px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${props => (props.isOpen || props.active ? 'var(--highlightGrey)' : 'transparent')};

  border-radius: 5px;
  .iconContainer {
    height: 35px;
  }
  .icon {
    padding: 7px 5px;
    // height: 35px;
    // width: 100%;
    // object-fit: contain;
  }
  .corner {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const PopupFilter = styled.div`
  background: white;
  position: absolute;
  padding: 0;
  margin: 0;
  z-index: 1050;
  left: 0;
  top: 33px;
  border-top: 3px solid var(--highlightGrey);
  padding: 1rem 1.5rem;
  width: auto;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
`;
const Badge = styled.div`
  width: 15px;
  height: 15px;
  background: var(--deepBlue);
  border-radius: 7.5px;
  color: var(--white);
  font-size: 9px;
  display: flex;
  align-items: Center;
  justify-content: center;
  position: absolute;
  right: -7px;
  top: -7px;
`;

const IconCustom = styled(Icon)`
  font-size: 0.75rem;
  margin-left: 5px;
`;

const CustomButtont = styled(Button)`
  padding: 2px 1rem;
  color: var(--white);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
`;

const SpanCustom = styled.span`
  margin-right: 3px;

  @media (max-width: 1344px) {
    display: none;
  }
`;

const ImgCustom = styled.img`
  @media (max-width: 1344px) {
    display: none;
  }
`;

const FilterIcon = ({
  src,
  multi_choice = false,
  count = false,
  active = false,
  children,
  isOpen,
  filters,
  setFilters = null,
  hitsTagId = null,
  dataType = [],
  setDataType = () => {},
  dtype = '',
  setIconClicked = () => {},
  setIsOpen = () => {}
}) => {
  const [activeIcon, setActiveIcon] = useState(dataType?.indexOf(dtype) != -1 || active);
  if (activeIcon) setIconClicked(true);
  else setIconClicked(false);

  const handleToggle = () => {
    let t = dataType;
    if (t.indexOf(dtype) == -1) {
      t.push(dtype);
    } else {
      t.splice(t.indexOf(dtype), 1);
    }
    setDataType([...t]);
    setIsOpen(prev => !prev);
    setActiveIcon(prev => !prev);
  };
  const openPopup = () => {
    setIsOpen(true);
  };
  const closePopup = () => {
    setIsOpen(false);
    //setActiveIcon(false);
  };
  return (
    <>
      <FilterIconContainer
        isOpen={isOpen}
        active={(count && count > 0) || activeIcon}
        className="filter_icon"
        onMouseEnter={openPopup}
        onMouseLeave={closePopup}
      >
        <div
          className="iconContainer"
          onClick={handleToggle}
          style={{ cursor: 'pointer' }}
          //onMouseEnter={openPopup}
          //onMouseLeave={closePopup}
        >
          {count && count > 0 && <Badge>{count}</Badge>}
          <img src={src} className="icon " />
          {multi_choice && !isOpen && <img src={corner} className="corner" />}
        </div>
        {multi_choice && isOpen && <PopupFilter onMouseLeave={closePopup}>{children}</PopupFilter>}
      </FilterIconContainer>
    </>
  );
};

// Only for Videoclips
const FilterIconVideoClip = ({
  src,
  multi_choice = false,
  count = false,
  active = false,
  children,
  isOpen,
  filters,
  setFilters = null,
  hitsTagId = null,
  setisVideoIconClicked,
  setIsOpen = () => {}
}) => {
  const [activeIcon, setActiveIcon] = useState(active);
  if (activeIcon) setisVideoIconClicked(true);
  else setisVideoIconClicked(false);

  const handleToggle = () => {
    setIsOpen(prev => !prev);
    setActiveIcon(prev => !prev);
  };
  const openPopup = () => {
    setIsOpen(true);
  };
  const closePopup = () => {
    setIsOpen(false);
    //setActiveIcon(false);
  };
  return (
    <>
      <FilterIconContainer
        isOpen={isOpen}
        active={(count && count > 0) || activeIcon}
        className="filter_icon"
        onMouseEnter={openPopup}
        onMouseLeave={closePopup}
      >
        <div
          className="iconContainer"
          onClick={handleToggle}
          //onMouseEnter={openPopup}
          //onMouseLeave={closePopup}
        >
          {count && count > 0 && <Badge>{count}</Badge>}
          <img src={src} className="icon " />
          {multi_choice && !isOpen && <img src={corner} className="corner" />}
        </div>
        {multi_choice && isOpen && <PopupFilter onMouseLeave={closePopup}>{children}</PopupFilter>}
      </FilterIconContainer>
    </>
  );
};

// Only for Audience
const FilterIconAudience = ({
  src,
  multi_choice = false,
  count = false,
  active = false,
  children,
  isOpen,
  dataType,

  setIsOpen2 = () => {}
}) => {
  const [activeIcon, setActiveIcon] = useState(active);

  const handleToggle = () => {
    setIsOpen2(prev => !prev);
    setActiveIcon(prev => !prev);
  };
  const openPopup = () => {
    setIsOpen2(true);
  };
  const closePopup = () => {
    setIsOpen2(false);
    setActiveIcon(false);
  };
  return (
    <>
      <FilterIconContainer
        isOpen={isOpen}
        active={(count && count > 0) || activeIcon}
        className="filter_icon"
        onMouseEnter={openPopup}
        onMouseLeave={closePopup}
      >
        <div
          className="iconContainer"
          onClick={handleToggle}
          //onMouseEnter={openPopup}
          //onMouseLeave={closePopup}
        >
          {count && count > 0 && <Badge>{count}</Badge>}
          <img src={src} className="icon " />
          {multi_choice && !isOpen && <img src={corner} className="corner" />}
        </div>
        {multi_choice && isOpen && <PopupFilter onMouseLeave={closePopup}>{children}</PopupFilter>}
      </FilterIconContainer>
    </>
  );
};

const DownIndicatorCustom = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropdownBlue} alt="Dropdown Icon" />
    </components.DropdownIndicator>
  );
};
const controlStyle = {
  minHeight: 'auto',
  width: '100px',
  '&:hover': {
    borderColor: 'var(--deepBlue)'
  }
};

const ResultCount = ({ name, children, props }) => {
  return (
    <div className="ResultCount" {...props}>
      <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" breakHeading={false}>
        {name}
      </Heading>
      <div>
        <Heading fontSize="14px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0">
          {children}
        </Heading>
      </div>
    </div>
  );
};

const CustomDivider = ({ height = '40px', props }) => {
  return (
    <Divider
      width="1px"
      height={height}
      orientation="vertical"
      // margin='0 0.5rem 0 0.7rem'
      margin="0"
      background="rgba(0, 0, 0, 0.1)"
      {...props}
    />
  );
};

const ReportContainer = styled.div`
  margin: 0px !important;
  padding: 0px !important;

  box-shadow: none;
  border-radius: 5px;
  height: 168px;
  // background: red;
  width: 100%;
  // border: 1px solid rgba(121, 142, 143, 0.3);
`;
const ReportDataContainer = styled.div`
  border: 1px solid rgba(121, 142, 143, 0.3);
  border-top: none;
  height: 125px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .action {
    display: none !important;

    img {
      padding: 0 5px;
      cursor: pointer;
    }
  }
  .report_item {
    padding-right: 8px;
  }
  .report_item:hover {
    .action {
      display: flex !important;
    }
  }
  .report_list {
    overflow-y: scroll;
    height: 100%;
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #c4c4c4;
    }
  }
`;
const Header = styled.div`
  background: var(--darkWhite);
  border: 1px solid rgba(121, 142, 143, 0.3);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.5rem 1rem;
`;
const EmptyReportDeck = () => {
  return (
    <div className="mt-4" style={{ width: '150px', textAlign: 'center' }}>
      <img style={{ marginRight: 'auto', marginLeft: 'auto' }} src={empty_report} />
      <Heading fontSize="12px" fontWeight="400" color="var(--middleGrey)" className="mb-0 pb-0 mt-2">
        Select data and then click on the copy to report icon to create report slides{' '}
      </Heading>
    </div>
  );
};

const CustomEditText = ({ title, handleReport, handleClose }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <EditableInput editnow={true} width={'90%'} value={title} submit={handleReport} />
      <div onClick={handleClose}>
        <Icon className="ml-2" color="var(--sapGreen)" style={{ fontSize: '14px', cursor: 'pointer' }}>
          close
        </Icon>
      </div>
    </div>
  );
};

const ReportDeckItem = ({ itemKey, title, itemEdit, onUpdate, onDelete }) => {
  const [edit, setEdit] = useState(itemEdit);

  return (
    <div className="d-flex align-items-center justify-content-between report_item">
      <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 px-3  py-1">
        {edit ? (
          <CustomEditText
            title={title}
            handleReport={text => {
              onUpdate(itemKey, text);
              setEdit(false);
              //handlereport(index, text)
            }}
            handleClose={() => {
              onDelete(itemKey);
              setEdit(false);
            }}
          />
        ) : (
          title
        )}
      </Heading>
      {!edit ? (
        <div className="d-flex align-items-center action">
          <img
            src={edit_darkgreen}
            onClick={() => {
              setEdit(true);
            }}
          />
          <img onClick={() => {}} src={eye_darkGreen} />
          <img
            onClick={() => {
              onDelete(itemKey);
            }}
            src={delete_darkGreen}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
const ReportDeck = ({ report, setReport, pid, onDelete, onUpdate }) => {
  const handlereport = (id, text) => {
    const temp = [...report];
    const data = temp?.map((item, index) => {
      if (index == id) return { title: text, edit: false };
      else return { ...item };
    });
    setReport([...data]);
  };
  return (
    <ReportContainer>
      <Header className="d-flex justify-content-between align-items-center">
        <Title>Report Deck</Title>
        <div className="d-flex  align-items-center">
          {report && report.length > 0 && (
            <Heading fontSize="10px" fontWeight="normal" className="mb-0 pb-0 mr-2" color="var(--deepBlue)">
              {report.length} Slides
            </Heading>
          )}
          <Link
            style={{ display: 'flex' }}
            // to={
            // 	report && report.length > 0
            // 		? '/project/1/analysis/report'
            // 		: '#'
            // }
            to={'/project/' + pid + '/analysis/report'}
          >
            <img src={report && report.length > 0 ? report_expand_active : report_expand} />
          </Link>
        </div>
      </Header>
      <ReportDataContainer>
        {report && report.length > 0 ? (
          <div className="report_list">
            {report?.map((item, index) => {
              return (
                <>
                  <ReportDeckItem
                    itemKey={index}
                    title={item?.question}
                    itemEdit={item?.edit}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                  />
                  <Divider background="var(--greyBlue30)" height="1px" margin="0" width="100%" />
                </>
              );
            })}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <EmptyReportDeck />
          </div>
        )}
      </ReportDataContainer>
    </ReportContainer>
  );
};

const CustomTagsFilter = props => {
  const [popup, setPopup] = useState();
  const [selection, setSelection] = useState(props.activeFilters['CustomTags']);
  const [customtagList, setCustomtagList] = useState(props?.tagsFilterdata?.CustomTags);

  useEffect(() => {
    setCustomtagList(customtagList);
    const updatedFilterdata = {
      ...props.tagsFilterdata,
      CustomTags: customtagList
    };
    props.setTagsFilterdata(updatedFilterdata);
  }, [customtagList]);

  const handleClearAll = () => {
    setSelection([]);
    props.onApply([]);
  };

  const closeHandler = () => {
    setPopup(undefined);
  };

  const handleDeleteCustomtag = (tagid, projectid) => {
    let index = customtagList.findIndex(tag => tag.tag_ID == tagid);
    if (index !== -1) {
      let tempCustomtags = [...customtagList];
      tempCustomtags.splice(index, 1);
      setCustomtagList(tempCustomtags);
      props.dispatch({
        type: 'REQUEST_DELETE_CUSTOMTAG',
        payload: {
          user_ID: props.userid,
          projectTag_ID: tagid
        }
      });
      setTimeout(() => {
        props.dispatch({
          type: REQUEST_FILTER_DATA,
          payload: {
            payload: projectid
          }
        });
      }, 1000);
    }
  };

  // useEffect(() => {
  //   setSelection([...selection, ...props.activeFilters['CustomTags']]);
  // }, [props]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="pb-2">
          Custom Tags
        </Heading>
        <div onClick={handleClearAll} style={{ cursor: 'pointer' }}>
          <Heading
            fontSize="10px"
            fontWeight="400"
            color="var(--sapGreen)"
            className="pb-2"
            style={{ marginLeft: '30px', whiteSpace: 'nowrap' }}
          >
            Clear All
          </Heading>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="pr-1">
          {props?.tagsFilterdata?.CustomTags?.map((a, i) => {
            return (
              <>
                {a?.tag !== 'Hits' && a?.tag !== 'Misses' ? (
                  <div
                    key={a.tag_ID}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      position: 'relative',
                      gap: '0.3rem',
                      marginBottom: '0.3rem'
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Checkbox
                        color="var(--deepBlue)"
                        fontSize="10px"
                        key={'ct' + a.tag_ID}
                        value={a.tag_ID}
                        checked={selection.indexOf(a.tag_ID.toString()) != -1 || selection.indexOf(a.tag_ID) != -1}
                        style={{ marginBottom: '0rem' }}
                        onChange={ev => {
                          let t = selection;
                          if (t.indexOf(ev.target.value.toString()) == -1) {
                            t.push(ev.target.value.toString());
                          } else {
                            t.splice(t.indexOf(ev.target.value.toString()), 1);
                          }

                          setSelection([...t]);
                        }}
                      >
                        {a.tag}{' '}
                      </Checkbox>

                      <Text
                        fontSize={'0.65rem'}
                        color={
                          selection.indexOf(a.tag_ID.toString()) != -1 || selection.indexOf(a.tag_ID) != -1
                            ? 'var(--sapGreen)'
                            : 'var(--middleGrey)'
                        }
                        type="6"
                        margin={'0px 0.55rem 0rem 0.25rem'}
                      >
                        {a.tag_count}
                      </Text>
                    </div>
                    <div
                      onClick={() => {
                        popup ? setPopup(undefined) : setPopup(a.tag_ID);
                      }}
                    >
                      <IconCustom color="var(--inputColor)" style={{ cursor: 'pointer' }}>
                        close
                      </IconCustom>
                      <CustomPopup
                        onClose={closeHandler}
                        showed={popup == a.tag_ID}
                        heading="Confirm delete"
                        secondheading="Are you sure you want to delete this Customtag?"
                        secondheading2="Once deleted, it cant be retrieved."
                      >
                        <CustomButtont
                          fontSize="14px"
                          fontWeight="300"
                          color="black"
                          size="medium"
                          style={{ justifyContent: 'space-between', marginRight: '20px' }}
                          onClick={() => {
                            handleDeleteCustomtag(a.tag_ID, props.projectid);
                            setPopup(undefined);
                          }}
                        >
                          Delete
                        </CustomButtont>
                      </CustomPopup>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            );
          })}
        </div>
      </div>
      <div>
        <Button
          size="small"
          type="button"
          onClick={() => {
            props.onApply(selection);
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

const UserTypeFilter = props => {
  const [fetchingData, setfetchingData] = useState(false);

  const [userList, setUserlist] = useState();
  const [likertScale, setLikertScale] = useState();
  const [likertFilter, setLikertFilters] = useState([]);

  useEffect(() => {
    setfetchingData(true);
    setTimeout(() => {
      let newOptions = [];
      props.filterData?.UserTypeandOwnership?.map(item => {
        item?.likertScaleName_array?.map(data => {
          newOptions.push({ value: data.questionLikertScale_ID, label: data.questionLikertScaleName });
        });
      });
      setUserlist(newOptions);
      setfetchingData(false);
    }, 3000);
  }, []);
  const handleClearAll = () => {
    setLikertFilters([]);
    setSelection([]);

    props.onApply([]);
  };

  const handleSelectOption = item => {
    setSelected(item);
    setIsOpen(false);
  };

  const [selection, setSelection] = useState([]);

  useEffect(() => {
    let keys = Object.keys(props.activeFilters['UserType']);
    let t = keys.reduce((f, k) => {
      props.activeFilters['UserType'][k]?.map(ky => f.push(ky));
      return f;
    }, []);

    const likertFilters = {
      ...likertFilter,
      LikerT: props.activeFilters['LikerT'] || []
    };

    setLikertFilters(likertFilters);

    setSelection([...selection, ...t]);
  }, [props]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading
          fontSize="12px"
          fontWeight="700"
          color="var(--deepBlue)"
          className="pb-2"
          style={{ whiteSpace: 'nowrap' }}
        >
          Screener Filters
        </Heading>
        <div onClick={handleClearAll} style={{ cursor: 'pointer' }}>
          <Heading
            fontSize="10px"
            fontWeight="400"
            color="var(--sapGreen)"
            className="pb-2"
            style={{ marginLeft: '30px', whiteSpace: 'nowrap' }}
          >
            Clear All
          </Heading>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        {props.filterData?.UserTypeandOwnership?.map((o, i) => {
          return (
            <>
              <div className="pr-4">
                <Heading
                  fontSize="10px"
                  fontWeight="600"
                  color="var(--deepBlue)"
                  className="pb-1"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {o.groupFilterLable}
                </Heading>
                {o.likertScaleName_array && (
                  <div style={{ width: '7rem', marginBottom: '1rem' }}>
                    <Dropdown
                      placeholder="Select Scale"
                      options={userList}
                      fetchingData={fetchingData}
                      selectedFontSize="small !important"
                      selectedFontWeight="60"
                      showCharacters={false}
                      controlStyle={controlStyle}
                      valueContainerStyle={{ padding: '0' }}
                      onChange={evt => setLikertScale(evt.value)}
                    />
                  </div>
                )}

                {o.likertScaleName_array &&
                  o.choices?.map((a, i) => (
                    <Checkbox
                      style={{ whiteSpace: 'nowrap' }}
                      color="var(--deepBlue)"
                      fontSize="10px"
                      key={`owner_${a.choice_ID}`}
                      value={a.choice_ID}
                      checked={selection.includes(a.choice_ID.toString())}
                      onChange={ev => {
                        const value = ev.target.value?.toString();

                        let t = likertFilter;
                        if (t.length == 0) {
                          t['LikerT'] = [];
                        }
                        let existingIndex = -1;
                        if (t['LikerT'].length > 0) {
                          existingIndex = t['LikerT'].findIndex(item => item[value] !== undefined);
                        }

                        let likertScaleID = likertScale?.toString();

                        // Update or add new dictionary
                        if (existingIndex !== -1) {
                          // Update existing dictionary
                          t['LikerT'][existingIndex] = { [value]: [likertScaleID] };
                        } else {
                          // Add new dictionary to the array
                          t['LikerT'].push({ [value]: [likertScaleID] });
                        }
                        setLikertFilters(t);
                      }}
                    >
                      {a.choiceName}
                    </Checkbox>
                  ))}

                {!o.likertScaleName_array &&
                  o.choices?.map((a, i) => {
                    return (
                      <Checkbox
                        style={{ whiteSpace: 'nowrap' }}
                        color="var(--deepBlue)"
                        fontSize="10px"
                        key={'owner_' + a.choice_ID}
                        value={a.choice_ID}
                        checked={
                          selection.indexOf(a.choice_ID.toString()) != -1 || selection.indexOf(a.choice_ID) != -1
                        }
                        onChange={ev => {
                          let t = selection;

                          if (t.indexOf(ev.target.value.toString()) == -1) {
                            t.push(ev.target.value.toString());
                          } else {
                            t.splice(t.indexOf(ev.target.value.toString()), 1);
                          }

                          setSelection([...t]);
                        }}
                      >
                        {a.choiceName}{' '}
                      </Checkbox>
                    );
                  })}
              </div>
            </>
          );
        })}
      </div>
      <div>
        <Button
          size="small"
          onClick={() => {
            props.onApply(selection, likertFilter);
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

const OwnershipFilter = props => {
  const handleClearAll = () => {
    setSelection([]);
    props.onApply([]);
  };

  const [selection, setSelection] = useState([]);
  useEffect(() => {
    let keys = Object.keys(props.activeFilters['Ownership']);
    let t = keys.reduce((f, k) => {
      props.activeFilters['Ownership'][k]?.map(ky => f.push(ky));
      return f;
    }, []);

    setSelection([...selection, ...t]);
  }, [props]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="pb-2">
          Ownership
        </Heading>
        <div onClick={handleClearAll} style={{ cursor: 'pointer' }}>
          <Heading fontSize="10px" fontWeight="400" color="var(--sapGreen)" className="pb-2">
            Clear All
          </Heading>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        {props.filterData?.Ownership?.map((o, i) => {
          return (
            <>
              <div className="pr-4">
                {o.choices?.map((a, i) => {
                  return (
                    <Checkbox
                      color="var(--deepBlue)"
                      fontSize="10px"
                      key={'owner_' + a.choice_ID}
                      value={a.choice_ID}
                      checked={selection.indexOf(a.choice_ID.toString()) != -1 || selection.indexOf(a.choice_ID) != -1}
                      onChange={ev => {
                        let t = selection;
                        if (t.indexOf(ev.target.value.toString()) == -1) {
                          t.push(ev.target.value.toString());
                        } else {
                          t.splice(t.indexOf(ev.target.value.toString()), 1);
                        }
                        setSelection([...t]);
                      }}
                    >
                      {a.choiceName}{' '}
                    </Checkbox>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
      <div>
        <Button
          size="small"
          onClick={() => {
            props.onApply(selection);
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

const DemographicFilter = props => {
  const handleClearAll = () => {
    setAgeGroup([]);
    setMaritalStatus([]);
    setGender([]);
    setClassificationData([]);
    props.onApply([], [], [], []);
  };
  const { filterData } = props;

  const [ageGroup, setAgeGroup] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [gender, setGender] = useState([]);
  const [classificationData, setClassificationData] = useState([]);

  // useEffect(() => {
  // setAgeGroup([...ageGroup, ...props.activeFilters['AgeGroup']]);
  // setMaritalStatus([...maritalStatus, ...props.activeFilters['MaritalStatus']]);
  // if(props.activeFilters['Gender'].length > 0){
  // 	setGender([...props.activeFilters['Gender']])
  // }
  // setGender([...gender, ...props.activeFilters['Gender']]);
  // setClassificationData([...classificationData, ...props.activeFilters['classificationData']]);
  // }, [props]);
  useEffect(() => {
    setAgeGroup(prev => [...prev, ...props.activeFilters['AgeGroup'].filter(item => !prev.includes(item))]);

    setMaritalStatus(prev => [...prev, ...props.activeFilters['MaritalStatus'].filter(item => !prev.includes(item))]);

    setGender(prev => [...prev, ...props.activeFilters['Gender'].filter(item => !prev.includes(item))]);

    setClassificationData(prev => [
      ...prev,
      ...props.activeFilters['classificationData'].filter(item => !prev.includes(item))
    ]);
  }, [props]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="pb-2">
          Demography
        </Heading>
        <div onClick={handleClearAll} style={{ cursor: 'pointer' }}>
          <Heading fontSize="10px" fontWeight="400" color="var(--sapGreen)" className="pb-2">
            Clear All
          </Heading>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="pr-4">
          <Heading
            fontSize="10px"
            fontWeight="600"
            color="var(--deepBlue)"
            className="pb-1"
            style={{ whiteSpace: 'nowrap' }}
          >
            Age Group
          </Heading>
          {filterData?.Demography?.AgeGroup?.map((a, i) => {
            if (a.ageGroup) {
              return (
                <Checkbox
                  color="var(--deepBlue)"
                  fontSize="10px"
                  key={'age_' + a.ageGroup}
                  value={a.ageGroup}
                  style={{ whiteSpace: 'nowrap' }}
                  checked={ageGroup.indexOf(a.ageGroup) != -1}
                  onChange={ev => {
                    let t = ageGroup;
                    if (t.indexOf(ev.target.value) == -1) {
                      t.push(ev.target.value);
                    } else {
                      t.splice(t.indexOf(ev.target.value), 1);
                    }

                    setAgeGroup([...t]);
                  }}
                >
                  {a.ageGroup}{' '}
                </Checkbox>
              );
            }
          })}
        </div>
        <div className="pr-4">
          <Heading
            fontSize="10px"
            fontWeight="600"
            color="var(--deepBlue)"
            className="pb-1"
            style={{ whiteSpace: 'nowrap' }}
          >
            Marital Status
          </Heading>

          <Checkbox
            color="var(--deepBlue)"
            fontSize="10px"
            value={1}
            style={{ whiteSpace: 'nowrap' }}
            checked={
              maritalStatus.indexOf('1') != -1 ||
              maritalStatus.indexOf(1) != -1 ||
              maritalStatus.indexOf('married') != -1
            }
            onChange={ev => {
              let t = maritalStatus;
              if (t.indexOf(ev.target.value) == -1) {
                t.push(ev.target.value);
              } else {
                t.splice(t.indexOf(ev.target.value), 1);
              }

              setMaritalStatus([...t]);
            }}
          >
            {`Married`}{' '}
          </Checkbox>
          <Checkbox
            color="var(--deepBlue)"
            fontSize="10px"
            value={0}
            style={{ whiteSpace: 'nowrap' }}
            checked={
              maritalStatus.indexOf('0') != -1 ||
              maritalStatus.indexOf(0) != -1 ||
              maritalStatus.indexOf('unmarried') != -1
            }
            onChange={ev => {
              let t = maritalStatus;
              if (t.indexOf(ev.target.value) == -1) {
                t.push(ev.target.value);
              } else {
                t.splice(t.indexOf(ev.target.value), 1);
              }

              setMaritalStatus([...t]);
            }}
          >
            {`Unmarried`}{' '}
          </Checkbox>
        </div>
        <div className="pr-4">
          <Heading fontSize="10px" fontWeight="600" color="var(--deepBlue)" className="pb-1">
            Gender
          </Heading>

          {filterData?.Demography?.Gender?.map((a, i) => {
            return (
              <Checkbox
                color="var(--deepBlue)"
                fontSize="10px"
                key={'age_' + a.gender_ID}
                style={{ whiteSpace: 'nowrap' }}
                value={a.gender_ID}
                checked={gender.indexOf(a.gender_ID.toString()) != -1 || gender.indexOf(a.gender_ID) != -1}
                onChange={ev => {
                  let t = gender;

                  if (t.indexOf(ev.target.value.toString()) == -1) {
                    t.push(ev.target.value.toString());
                  } else {
                    t.splice(t.indexOf(ev.target.value.toString()), 1);
                  }

                  setGender([...t]);
                }}
              >
                {a.gender}{' '}
              </Checkbox>
            );
          })}
        </div>
        <div className="pr-4">
          <Heading fontSize="10px" fontWeight="600" color="var(--deepBlue)" className="pb-1">
            Classification
          </Heading>
          <div className="d-flex">
            <div className="pr-3">
              {filterData?.Demography?.classificationData?.map((a, i) => {
                return (
                  <Checkbox
                    color="var(--deepBlue)"
                    fontSize="10px"
                    key={'age_' + a.classification_ID}
                    value={a.classification_ID}
                    style={{ whiteSpace: 'nowrap' }}
                    checked={
                      classificationData.indexOf(a.classification_ID.toString()) != -1 ||
                      classificationData.indexOf(a.classification_ID) != -1
                    }
                    onChange={ev => {
                      let t = classificationData;
                      if (t.indexOf(ev.target.value) == -1) {
                        t.push(ev.target.value);
                      } else {
                        t.splice(t.indexOf(ev.target.value), 1);
                      }

                      setClassificationData([...t]);
                    }}
                  >
                    {a.classificationName}{' '}
                  </Checkbox>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button
          size="small"
          onClick={event => {
            props.onApply(ageGroup, maritalStatus, gender, classificationData);
            event.preventDefault();
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
};
const GeographyFilter = props => {
  const handleClearAll = () => {
    setSelection([]);
    props.onApply([]);
  };
  const { filterData } = props;
  const [selection, setSelection] = useState(props.activeFilters['Geography']);

  useEffect(() => {
    // setSelection([...selection, ...props.activeFilters['Geography']]);
    setSelection(prev => [...prev, ...props.activeFilters['Geography'].filter(item => !prev.includes(item))]);
  }, [props]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading fontSize="12px" fontWeight="700" color="var(--deepBlue)" className="pb-2">
          Geography
        </Heading>
        <div onClick={handleClearAll} style={{ cursor: 'pointer' }}>
          <Heading fontSize="10px" fontWeight="400" color="var(--sapGreen)" className="pb-2">
            Clear All
          </Heading>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="">
          <Heading fontSize="10px" fontWeight="600" color="var(--deepBlue)" className="pb-1">
            Popstrata
          </Heading>

          <div className="d-flex ">
            {filterData?.Geography?.map((t, i) => {
              return (
                <div className="pr-3">
                  <Checkbox
                    color="var(--deepBlue)"
                    fontSize="10px"
                    onChange={ev => {
                      let l = selection;
                      let ns = t.region_array.reduce((ff, r) => {
                        let nn = r.center.reduce((f, k) => {
                          f.push(k.center_ID.toString());
                          return f;
                        }, []);
                        for (let i = 0; i < nn.length; i++) {
                          ff.push(nn[i]);
                        }
                        return ff;
                      }, []);

                      let count = 0;
                      for (let i = 0; i < ns.length; i++) {
                        if (l.indexOf(ns[i]) != -1) {
                          count = count + 1;
                        }
                      }

                      if (count == ns.length) {
                        for (let i = 0; i < ns.length; i++) {
                          l.splice(l.indexOf(ns[i]), 1);
                        }
                      } else {
                        for (let i = 0; i < ns.length; i++) {
                          if (l.indexOf(ns[i]) == -1) {
                            l.push(ns[i]);
                          }
                        }
                      }
                      setSelection([...l]);
                    }}
                  >
                    {t.tierName}{' '}
                  </Checkbox>
                  {t.region_array?.map((r, j) => {
                    return (
                      <div>
                        <Checkbox
                          color="var(--deepBlue)"
                          fontSize="10px"
                          fontWeight="600"
                          className="mt-2"
                          value={r.region_ID}
                          onChange={ev => {
                            let t = selection;
                            let ns = r.center.reduce((f, k) => {
                              f.push(k.center_ID.toString());
                              return f;
                            }, []);
                            let count = 0;
                            for (let i = 0; i < ns.length; i++) {
                              if (t.indexOf(ns[i]) != -1) {
                                count = count + 1;
                              }
                            }

                            if (count == ns.length) {
                              for (let i = 0; i < ns.length; i++) {
                                t.splice(t.indexOf(ns[i]), 1);
                              }
                            } else {
                              for (let i = 0; i < ns.length; i++) {
                                if (t.indexOf(ns[i]) == -1) {
                                  t.push(ns[i]);
                                }
                              }
                            }
                            setSelection([...t]);
                          }}
                        >
                          {r.regionName}{' '}
                        </Checkbox>
                        <div style={{ marginLeft: '20px' }}>
                          {r.center?.map((c, k) => {
                            return (
                              <Checkbox
                                color="var(--deepBlue)"
                                fontSize="10px"
                                value={c.center_ID}
                                checked={
                                  selection.indexOf(c.center_ID.toString()) != -1 ||
                                  selection.indexOf(c.center_ID) != -1
                                }
                                onChange={ev => {
                                  let t = selection;
                                  if (t.indexOf(ev.target.value) == -1) {
                                    t.push(ev.target.value);
                                  } else {
                                    t.splice(t.indexOf(ev.target.value), 1);
                                  }

                                  setSelection([...t]);
                                }}
                              >
                                {c.centerName}{' '}
                              </Checkbox>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <Button
          size="small"
          onClick={() => {
            props.onApply(selection);
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

function AnalysisScreen(props) {
  const [demographic, setDemographic] = useState(false);
  const [geography, setGeography] = useState(false);
  const [ownership, setOwnership] = useState(false);
  const [userType, setUserType] = useState(false);
  const [ctag, setCtag] = useState(false);
  const [dataType, setDataType] = useState([]);
  const [report, setReport] = useState([]);
  const [cohorts, setCohorts] = useState('saveButton');
  const [cohortList, setCohortList] = useState([]);
  const [justRefresh, setJustRefresh] = useState(false);
  const [vType, setVtype] = useState([]);
  const [sbuild, setSbuild] = useState(false);
  const [stitch, setStitch] = useState(false);
  const [stitchQID, setStitchQID] = useState(null);
  const [isVideoIconClicked, setisVideoIconClicked] = useState(false);
  const [isImageIconClicked, setisImageIconClicked] = useState(false);
  const [isAudioIconClicked, setisAudioIconClicked] = useState(false);
  const [isGraphIconClicked, setisGraphIconClicked] = useState(false);
  const [isLikertIconClicked, setisLikertIconClicked] = useState(false);
  const [isWcloudIconClicked, setisWcloudIconClicked] = useState(false);
  const [filters, setFilters] = useState({
    AgeGroup: [],
    MaritalStatus: [],
    Gender: [],
    classificationData: [],
    Ownership: [],
    UserType: [],
    CustomTags: [],
    Geography: [],
    LikerT: []
  });
  const [cohortIsOpen, setCohortIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const clickedOnDeleteForCohort = useRef();
  const clist = useRef();
  const isGenerated = useSelector(state => state.checkSummaryReducer?.Analysisdata);
  const interviewsCompleted = useSelector(state => state.checkSummaryReducer?.InterviewComplete);
  const dispatch = useDispatch();
  const [formSections, setFormSections] = useState([]);
  const [interviewSections, setInterviewSections] = useState([]);
  const [formSections2, setFormSections2] = useState([]);
  const [interviewSections2, setInterviewSections2] = useState([]);
  let { id } = useParams();
  let csvFormats = [
    { label: 'XLSX Format 1', value: 0 },
    { label: 'XLSX Format 2', value: 1 }
  ];

  const loggedUser = useSelector(state => state.LoginReducer);
  const projectTitle = useSelector(state =>
    state.ProjectReducer.resumeData?.project_data != undefined &&
    state.ProjectReducer.resumeData?.project_data.length > 0
      ? state.ProjectReducer.resumeData?.project_data[0]?.identity
      : state.ProjectReducer.projectIdentity
  );
  let hitsTagId;
  const filterData = useSelector(state => state.analysisReducer.filters);
  filterData?.CustomTags?.map((a, i) => {
    if (a.tag == 'Hits') {
      hitsTagId = a.tag_ID;
    }
  });
  const [tagsFilterdata, setTagsFilterdata] = useState();
  const respondents = useSelector(state => state.analysisReducer.respondents);
  const cohortData = useSelector(state => state.analysisReducer.cohort);
  const cohortDeleteStatus = useSelector(state => state.analysisReducer?.deleteCohortStatus);
  const deleteCustomtagStatus = useSelector(state => state.analysisReducer?.deleteCustomtagStatus);

  const initialLoad = useRef(true);
  const toastDisplayed = useRef(false);
  const initialLoadCustomtag = useRef(true);
  const toastDisplayedCustomtag = useRef(false);


 
  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (cohortDeleteStatus) {
      if (cohortDeleteStatus?.StatusCode === '-1') {
        toast.error(<>{'Failed to Delete the cohort.'}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
      } else if (cohortDeleteStatus?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{'Cohort Deleted Sucessfully!'}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
      }

      toastDisplayed.current = true;

      setTimeout(() => {
        dispatch({ type: 'CLEAR_DELETE_COHORT' });
        toastDisplayed.current = false;
      }, 4500);
    }
  }, [cohortDeleteStatus]);

  useEffect(() => {
    if (initialLoadCustomtag.current) {
      initialLoadCustomtag.current = false;
      return;
    }

    if (deleteCustomtagStatus) {
      if (deleteCustomtagStatus?.StatusCode === '-1') {
        toast.error(<>{'Failed to Delete Customtag.'}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
      } else if (deleteCustomtagStatus?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{'Customtag Deleted Sucessfully!'}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
      }

      toastDisplayedCustomtag.current = true;

      setTimeout(() => {
        dispatch({ type: 'CLEAR_DELETE_CUSTOMTAG' });
        toastDisplayedCustomtag.current = false;
      }, 4500);
    }
  }, [deleteCustomtagStatus]);

  const demoFilterCount = () => {
    // console.log("filters['AgeGroup']",filters['AgeGroup'])
    // console.log("filters['MaritalStatus']",filters['MaritalStatus'])
    // console.log("filters['Gender']",filters['Gender'])
    // console.log("filters['classificationData']",filters['classificationData'])
    return (
      filters['AgeGroup'].length +
      filters['MaritalStatus'].length +
      filters['Gender'].length +
      filters['classificationData'].length
    );
  };

  useEffect(() => {
    setTagsFilterdata(filterData);
  }, [filterData]);

  useEffect(() => {
    dispatch({
      type: REQUEST_ANALYSIS_CSV,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: filters['UserType'],
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags']
      }
    });

    dispatch({
      type: REQUEST_ANALYSIS_CSV_WITH_CODE,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: filters['UserType'],
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags']
      }
    });
  }, []);

  useEffect(() => {
    const Payload = {
      user_ID: loggedUser.userId,
      project_ID: id
    };
    dispatch({ type: 'RESUME_DATA', payload: Payload });
    let data = {
      trimVideo_ID: []
    };
    // dispatch({
    //   type: REQUEST_STICH_VIDEO,
    //   data: { payload: data }
    // });
  }, []);

  useEffect(() => {
    setisVideoIconClicked(isVideoIconClicked);
  }, [isVideoIconClicked]);

  useEffect(() => {
    if (id !== null && id !== undefined && !sbuild) {
      dispatch({
        type: REQUEST_FILTER_DATA,
        payload: {
          payload: id
        }
      });

      setSbuild(true);
    }

    dispatch({
      type: REQUEST_ANALYSIS_CLIPS_WITHOUT_URL,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: filters['UserType'],
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags']
      }
    });

    dispatch({
      type: REQUEST_ANALYSIS_CLIPS_DATA,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: filters['UserType'],
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags'],
        CustomTags1: filters['CustomTags'],
        question_ID  : 0
      }
    });
    if (filters['CustomTags'].length !== 0) {
      let t = dataType;
      if (t.indexOf('video') == -1) {
        t.push('video');
      } else {
        t.splice(t.indexOf('video'), 1);
      }
      setDataType([...t]);
    } else if (filters['CustomTags'].length == 0 && !isVideoIconClicked) {
      let t = dataType;
      t.splice(t.indexOf('video'), 1);
      setDataType([...t]);
    }
    // } else if (filters['CustomTags'].length == 0 && isVideoIconClicked == 0) {
    //   let t = dataType;
    //   t.splice(t.indexOf('video'), 1);
    //   setDataType([...t]);
    // }
  }, [filters]);

  useEffect(() => {
    dispatch({
      type: REQUEST_CHECK_SUMMARY,
      payload: {
        project_ID: id
      }
    });
  }, []);

  // useEffect(() => {
  //   setDataType(dataType);
  // },[filters])

  useEffect(() => {
    setTagsFilterdata(filterData);
  }, [filterData]);

  const questionsAnalysisData = useSelector(state => state.analysisReducer.questionsAnalysisData);
  const questionsAnalysisData1 = useSelector(state => state.analysisReducer.questionsAnalysisData1);
  const questionsAnalysisData2 = useSelector(state => state.analysisReducer.questionsAnalysisData2);
  const dropDowns = useSelector(state => state.analysisReducer.dropDowns);
  const activeReport = useSelector(state => state.analysisReducer.report);
  const stitchResponse = useSelector(state => state.analysisReducer.stitchResponse);
  const analysisClipsData = useSelector(state => state.analysisReducer?.clipsData?.questionsAnalysisData);
  const analysisClipsWithoutUrl = useSelector(
    state => state.analysisReducer?.clipsDataWithoutUrl?.questionsAnalysisData
  );
  // const stitchResponse = props.stitchResponse != undefined ? props.stitchResponse : "";
  const [text, setText] = useState('Select one');
  const projectDuration = useSelector(state => state.analysisReducer.projectDuration);
  const [localQuestionsData, setLocalQuestionsData] = useState(questionsAnalysisData);
  const [stitchvideo, setstitchvideo] = useState(
    stitchResponse != null && stitchResponse.stitchingClipPath != null ? stitchResponse.stitchingClipPath : ''
  );

  useEffect(() => {
    if (!questionsAnalysisData1) return;

    setFormSections(questionsAnalysisData1?.filter(a => a.taskType === 1) || []);
    setInterviewSections(questionsAnalysisData1?.filter(a => a.taskType === 2) || []);

  }, [questionsAnalysisData1]); 

  useEffect(() => {
    if (!questionsAnalysisData2) return;

    setFormSections2(questionsAnalysisData2?.filter(a => a.taskType === 1) || []);
    setInterviewSections2(questionsAnalysisData2?.filter(a => a.taskType === 2) || []);

  }, [questionsAnalysisData2]); 

  useEffect(() => {
    setLocalQuestionsData(questionsAnalysisData);
  }, [questionsAnalysisData]);

  const fetchAnalysisData = () => {
    dispatch({
      type: REQUEST_ANALYSIS_DATA,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: filters['UserType'],
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags'],
        LikerT: filters['LikerT'],
        CustomTags1: filters['CustomTags']
      }
    });
  };
  useEffect(() => {
    console.log('Event Triggered');
    const handleTagAdded = () => {
      fetchAnalysisData();
      window.isTagAdded = false;
    };

    window.addEventListener('tagAdded', handleTagAdded);
    console.log('Event Triggered 2');
    return () => {
      window.removeEventListener('tagAdded', handleTagAdded);
    };
  }, []);

  useEffect(() => {
    const userTypeFilter = filters['UserType'];

    const finalUserTypeFilter =
      typeof userTypeFilter === 'object' && userTypeFilter !== null
        ? Object.keys(userTypeFilter).length === 0
          ? []
          : [userTypeFilter]
        : userTypeFilter;
    dispatch({
      type: REQUEST_ANALYSIS_DATA,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: finalUserTypeFilter,
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags'],
        LikerT: filters['LikerT'],
        CustomTags1: filters['CustomTags']
      }
    });
    if (filters['CustomTags'].length !== 0) {
      let t = dataType;
      if (t.indexOf('video') == -1) {
        t.push('video');
        setDataType([...t]);
      } else {
        t.splice(t.indexOf('video'), 1);
      }
    } else if (filters['CustomTags'].length == 0 && !isVideoIconClicked) {
      let t = dataType;
      t.splice(t.indexOf('video'), 1);
      setDataType([...t]);
    } else if (filters['CustomTags'].length == 0 && isVideoIconClicked == 0) {
      let t = dataType;

      t.splice(t.indexOf('video'), 1);

      setDataType([...t]);
    }

    if (isImageIconClicked) {
      let t = dataType;
      if (t?.indexOf('images') == -1) {
        t.push('images');
        setDataType([...t]);
      }
    }
    if (isAudioIconClicked) {
      let t = dataType;
      if (t?.indexOf('audios') == -1) {
        t.push('audios');
        setDataType([...t]);
      }
    }
    if (isGraphIconClicked) {
      let t = dataType;
      if (t?.indexOf('bar_chart') == -1) {
        t.push('bar_chart');
        setDataType([...t]);
      }
    }
    if (isLikertIconClicked) {
      let t = dataType;
      if (t?.indexOf('likert') == -1) {
        t.push('likert');
        setDataType([...t]);
      }
    }
    if (isWcloudIconClicked) {
      let t = dataType;
      if (t?.indexOf('wcloud') == -1) {
        t.push('wcloud');
        setDataType([...t]);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (cohortData != undefined && cohortData.length > 0) {
      let i = 0;
      let t = cohortData.reduce((f, r) => {
        if (i++ == 0) {
          f.push({
            value: null,
            label: 'Select one',
            data: {
              AgeGroup: [],
              Center: [],
              Classification: [],
              CustomTags: [],
              Gender: [],
              MaritalStatus: [],
              UserType: [],
              OwnerShip: []
            }
          });
        }
        f.push({
          value: r.cohort_ID,
          label: r.cohort,
          data: {
            AgeGroup: r['AgeGroup'],
            Center: r['Center'],
            Classification: r['Classification'],
            CustomTags: r['CustomTags'],
            Gender: r['Gender'],
            MaritalStatus: r['MaritalStatus'],
            UserType: r['UserType'],
            OwnerShip: r['OwnerShip']
          }
        });
        return f;
      }, []);
      setCohortList([...t]);
    }
  }, [cohortData]);

  useEffect(() => {
    let qcount = 0;
    if (questionsAnalysisData != null && activeReport != null && activeReport.length > 0) {
      for (let i = 0; i < questionsAnalysisData.length; i++) {
        let qm = questionsAnalysisData[i];
        for (let j = 0; j < qm.questionName_array.length; j++) {
          activeReport?.map(report => {
            if (report != null && qm.questionName_array[j].question_ID == report.qid) {
              qcount++;
            }
          });
        }
      }
      if (qcount == 0 && activeReport.length != 0) {
        dispatch({
          type: ADD_TO_REPORT,
          data: []
        });
      }
    }
  }, [questionsAnalysisData, activeReport]);

  useEffect(() => {
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () => {
        if (stitchResponse != null && stitch && stitchQID != null && stitchvideo != stitchResponse.stitchingClipPath) {
          let d = [];
          if (typeof activeReport === 'object' && activeReport != null) {
            d = activeReport;
          } else {
          }

          let config = {};
          config[stitchQID.qid] = {
            thumb: stitchResponse.ThumbNailPath,
            path: stitchResponse.stitchingClipPath
          };
          let l = activeReport == undefined || activeReport.length == 0 ? 0 : activeReport.length;
          d[l] = {
            qid: stitchQID.qid,
            question: stitchQID.question,
            config,
            questionObject: stitchQID.questionObject,
            edit: true
          };
          dispatch({
            type: ADD_TO_REPORT,
            data: d
          });
          setJustRefresh(prev => !prev);
          setStitch(false);
          setStitchQID(null);
          setstitchvideo(stitchResponse.stitchingClipPath);
        }
      },
      2000
    );
  }, [stitchResponse, stitch]);

  const handleCSVformatChange = item => {
    let csvFormat = item.value;
    if (csvFormat == 0) downloadXlsx();
    else if (csvFormat == 1) downloadXlsxWithCode();
    // else if(csvFormat == 1) downloadCsv1() ;
  };

  const onDemographicFilter = (ageGroup, maritalStatus, gender, classificationData) => {
    let t = filters;
    t['AgeGroup'] = ageGroup;
    t['MaritalStatus'] = maritalStatus;
    t['Gender'] = gender;
    t['classificationData'] = classificationData;

    setFilters({ ...t });
    setDemographic(false);
  };

  const onGeographicFilter = selection => {
    let t = filters;
    t['Geography'] = selection;
    setFilters({ ...t });
    setGeography(false);
  };

  const onOwnershipFilter = selection => {
    let t = filters;
    let ow = filterData['Ownership'];
    let f = [];
    for (let i = 0; i < ow.length; i++) {
      let q = ow[i];
      let t1 = [];
      for (let j = 0; j < q.choices.length; j++) {
        if (selection.indexOf(q.choices[j].choice_ID.toString()) != -1) {
          t1.push(q.choices[j].choice_ID.toString());
        }
      }

      if (t1.length > 0) {
        let m = {};
        m[q.question_ID.toString()] = t1;
        f.push(m);
      }
    }
    let final = f.reduce((fi, k) => {
      fi = { ...fi, ...k };
      return fi;
    }, {});

    t['Ownership'] = final;
    setFilters({ ...t });
    setOwnership(false);
  };

  const onUserTypeFilter = (selection = null, likertFilter = null) => {
    let t = filters;

    if (selection !== null) {
      let ow = filterData['UserType'];
      let f = [];
      for (let i = 0; i < ow.length; i++) {
        let q = ow[i];
        let t1 = [];
        for (let j = 0; j < q.choices.length; j++) {
          if (selection.indexOf(q.choices[j].choice_ID.toString()) != -1) {
            t1.push(q.choices[j].choice_ID.toString());
          }
        }

        if (t1.length > 0) {
          let m = {};
          m[q.question_ID.toString()] = t1;
          f.push(m);
        }
      }
      let final = f.reduce((fi, k) => {
        fi = { ...fi, ...k };
        return fi;
      }, {});
      t['UserType'] = final;
      setFilters({ ...t });
      setUserType(false);
    }

    if (likertFilter !== null) {
      t['LikerT'] = likertFilter['LikerT'];
      setFilters({ ...t });
      setUserType(false);
    }

    if (likertFilter === null && selection.length < 1) {
      t['LikerT'] = [];

      setFilters({ ...t });
      setUserType(false);
    }
  };

  const onCustomTagFilter = selection => {
    let t = filters;
    t['CustomTags'] = selection;

    setFilters({ ...t });
    setCtag(false);
  };

  const regionCount = regions => {
    return regions?.reduce((f, r) => {
      f =
        f +
        r.region_array?.reduce((rf, ra) => {
          rf = rf + ra.center.length;
          return rf;
        }, 0);
      return f;
    }, 0);
  };

  const demographyFilterCount = () => {
    return (
      filters.AgeGroup.length + filters.MaritalStatus.length + filters.Gender.length + filters.classificationData.length
    );
  };

  const UsertTypeCount = usertypes => {
    return usertypes?.reduce((f, r) => {
      f = f + r.choices.length;
      return f;
    }, 0);
  };

  const OwnershipCount = ownertypes => {
    return ownertypes?.reduce((f, r) => {
      f = f + r.choices.length;
      return f;
    }, 0);
  };

  useEffect(() => {}, [activeReport]);

  useEffect(() => {
    if (filterData?.hasOwnProperty('cohortData')) {
      let i = 0;
      let t = filterData?.cohortData?.reduce((f, r) => {
        if (i++ == 0) {
          f.push({
            value: null,
            label: 'Select one',
            data: {
              AgeGroup: [],
              Center: [],
              Classification: [],
              CustomTags: [],
              Gender: [],
              MaritalStatus: [],
              UserType: [],
              OwnerShip: []
            }
          });
        }
        f.push({
          value: r.cohort_ID,
          label: r.cohort,
          data: {
            AgeGroup: r['AgeGroup'],
            Center: r['Center'],
            Classification: r['Classification'],
            CustomTags: r['CustomTags'],
            Gender: r['Gender'],
            MaritalStatus: r['MaritalStatus'],
            UserType: r['UserType'],
            OwnerShip: r['OwnerShip']
          }
        });
        return f;
      }, []);
      setCohortList([...t]);
    }
  }, [filterData]);

  const onSlideDelete = key => {
    let t = activeReport;
    t.splice(key, 1);
    dispatch({
      type: ADD_TO_REPORT,
      data: t
    });
    setJustRefresh(prev => !prev);
  };

  const onSlideTitleUpdate = (key, text) => {
    let t = activeReport;
    t[key].question = text;
    t[key].edit = false;
    dispatch({
      type: ADD_TO_REPORT,
      data: t
    });
    setJustRefresh(prev => !prev);
  };

  const ownershipFilterCount = () => {
    let keys = Object.keys(filters['Ownership']);
    let t = keys.reduce((f, k) => {
      filters['Ownership'][k]?.map(ky => f.push(ky));
      return f;
    }, []);
    return t.length;
  };

  const userTypeFilterCount = () => {
    let keys = Object.keys(filters['UserType']);
    let t = keys.reduce((f, k) => {
      filters['UserType'][k]?.map(ky => f.push(ky));
      return f;
    }, []);
    return t.length;
  };

  const findQuestionType = qid => {
    for (let i = 0; i < questionsAnalysisData.length; i++) {
      let qm = questionsAnalysisData[i];
      for (let j = 0; j < qm.questionName_array.length; j++) {
        if (qm.questionName_array[j].question_ID.toString() === qid.toString()) {
          return qm.questionName_array[j].questionType_ID;
        }
      }
    }
  };
  const projectStatus = props.projectStatus != undefined ? props.projectStatus : '';
  const endproject = projectId => {
    dispatch({ type: 'REQUEST_ENDPROJECT', payload: { user_ID: loggedUser.userId, project_ID: projectId } });
  };

  let customFilterCount = 0;

  filterData?.CustomTags?.map(value => {
    if (value?.tag !== 'Hits' && value?.tag !== 'Misses') {
      customFilterCount += 1;
    }
  });
  const sanitizeHeader = header => {
    // Replace punctuation with underscores
    return header.replace(/[^\w\s]/g, '_');
  };

  const downloadXlsxWithCode = () => {
    console.log('DOWNLOAD BUTTON 1', questionsAnalysisData2);
    dispatch({
      type: REQUEST_ANALYSIS_CSV_WITH_CODE,
      payload: {
        project_ID: id,
        AgeGroup: filters['AgeGroup'],
        MaritalStatus: filters['MaritalStatus'],
        Gender: filters['Gender'],
        classificationData: filters['classificationData'],
        UserType: filters['UserType'],
        Geography: filters['Geography'],
        Ownership: filters['Ownership'],
        CustomTags: filters['CustomTags']
      }
    });

    const rows = [];
    const headers = ['Name', 'Gender', 'Age', 'Marital Status', 'Center', 'Classification', 'Group Title']; // Static headers

    // Map to track duplicate headers
    const headerCountMap = new Map();

    try {
        formSections2?.forEach((section, sid) => {
        section.questionName_array?.forEach((question, qid) => {
          if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
            // Exclude questions with questionType_ID equal to 8 or 12
            const sanitizedHeader = question.questionName.replace(/[^a-zA-Z0-9 ]/g, '');

            if (question.questionType_ID == 2) {
              const userAnswerCounts = new Map();

              // Count the occurrences of each user in the answer array
              question.answer.forEach(answer => {
                userAnswerCounts.set(answer.user_ID, (userAnswerCounts.get(answer.user_ID) || 0) + 1);
              });

              // Find the maximum count for any user
              const maxChoices = Math.max(...userAnswerCounts.values(), 1);

              // Push the question name into the headers array maxChoices times
              for (let i = 0; i < maxChoices; i++) {
                const uniqueHeader = `${sid + 1}.${qid + 1} ${sanitizedHeader} (${i + 1})`;
                headers.push(uniqueHeader);
              }
            } else {
              const uniqueHeader = `${sid + 1}.${qid + 1} ${sanitizedHeader}`;
              // Check for duplicates and add a unique suffix if necessary
              if (headerCountMap.has(uniqueHeader)) {
                const count = headerCountMap.get(uniqueHeader) + 1;
                headerCountMap.set(uniqueHeader, count);
                headers.push(`${uniqueHeader} (${count})`);
              } else {
                headerCountMap.set(uniqueHeader, 1);
                headers.push(uniqueHeader);
              }
            }
          }
        });
      });
      interviewSections2?.forEach((section, sid) => {
        section.questionName_array?.forEach((question, qid) => {
          if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
            // Exclude questions with questionType_ID equal to 8 or 12
            const sanitizedHeader = question.questionName.replace(/[^a-zA-Z0-9 ]/g, '');

            if (question.questionType_ID == 2) {
              const userAnswerCounts = new Map();

              // Count the occurrences of each user in the answer array
              question.answer.forEach(answer => {
                userAnswerCounts.set(answer.user_ID, (userAnswerCounts.get(answer.user_ID) || 0) + 1);
              });

              // Find the maximum count for any user
              const maxChoices = Math.max(...userAnswerCounts.values(), 1);

              // Push the question name into the headers array maxChoices times
              for (let i = 0; i < maxChoices; i++) {
                const uniqueHeader = `${sid + 1}.${qid + 1} ${sanitizedHeader} (${i + 1})`;
                headers.push(uniqueHeader);
              }
            } else {
              const uniqueHeader = `${sid + 1}.${qid + 1} ${sanitizedHeader}`;
              // Check for duplicates and add a unique suffix if necessary
              if (headerCountMap.has(uniqueHeader)) {
                const count = headerCountMap.get(uniqueHeader) + 1;
                headerCountMap.set(uniqueHeader, count);
                headers.push(`${uniqueHeader} (${count})`);
              } else {
                headerCountMap.set(uniqueHeader, 1);
                headers.push(uniqueHeader);
              }
            }
          }
        });
      });
    } catch (error) {
      console.log('Error while generating headers:', error);
    }

    rows.push(headers);

    // Map to track user data
    const map = new Map();
    formSections2?.forEach(section => {
      section.questionName_array?.forEach(question => {
        if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
          question.answer.forEach(answer => {
            map.set(answer.user_ID, answer.name);
          });
        }
      });
    });

    interviewSections2?.forEach(section => {
      section.questionName_array?.forEach(question => {
        if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
          question.answer.forEach(answer => {
            map.set(answer.user_ID, answer.name);
          });
        }
      });
    });

    // Generate rows for each user
    map?.forEach((uname, user_ID) => {
      const row = new Array(headers.length).fill(0);
      row[0] = uname; // Assign name to the first field

      try {
        formSections2?.forEach((section, sid) => {
          section.questionName_array?.forEach((question, qid) => {
            if (question.answer) {
              question.answer.forEach(answer => {
                if (answer.user_ID === user_ID) {
                  row[1] = answer.genderDescription;
                  row[2] = answer.age;
                  row[3] = answer?.isMarried === 1 ? 'Married' : answer?.isMarried === 0 ? 'Not married' : '';
                  row[4] = answer.centerName;
                  row[5] = answer.classificationName;
                  row[6] = answer.group_title;

                  let columnIndex = headers.findIndex(header =>
                    header.startsWith(`${sid + 1}.${qid + 1} ${question.questionName.replace(/[^a-zA-Z0-9 ]/g, '')}`)
                  );
                  // if(uname == 'Bhumika') console.log("columnIndex",columnIndex);
                  if (columnIndex !== -1) {
                    if (question.questionType_ID === 2) {
                      let choiceCount = 0;

                      question.answer.forEach(ans => {
                        if (ans.user_ID === user_ID) {
                          const targetColumnIndex = headers.findIndex(header =>
                            header.startsWith(
                              `${sid + 1}.${qid + 1} ${question.questionName.replace(
                                /[^a-zA-Z0-9 ]/g,
                                ''
                              )} (${choiceCount + 1})`
                            )
                          );
                          if (targetColumnIndex !== -1) {
                            row[targetColumnIndex] = `${ans.choice_number}`;
                            choiceCount++; // Increment after successfully adding a choice
                          }
                        }
                      });
                    } else if (question.questionType_ID === 4 || question.questionType_ID === 14) {
                      let choices = '';
                      question.answer.forEach(ans => {
                        if (ans.user_ID === user_ID) {
                          if (question.questionType_ID === 4) {
                            choices += ` ${ans.grpchoiceName}-${ans.choice_number} |`;
                          } else if (question.questionType_ID === 14) {
                            choices += ` ${ans.grpchoiceName}-${ans.choiceName} |`;
                          }
                        }
                      });
                      row[columnIndex] = choices;
                    } else if (question.questionType_ID === 1 || question.questionType_ID === 7) {
                      row[columnIndex] = `${answer.choice_number}`;
                    } else {
                      row[columnIndex] = answer.choiceName;
                    }
                  }
                }
              });
            }
          });
        });
        interviewSections2?.forEach((section, sid) => {
          section.questionName_array?.forEach((question, qid) => {
            if (question.answer) {
              question.answer.forEach(answer => {
                if (answer.user_ID === user_ID) {
                  row[1] = answer.genderDescription;
                  row[2] = answer.age;
                  row[3] = answer?.isMarried === 1 ? 'Married' : answer?.isMarried === 0 ? 'Not married' : '';
                  row[4] = answer.centerName;
                  row[5] = answer.classificationName;
                  row[6] = answer.group_title;

                  let columnIndex = headers.findIndex(header =>
                    header.startsWith(`${sid + 1}.${qid + 1} ${question.questionName.replace(/[^a-zA-Z0-9 ]/g, '')}`)
                  );
                  // if(uname == 'Bhumika') console.log("columnIndex",columnIndex);
                  if (columnIndex !== -1) {
                    if (question.questionType_ID === 2) {
                      let choiceCount = 0;

                      question.answer.forEach(ans => {
                        if (ans.user_ID === user_ID) {
                          const targetColumnIndex = headers.findIndex(header =>
                            header.startsWith(
                              `${sid + 1}.${qid + 1} ${question.questionName.replace(
                                /[^a-zA-Z0-9 ]/g,
                                ''
                              )} (${choiceCount + 1})`
                            )
                          );
                          if (targetColumnIndex !== -1) {
                            row[targetColumnIndex] = `${ans.choice_number}`;
                            choiceCount++; // Increment after successfully adding a choice
                          }
                        }
                      });
                    } else if (question.questionType_ID === 4 || question.questionType_ID === 14) {
                      let choices = '';
                      question.answer.forEach(ans => {
                        if (ans.user_ID === user_ID) {
                          if (question.questionType_ID === 4) {
                            choices += ` ${ans.grpchoiceName}-${ans.choice_number} |`;
                          } else if (question.questionType_ID === 14) {
                            choices += ` ${ans.grpchoiceName}-${ans.choiceName} |`;
                          }
                        }
                      });
                      row[columnIndex] = choices;
                    } else if (question.questionType_ID === 1 || question.questionType_ID === 7) {
                      row[columnIndex] = `${answer.choice_number}`;
                    } else {
                      row[columnIndex] = answer.choiceName;
                    }
                  }
                }
              });
            }
          });
        });
      } catch (error) {
        console.log('Error while generating rows:', error);
      }

      rows.push(row);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Create a downloadable Excel file
    XLSX.writeFile(workbook, `${projectTitle} format_2.xlsx`);
  };

  const downloadXlsx = () => {
    dispatch({
        type: REQUEST_ANALYSIS_CSV,
        payload: {
            project_ID: id,
            AgeGroup: filters['AgeGroup'],
            MaritalStatus: filters['MaritalStatus'],
            Gender: filters['Gender'],
            classificationData: filters['classificationData'],
            UserType: filters['UserType'],
            Geography: filters['Geography'],
            Ownership: filters['Ownership'],
            CustomTags: filters['CustomTags']
        }
    });
    // const csvRows = [];
    // const headers = Object.keys(questionsAnalysisData1[0]);
    // csvRows.push(headers.join(','));
    // for (const row of questionsAnalysisData1) {
    //     const values = headers.map(header => {
    //         let value = row[header];
    //         if (typeof value === 'object') {
    //             value = JSON.stringify(value);
    //         }
    //         return value;
    //     });
    //     csvRows.push(values.join(','));
    // }
    // const csvData = csvRows.join('\n');
    // // Create a Blob object for the CSV data
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // // Create a virtual link and trigger the download
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questionsAnalysisData.csv';
    // document.body.appendChild(link); // Required for Firefox
    // link.click();
    // document.body.removeChild(link);
    //   const csvRows = [];
    // const headers = Object.keys(questionsAnalysisData1[0]);
    // csvRows.push(headers.join(','));
    // for (const row of questionsAnalysisData1) {
    //   const values = headers.map(header => {
    //     let value = row[header];
    //     // Ensure value is a string
    //     if (value && typeof value !== 'string') {
    //       value = JSON.stringify(value);
    //     }
    //     // Escape double quotes in CSV values
    //     if (value && value.includes(',')) {
    //       value = `"${value.replace(/"/g, '""')}"`;
    //     }
    //     return value;
    //   });
    //   csvRows.push(values.join(','));
    // }
    // const csvData = csvRows.join('\n');
    // // Create a Blob object for the CSV data
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // // Create a virtual link and trigger the download
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questionsAnalysisData.csv';
    // document.body.appendChild(link); // Required for Firefox
    // link.click();
    // document.body.removeChild(link);
    // const csvRows = [];
    // const headers = ['name'];
    // // Add question names to headers
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     headers.push(question.questionName);
    //   });
    // });
    // csvRows.push(headers.join(','));
    // // Add respondent data
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer) {
    //       question.answer.forEach(answer => {
    //         const rowData = [answer.name];
    //         section.questionName_array.forEach(q => {
    //           // Check if 'answer' property exists and is an array
    //           if (q.answer && Array.isArray(q.answer)) {
    //             const choice = q.answer.find(a => a.user_ID === answer.user_ID);
    //             if (choice) {
    //               if (q.questionType_ID === 4) {
    //                 rowData.push(`${choice.grpchoiceName} - ${choice.choiceName}`);
    //               } else {
    //                 rowData.push(choice.choiceName);
    //               }
    //             } else {
    //               rowData.push('');
    //             }
    //           } else {
    //             rowData.push('');
    //           }
    //         });
    //         csvRows.push(rowData.join(','));
    //       });
    //     }
    //   });
    // });
    // const csvData = csvRows.join('\n');
    // // Create a Blob object for the CSV data
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // // Create a virtual link and trigger the download
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questionsAnalysisData.csv';
    // document.body.appendChild(link); // Required for Firefox
    // link.click();
    // document.body.removeChild(link);
    // const csvRows = [];
    // const headers = ['name'];
    // // Add question names to headers
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     // Exclude questions with questionType_ID equal to 8
    //     if (question.questionType_ID !== 8) {
    //       headers.push(question.questionName);
    //     }
    //   });
    // });
    // csvRows.push(headers.join(','));
    // // Add respondent data
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8) { // Exclude questionType_ID equal to 8
    //       question.answer.forEach(answer => {
    //         const rowData = [answer.name];
    //         section.questionName_array.forEach(q => {
    //           // Exclude questionType_ID equal to 8
    //           if (q.answer && Array.isArray(q.answer) && q.questionType_ID !== 8) {
    //             const choice = q.answer.find(a => a.user_ID === answer.user_ID);
    //             if (choice) {
    //               if (q.questionType_ID === 4) {
    //                 rowData.push(`${choice.grpchoiceName} - ${choice.choiceName}`);
    //               } else {
    //                 rowData.push(choice.choiceName);
    //               }
    //             } else {
    //               rowData.push('');
    //             }
    //           } else {
    //             rowData.push('');
    //           }
    //         });
    //         csvRows.push(rowData.join(','));
    //       });
    //     }
    //   });
    // });
    // const csvData = csvRows.join('\n');
    // // Create a Blob object for the CSV data
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // // Create a virtual link and trigger the download
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questionsAnalysisData.csv';
    // document.body.appendChild(link); // Required for Firefox
    // link.click();
    // document.body.removeChild(link);
    // const csvRows = [];
    // const headers = ['name'];
    // // Add question names to headers
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     // Exclude questions with questionType_ID equal to 8
    //     if (question.questionType_ID !== 8) {
    //       headers.push(question.questionName);
    //     }
    //   });
    // });
    // csvRows.push(headers.join(','));
    // // Add respondent data
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8) { // Exclude questionType_ID equal to 8
    //       // Extract unique user IDs for this question
    //       const uniqueUserIDs = [...new Set(question.answer.map(ans => ans.user_ID))];
    //       uniqueUserIDs.forEach(userID => {
    //         // Find the response for this user ID
    //         const userResponse = question.answer.find(ans => ans.user_ID === userID);
    //         if (userResponse) {
    //           const rowData = [userResponse.name];
    //           section.questionName_array.forEach(q => {
    //             // Exclude questionType_ID equal to 8
    //             if (q.answer && Array.isArray(q.answer) && q.questionType_ID !== 8) {
    //               const choice = q.answer.find(a => a.user_ID === userID);
    //               if (choice) {
    //                 if (q.questionType_ID === 4) {
    //                   rowData.push(`${choice.grpchoiceName} - ${choice.choiceName}`);
    //                 } else {
    //                   rowData.push(choice.choiceName);
    //                 }
    //               } else {
    //                 rowData.push('');
    //               }
    //             } else {
    //               rowData.push('');
    //             }
    //           });
    //           csvRows.push(rowData.join(','));
    //         }
    //       });
    //     }
    //   });
    // });
    // const csvData = csvRows.join('\n');
    // // Create a Blob object for the CSV data
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // // Create a virtual link and trigger the download
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questionsAnalysisData.csv';
    // document.body.appendChild(link); // Required for Firefox
    // link.click();
    // document.body.removeChild(link);
    // const csvRows = [];
    //   const headers = ['name'];
    //   // Add question names to headers
    //   questionsAnalysisData1.forEach(section => {
    //     section.questionName_array.forEach(question => {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       if (question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //         headers.push(question.questionName);
    //       }
    //     });
    //   });
    //   csvRows.push(headers.join(','));
    //   // Add respondent data
    //   questionsAnalysisData1.forEach(section => {
    //     section.questionName_array.forEach(question => {
    //       if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) { // Exclude questionType_ID equal to 8 or 12
    //         // Extract unique user IDs for this question
    //         const uniqueUserIDs = [...new Set(question.answer.map(ans => ans.user_ID))];
    //         uniqueUserIDs.forEach(userID => {
    //           // Find the response for this user ID
    //           const userResponse = question.answer.find(ans => ans.user_ID === userID);
    //           if (userResponse) {
    //             const rowData = [userResponse.name];
    //             section.questionName_array.forEach(q => {
    //               // Exclude questionType_ID equal to 8 or 12
    //               if (q.answer && Array.isArray(q.answer) && q.questionType_ID !== 8 && q.questionType_ID !== 12) {
    //                 const choice = q.answer.find(a => a.user_ID === userID);
    //                 if (choice) {
    //                   if (q.questionType_ID === 4) {
    //                     rowData.push(`${choice.grpchoiceName} - ${choice.choiceName}`);
    //                   } else {
    //                     rowData.push(choice.choiceName);
    //                   }
    //                 } else {
    //                   rowData.push('');
    //                 }
    //               } else {
    //                 rowData.push('');
    //               }
    //             });
    //             csvRows.push(rowData.join(','));
    //           }
    //         });
    //       }
    //     });
    //   });
    //   const csvData = csvRows.join('\n');
    //   // Create a Blob object for the CSV data
    //   const blob = new Blob([csvData], { type: 'text/csv' });
    //   // Create a virtual link and trigger the download
    //   const link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = 'questionsAnalysisData.csv';
    //   document.body.appendChild(link); // Required for Firefox
    //   link.click();
    //   document.body.removeChild(link);

    const rows = [];
    const headers = ['Name', 'Gender', 'Age', 'Marital Status', 'Center', 'Classification', 'Group Title'];

    // Main Sheet: Headers
    // questionsAnalysisData1?.forEach((section, sid) => {
    //     section.questionName_array?.forEach((question, qid) => {
    //         if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //             const sanitizedHeader = question.questionName.replace(/[^a-zA-Z0-9 ]/g, ''); 
    //             headers.push(`${sid + 1}.${qid + 1} ` + sanitizedHeader);
    //         }
    //     });
    // });

    formSections?.forEach((section, sid) => {
      section.questionName_array?.forEach((question, qid) => {
          if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
              const sanitizedHeader = question.questionName.replace(/[^a-zA-Z0-9 ]/g, ''); 
              headers.push(`${sid + 1}.${qid + 1} ` + sanitizedHeader);
          }
      });
  });



  interviewSections?.forEach((section, sid) => {
    section.questionName_array?.forEach((question, qid) => {
        if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
            const sanitizedHeader = question.questionName.replace(/[^a-zA-Z0-9 ]/g, ''); 
            headers.push(`${sid + 1}.${qid + 1} ` + sanitizedHeader);
        }
    });
});
    rows.push(headers);

    const map = new Map();
  
    formSections?.forEach(section => {
      section.questionName_array?.forEach(question => {
          if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
              question.answer.forEach(answer => {
                  map.set(answer.user_ID, answer.name);
              });
          }
      });
  });
  interviewSections?.forEach(section => {
    section.questionName_array?.forEach(question => {
        if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
            question.answer.forEach(answer => {
                map.set(answer.user_ID, answer.name);
            });
        }
    });
});

    map?.forEach((uname, user_ID) => {
        const row = new Array(headers.length).fill('');
        row[0] = uname;

        formSections?.forEach((section, sid) => {
            section.questionName_array?.forEach((question, qid) => {
                if (question.answer) {
                    question.answer.forEach(answer => {
                        if (answer.user_ID === user_ID) {
                            row[1] = answer.genderDescription;
                            row[2] = answer.age;
                            row[3] = answer?.isMarried === 1 ? 'Married' : answer?.isMarried === 0 ? 'Not married' : '';
                            row[4] = answer.centerName;
                            row[5] = answer.classificationName;
                            row[6] = answer.group_title;
                            const columnIndex = headers.indexOf(
                                `${sid + 1}.${qid + 1} ` + question.questionName.replace(/[^a-zA-Z0-9 ]/g, '')
                            );
                            if (columnIndex !== -1) {
                                let choices = '';
                                if (question.questionType_ID === 4 || question.questionType_ID === 14) {
                                    question.answer.forEach(answer => {
                                        if (answer.user_ID === user_ID) choices += ` ${answer.grpchoiceName}-${answer.choiceName} |`;
                                    });
                                } else if (question.questionType_ID === 2) {
                                    question.answer.forEach(answer => {
                                        if (answer.user_ID === user_ID) choices += ` ${answer.choiceName} |`;
                                    });
                                } else {
                                    choices += answer.choiceName;
                                }
                                row[columnIndex] = choices;
                            }
                        }
                    });
                }
            });
        });
        interviewSections?.forEach((section, sid) => {
          section.questionName_array?.forEach((question, qid) => {
              if (question.answer) {
                  question.answer.forEach(answer => {
                      if (answer.user_ID === user_ID) {
                          row[1] = answer.genderDescription;
                          row[2] = answer.age;
                          row[3] = answer?.isMarried === 1 ? 'Married' : answer?.isMarried === 0 ? 'Not married' : '';
                          row[4] = answer.centerName;
                          row[5] = answer.classificationName;
                          row[6] = answer.group_title;
                          const columnIndex = headers.indexOf(
                              `${sid + 1}.${qid + 1} ` + question.questionName.replace(/[^a-zA-Z0-9 ]/g, '')
                          );
                          if (columnIndex !== -1) {
                              let choices = '';
                              if (question.questionType_ID === 4 || question.questionType_ID === 14) {
                                  question.answer.forEach(answer => {
                                      if (answer.user_ID === user_ID) choices += ` ${answer.grpchoiceName}-${answer.choiceName} |`;
                                  });
                              } else if (question.questionType_ID === 2) {
                                  question.answer.forEach(answer => {
                                      if (answer.user_ID === user_ID) choices += ` ${answer.choiceName} |`;
                                  });
                              } else {
                                  choices += answer.choiceName;
                              }
                              row[columnIndex] = choices;
                          }
                      }
                  });
              }
          });
      });
  // questionsAnalysisData1?.forEach(section => {
      //   section.questionName_array?.forEach(question => {
      //     const choices = [];
      //     if (question.answer) {
      //       question.answer.forEach(answer => {
      //         if (answer.user_ID === user_ID) {
      //           if (question.questionType_ID === 4) {
      //             choices.push(`${answer.grpchoiceName} - ${answer.choiceName}`);
      //           } else {
      //             choices.push(answer.choiceName);
      //           }
      //         }
      //       });
      //     }
      //     csvRow.push(choices.join(', '));
      //   });
      // });
      // console.log("csvRow",csvRow);
        rows.push(row);
    });
     // map.forEach((name, userId) => {
    //   const csvRow = new Array(headers.length).fill('');
    //   csvRow[0] = name; // Assign name to the first field
    //   // Step 3: Iterate over questionsAnalysisData1 to find matching answers
    //   questionsAnalysisData1.forEach(section => {
    //     section.questionName_array.forEach(question => {
    //       if (question.answer) {
    //         question.answer.forEach(answer => {
    //           if (answer.user_Id === userId) {
    //             const columnIndex = headers.indexOf(question.questionName.replace(/[^a-zA-Z0-9 ]/g, ''));
    //             if (columnIndex !== -1) {
    //               csvRow[columnIndex] = answer.choiceName;
    //             }
    //           }
    //         });
    //       }
    //     });
    //   });
    //   newCsvRows.push(csvRow.join(','));
    // });
    // Iterate over respondents and populate CSV rows
    // questionsAnalysisData1?.forEach(section => {
    //   section.questionName_array?.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       const userIdsSet = new Set();
    //       question.answer.forEach(answer => {
    //         if(!userIdsSet.has(answer.user_ID)){
    //           userIdsSet.add(answer.user_ID);
    //           const rowData = new Array(headers.length).fill('');
    //           rowData[0] = answer.name ;
    //           // Iterate over questions again to match responses with appropriate columns
    //           questionsAnalysisData1.forEach(qSection => {
    //             qSection.questionName_array.forEach(q => {
    //               if (q.questionName === question.questionName) {
    //                 // Append answer to corresponding column
    //                 const columnIndex = headers.indexOf(question.questionName.replace(/[^a-zA-Z0-9 ]/g, ''));
    //                 rowData[columnIndex] = answer.choiceName ;
    //               }
    //             });
    //           });
    //           csvRows.push(rowData.join(','));
    //         }
    //       });
    //     }
    //   });
    // });
    

    // Create the main worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Create a new sheet for questionType_ID === 14
    const type14Rows = [['Question', 'Options & Composite Scores']];
    // const csvRows = [];
    // const headers = ['Name']; // Initialize with 'Name' column header
    // // Iterate over questions and dynamically add headers
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       const sanitizedHeader = question.questionName.replace(/[^a-zA-Z0-9 ]/g, ''); // Remove non-alphanumeric characters from the question name
    //       headers.push(sanitizedHeader);
    //     }
    //   });
    // });
    // csvRows.push(headers.join(','));
    // // Iterate over respondents and populate CSV rows
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       question.answer.forEach(answer => {
    //         const rowData = [answer.name];
    //         // Iterate over all questions to match responses with appropriate columns
    //         headers.slice(1).forEach(header => {
    //           let found = false;
    //           section.questionName_array.forEach(q => {
    //             if (q.questionName === header) {
    //               found = true;
    //               const match = q.answer.find(ans => ans.user_ID === answer.user_ID)?.choiceName || ''; // Extract the answer corresponding to the respondent
    //               rowData.push(match);
    //             }
    //           });
    //           if (!found) {
    //             // Add an empty value for the header if the respondent didn't answer this question
    //             rowData.push('');
    //           }
    //         });
    //         csvRows.push(rowData.join(','));
    //       });
    //     }
    //   });
    // });

    formSections?.forEach(section => {
        section.questionName_array?.forEach(question => {
            if (question.questionType_ID === 14 && question.Composite) {
                // Join all choices into a single string with "||" separator
                const optionsWithScores = question.Composite
                    .map(choice => `${choice.choiceName}-${choice.compositeScore}`)
                    .join(' | ');

                type14Rows.push([question.questionName, optionsWithScores]);
            }
        });
    });
    interviewSections?.forEach(section => {
      section.questionName_array?.forEach(question => {
          if (question.questionType_ID === 14 && question.Composite) {
              // Join all choices into a single string with "||" separator
              const optionsWithScores = question.Composite
                  .map(choice => `${choice.choiceName}-${choice.compositeScore}`)
                  .join(' | ');

              type14Rows.push([question.questionName, optionsWithScores]);
          }
      });
  });
     // const csvData = csvRows.join('\n');
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questions_analysis_data.csv'; // Adjust the filename as needed
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // const csvRows = [];
    // const headers = ['Name']; // Initialize with 'Name' column header
    // const questionMap = {}; // Map question IDs to column indices
    // // Dynamically add headers and build question map
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach((question, index) => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       const header = question.questionName;
    //       headers.push(header);
    //       questionMap[question.question_ID] = index + 1; // Column index is 1-based
    //     }
    //   });
    // });

    const type14Sheet = XLSX.utils.aoa_to_sheet(type14Rows);
    XLSX.utils.book_append_sheet(workbook, type14Sheet, 'Ranking Analysis');
     // csvRows.push(headers.join(','));
    // // Populate CSV rows
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       question.answer.forEach(answer => {
    //         const rowData = [answer.name];
    //         headers.forEach((header, index) => {
    //           if (index > 0) { // Skip the first column (Name)
    //             // Fetch column index from question map
    //             const columnIndex = questionMap[question.question_ID];
    //             const value = (columnIndex === index) ? answer.choiceName : '';
    //             rowData[index] = value; // Set value in appropriate column
    //           }
    //         });
    //         csvRows.push(rowData.join(','));
    //       });
    //     }
    //   });
    // });
     // const csvData = csvRows.join('\n');
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questions_analysis_data.csv'; // Adjust the filename as needed
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // const csvRows = [];
    // const headers = ['Name']; // Initialize with 'Name' column header
    // const questionMap = {}; // Map question IDs to column indices
    // // Dynamically add headers and build question map
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach((question, index) => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       const header = sanitizeHeader(question.questionName); // Sanitize header
    //       headers.push(header);
    //       questionMap[question.question_ID] = index + 1; // Column index is 1-based
    //     }
    //   });
    // });

    // csvRows.push(headers.join(','));
    // // Populate CSV rows
    // questionsAnalysisData1.forEach(section => {
    //   section.questionName_array.forEach(question => {
    //     if (question.answer && question.questionType_ID !== 8 && question.questionType_ID !== 12) {
    //       // Exclude questions with questionType_ID equal to 8 or 12
    //       question.answer.forEach(answer => {
    //         const rowData = [answer.name];
    //         headers.forEach((header, index) => {
    //           if (index > 0) { // Skip the first column (Name)
    //             // Fetch column index from question map
    //             const columnIndex = questionMap[question.question_ID];
    //             const value = (columnIndex === index) ? answer.choiceName : '';
    //             rowData[index] = value; // Set value in appropriate column
    //           }
    //         });
    //         csvRows.push(rowData.join(','));
    //       });
    //     }
    //   });
    // });

    // const csvData = csvRows.join('\n');
    // const blob = new Blob([csvData], { type: 'text/csv' });
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = 'questions_analysis_data.csv'; // Adjust the filename as needed
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // Create a downloadable Excel file
    XLSX.writeFile(workbook, `${projectTitle} format_1.xlsx`);
};




  const handleDeleteDispatch = cohortId => {
    console.log('handleDeleteDispatch called');
    console.log('cohortId', cohortId);
    console.log('id', id);
    dispatch({
      type: 'REQUEST_DELETE_COHORT',
      payload: {
        user_ID: loggedUser.userId,
        cohort_ID: cohortId
      }
    });
    setTimeout(() => {
      dispatch({
        type: REQUEST_FILTER_DATA,
        payload: {
          payload: id
        }
      });
    }, 1000);
  };
  const handleClickonDelete = dclick => {
    clickedOnDeleteForCohort.current = dclick;
  };
  const handleClist = list => {
    // console.log("list",list);
    clist.current = list;
  };

  return (
    
    <ProjectWrapper  projectTitle={projectTitle} step={5} projectStatus={projectStatus} projectId={id} sticky={false}>
       
      <div>
        <div>
          {/* <Title className="mb-2  icon_title ">ANALYSIS</Title> */}
          {projectStatus[projectStatus.length - 1] != undefined &&
          projectStatus[projectStatus.length - 1].ModuleDescription == 'Analysis' &&
          projectStatus[projectStatus.length - 1].stepStatus == 'completed' &&
          projectDuration != undefined &&
          projectDuration[0]?.projectLead_ID == loggedUser.userId &&
          new Date(projectDuration[0]?.endDuration) < new Date() ? (
            <div
              style={{
                color: 'var(--deepBlue)',
                fontWeight: '700',
                fontSize: '12px',
                marginLeft: '20px',
                marginBottom: '20px'
              }}
            >
              <span style={{ marginRight: '20px' }}>Post field dates have ended. Do you want to end this project?</span>
              <Button
                variant={'fill'}
                buttonColor="var(--sapGreen)"
                round={true}
                size="small"
                fontSize="10px"
                fontWeight="bold"
                onClick={() => endproject(id)}
              >
                End Project
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
        <div
    className="sticky"
    style={{
      // position: 'sticky',
      // top: '170px', 
      zIndex: 1000, 
      backgroundColor: 'white', 
    }}
  >
       <div className="row" >
          <div className="col-md-9"   >
            <div className="d-flex justify-content-between">
              <FilterContainer>
                <div className="filter" style={{ width: '36%' }}>
                  <Title className="icon_title ">Audience</Title>
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <FilterIconAudience
                      src={contact_search}
                      multi_choice
                      isOpen={demographic}
                      setIsOpen2={setDemographic}
                      count={demoFilterCount() > 0 ? demoFilterCount() : false}
                    >
                      <DemographicFilter
                        filterData={filterData}
                        onApply={onDemographicFilter}
                        activeFilters={filters}
                      />
                    </FilterIconAudience>
                    {/* <FilterIconAudience
                      src={refresh}
                      multi_choice
                      isOpen={userType}
                      setIsOpen2={setUserType}
                      count={Object.keys(filters['UserType']).length > 0 ? userTypeFilterCount() : false}
                    >
                      <UserTypeFilter filterData={filterData} onApply={onUserTypeFilter} activeFilters={filters} />
                    </FilterIconAudience> */}
                      <FilterIconAudience
                        src={location}
                        multi_choice
                        isOpen={geography}
                        setIsOpen2={setGeography}
                        width="480px"
                        count={filters['Geography'].length > 0 ? filters['Geography'].length : false}
                      >
                        <GeographyFilter filterData={filterData} onApply={onGeographicFilter} activeFilters={filters} />
                      </FilterIconAudience>

                      {/* <FilterIconAudience
                      src={heart}
                      multi_choice
                      isOpen={ownership}
                      setIsOpen2={setOwnership}
                      count={Object.keys(filters['Ownership']).length > 0 ? ownershipFilterCount() : false}
                    >
                      <OwnershipFilter filterData={filterData} onApply={onOwnershipFilter} activeFilters={filters} />
                    </FilterIconAudience> */}
                      <FilterIconAudience
                        src={refresh}
                        multi_choice
                        isOpen={userType}
                        setIsOpen2={setUserType}
                        count={Object.keys(filters['UserType']).length > 0 ? userTypeFilterCount() : false}
                      >
                        <UserTypeFilter filterData={filterData} onApply={onUserTypeFilter} activeFilters={filters} />
                      </FilterIconAudience>
                    </div>
                  </div>

                  <div className="filter" style={{ width: '46%' }}>
                    <Title className="icon_title">Data</Title>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <div
                        onClick={ev => {
                          let t = dataType;

                          if (t.indexOf('video') == -1) {
                            t.push('video');
                          } else if (filters['CustomTags'].length == 0 && t.indexOf('video') != -1) {
                            t.splice(t.indexOf('video'), 1);
                          }
                          setDataType([...t]);
                          // setisVideoIconClicked(!isVideoIconClicked);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FilterIconVideoClip
                          src={video}
                          active={dataType.indexOf('video') != -1}
                          setisVideoIconClicked={setisVideoIconClicked}
                        />
                      </div>
                      <div
                      // onClick={ev => {
                      //   let t = dataType;
                      //   if (t.indexOf('images') == -1) {
                      //     t.push('images');
                      //   } else {
                      //     t.splice(t.indexOf('images'), 1);
                      //   }

                      //   setDataType([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon src={images} active={dataType.indexOf('images') != -1} /> */}
                        <FilterIcon
                          src={images}
                          dataType={dataType}
                          setIconClicked={setisImageIconClicked}
                          dtype={'images'}
                          setDataType={setDataType}
                        />
                      </div>
                      <div
                      // onClick={ev => {
                      //   let t = dataType;
                      //   if (t.indexOf('audios') == -1) {
                      //     t.push('audios');
                      //   } else {
                      //     t.splice(t.indexOf('audios'), 1);
                      //   }
                      //   setDataType([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon src={audio} active={dataType.indexOf('audios') != -1} /> */}
                        <FilterIcon
                          src={audio}
                          dataType={dataType}
                          setIconClicked={setisAudioIconClicked}
                          dtype={'audios'}
                          setDataType={setDataType}
                        />
                      </div>
                      <div
                      // onClick={ev => {
                      //   let t = dataType;
                      //   if (t.indexOf('bar_chart') == -1) {
                      //     t.push('bar_chart');
                      //   } else {
                      //     t.splice(t.indexOf('bar_chart'), 1);
                      //   }
                      //   setDataType([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon src={bar_chart} active={dataType.indexOf('bar_chart') != -1} /> */}
                        <FilterIcon
                          src={bar_chart}
                          dataType={dataType}
                          setIconClicked={setisGraphIconClicked}
                          dtype={'bar_chart'}
                          setDataType={setDataType}
                        />
                      </div>
                      <div
                      // onClick={ev => {
                      //   let t = dataType;
                      //   if (t.indexOf('likert') == -1) {
                      //     t.push('likert');
                      //   } else {
                      //     t.splice(t.indexOf('likert'), 1);
                      //   }
                      //   setDataType([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon src={star} active={dataType.indexOf('likert') != -1} /> */}
                        <FilterIcon
                          src={star}
                          dataType={dataType}
                          setIconClicked={setisLikertIconClicked}
                          dtype={'likert'}
                          setDataType={setDataType}
                        />
                      </div>
                      <div
                      // onClick={ev => {
                      //   let t = dataType;
                      //   if (t.indexOf('wcloud') == -1) {
                      //     t.push('wcloud');
                      //   } else {
                      //     t.splice(t.indexOf('wcloud'), 1);
                      //   }
                      //   setDataType([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon src={comment} active={dataType.indexOf('wcloud') != -1} /> */}
                        <FilterIcon
                          src={comment}
                          dataType={dataType}
                          setIconClicked={setisWcloudIconClicked}
                          dtype={'wcloud'}
                          setDataType={setDataType}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="filter" style={{ width: '28.5%' }}>
                    <Title className=" icon_title">Tags</Title>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <div
                      // onClick={ev => {
                      //   let t = vType;
                      //   if (t.indexOf('Hits') == -1) {
                      //     t.push('Hits');
                      //   } else {
                      //     t.splice(t.indexOf('Hits'), 1);
                      //   }
                      //   setVtype([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon
                        filters={filters}
                        setFilters={setFilters}
                        hitsTagId={hitsTagId}
                        src={like}
                        active={vType.indexOf('Hits') != -1}
                      /> */}
                        <FilterIcon
                          filters={filters}
                          setFilters={setFilters}
                          hitsTagId={hitsTagId}
                          src={like}
                          dataType={vType}
                          setDataType={setVtype}
                          dtype="Hits"
                        />
                      </div>
                      <div
                      // onClick={ev => {
                      //   let t = vType;
                      //   if (t.indexOf('Misses') == -1) {
                      //     t.push('Misses');
                      //   } else {
                      //     t.splice(t.indexOf('Misses'), 1);
                      //   }
                      //   setVtype([...t]);
                      // }}
                      // style={{ cursor: 'pointer' }}
                      >
                        {/* <FilterIcon src={dislike} active={vType.indexOf('Misses') != -1} /> */}
                        <FilterIcon src={dislike} dataType={vType} setDataType={setVtype} dtype="Misses" />
                      </div>
                      <div style={{ cursor: 'pointer' }}>
                        <FilterIcon
                          src={tag_blue}
                          multi_choice
                          isOpen={ctag}
                          setIsOpen={setCtag}
                          count={filters['CustomTags'].length > 0 ? filters['CustomTags'].length : false}
                        >
                          <CustomTagsFilter
                            tagsFilterdata={tagsFilterdata}
                            setTagsFilterdata={setTagsFilterdata}
                            onApply={onCustomTagFilter}
                            activeFilters={filters}
                            dispatch={dispatch}
                            userid={loggedUser.userId}
                            projectid={id}
                          />
                        </FilterIcon>
                      </div>
                    </div>
                  </div>
                </FilterContainer>
                <DropdownContainer className="ml-1 " onMouseLeave={() => setCohortIsOpen(false)}>
                  <Title>Cohorts</Title>

                  {cohorts == 'edit' ? (
                    <div className="d-flex mt-2 align-items-center">
                      <EditableInput
                        FormContainerWidth="100px"
                        autoFocus
                        editnow={true}
                        width="90%"
                        value={'Unsaved'}
                        color="var(--deepBlue)"
                        submit={text => {
                          dispatch({
                            type: REQUEST_ADD_COHORT,
                            payload: {
                              project_ID: id,
                              cohort: text,
                              AgeGroup: filters['AgeGroup'],
                              MaritalStatus: filters['MaritalStatus'],
                              Gender: filters['Gender'],
                              classificationData: filters['classificationData'],
                              UserType: filters['UserType'],
                              Geography: filters['Geography'],
                              Ownership: filters['Ownership'],
                              //"UserType" : {"15503" : [52156 ,52157]},
                              //"Geography" : {"center_ID":"15"},
                              //"Ownership" : {"15504" : [52161,52159] ,"15505" : [52165]},
                              CustomTags: filters['CustomTags']
                            }
                          });
                          setCohorts('saveButton');
                        }}
                        inputStyle={{
                          fontSize: '10px'
                        }}
                      />
                      <Icon
                        style={{
                          fontSize: '14px',
                          color: 'var(--deepBlue)',
                          marginLeft: '5px'
                        }}
                      >
                        close
                      </Icon>
                    </div>
                  ) : (
                    <div className="d-flex mt-2 ">
                      <DeletableDropdown
                        selectedFontSize="10px"
                        selectedFontWeight="600"
                        options={cohortList}
                        cohortList={cohortList}
                        setCohortList={setCohortList}
                        selectedColor="var(--deepBlue)"
                        defaultValue={{
                          value: 1,
                          label: 'Unsaved'
                        }}
                        value={{ label: text }}
                        controlStyle={controlStyle}
                        valueContainerStyle={{
                          padding: '0'
                        }}
                        selectedBorderBottom="1px solid var(--deepBlue)"
                        DropdownIndicator={props => <DownIndicatorCustom {...props} />}
                        crossOption={true}
                        menuIsOpen={cohortIsOpen}
                        setMenuIsOpen={setCohortIsOpen}
                        passClickedonDelete={handleClickonDelete}
                        passCohortlist={handleClist}
                        passHandleDeleteDispatch={handleDeleteDispatch}
                        onChange={val => {
                          if (clickedOnDeleteForCohort.current !== true) {
                            setText(val.label);
                            let ownerShipCohortData = {};
                            let ost = val.data.OwnerShip.reduce((f, r) => {
                              if (f[r.question_ID.toString()] == undefined) {
                                f[r.question_ID.toString()] = [];
                              }
                              f[r.question_ID.toString()].push(r.questionChoice_ID);
                              return f;
                            }, []);
                            ost?.map((v, i) => [(ownerShipCohortData = { ...ownerShipCohortData, [i]: v })]);
                            let userTypeCohortData = {};
                            let utt = val.data.UserType.reduce((f, r) => {
                              if (f[r.question_ID.toString()] == undefined) {
                                f[r.question_ID.toString()] = [];
                              }
                              f[r.question_ID.toString()].push(r.questionChoice_ID);
                              return f;
                            }, []);
                            utt?.map((v, i) => [(userTypeCohortData = { ...userTypeCohortData, [i]: v })]);
                            let t = {
                              AgeGroup: val.data.AgeGroup.reduce((f, r) => {
                                f.push(r.ageGroup);
                                return f;
                              }, []),
                              MaritalStatus: val.data.MaritalStatus.reduce((f, r) => {
                                f.push(r.maritalStatus);
                                return f;
                              }, []),
                              Gender: val.data.Gender.reduce((f, r) => {
                                f.push(r.gender_ID);
                                return f;
                              }, []),
                              classificationData: val.data.Classification.reduce((f, r) => {
                                f.push(r.classification_ID);
                                return f;
                              }, []),
                              Ownership: ownerShipCohortData,
                              UserType: userTypeCohortData,
                              CustomTags: val.data.CustomTags.reduce((f, r) => {
                                f.push(r.projectRepondentVideoTags_ID);
                                return f;
                              }, []),
                              Geography: val.data.Center.reduce((f, r) => {
                                f.push(r.center_ID);
                                return f;
                              }, [])
                            };
                            setFilters({ ...t });
                          } else {
                            const ind = clist.current?.findIndex(mtag => mtag.label == text);
                            if (ind >= 0) {
                              let txt = clist.current[ind]?.label;
                              console.log('txt', txt);
                              // setText(txt);
                            } else {
                              let t = {
                                AgeGroup: [],
                                MaritalStatus: [],
                                Gender: [],
                                classificationData: [],
                                Ownership: {},
                                UserType: {},
                                CustomTags: [],
                                Geography: []
                              };
                              setFilters({ ...t });
                              setText('Select one');
                            }
                          }
                        }}
                      />
                      {cohorts == 'saveButton' ? (
                        <ImgCustom className="icon" src={save} onClick={() => setCohorts('edit')} />
                      ) : (
                        <img src={EditDeepBlue} className="icon" onClick={() => setCohorts('saveButton')} />
                      )}
                    </div>
                  )}
                </DropdownContainer>
              </div>

              <ResultContainer className="mt-2 d-flex " style={{ width: '100%' }}>
                <div
                  className="d-flex "
                  style={{
                    width: '22%'
                  }}
                >
                  <div
                    style={{
                      width: '100%'
                    }}
                  >
                    <Title>Results</Title>
                    <div className="d-flex mt-2 justify-content-between">
                      <ResultCount name="Respondents">{respondents}</ResultCount>
                      <CustomDivider />
                      <ResultCount name="Capture Areas">
                        {filterData && filterData.hasOwnProperty('Geography')
                          ? filters.Geography.length > 0
                            ? filters.Geography.length
                            : regionCount(filterData.Geography) + '/' + regionCount(filterData.Geography)
                          : '0/0'}
                      </ResultCount>
                    </div>
                  </div>

                  <CustomDivider height="70px" margin="1rem 1rem" />
                </div>
                <div
                  className="d-flex ResultCount"
                  style={{
                    width: '78%',
                    marginLeft: '1rem',
                    marginRight: '0px'
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Title>Filters</Title>
                    <div className="d-flex mt-2  justify-content-between">
                      {filterData?.hasOwnProperty('Demography') && (
                        <>
                          <ResultCount name="Demographic">
                            {demographyFilterCount() > 0
                              ? demographyFilterCount()
                              : filterData.Demography.AgeGroup.length +
                                filterData.Demography.MaritalStatus.length +
                                filterData.Demography.Gender.length +
                                filterData.Demography.classificationData.length}
                            /
                            {filterData.Demography.AgeGroup.length +
                              filterData.Demography.MaritalStatus.length +
                              filterData.Demography.Gender.length +
                              filterData.Demography.classificationData.length}
                          </ResultCount>

                          <CustomDivider />
                          <ResultCount name="Geography">
                            {filters.Geography.length > 0
                              ? filters.Geography.length
                              : regionCount(filterData.Geography)}
                            /{regionCount(filterData.Geography)}
                          </ResultCount>
                          <CustomDivider />
                          <ResultCount name="Screener Filters">
                            {/* {filters.UserType.length > 0 ? filters.UserType.length : UsertTypeCount(filterData.UserType)}/{UsertTypeCount(filterData.UserType)} */}
                            {Object.keys(filters.UserType).length > 0
                              ? userTypeFilterCount()
                              : UsertTypeCount(filterData.UserType)}
                            /{UsertTypeCount(filterData.UserType)}
                          </ResultCount>
                          <CustomDivider />

                          {/* <ResultCount name="Ownership"> */}
                          {/* {filters.Ownership.length > 0 ? filters.Ownership.length : OwnershipCount(filterData.Ownership)}/{OwnershipCount(filterData.Ownership)} */}
                          {/* {Object.keys(filters.Ownership).length > 0
                            ? ownershipFilterCount()
                            : OwnershipCount(filterData.Ownership)}
                          /{OwnershipCount(filterData.Ownership)}
                        </ResultCount> */}
                          {/* <CustomDivider /> */}
                          {/* <ResultCount name='Data Type'>
													{dataType.length == 0 ? '5/5' : dataType.length.toString() + '/5'}
												</ResultCount> */}
                          <ResultCount name="Data Type">
                            {dataType.length == 0 ? '6/6' : dataType.length.toString() + '/6'}
                          </ResultCount>
                          <CustomDivider />
                          <ResultCount name="Misses & Hits">
                            {vType.length == 0 ? '2/2' : vType.length.toString() + '/2'}
                          </ResultCount>
                          <CustomDivider />
                          <ResultCount name="Tags">
                            {filters?.CustomTags?.length == 0
                              ? `${customFilterCount}/${customFilterCount}`
                              : `${filters?.CustomTags?.length.toString()}/${customFilterCount}`}
                          </ResultCount>
                        </>
                      )}
                      <CustomDivider />
                      {/* <Button size="small" className="ml-2" padding="0.09rem 0.281rem" buttonColor="var(--sapGreen)" style={{cursor: 'pointer'}}>
                      <div fontSize="10px" fontWeight="300" color="white" className="ml-1">
                        <img src={download_csv} onClick={downloadCsv} /> Download <img src={whiteDropdown} alt="White Dropdown" />
                      </div>
                    </Button> */}
                      <div className="mt-1 ml-2">
                        {questionsAnalysisData1 != null && questionsAnalysisData2 != null && (
                          <ActionButton size="small" normalBtn={true} left="83.5%" top="75%">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={download_csv} style={{ marginRight: '3px' }} />
                              <SpanCustom>Download</SpanCustom>
                              <ImgCustom src={whiteDropdown} alt="White Dropdown" />
                            </div>
                            <TaskMenu className="task-menu" width="150px">
                              {csvFormats.map((item, i) => {
                                return (
                                  <>
                                    <BtnAssignGroup
                                      key={i}
                                      className="border-bottom"
                                      onClick={() => handleCSVformatChange(item)}
                                    >
                                      <Text type="5" className="text-left">
                                        {item.label}
                                      </Text>
                                    </BtnAssignGroup>
                                  </>
                                );
                              })}
                            </TaskMenu>
                          </ActionButton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ResultContainer>
            </div>
            <div className="col-md-3">
              <ReportDeck
                report={activeReport}
                setReport={setReport}
                pid={id}
                onDelete={onSlideDelete}
                onUpdate={onSlideTitleUpdate}
              />
            </div>
          </div>
        </div>
        {stitch && <div className="mt-3">Video stiching in progress...</div>}
        <div>
          <AnalysisList
            analysisData={localQuestionsData}
            getClipsData={analysisClipsData}
            getClipsWithoutUrl={analysisClipsWithoutUrl}
            tagsFilterdata={tagsFilterdata}
            filters={filters}
            dropDowns={dropDowns}
            isGenerated={isGenerated}
            interviewsCompleted={interviewsCompleted}
            onCopyClick={(qid, config, question, questionObject) => {
              let data = {
                trimVideo_ID: []
              };
              let qtype = findQuestionType(qid);
              if (qtype == 10 || qtype == '10') {
                let keys = Object.keys(config);

                for (let i = 0; i < keys.length; i++) {
                  let k = parseInt(keys[i]);
                  if (config[k].hasOwnProperty('qtype') && config[k].qtype == 10) {
                    data.trimVideo_ID.push(config[k].vidId);
                  }
                }

                if (data.trimVideo_ID.length > 0) {
                  if (stitch) {
                    alert('You can only add a video question when previous stiching has finished.');
                    return;
                  }
                  let t = {
                    qid,
                    question,
                    questionObject
                  };
                  setStitch(true);
                  setStitchQID({ ...t });
                  dispatch({
                    type: REQUEST_STICH_VIDEO,
                    data: { payload: data }
                  });
                  // setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
                  // () => stitchResponse === "Unable to save concatenated video" ? dispatch({
                  // 	type: REQUEST_STICH_VIDEO,
                  // 	data: {payload: data}
                  // }) : "",
                  // 400);
                  return;
                }
              }
              let d = [];
              if (typeof activeReport === 'object' && activeReport != null) {
                d = activeReport;
              }

              d[activeReport.length] = { qid, question, config, questionObject, edit: true };

              dispatch({
                type: ADD_TO_REPORT,
                data: d
              });
              setJustRefresh(prev => !prev);
            }}
            dataType={dataType}
            cTag={ctag}
            vType={vType}
          />
        </div>
      </div>

      <ToastContainer />
    </ProjectWrapper>
  );
}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData
    projectTitle:
      state.ProjectReducer.resumeData.project_data != undefined
        ? state.ProjectReducer.resumeData.project_data[0].identity
        : '',
    projectStatus:
      state.ProjectReducer.resumeData.projectStatus != undefined
        ? state.ProjectReducer.resumeData?.projectStatus[0]?.steps_array != undefined
          ? state.ProjectReducer.resumeData?.projectStatus[0]?.steps_array
          : ''
        : '',
    stitchResponse: state.analysisReducer.stitchResponse
  };
};

export default connect(mapStateToProps)(AnalysisScreen);
