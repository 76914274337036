import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { Card, Heading } from '../../common';
import DownIndicator from '../../common/DownIndicator';
import { Dropdown } from '../../components/form';
import AnalysisBarChart from './AnalysisBarChart';
import AnalysisLikert from './AnalysisLikert';
import AnalysisQuestion from './AnalysisQuestion';
import AnalysisWordCloud from './AnalysisWordCloud';
import Accordion from 'react-bootstrap/Accordion';
import img1 from '../../images/video_thumbnail/1.jpg';
import img2 from '../../images/video_thumbnail/2.jpg';
import img3 from '../../images/video_thumbnail/3.jpg';
import img4 from '../../images/video_thumbnail/4.jpg';
import img5 from '../../images/video_thumbnail/5.jpg';
import img6 from '../../images/video_thumbnail/6.jpg';
import img7 from '../../images/video_thumbnail/7.jpg';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { id } from 'date-fns/locale';
import loading from '../../assets/images/loading.svg';
import { useState, useEffect } from 'react';
import { Button } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { dispatch } from 'd3';
import { REQUEST_CHECK_SUMMARY } from '../../constants/analysis/checkSummaryConstants';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import { useParams } from 'react-router-dom';
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import VerticalStackedRankedBarChart from './VerticalStackedRankedBarChart';

const data1 = [{ img: img1 }, { img: img2 }, { img: img3 }, { img: img4 }, { img: img5 }, { img: img6 }, { img: img7 }];

const data2 = [{ img: img1 }, { img: img2 }, { img: img3 }, { img: img4 }, { img: img5 }, { img: img6 }, { img: img7 }];

const Header = styled(Card)`
  background: var(--midWhite);
  box-shadow: none;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const product_types = [
  { value: 1, label: 'Jump To' },
  { value: 2, label: 'New' }
];
const controlStyle = {
  minHeight: 'auto',
  width: '80px',
  '&:hover': {
    borderColor: 'var(--sapGreen)'
  }
};

const DownIndicatorCustom = ({ ...props }) => {
  return <DownIndicator color="var(--sapGreen)" iconStyle={{ fontSize: 16 }} {...props} />;
};


const ListContainer = styled.div`
 max-height: 600px; 
  width: 100%;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 1rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
  overflow-y: scroll;

  .question {
    padding-top: 1rem;
  }
  .question:first-of-type {
    padding-top: 0;
  }
  
`;

const AnalysisListHeader = ({
  title,
  children,
  eventKey,
  activeKey,
  toggleAccordion,
  dropdownOptions,
  onDropdownChange,
  dropdownValue,
  dropdownDisabled
}) => {
  return (
    <div className="pb-2">
      <Header withBorder={false}>
        <Heading
          fontSize="14px"
          fontWeight="700"
          color="var(--deepBlue)"
          className="mb-0 pb-0"
          onClick={() => toggleAccordion(eventKey)}
        >
          {title}{' '}
          {activeKey.includes(eventKey) ? (
            <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
          ) : (
            <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
          )}
        </Heading>
        {eventKey !== 'form' && eventKey !== 'interview' && (
        <Dropdown
          selectedFontSize="12px"
          selectedFontWeight="400"
          options={dropdownOptions}
          isSummary
          selectedColor="var(--deepBlue)"
          defaultValue={{
            value: 1,
            label: 'Jump To'
          }}
          value={dropdownValue}
          controlStyle={controlStyle}
          valueContainerStyle={{
            padding: '0'
          }}
          indicatorContainer={{ padding: '0' }}
          selectedBorderBottom="1px solid var(--sapGreen)"
          DropdownIndicator={props => <DownIndicatorCustom {...props} />}
          onChange={onDropdownChange}
          isDisabled={dropdownDisabled}
        />)}
      </Header>
      {children}
    </div>
  );
};

function AnalysisList({
  onCopyClick,
  analysisData,
  tagsFilterdata,
  filters,
  getClipsData,
  getClipsWithoutUrl,
  dropDowns,
  dataType,
  cTag,
  vType,
  isGenerated,
  interviewsCompleted
}) {
  var isSummary = 0;
  const [summaryData, setSummaryData] = useState('');
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);

  let project_id = useParams(id);
  const [summaryWithQid, setSummaryWithQid] = useState('');
  const [summary, setSummary] = useState('');
  const [sliceSummary, setSliceSummary] = useState('');

  var onLoad = false;

  // console.log(getClipsData)

  // useEffect(()=>{
  // 	onLoad = true;
  // 	console.log(onLoad);
  // },[])

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
  const [activeKey, setActiveKey] = useState(() => (analysisData ? analysisData.map((_, i) => i.toString()) : []));
  const [formActiveKey, setFormActiveKey] = useState(['form']);
  const [interviewActiveKey, setInterviewActiveKey] = useState(['interview']);
  const [formSections, setFormSections] = useState([]);
  const [interviewSections, setInterviewSections] = useState([]);
  const [selectedDropdowns, setSelectedDropdowns] = useState({}); // Track dropdown selections
  
  // Initialize active keys for sections within form
  // useEffect(() => {
  //   if (!analysisData) return;

  //   setFormActiveKey(['form', ...analysisData.filter(a => a.taskType === 1).map((_, i) => (i + 1).toString())]);
  //   setInterviewActiveKey([
  //     'interview',
  //     ...analysisData.filter(a => a.taskType === 2).map((_, i) => (i + 1).toString())
  //   ]);
  // }, [analysisData]);

  useEffect(() => {
    if (!analysisData) return;

    setFormSections(analysisData?.filter(a => a.taskType === 1) || []);
    setInterviewSections(analysisData?.filter(a => a.taskType === 2) || []);

  }, [analysisData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setIsLoadingVideo(false);
    }, 10000); // Adjust the delay time (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [getClipsData]);

  useEffect(() => {
    const timerMp3 = setTimeout(() => {
      setIsLoadingAudio(false);
      setIsLoadingView(false);
    }, 10000); // Adjust the delay time (in milliseconds) as needed
    setActiveKey(analysisData ? analysisData.map((_, i) => i.toString()) : []);
    if (analysisData !== null) setIsLoadingQuestion(false);

    return () => clearTimeout(timerMp3);
  }, [analysisData]);

  // var summaryData = ''
  // const summaryData = useSelector(state=>state.getSummaryReducer.Analysisdata);

  var flag = 0;
  let firstClipData = null;
  let firstClipDataWithoutUrl = null;
  const checkFlag = firstClipData => {
    for (var i = 0; i < firstClipData.answer.length; i++) {
      if (
        firstClipData.answer[i]?.ClipUpdateStatusSummary !== '0' &&
        firstClipData.answer[i]?.ClipUpdateStatusSummary !== null
      ) {
        flag = 1;
      }
    }
  };

  const handleCopyClick = e => {
    // e.preventDefault();
    onCopyClick(prev => [
      ...prev,
      {
        title: 'Storage Directors',
        edit: true
      }
    ]);
  };

  const toggleFormAccordion = key => {
    setFormActiveKey(prevActiveKey => {
      if (prevActiveKey.includes(key)) {
        // Accordion is being closed, reset the dropdown for this key
        setSelectedDropdowns(prev => ({ ...prev, [key]: { value: 1, label: 'Jump To' } }));
        return prevActiveKey.filter(k => k !== key);
      } else {
        return [...prevActiveKey, key];
      }
    });
  };

  const toggleInterviewAccordion = key => {
    setInterviewActiveKey(prevActiveKey => {
      if (prevActiveKey.includes(key)) {
        // Accordion is being closed, reset the dropdown for this key
        setSelectedDropdowns(prev => ({ ...prev, [key]: { value: 1, label: 'Jump To' } }));
        return prevActiveKey.filter(k => k !== key);
      } else {
        return [...prevActiveKey, key];
      }
    });
  };

  const questionRefs = useRef({});

  const onDropdownChange = (selectedOption, sectionKey) => {
    setSelectedDropdowns(prev => ({ ...prev, [sectionKey]: selectedOption }));
    const questionRef = questionRefs.current[selectedOption.value];
    if (questionRef) {
      questionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.warn(`No question found with ID: ${selectedOption.value}`);
    }
  };

  const questionRefsInterview = useRef({});
  const onDropdownChangeInterview = (selectedOption, sectionKey) => {
    setSelectedDropdowns(prev => ({ ...prev, [sectionKey]: selectedOption }));
    const questionRef = questionRefsInterview.current[selectedOption.value];
    if (questionRef) {
      questionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.warn(`No question found with ID: ${selectedOption.value}`);
    }
  };

  return (
    <>
      {analysisData === null ? (
        <div >
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
        </div>
      ) : (
        <>
      
          {formSections && formSections.length > 0 && (
            <Accordion
              activeKey={formActiveKey}
              style={{
                flexDirection: 'column',
                display: 'block',
             
              }}
              
            >
              <AnalysisListHeader
                title="FORM"
                eventKey="form"
                activeKey={formActiveKey}
                toggleAccordion={() => toggleFormAccordion('form')}
              >
                <Accordion.Collapse eventKey="form">
                  <Accordion activeKey={formActiveKey} >
                    <ListContainer>
                      {formSections?.map((a, i) => {
                        const sectionKey = (i + 1).toString();
                        const dropdownOptions = a?.questionName_array?.map((_, j) => ({
                          value: `${sectionKey}.${j + 1}`,
                          label: `${sectionKey}.${j + 1}`
                        }));
                        return (
                          <AnalysisListHeader
                            title={(i + 1).toString() + ' | ' + a.sectionName}
                            key={sectionKey}
                            eventKey={sectionKey}
                            activeKey={formActiveKey}
                            toggleAccordion={() => toggleFormAccordion(sectionKey)}
                            dropdownOptions={dropdownOptions}
                            // onDropdownChange={onDropdownChange}
                            dropdownValue={selectedDropdowns[sectionKey] || { value: 1, label: 'Jump To' }}
                            onDropdownChange={selectedOption => onDropdownChange(selectedOption, sectionKey)}
                            dropdownDisabled={!formActiveKey.includes(sectionKey)}
                          >
                            <Accordion.Collapse eventKey={sectionKey}>
                              <div className="px-3" key={a.questionPaperSection_ID}>
                                
                                {a.questionName_array.map((q, j) => {
                                  const questionTextWithNumber = `${i + 1}.${j + 1} ${q.questionName}`;
                               
                                  const questionId = `${sectionKey}.${j + 1}`;
                                  // questionRefs.current[questionId] = useRef(null);
                                  questionRefs.current[questionId] = React.createRef();
                                  {
                                    let hmlist = [];
                                    let hmlistWithoutUrl = [];
                                    if (Array.isArray(getClipsData) && Array.isArray(getClipsWithoutUrl)) {
                                      const relevantClipsData = getClipsData?.find(cd =>
                                        cd.questionName_array?.some(q2 => q.question_ID === q2.question_ID)
                                      );
                                      const relevantClipsWithoutUrl = getClipsWithoutUrl?.find(cd => 
                                        cd.questionName_array?.some(q2 => q.question_ID === q2.question_ID)
                                      )
                                      if (isGenerated && isGenerated[q.question_ID]) {
                                        isSummary = isGenerated[q.question_ID][0].isSummary;
                                      } else {
                                        isSummary = 0;
                                      }

                                if (
                                  q.questionType_ID === 10 &&
                                  q.answer !== null &&
                                  (dataType.length == 0 || dataType.indexOf('video') != -1) &&
                                  relevantClipsData
                                ) {
                                  const clipsData = relevantClipsData?.questionName_array.filter(
                                    q2 => q.question_ID === q2.question_ID
                                  );
                                  const clipsWithoutUrl = relevantClipsWithoutUrl?.questionName_array.filter(
                                    q2 => q.question_ID === q2.question_ID
                                  )

                                  if (clipsData?.length > 0 && clipsWithoutUrl?.length > 0) {
                                    firstClipData = clipsData[0];
                                    firstClipDataWithoutUrl = clipsWithoutUrl[0];
                                    checkFlag(firstClipData);
                                    firstClipData.answer.map((item, index) => {
                                      let tagH = [];
                                      item.tags.map((a, i) => {
                                        if (a.tag == 'Hits') {
                                          tagH.push('Hits');
                                        }
                                        if (a.tag == 'Misses') {
                                          tagH.push('Misses');
                                        }
                                      });
                                      if (!cTag && isLoadingVideo == false && vType.length > 0) {
                                        if (
                                          vType.indexOf(item.buttonName) != -1 ||
                                          tagH.some(val => vType.includes(val))
                                        ) {
                                          hmlist.push(item);
                                        }
                                      }
                                    });
                                    firstClipDataWithoutUrl?.answer.map((item, index) => {
                                      let tagH = [];
                                      item.tags.map((a, i) => {
                                        if (a.tag == 'Hits') {
                                          tagH.push('Hits');
                                        }
                                        if (a.tag == 'Misses') {
                                          tagH.push('Misses');
                                        }
                                      });
                                      if (!cTag && isLoadingVideo == false && vType.length > 0) {
                                        if (vType.indexOf(item.buttonName) != -1 || tagH.some(val => vType.includes(val))) {
                                          hmlistWithoutUrl.push(item);
                                        }
                                      }
                                    });

                                          if (isLoading) {
                                            return (
                                              <div style={{ borderBottom: '1px solid var(--greyBlue30)' }}>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '75%'
                                                  }}
                                                >
                                                  <div style={{ width: '40%' }}>
                                                    <Heading
                                                      fontSize="14px"
                                                      fontWeight="700"
                                                      color="var(--deepBlue)"
                                                      className="mb-0 pb-0 mt-3"
                                                      breakHeading={false}
                                                      style={{ marginRight: '5px' }}
                                                    >
                                                      {questionTextWithNumber}
                                                      <SummaryTextContainer
                                                        type={'video'}
                                                        interviewsCompleted={interviewsCompleted}
                                                        setSliceSummary={setSliceSummary}
                                                        setSummary={setSummary}
                                                        summary={summary}
                                                        isMp4={false}
                                                        isLoadingVideo={isLoadingVideo}
                                                        setIsLoadingView={setIsLoadingView}
                                                        setSummaryWithQid={setSummaryWithQid}
                                                        summaryWithQid={summaryWithQid}
                                                        question_ID={q.question_ID}
                                                        id={firstClipData?.answer[0]?.projectRepondentVideo_ID}
                                                        project_id={project_id.id}
                                                        setSelectedGenerateSummary={setSelectedGenerateSummary}
                                                        selectedGenerateSummary={selectedGenerateSummary}
                                                        isLoading={isLoading}
                                                        setIsLoading={setIsLoading}
                                                        flag={flag}
                                                        isSummary={isSummary}
                                                      />
                                                    </Heading>
                                                  </div>
                                                  <div className="pb-2">
                                                    <Heading
                                                      fontSize="12px"
                                                      fontWeight="500"
                                                      color="var(--deepBlue)"
                                                      className="mb-0 pb-0 mt-0"
                                                    >
                                                      Clips Loading...
                                                    </Heading>
                                                    <img src={loading} className="loading" alt="loading" />
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          // return (
                                          //   <AnalysisQuestion
                                          //     questionObject={q}
                                          //     question={q.questionName}
                                          //     information={firstClipData?.answer.length + ' Videos'}
                                          //     handleCopyClick={onCopyClick}
                                          //     type="video"
                                          //     description="Hits"
                                          //     tag="Hits"
                                          //     note="Uses cold air function to prevent heat damage"
                                          //     key={q.question_ID}
                                          //     cTag={cTag}
                                          //     vType={vType}
                                          //     qid={q.question_ID}
                                          //     data={vType?.length > 0 ? hmlist : firstClipData?.answer}
                                          //     isSummary={isSummary}
                                          //     interviewsCompleted={interviewsCompleted}
                                          //     preAnalysisData={summaryData}
                                          //     preSelectedGenerateSummary={selectedGenerateSummary}
                                          //     summaryWithQid={summaryWithQid}
                                          //     setIsLoadingView={setIsLoadingView}
                                          //     isLoadingView={isLoadingView}
                                          //     isLoadingVideo={isLoadingVideo}
                                          //   />
                                          // );
                                        }
                                      }
                                    }
                                    if (
                                      q.questionType_ID === 10 &&
                                      (dataType.length == 0 || dataType.indexOf('video') != -1)
                                    ) {
                                      return (
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                          <AnalysisQuestion
                                            questionObject={q}
                            
                                            question={questionTextWithNumber}
                                            information={firstClipData ? firstClipData?.answer.length + ' Videos' : ''}
                                            type="video"
                                            description="Hits"
                                            tag="Hits"
                                            note="Uses cold air function to prevent heat damage"
                                            key={q.question_ID}
                                            filters={filters}
                                            cTag={cTag}
                                            vType={vType}
                                            qid={q.question_ID}
                                            data={vType?.length > 0 ? hmlist : firstClipData?.answer}
                                            dataWithoutUrl={vType?.length > 0 ? hmlistWithoutUrl : firstClipDataWithoutUrl?.answer}
                                            isSummary={isSummary}
                                            interviewsCompleted={interviewsCompleted}
                                            preAnalysisData={summaryData}
                                            tagsFilterdata={tagsFilterdata}
                                            preSelectedGenerateSummary={selectedGenerateSummary}
                                            summaryWithQid={summaryWithQid}
                                            setIsLoadingView={setIsLoadingView}
                                            isLoadingView={isLoadingView}
                                            isLoadingVideo={isLoadingVideo}
                                            handleCopyClick={onCopyClick}
                                          />
                                        </div>
                                      );
                                    }

                                    if (isGenerated && isGenerated[q.question_ID]) {
                                      isSummary = isGenerated[q.question_ID][0].isSummary;
                                    } else {
                                      isSummary = 0;
                                    }

                                    if (
                                      q.questionType_ID === 8 &&
                                      q.answer !== null &&
                                      (dataType.length == 0 || dataType.indexOf('images') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={q.answer.length + ' Photos'}
                                            handleCopyClick={onCopyClick}
                                            type="image"
                                            data={q.answer}
                                            // vType={vType}
                                            key={'key_' + q.question_ID}
                                            selectCheckBox={ev => {
                                              console.log('ev', ev);
                                            }}
                                            qid={q.question_ID}
                                            isLoadingView={isLoadingView}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      q.questionType_ID === 12 &&
                                      q.answer !== null &&
                                      (dataType.length == 0 || dataType.indexOf('audios') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={q.answer.length + ' Audios'}
                                            handleCopyClick={onCopyClick}
                                            type="audio"
                                            data={q.answer}
                                            // vType={vType}
                                            key={'key_' + q.question_ID}
                                            selectCheckBox={ev => {
                                              console.log('ev', ev);
                                            }}
                                            qid={q.question_ID}
                                            isLoadingAudio={isLoadingAudio}
                                            isSummary={isSummary}
                                          />
                                        </div>
                                      );
                                    }

                                    if (
                                      q.questionType_ID === 13 || q.questionType_ID === 15 &&
                                      q.answer?.preAssingedImageUrl !== null &&
                                      (dataType.length == 0 || dataType.indexOf('video') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                 
                                      return (
                                        
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                         
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={q.answer.length + ' Videos'}
                                            handleCopyClick={onCopyClick}
                                            type="video"
                                            isMp4={true}
                                            data={q.answer}
                                            dataWithoutUrl={q.answer}
                                            vType={vType}
                                            key={'key_' + q.question_ID}
                                            selectCheckBox={ev => {
                                              console.log('ev', ev);
                                            }}
                                            qid={q.question_ID}
                                            isLoadingAudio={isLoadingAudio}
                                            filters={filters}
                                            isSummary={isSummary}
                                            cTag={cTag}
                                            interviewsCompleted={interviewsCompleted}
                                            preAnalysisData={summaryData}
                                            preSelectedGenerateSummary={selectedGenerateSummary}
                                            tagsFilterdata={tagsFilterdata}
                                            summaryWithQid={summaryWithQid}
                                            setIsLoadingView={setIsLoadingView}
                                            isLoadingView={isLoadingView}
                                            isLoadingVideo={isLoadingVideo}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      (q.questionType_ID === 2 ||
                                        q.questionType_ID === 1 ||
                                        q.questionType_ID === 7 ||
                                        q.questionType_ID === 11) &&
                                      q.answer !== null &&
                                      (dataType.length == 0 || dataType.indexOf('bar_chart') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        activeKey.includes(i.toString()) && (
                                          <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                            <AnalysisBarChart
                                              questionObject={q}
                                              qid={q.question_ID}
                                              question={questionTextWithNumber}
                                              information="Bar Graph"
                                              handleCopyClick={onCopyClick}
                                              data={q.answerCnt}
                                              options={dropDowns}
                                              filters={filters}
                                              key={'key_' + q.question_ID}
                                              questionID={q.question_ID}
                                              isLoadingQuestion={isLoadingQuestion}
                                            />
                                          </div>
                                        )
                                      );
                                    }

                                    if (
                                      (q.questionType_ID === 3 || q.questionType_ID === 4) &&
                                      q.answerCnt != null &&
                                      (dataType.length == 0 || dataType.indexOf('likert') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                          <AnalysisLikert
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information="Likert Graph"
                                            handleCopyClick={onCopyClick}
                                            dataset={q.answerCnt}
                                            qid={q.question_ID}
                                            key={'key_' + q.question_ID}
                                            isLoadingQuestion={isLoadingQuestion}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      (q.questionType_ID === 5 || q.questionType_ID === 6) &&
                                      q.answerCnt !== null &&
                                      (dataType.length === 0 || dataType.indexOf('wcloud') !== -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                          <AnalysisWordCloud
                                            questionObject={q}
                                            qid={q.question_ID}
                                            question={questionTextWithNumber}
                                            information="Word Cloud"
                                            handleCopyClick={onCopyClick}
                                            dataset={q['questionAns']}
                                            questionID={q.question_ID}
                                            key={'key_' + q.question_ID}
                                            isLoadingQuestion={isLoadingQuestion}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      q.questionType_ID === 14 && 
                                      q.answerCnt &&
                                      (dataType.length == 0 || dataType.indexOf('bar_chart') != -1) &&  // Added bar_chart filter here
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div ref={el => (questionRefs.current[questionId] = el)} key={q.question_ID}>
                                          <VerticalStackedRankedBarChart
                                            questionObject={q}
                                            qid={q.question_ID}
                                            question={questionTextWithNumber}
                                            information="Ranking Graph"
                                            data={q.answerCnt}
                                            questionID={q.question_ID}
                                            handleCopyClick={onCopyClick}
                                            key={'key_' + q.question_ID}
                                          />
                                        </div>
                                      );
                                    }
                                  }
                                })}
                              </div>
                            </Accordion.Collapse>
                          </AnalysisListHeader>
                        );
                      })}
                    </ListContainer>
                  </Accordion>
                </Accordion.Collapse>
              </AnalysisListHeader>
            </Accordion>
          )}
          {interviewSections && interviewSections.length > 0 && (
            <Accordion
              activeKey={interviewActiveKey}
              style={{
                flexDirection: 'column',
                display: 'block',
             
              }}
            
            >
              <AnalysisListHeader
                title="INTERVIEW"
                eventKey="interview"
                activeKey={interviewActiveKey}
                toggleAccordion={() => toggleInterviewAccordion('interview')}
              >
                <Accordion.Collapse eventKey="interview">
                  <Accordion activeKey={interviewActiveKey} >
                    <ListContainer>
                      {interviewSections?.map((a, i) => {
                        const sectionKey = (i + 1).toString();

                        const dropdownOptions = a.questionName_array.map((_, j) => ({
                          value: `${sectionKey}.${j + 1}`,

                          label: `${sectionKey}.${j + 1}`
                        }));
                        return (
                          <AnalysisListHeader
                            title={(i + 1).toString() + ' | ' + a.sectionName}
                            key={sectionKey}
                            eventKey={sectionKey}
                            activeKey={interviewActiveKey}
                            toggleAccordion={() => toggleInterviewAccordion(sectionKey)}
                            dropdownOptions={dropdownOptions}
                            dropdownValue={selectedDropdowns[sectionKey] || { value: 1, label: 'Jump To' }}
                            onDropdownChange={selectedOption => onDropdownChangeInterview(selectedOption, sectionKey)}
                            dropdownDisabled={!interviewActiveKey.includes(sectionKey)}
                          >
                            <Accordion.Collapse eventKey={sectionKey}>
                              <div className="px-3" key={a.questionPaperSection_ID}>
                                {a.questionName_array.map((q, j) => {
                                  const questionTextWithNumber = `${i + 1}.${j + 1} ${q.questionName}`;
               
                                  const questionId = `${sectionKey}.${j + 1}`;
                                  questionRefsInterview.current[questionId] = React.createRef();
                                  {
                                    let hmlist = [];
                                    let hmlistWithoutUrl = [];
                                    if (Array.isArray(getClipsData) && Array.isArray(getClipsWithoutUrl)) {
                                      const relevantClipsData = getClipsData?.find(cd =>
                                        cd.questionName_array?.some(q2 => q.question_ID === q2.question_ID)
                                      );
                                      const relevantClipsWithoutUrl = getClipsWithoutUrl?.find(cd => 
                                        cd.questionName_array?.some(q2 => q.question_ID === q2.question_ID)
                                      )
                                      if (isGenerated && isGenerated[q.question_ID]) {
                                        isSummary = isGenerated[q.question_ID][0].isSummary;
                                      } else {
                                        isSummary = 0;
                                      }

                                      if (
                                        q.questionType_ID === 10 &&
                                        q.answer !== null &&
                                        (dataType.length == 0 || dataType.indexOf('video') != -1) &&
                                        relevantClipsData
                                      ) {
                                        const clipsData = relevantClipsData?.questionName_array.filter(
                                          q2 => q.question_ID === q2.question_ID
                                        );
                                        const clipsWithoutUrl = relevantClipsWithoutUrl?.questionName_array.filter(
                                          q2 => q.question_ID === q2.question_ID
                                        )

                                        if (clipsData?.length > 0 && clipsWithoutUrl?.length > 0) {
                                          firstClipData = clipsData[0];
                                          firstClipDataWithoutUrl = clipsWithoutUrl[0];
                                          checkFlag(firstClipData);
                                          firstClipData.answer.map((item, index) => {
                                            let tagH = [];
                                            item.tags.map((a, i) => {
                                              if (a.tag == 'Hits') {
                                                tagH.push('Hits');
                                              }
                                              if (a.tag == 'Misses') {
                                                tagH.push('Misses');
                                              }
                                            });
                                            if (!cTag && isLoadingVideo == false && vType.length > 0) {
                                              if (
                                                vType.indexOf(item.buttonName) != -1 ||
                                                tagH.some(val => vType.includes(val))
                                              ) {
                                                hmlist.push(item);
                                              }
                                            }
                                          });
                                          firstClipDataWithoutUrl?.answer.map((item, index) => {
                                            let tagH = [];
                                            item.tags.map((a, i) => {
                                              if (a.tag == 'Hits') {
                                                tagH.push('Hits');
                                              }
                                              if (a.tag == 'Misses') {
                                                tagH.push('Misses');
                                              }
                                            });
                                            if (!cTag && isLoadingVideo == false && vType.length > 0) {
                                              if (vType.indexOf(item.buttonName) != -1 || tagH.some(val => vType.includes(val))) {
                                                hmlistWithoutUrl.push(item);
                                              }
                                            }
                                          });

                                          if (isLoading) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    borderBottom: '1px solid var(--greyBlue30)'
                                                  }}
                                                >
                                                  <div style={{ width: '40%' }}>
                                                    <Heading
                                                      fontSize="14px"
                                                      fontWeight="700"
                                                      color="var(--deepBlue)"
                                                      className="mb-0 pb-0 mt-3"
                                                      breakHeading={false}
                                                      style={{ marginRight: '5px' }}
                                                    >
                                                      {questionTextWithNumber}
                                                      <SummaryTextContainer
                                                        type={'video'}
                                                        interviewsCompleted={interviewsCompleted}
                                                        setSliceSummary={setSliceSummary}
                                                        setSummary={setSummary}
                                                        summary={summary}
                                                        isMp4={false}
                                                        isLoadingVideo={isLoadingVideo}
                                                        setIsLoadingView={setIsLoadingView}
                                                        setSummaryWithQid={setSummaryWithQid}
                                                        summaryWithQid={summaryWithQid}
                                                        question_ID={q.question_ID}
                                                        id={firstClipData?.answer[0]?.projectRepondentVideo_ID}
                                                        project_id={project_id.id}
                                                        setSelectedGenerateSummary={setSelectedGenerateSummary}
                                                        selectedGenerateSummary={selectedGenerateSummary}
                                                        isLoading={isLoading}
                                                        setIsLoading={setIsLoading}
                                                        flag={flag}
                                                        isSummary={isSummary}
                                                      />
                                                    </Heading>
                                                  </div>
                                                  <div className="pb-2">
                                                    <Heading
                                                      fontSize="12px"
                                                      fontWeight="500"
                                                      color="var(--deepBlue)"
                                                      className="mb-0 pb-0 mt-0"
                                                    >
                                                      Clips Loading...
                                                    </Heading>
                                                    <img src={loading} className="loading" alt="loading" />
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      }
                                    }
                                    if (
                                      q.questionType_ID === 10 &&
                                      (dataType.length == 0 || dataType.indexOf('video') != -1)
                                    ) {
                                      return (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={firstClipData ? firstClipData?.answer.length + ' Videos' : ''}
                                            type="video"
                                            description="Hits"
                                            tag="Hits"
                                            note="Uses cold air function to prevent heat damage"
                                            key={q.question_ID}
                                            filters={filters}
                                            cTag={cTag}
                                            vType={vType}
                                            qid={q.question_ID}
                                            data={vType?.length > 0 ? hmlist : firstClipData?.answer}
                                            dataWithoutUrl={vType?.length > 0 ? hmlistWithoutUrl : firstClipDataWithoutUrl?.answer}
                                            isSummary={isSummary}
                                            interviewsCompleted={interviewsCompleted}
                                            preAnalysisData={summaryData}
                                            tagsFilterdata={tagsFilterdata}
                                            preSelectedGenerateSummary={selectedGenerateSummary}
                                            summaryWithQid={summaryWithQid}
                                            setIsLoadingView={setIsLoadingView}
                                            isLoadingView={isLoadingView}
                                            isLoadingVideo={isLoadingVideo}
                                            handleCopyClick={onCopyClick}
                                          />
                                        </div>
                                      );
                                    }

                                    if (isGenerated && isGenerated[q.question_ID]) {
                                      isSummary = isGenerated[q.question_ID][0].isSummary;
                                    } else {
                                      isSummary = 0;
                                    }

                                    if (
                                      q.questionType_ID === 8 &&
                                      q.answer !== null &&
                                      (dataType.length == 0 || dataType.indexOf('images') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={q.answer.length + ' Photos'}
                                            handleCopyClick={onCopyClick}
                                            type="image"
                                            data={q.answer}
                                            // vType={vType}
                                            key={'key_' + q.question_ID}
                                            selectCheckBox={ev => {
                                              console.log('ev', ev);
                                            }}
                                            qid={q.question_ID}
                                            isLoadingView={isLoadingView}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      q.questionType_ID === 12 &&
                                      q.answer !== null &&
                                      (dataType.length == 0 || dataType.indexOf('audios') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={q.answer.length + ' Audios'}
                                            handleCopyClick={onCopyClick}
                                            type="audio"
                                            data={q.answer}
                                            // vType={vType}
                                            key={'key_' + q.question_ID}
                                            selectCheckBox={ev => {
                                              console.log('ev', ev);
                                            }}
                                            qid={q.question_ID}
                                            isLoadingAudio={isLoadingAudio}
                                            isSummary={isSummary}
                                          />
                                        </div>
                                      );
                                    }

                                    if (
                                      q.questionType_ID === 13 &&
                                      q.answer.preAssingedImageUrl !== null &&
                                      (dataType.length == 0 || dataType.indexOf('video') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <AnalysisQuestion
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information={q.answer.length + ' Videos'}
                                            handleCopyClick={onCopyClick}
                                            type="video"
                                            isMp4={true}
                                            data={q.answer}
                                            dataWithoutUrl={q.answer}
                                            vType={vType}
                                            key={'key_' + q.question_ID}
                                            selectCheckBox={ev => {
                                              console.log('ev', ev);
                                            }}
                                            qid={q.question_ID}
                                            isLoadingAudio={isLoadingAudio}
                                            isSummary={isSummary}
                                            cTag={cTag}
                                            filters={filters}
                                            interviewsCompleted={interviewsCompleted}
                                            preAnalysisData={summaryData}
                                            tagsFilterdata={tagsFilterdata}
                                            preSelectedGenerateSummary={selectedGenerateSummary}
                                            summaryWithQid={summaryWithQid}
                                            setIsLoadingView={setIsLoadingView}
                                            isLoadingView={isLoadingView}
                                            isLoadingVideo={isLoadingVideo}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      (q.questionType_ID === 2 ||
                                        q.questionType_ID === 1 ||
                                        q.questionType_ID === 7 ||
                                        q.questionType_ID === 11) &&
                                      q.answer !== null &&
                                      (dataType.length == 0 || dataType.indexOf('bar_chart') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        activeKey.includes(i.toString()) && (
                                          <div
                                            ref={el => (questionRefsInterview.current[questionId] = el)}
                                            key={q.question_ID}
                                          >
                                            <AnalysisBarChart
                                              questionObject={q}
                                              qid={q.question_ID}
                                              question={questionTextWithNumber}
                                              information="Bar Graph"
                                              handleCopyClick={onCopyClick}
                                              data={q.answerCnt}
                                              options={dropDowns}
                                              filters={filters}
                                              key={'key_' + q.question_ID}
                                              questionID={q.question_ID}
                                              isLoadingQuestion={isLoadingQuestion}
                                            />
                                          </div>
                                        )
                                      );
                                    }

                                    if (
                                      (q.questionType_ID === 3 || q.questionType_ID === 4) &&
                                      q.answerCnt != null &&
                                      (dataType.length == 0 || dataType.indexOf('likert') != -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        activeKey.includes(i.toString()) && (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <AnalysisLikert
                                            questionObject={q}
                                            question={questionTextWithNumber}
                                            information="Likert Graph"
                                            handleCopyClick={onCopyClick}
                                            dataset={q.answerCnt}
                                            qid={q.question_ID}
                                            key={'key_' + q.question_ID}
                                            isLoadingQuestion={isLoadingQuestion}
                                          />
                                        </div>
                                        )
                                      );
                                    }
                                    if (
                                      (q.questionType_ID === 5 || q.questionType_ID === 6) &&
                                      q.answerCnt !== null &&
                                      (dataType.length === 0 || dataType.indexOf('wcloud') !== -1) &&
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <AnalysisWordCloud
                                            questionObject={q}
                                            qid={q.question_ID}
                                            question={questionTextWithNumber}
                                            information="Word Cloud"
                                            handleCopyClick={onCopyClick}
                                            dataset={q['questionAns']}
                                            questionID={q.question_ID}
                                            key={'key_' + q.question_ID}
                                            isLoadingQuestion={isLoadingQuestion}
                                          />
                                        </div>
                                      );
                                    }
                                    if (
                                      q.questionType_ID === 14 && 
                                      q.answerCnt &&
                                      (dataType.length == 0 || dataType.indexOf('bar_chart') != -1) &&  // Added bar_chart filter here
                                      vType.indexOf('Hits') === -1 &&
                                      vType.indexOf('Misses') === -1
                                    ) {
                                      return (
                                        <div
                                          ref={el => (questionRefsInterview.current[questionId] = el)}
                                          key={q.question_ID}
                                        >
                                          <VerticalStackedRankedBarChart
                                            questionObject={q}
                                            qid={q.question_ID}
                                            question={questionTextWithNumber}
                                            information="Ranking Graph"
                                            data={q.answerCnt}
                                            questionID={q.question_ID}
                                            handleCopyClick={onCopyClick}
                                            key={'key_' + q.question_ID}
                                          />
                                        </div>
                                      );
                                    }
                                  }
                                })}
                              </div>
                            </Accordion.Collapse>
                          </AnalysisListHeader>
                        );
                      })}
                    </ListContainer>
                  </Accordion>
                </Accordion.Collapse>
              </AnalysisListHeader>
            </Accordion>
          )}
        
        </>
      )}
    </>
  );
}

export default AnalysisList;
