import React from 'react';
import { Text, Card, Divider } from '../../common';
import styled from '@emotion/styled';

const CardContainer = styled(Card)`
  height: ${props => props.height || ''};
`;

export function RecruitStatus({data}) {
  
  
  return (
    <div className="row">
      <div className="col-md-12 p-0">
        <div className="mt-4">
          <CardContainer>
            <Text type="4" fontSize="16px">
              Recruit Status
            </Text>
            
              {data && data.map((val, index)=>
              <div className="row" key={index}>
                <div className="col-md-5 pt-3 d-flex justify-content-start">
                <div style={{ marginRight: "10px" }}>
                  <Text type="5" fontSize="14px" color="#526666" padding="0px 0px 8px 0px" >
                    {`${val.projectGroupTag} - ${val.projecGroupName}`}
                  </Text>
                </div>
                <Divider margin="0" orientation="vertical" height="auto" width="1px" />
              </div>
              <div className="col-md-2 pt-3 pl-0 pr-0 d-flex justify-content-around">
                <div>
                  <Text type="5" fontSize="14px" color="#9EBE21" padding="0px 0px 8px 0px">
                    {val.hasOwnProperty('invitedCount')?`${val.invitedCount} Invited`:`${val.recruitedCount} Recruited`}
                  </Text>
                </div>
                <Divider margin="0" orientation="vertical" height="auto" width="1px" />
              </div>
              <div className="col-md-2  pt-3 ">
                <div>
                  <Text type="5" fontSize="14px" padding="0px 0px 8px 0px">
                    {val.hasOwnProperty('invitedCount')?val.sampleSize - val.invitedCount + ` Remaining`:val.sampleSize - val.recruitedCount + ` Remaining`}
                  </Text>
                </div>
              </div>
              </div>)}
            
          </CardContainer>
        </div>
      </div>
    </div>
  );
}
