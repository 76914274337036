import styled from '@emotion/styled';
import { Children } from 'preact/compat';
import React, { useEffect, useState } from 'react';
import barChart from '../../images/bar_chart_2.svg';
import table from '../../images/table.svg';
import BarChart from './charts/BarChart';
import AnalysisLikerTable from './AnalysisLikerTable';
import Scale from './charts/Scale';
import HorizontalBarChart from './charts/HorizontalBarChart';
import useWindowDimensions from '../../common/useWindowDimensions';
import Likert from './charts/dthree/likert';
import { Heading, Icon, Button } from '../../common';
import copy_active from '../../images/copy_active.svg';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import loading from '../../assets/images/loading.svg';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useInView } from 'react-intersection-observer';
import { Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Item = styled.div`
  width: 25px;
  height: 25px;
  color: var(--seaBlue);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: white;
  }
`;
const Filter = styled.div`
  display: flex;
  background: var(--darkWhite);
  border: 2px solid var(--darkWhite);
  border-radius: 3px;
  div:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  div:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
const chartTypeList = [barChart, table];
const valueTypeList = ['#', '%'];

const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--midWhite);
  color: var(--sapGreen);
  border-radius: 3px;
  padding: 0.4rem 0.9rem;
  img {
    padding-right: 5px;
  }
  :hover {
    background: var(--midWhite);
    color: ${props => props.buttonColor};
  }

  :disabled,
  [disabled] {
    background: var(--midWhite);
    border: none;
    color: var(--middleGrey);

    :hover {
      background: var(--midWhite);
      border: none;
      color: var(--middleGrey);
      cursor: not-allowed;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  display: block;
  border: none;
  background-color: transparent;

  .accordion-item {
    border: none;
    background-color: transparent;
  }

  .accordion-header {
    button {
    background-color: transparent;
      border: none;
    white-space: nowrap;
  }
`;

function AnalysisLikert({ questionObject, qid, question, information, handleCopyClick, dataset, isLoadingQuestion }) {
  const [chartType, setChartType] = useState(barChart);
  const [valueType, setValueType] = useState('#');
  const [header, setHeader] = useState([]);
  const [high, setHigh] = useState([]);
  const [low, setLow] = useState([]);
  const [neutral, setNeutral] = useState([]);
  const [highList, setHighList] = useState([]);
  const [lowList, setLowList] = useState([]);
  const [mean, setMean] = useState([]);
  const [sum, setSum] = useState([]);
  const { height, width } = useWindowDimensions();
  const barWidth = width > 1500 ? width / 1.3 : width / 1.4;
  const barHeight = height / 2.4;
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [forceRender, setForceRender] = useState(0);

  // Determine the number of options based on the dataset
  const numOptions = dataset && dataset.length > 0 ? Object.keys(dataset[0]).filter(key => !['id', 'choiceName', 'mean'].includes(key)).length : 0;

  const generateColors = (numSegments) => {
    const baseColors = [
      [201, 4, 3, 1.0], // Start color
      [229, 85, 85, 1.0],
      [237, 125, 125, 1.0],
      [166, 166, 166, 1.0], // Middle color
      [80, 131, 204, 1.0],
      [35, 97, 161, 1.0],
      [22, 62, 102, 1.0], // End color
    ];

    // Map the base colors to the desired number of segments
    if (numSegments === 5) {
      return [
        `rgba(${baseColors[0].join(",")})`,
        `rgba(${baseColors[1].join(",")})`,
        `rgba(${baseColors[3].join(",")})`, // Middle color
        `rgba(${baseColors[5].join(",")})`,
        `rgba(${baseColors[6].join(",")})`,
      ];
    } else if (numSegments === 7) {
      return baseColors.map((color) => `rgba(${color.join(",")})`);
    } else if (numSegments === 10) {
      // Interpolate additional colors for 10 segments
      return [
        `rgba(${baseColors[0].join(",")})`,
        `rgba(${interpolateColor(baseColors[0], baseColors[1], 0.5).join(",")})`,
        `rgba(${baseColors[1].join(",")})`,
        `rgba(${interpolateColor(baseColors[1], baseColors[2], 0.5).join(",")})`,
        `rgba(${baseColors[2].join(",")})`,
        `rgba(${baseColors[3].join(",")})`, // Middle color
        `rgba(${baseColors[4].join(",")})`,
        `rgba(${interpolateColor(baseColors[4], baseColors[5], 0.5).join(",")})`,
        `rgba(${baseColors[5].join(",")})`,
        `rgba(${baseColors[6].join(",")})`,
      ];
    }
    return baseColors.map((color) => `rgba(${color.join(",")})`); // Default to 7 colors
  };

    // Helper function to interpolate between two colors
    const interpolateColor = (color1, color2, factor) => {
      return color1.map((channel, index) =>
        Math.round(channel + factor * (color2[index] - channel))
      );
    };

  useEffect(() => {
    setChartType(barChart); 
    setValueType('#')
  }, []);
  

   const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1
    });
  
  const [addReport, setAddReport] = useState({
    chartType: barChart,
    valueType: '#',
    data: {
      table: {}
    }
  });




  useEffect(() => {
    if (dataset && dataset.length > 0) {
      // Get headers (choice names)
      let s = dataset.reduce((f, d) => {
        f.push(d['choiceName']);
        return f;
      }, []);
      setHeader([...s]);

      // Determine scale size by counting ScaleNo_ keys
      const scaleKeys = Object.keys(dataset[0]).filter(key => key.startsWith('ScaleNo_'));
      const scaleSize = scaleKeys.length;
      const midpoint = Math.ceil(scaleSize / 2);

      // Calculate sums first
      let sumTemp = [];
      dataset.forEach(ds => {
        const scaleValues = scaleKeys.map(key => parseInt(ds[key]) || 0);
        sumTemp.push(scaleValues.reduce((a, b) => a + b, 0));
      });

      // Initialize high scores (upper half of scale)
      let hl = Array.from({ length: Math.floor((scaleSize - midpoint + 1)) }, 
        (_, i) => [scaleSize - i]);
      hl = dataset.reduce((f, d) => {
        scaleKeys.forEach(key => {
          const scaleNum = parseInt(key.split('_')[1]);
          if (scaleNum > midpoint) {
            const arrayIndex = scaleSize - scaleNum;
            if (!f[arrayIndex]) f[arrayIndex] = [scaleSize - arrayIndex];
            f[arrayIndex].push(d[key]);
          }
        });
        return f;
      }, hl);
      setHighList(hl);

      // Initialize low scores (lower half of scale)
      let ll = Array.from({ length: midpoint - 1 }, 
        (_, i) => [i + 1]);
      ll = dataset.reduce((f, d) => {
        scaleKeys.forEach(key => {
          const scaleNum = parseInt(key.split('_')[1]);
          if (scaleNum < midpoint) {
            const arrayIndex = scaleNum - 1;
            if (!f[arrayIndex]) f[arrayIndex] = [arrayIndex + 1];
            f[arrayIndex].push(d[key]);
          }
        });
        return f;
      }, ll);
      setLowList(ll);

      // Get neutral responses (midpoint)
      let nl = dataset.reduce((f, d) => {
        const midpointKey = `ScaleNo_${midpoint}`;
        f.push(d[midpointKey]);
        return f;
      }, []);
      setNeutral(nl);

      // Calculate sums for high and low scores
      let hsum = new Array(hl[0]?.length - 1 || 0).fill(0);
      hl.forEach(row => {
        for (let j = 1; j < row.length; j++) {
          hsum[j - 1] += parseInt(row[j] || 0);
        }
      });
      setHigh(hsum);

      let lsum = new Array(ll[0]?.length - 1 || 0).fill(0);
      ll.forEach(row => {
        for (let j = 1; j < row.length; j++) {
          lsum[j - 1] += parseInt(row[j] || 0);
        }
      });
      setLow(lsum);

      // Calculate means
      let meanTemp = dataset.map(d => d.mean);
      setMean(meanTemp);

      // Update sum state based on valueType
      if (valueType === '%') {
        setSum(sumTemp);
      } else {
        setSum([]);
      }

      // Update addReport
      let t = {
        chartType: chartType,
        valueType: valueType,
        data: {
          chart: dataset,
          table: {
            header: header,
            highList: hl,
            lowList: ll,
            neutral: nl,
            low: lsum,
            mean: meanTemp,
            high: hsum,
            sum: valueType === '%' ? sumTemp : []
          }
        }
      };
      
      setAddReport(t);
    }
  }, [dataset, chartType, valueType]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(prev => !prev);
    setTimeout(() => {
      setForceRender(prev => prev + 1);
    }, 100);
  };


 useEffect(() => {
    if (isAccordionOpen) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isAccordionOpen]);


  // useEffect(() => {

  // 	let tt = addReport
  // 	tt.chartType = chartType
  // 	tt.valueType = valueType

  // 	let sumTemp = [];
  // 	dataset.forEach(ds => {
  // 		let dk = Object.keys(ds)
  // 		let data = []

  // 		for (let i = 0; i < dk.length; i++) {
  // 			if (dk[i] != 'id' && dk[i] != 'choiceName' && dk[i] != 'mean') {
  // 				data.push(parseInt(ds[dk[i]]))
  // 			}
  // 		}
  // 		sumTemp.push(data.reduce((a, b) => a + b, 0));
  // 	});
  // 	if (valueType == '%') {
  // 		setSum(sumTemp)
  // 		tt.data.table = { ...tt.data.table, sum: sumTemp }
  // 	}
  // 	else {
  // 		setSum([])
  // 	}

  // 	setAddReport(tt)

  // }, [chartType, valueType])

  return (
    <div className="question " style={{ borderBottom: '1px solid var(--greyBlue30)' }} ref={ref}>
      {inView ? (
        <StyledAccordion>
          <Accordion style={{ display: 'block' }}>
            <Accordion.Item eventKey="0">
              <div className="d-flex justify-content-between my-2">
                <Accordion.Header>
                  <div style={{ width: '100%' }}>
                    <Heading
                      fontSize="14px"
                      fontWeight="700"
                      color="var(--deepBlue)"
                      className="mb-0 pb-0"
                      breakHeading={false}
                      onClick={() => {
                        setActiveKey(activeKey === '0' ? null : '0');
                        handleAccordionToggle();
                      }}
                      
                    >
                      {question}
                      {activeKey === '0' ? (
                        <FaChevronUp size={10} style={{ marginLeft: '8px' }} />
                      ) : (
                        <FaChevronDown size={10} style={{ marginLeft: '8px' }} />
                      )}
                      {information && (
                        <span>
                          <Heading fontSize="12px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0 pl-1">
                            {information}
                          </Heading>
                        </span>
                      )}
                    </Heading>
                  </div>
                </Accordion.Header>
                <div className="d-flex align-items-center">
                  <CustomButton
                    fontWeight="400"
                    fontSize="10px"
                    className="ml-2"
                    size="small"
                    variant="outlined"
                    buttonColor="var(--sapGreen)"
                    onClick={() => handleCopyClick(qid, addReport, question, questionObject)}
                  >
                    <img src={copy_active} /> Copy to Report
                  </CustomButton>
                </div>
              </div>
              <Accordion.Body>
                {isLoading === true ? (
                  <div className="pb-2">
                    <Heading fontSize="12px" fontWeight="500" color="var(--deepBlue)" className="mb-0 pb-0 mt-4">
                      Clips Loading...
                    </Heading>
                    <img src={loading} className="loading" alt="loading" />
                  </div>
                ) : (
                  ''
                )}

                <div className="d-flex">
                  <Filter className="mr-2">
                    {chartTypeList.map((item, index) => (
                      <Item
                        key={index}
                        className={`${chartType == item && 'active'}`}
                        onClick={() => {
                          setChartType(item);
                        }}
                      >
                        <img src={item} />
                      </Item>
                    ))}
                  </Filter>
                  <Filter>
                    {valueTypeList.map((item, index) => (
                      <Item
                        key={index}
                        className={`${valueType == item && 'active'}`}
                        onClick={() => setValueType(item)}
                      >
                        {item}
                      </Item>
                    ))}
                  </Filter>
                </div>
                {isLoadingQuestion !== false && chartType == barChart ? (
                  <div style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
                    <Skeleton count={2} height={30} width="100%" style={{ marginBottom: '0.5rem' }} />
                  </div>
                ) : (
                  chartType == barChart && (
                    <div className="py-3">
                      {dataset != undefined && (
                        <div className="row">
                          <div className="col-md-12 row" style={{ float: 'left', width: '200px' }}>
                            <div className="offset-md-9 col-md-3 col-sm-12 row" style={{ fontSize: '9px' }}>
                              <div className="col-4" style={{ textAlign: 'left', color: 'rgba(201, 4, 3, 1.0)' }}>
                                LOWEST
                              </div>
                              <div className="col-4" style={{ textAlign: 'center', color: 'rgba(166, 166, 166, 1.0)' }}>
                                NEUTRAL
                              </div>
                              <div className="col-4" style={{ textAlign: 'right', color: 'rgba(22, 62, 102, 1.0)' }}>
                                HIGHEST
                              </div>
                              <div className="col-12" style={{ display: 'flex', height: '5px' }}>
                                {Array.from({ length: numOptions }).map((_, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      flex: 1,
                                      backgroundColor: generateColors(numOptions)[i],
                                    }}
                                  ></div>
                                ))}
                              </div>
                              <div className="col-12" style={{ display: 'flex' }}>
                                {Array.from({ length: numOptions }).map((_, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      flex: 1,
                                      color: generateColors(numOptions)[i],
                                    }}
                                  >
                                    {i + 1}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {dataset.map((d, i) => {
                            return (
                              <div className="col-md-12" key={`${i}-${forceRender}`}>
                                <div style={{ fontSize: '12px', marginBottom: '8px' }}>
                                  <strong>{d.choiceName}</strong>&nbsp;<i>&mu;{d.mean}</i>
                                </div>
                                <Likert datasets={d} valueType={valueType} numOptions={numOptions} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )
                )}
                {chartType == table && (
                  <div className="mt-3">
                    <AnalysisLikerTable
                      High={high}
                      HighList={highList}
                      Neutral={neutral}
                      Low={low}
                      LowList={lowList}
                      MeanScore={mean}
                      Header={header}
                      Sum={sum}
                    />
                  </div>
                )}
                {/* <div className='py-3'>
				{chartType == chartTypeList[0] ? (
					<div>
						<div
							className='pb-4'
							style={{ position: 'relative' }}
						>
							<Scale />

							<HorizontalBarChart
								data={
									valueType === valueTypeList[0]
										? horizontalBarChart
										: horizontalPercentageBarChart
								}
							/>
						</div>
						<div
							className='mt-5'
							style={{ position: 'relative' }}
						>
							<Scale />

							<div className='py-2'>
								<BarChart
									addData={false}
									heightRatio={50}
									data={barChartData}
									width={barWidth}
									height={barHeight}
									top={20}
									bottom={30}
									left={30}
									right={0}
								/>
							</div>
						</div>
					</div>
				) : (
					<AnalysisLikerTable
						High={High}
						HighList={HighList}
						Neutral={Neutral}
						Low={Low}
						LowList={LowList}
						MeanScore={MeanScore}
					/>
				)}
			</div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </StyledAccordion>
      ) : (
        <div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Skeleton height={30} width="60%" style={{ marginBottom: '0.5rem' }} />
            <Skeleton count={3} height={20} width="100%" style={{ marginBottom: '0.5rem' }} />
          </div>
        </div>
        
      )}
    </div>
  );
}

export default AnalysisLikert;