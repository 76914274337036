import React, { useState, useEffect, useRef } from "react";
import {
    select,
    scaleBand,
    axisBottom,
    axisLeft,
    scaleLinear,
    stack,
    max,
    event,
    schemeTableau10,
    scaleOrdinal
} from "d3";

export const StackedBarGraph = (props) => {
    //const [data, setData] = useState(datasets);
    const svgRef = useRef();
    const wrapperRef = useRef();
    const canvasRef = useRef(null)

    const colors = schemeTableau10
    const [dkeys, setDkeys] = useState([])
    const [dcolors, setDcolors] = useState({})

    useEffect(() => {
        const svg = select(svgRef.current);
        const { width, height } = wrapperRef.current.getBoundingClientRect();
        const margin = { top: 20, right: 20, bottom: 100, left: 50 };
        const chartHeight = height - margin.bottom;
        
        // Process data for multi-bar graph
        let categories = [...new Set(props.data.map(d => d.cn1))];
        let types = [...new Set(props.data.map(d => d.cn3))];
        let subgroups = [...new Set(props.data.map(d => d[props.secondary]))];
        
        // Create processed data structure
        let processedData = [];
        categories.forEach(cat => {
            types.forEach(type => {
                let dataPoint = {
                    name: cat,
                    type: type
                };
                subgroups.forEach(sub => {
                    const matchingItem = props.data.find(d => 
                        d.cn1 === cat && 
                        d.cn3 === type && 
                        d[props.secondary] === sub
                    );
                    dataPoint[sub] = matchingItem ? matchingItem.anscnt : 0;
                });
                processedData.push(dataPoint);
            });
        });

        setDkeys(subgroups);

        // Set up scales
        const yScale = scaleLinear()
            .domain([0, max(processedData, d => max(subgroups, key => d[key]))])
            .range([chartHeight, margin.top]);

        const x0Scale = scaleBand()
            .domain(categories)
            .range([margin.left, width - margin.right])
            .padding(0.2);

        const x1Scale = scaleBand()
            .domain(types)
            .range([0, x0Scale.bandwidth()])
            .padding(0.1);

        const x2Scale = scaleBand()
            .domain(subgroups)
            .range([0, x1Scale.bandwidth()])
            .padding(0.05);

        // Set up color scale
        const color = scaleOrdinal()
            .domain(subgroups)
            .range(colors);

        let cd = {};
        subgroups.forEach((key, i) => {
            cd[key] = color(i);
        });
        setDcolors(cd);

        // Clear previous content
        svg.selectAll('.content > *').remove();

        // Update axes
        svg
            .select(".x-axis")
            .attr("transform", `translate(0, ${chartHeight})`)
            .call(axisBottom(x0Scale));

        svg
            .select(".y-axis")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(axisLeft(yScale));

        // Create category groups
        const categoryGroups = svg
            .select(".content")
            .selectAll(".category")
            .data(categories)
            .join("g")
            .attr("class", "category")
            .attr("transform", d => `translate(${x0Scale(d)}, 0)`);

        // Create type groups
        const typeGroups = categoryGroups
            .selectAll(".type")
            .data(d => types.map(type => ({category: d, type})))
            .join("g")
            .attr("class", "type")
            .attr("transform", d => `translate(${x1Scale(d.type)}, 0)`);

        // Create bars
        typeGroups.selectAll("rect")
            .data(d => subgroups.map(key => ({
                category: d.category,
                type: d.type,
                key: key,
                value: processedData.find(item => 
                    item.name === d.category && 
                    item.type === d.type
                )[key] || 0
            })))
            .join("rect")
            .attr("x", d => x2Scale(d.key))
            .attr("y", d => yScale(d.value))
            .attr("width", x2Scale.bandwidth())
            .attr("height", d => chartHeight - yScale(d.value))
            .attr("fill", d => cd[d.key]);

        // Add value labels
        typeGroups.selectAll(".value-label")
            .data(d => subgroups.map(key => ({
                category: d.category,
                type: d.type,
                key: key,
                value: processedData.find(item => 
                    item.name === d.category && 
                    item.type === d.type
                )[key] || 0
            })))
            .join("text")
            .attr("class", "value-label")
            .text(d => d.value || '')
            .attr("x", d => x2Scale(d.key) + x2Scale.bandwidth() / 2)
            .attr("y", d => yScale(d.value) - 5)
            .attr("text-anchor", "middle")
            .attr("font-family", "Noto Sans")
            .attr("font-size", "11px")
            .attr("fill", "black");

        // Add type labels
        typeGroups
            .append("text")
            .text(d => d.type)
            .attr("x", x1Scale.bandwidth() / 2)
            .attr("y", chartHeight + 20)
            .attr("text-anchor", "middle")
            .attr("font-family", "Noto Sans")
            .attr("font-size", "11px")
            .attr("fill", "black");

        // Add "Contribution" text
        svg
            .select(".content")
            .append("text")
            .attr("x", margin.left)
            .attr("y", chartHeight + 50)
            .attr("font-family", "Noto Sans")
            .attr("font-size", "12px")
            .text("Contribution");

        // Update legend position and layout
        const legendGroup = svg
            .select(".content")
            .append("g")
            .attr("class", "legend")
            .attr("transform", `translate(${margin.left}, ${chartHeight + 60})`);

        const legendsPerRow = Math.floor((width - margin.left - margin.right) / 120);
        
        const legends = legendGroup
            .selectAll(".legend-item")
            .data(subgroups)
            .join("g")
            .attr("class", "legend-item")
            .attr("transform", (d, i) => {
                const row = Math.floor(i / legendsPerRow);
                const col = i % legendsPerRow;
                return `translate(${col * 120}, ${row * 20})`;
            });

        // Add colored rectangles
        legends
            .append("rect")
            .attr("width", 16)
            .attr("height", 16)
            .attr("fill", d => cd[d]);

        // Add legend text
        legends
            .append("text")
            .attr("x", 24)
            .attr("y", 12)
            .attr("font-family", "Noto Sans")
            .attr("font-size", "12px")
            .text(d => d);

        let doctype = '<?xml version="1.0" standalone="no"?>'
            + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

        // serialize our SVG XML to a string.
        let source = (new XMLSerializer()).serializeToString(window.document.getElementById('svg_' + props.qid));


        // create a file blob of our SVG.
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        let img = new Image({ width: width, height: height })
        img.src = 'data:image/svg+xml;base64,' + window.btoa(doctype + source)
        canvas.width = width
        canvas.height = height + 100
        context.drawImage(img, 0, 0, width, height)

        img.onload = function () {
            // Draw the image with the new dimensions
            context.clearRect(0, 0, width, height + 100);
            context.drawImage(img, 0, 0, width, height + 100);

            if (props.onReady != undefined) {
                props.onReady(props.index, 2, canvas.toDataURL("image/png"), props.question)
            }
        }
    }, []);

    return (
        <>
            <div
                ref={wrapperRef}
                style={{ width: "100%", height: "450px", marginBottom: "2rem" }}
            >
                <svg ref={svgRef} style={{ width: "100%", height: "100%" }} id={'svg_' + props.qid}>
                    <g className="x-axis" />
                    <g className="y-axis" />
                    <g className="content" />
                </svg>
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
        </>
    );
};

export default StackedBarGraph
