import styled from '@emotion/styled';

const InputContainer = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    display: ${props => props.display ? props.display : 'block'};
    border-bottom: 1px solid var(--darkBorderColor);
    display: flex;
    align-items: center;

    .prefix {
        font-size: 15px;
        font-weight: 400;
        color: var(--quadColor);
        display: inline-block;
        margin-right: 7px;
    }
    .suffix {
        font-size: 15px;
        font-weight: 400;
        color: var(--quadColor);
        display: inline-block;
        margin-right: 70px;
    }
    

    input{
        flex: 1;
        width: 50px;
    }

    .characters {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        font-size: 12px;
        font-weight: 400;
        color: var(--inputColor);
    }
`

const StyledInputText = styled.input`
    border: 0;
    border-radius: 0;
    color: ${props => props.color || 'var(--inputColor)'};
    padding-left: 0;
    font-weight: ${props => props.fontWeight ? props.fontWeight : '700'};
    font-size: ${props => props.fontSize ? props.fontSize : '0.875rem'};
    background: transparent;

    :hover,
    :focus{
        box-shadow: none;
        outline: 0;
        border-color: var(--inputColor);
        color: ${props => props.color || 'var(--inputColor)'};
        background: transparent;
    }

    &.is-invalid:focus {
        box-shadow: none;
        outline: 0;
    }

    ::placeholder{
        color: var(--mediumGrey);
        font-weight: 500;
        font-size: 0.75rem;
    }
    ::-webkit-input-placeholder{
        color: var(--mediumGrey);
        font-weight: 500;
        font-size: 0.75rem;
    }
    ::-moz-placeholder{
        color: var(--mediumGrey);
        font-weight: 500;
        font-size: 0.75rem;
    }
    :-ms-input-placeholder {
        color: var(--mediumGrey);
        font-weight: 500;
        font-size: 0.75rem;
    }
`

export default function InputText({ children, className="", placeholder=null, type='text', value='', onChange=null, showCharacters=true, characterLimit = 30, width, display, prefix="",suffix="", ...rest }){
    const charactersTyped = value?.length || 0;

    if(showCharacters && value?.length >= characterLimit) {
        value = value.substr(0,(characterLimit-1))
    }
    
    return (
        <InputContainer width={width} display={display}>
            <span className="prefix">{prefix}</span>
            <StyledInputText value={value} type={type} className={`form-control ${className}`} placeholder={placeholder} defaultValue={value} onChange={onChange} {...rest} />
            {showCharacters && <span className="characters">{charactersTyped}/{characterLimit}</span>}
            <span className="suffix">{suffix}</span>
        </InputContainer>
    )
}