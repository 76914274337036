import React, { useEffect, useRef } from "react";
import { select, scaleLinear } from "d3";

export default function Likert({ datasets, valueType }) {
  const svgRef = useRef();
  const wrapperRef = useRef();

  // Function to generate color array based on the number of segments
  const generateColors = (numSegments) => {
    const baseColors = [
      [201, 4, 3, 1.0], // Start color
      [229, 85, 85, 1.0],
      [237, 125, 125, 1.0],
      [166, 166, 166, 1.0], // Middle color
      [80, 131, 204, 1.0],
      [35, 97, 161, 1.0],
      [22, 62, 102, 1.0], // End color
    ];

    // Map the base colors to the desired number of segments
    if (numSegments === 5) {
      return [
        `rgba(${baseColors[0].join(",")})`,
        `rgba(${baseColors[1].join(",")})`,
        `rgba(${baseColors[3].join(",")})`, // Middle color
        `rgba(${baseColors[5].join(",")})`,
        `rgba(${baseColors[6].join(",")})`,
      ];
    } else if (numSegments === 7) {
      return baseColors.map((color) => `rgba(${color.join(",")})`);
    } else if (numSegments === 10) {
      // Interpolate additional colors for 10 segments
      return [
        `rgba(${baseColors[0].join(",")})`,
        `rgba(${interpolateColor(baseColors[0], baseColors[1], 0.5).join(",")})`,
        `rgba(${baseColors[1].join(",")})`,
        `rgba(${interpolateColor(baseColors[1], baseColors[2], 0.5).join(",")})`,
        `rgba(${baseColors[2].join(",")})`,
        `rgba(${baseColors[3].join(",")})`, // Middle color
        `rgba(${baseColors[4].join(",")})`,
        `rgba(${interpolateColor(baseColors[4], baseColors[5], 0.5).join(",")})`,
        `rgba(${baseColors[5].join(",")})`,
        `rgba(${baseColors[6].join(",")})`,
      ];
    }
    return baseColors.map((color) => `rgba(${color.join(",")})`); // Default to 7 colors
  };

  // Helper function to interpolate between two colors
  const interpolateColor = (color1, color2, factor) => {
    return color1.map((channel, index) =>
      Math.round(channel + factor * (color2[index] - channel))
    );
  };

  // Determine the number of segments based on the dataset
  const numSegments = Object.keys(datasets).length - 3; // Subtract 'id', 'choiceName', and 'mean'
  const colors = generateColors(numSegments);

  useEffect(() => {
    const svg = select(svgRef.current);
    const { width } = wrapperRef.current.getBoundingClientRect();

    let dk = Object.keys(datasets);
    let data = [];
    for (let i = 0; i < dk.length; i++) {
      if (dk[i] !== "id" && dk[i] !== "choiceName" && dk[i] !== "mean") {
        data.push(parseInt(datasets[dk[i]]));
      }
    }

    let dataTemp = [];
    if (valueType === "%") {
      const sum = data.reduce((a, b) => a + b, 0);
      dataTemp = data.map((value) => Math.floor((value / sum) * 100));
    } else {
      dataTemp = data;
    }
    data = dataTemp;

    const total = data.reduce((acc, val) => acc + val, 0);

    svg.selectAll("*").remove();

    svg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("fill", (d, i) => colors[i])
      .attr("class", "barreact")
      .attr("x", (d, i) => {
        if (i === 0) return 0;
        let prevSum = data.slice(0, i).reduce((acc, val) => acc + val, 0);
        return (width * prevSum) / total;
      })
      .attr("width", (d) => (width * d) / total)
      .attr("y", 0)
      .attr("height", 30);

    const gr = svg.selectAll("rect");
    const ngroup = svg.append("g");
    gr.each(function (p, j) {
      ngroup
        .append("text")
        .text(p !== 0 ? p : "")
        .attr("x", () => {
          let x = 0;
          if (j !== 0) {
            let prevSum = data.slice(0, j).reduce((acc, val) => acc + val, 0);
            x = (width * prevSum) / total;
          }
          return x + (width * p) / total / 2;
        })
        .attr("y", 18)
        .attr("text-anchor", "middle")
        .attr("font-family", "Noto Sans")
        .attr("font-size", "11px")
        .attr("fill", "white");
    });
  }, [datasets, valueType, colors]);

  return (
    <div
      ref={wrapperRef}
      style={{ width: "100%", height: "30px", marginBottom: "1rem" }}
    >
      <svg ref={svgRef} style={{ width: "100%", height: "110%" }}>
        <g className="x-axis" />
        <g className="y-axis" />
        <g className="content" />
      </svg>
    </div>
  );
}