import styled from '@emotion/styled';
import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopup from '../../common/CustomPopup';
import { Button, Divider, Heading, Icon, ActionIcon, Text } from '../../common';
import { InputText, TextArea } from '../../components/form';
import { CustomButton } from '../../common/commonStyled';
import DesignQuestion from './DesignQuestion';
import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CSS } from '@dnd-kit/utilities';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import './DeleteQuestionToast.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const CustomButtont = styled(Button)`
  padding: 2px 1rem;
  color: var(--white);
  buttoncolor: var(--sapGreen);
  cursor: pointer;
`;

const CustomDivider = () => <Divider width="100%" height="1px" margin="1.5rem 0" background="var(--greyBlue)" />;

function isInteractiveElement(element) {
  const interactiveElements = ['button', 'input', 'textarea', 'select', 'option'];
  return element?.tagName && interactiveElements.includes(element.tagName.toLowerCase());
}

class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (!event.isPrimary || event.button !== 0 || isInteractiveElement(event.target)) {
          return false;
        }
        return true;
      }
    }
  ];
}

export default function DesignInterviewTab({
  task,
  nextTask,
  videoTaskList,
  questionTypeList,
  screenerTypeOption,
  masterTags,
  handleTaskTab,
  ValueTypeChoices,
  addQuestionDisable,
  setAddQuestionDisable,
  questionIndex
}) {
  const loggedUser = useSelector(state => state.LoginReducer);
  const [message, setMessage] = useState(false);
  const projectTask_ID = task.projectTask_ID;
  const { projectId } = useParams();
  const [index, setIndex] = useState(null);
  const [popup, setPopup] = useState(false);
  const [interviewTask, setInterviewTask] = useState(task);
  const [interviewTaskSections, setInterviewTaskSections] = useState(videoTaskList);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [editSectionIndex, seteditSectionIndex] = useState(null);
  const [sectionNameError, setSectionNameError] = useState(false);
  const [sectionSavedStatus, setSectionSavedStatus] = useState([]);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  const dispatch = useDispatch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  const [popupColor, setPopupColor] = useState('');
  const addSec = useSelector(state => state.ProjectReducer?.addSection);
  const editSec = useSelector(state => state.ProjectReducer?.editSection);
  const editQues = useSelector(state => state.TaskReducer?.editQuestion);
  const addQues = useSelector(state => state.TaskReducer?.addQuestion);
  const deleteSec = useSelector(state => state.ProjectReducer?.deleteSec);
  const deleteQues = useSelector(state => state.TaskReducer?.deleteQues);
  const initialLoad = useRef(true);

  const [sectionmessage, setsectionMessage] = useState(null);
  const [newQuestionIndex, setNewQuestionIndex] = useState(null);
  const [questionmessage, setquestionMessage] = useState(null);
  const [QuestionIndex, setQuestionIndex] = useState(null);
  const [switchvalue, setSwitchValue] = useState(0);
  const [isNewSection, setisNewSection] = useState(false);
  const [addSectionDisable, setAddSectionDisbale] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [isAddingNewSection, setIsAddingNewSection] = useState(false);
  const [deletesectionmessage, setdeletesectionMessage] = useState(null);
  const [deleteSectionIndex, setDeleteSectionIndex] = useState(null);
  const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleSetEditing = () => {
    setIsEditing(false);
  };

  const [openSections, setOpenSections] = useState(new Set(interviewTaskSections.map(s => s.questionPaperSection_ID)));

  const [draggingItem, setDraggingItem] = useState(null);

  const toggleOpen = sectionId => {
    setOpenSections(prev => {
      const newSet = new Set(prev);
      if (newSet.has(sectionId)) {
        newSet.delete(sectionId);
      } else {
        newSet.add(sectionId);
      }
      return newSet;
    });
  };

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 0, tolerance: 0 } }));

  const isSectionBeingDragged = activeId => {
    return interviewTaskSections.some(s => s.questionPaperSection_ID === activeId);
  };

  let questionTypes = questionTypeList?.map(questionType => {
    return questionType.questionType_ID == 9 ||
      questionType.questionType_ID == 10 ||
      questionType.questionType_ID == 11 ||
      questionType.questionType_ID == 4 ||
      questionType.questionType_ID == 1 ||
      questionType.questionType_ID == 5 ||
      questionType.questionType_ID == 6 ||
      questionType.questionType_ID == 2 ||
      questionType.questionType_ID == 7 ||
      questionType.questionType_ID == 14
      ? { ...questionType, label: questionType.questionTypeName, value: questionType.questionType_ID }
      : null;
  });

  questionTypes = questionTypes?.filter(function(questionType) {
    return questionType != null;
  });
  useEffect(() => {
    if (addSec?.StatusCode == 1) {
      setPopupColor('var(--sapGreen)');
      setsectionMessage('Section Added Successfully!');
    }
    if (addSec?.StatusCode == -1) {
      setPopupColor('red');
      setsectionMessage('Error While Adding Section');
    }

    const timeout = setTimeout(() => {
      setsectionMessage(null);
      seteditSectionIndex(null);
      dispatch({ type: 'CLEAR_ADD_SECTION' });
      dispatch({ type: 'CLEAR_EDIT_SECTION' });
    }, 3000);
  }, [addSec, editSec]);

  //Rutujas code for add question message
  useEffect(() => {
    if (addQues !== undefined) {
      if (addQues?.StatusCode == 1) {
        setPopupColor('var(--sapGreen)');
        setquestionMessage('Question Added Successfully!');
      }
      if (addQues?.StatusCode == -1) {
        setPopupColor('red');
        setquestionMessage('Error While Adding Question');
      }
      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getVideoTask.methodType
        )
        .then(response => {
          let videoTaskData = response.data.Response;
          setInterviewTaskSections(videoTaskData?.GetProjectTaskQuestions);
        });
      const timeout = setTimeout(() => {
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_ADD_QUESTION' });
      }, 3000);
    }
  }, [addQues]);

  useEffect(() => {
    if (editSec?.StatusCode == 1) {
      setPopupColor('var(--sapGreen)');
      setsectionMessage('Section Edited Successfully!');
    }
    if (editSec?.StatusCode == -1) {
      setPopupColor('red');
      setsectionMessage('Error While Editing The Section');
    }

    const timeout = setTimeout(() => {
      setsectionMessage(null);
      seteditSectionIndex(null);
      dispatch({ type: 'CLEAR_EDIT_SECTION' });
    }, 3000);
  }, [editSec]);

  useEffect(() => {
    if (editQues !== undefined) {
      if (editQues?.StatusCode == 1) {
        setPopupColor('var(--sapGreen)');
        // setquestionMessage('Question Edited Successfully!');
        setquestionMessage(editQues?.StatusDesc);
      }
      if (editQues?.StatusCode == -1) {
        setPopupColor('red');
        // setquestionMessage('Question response is already saved. You can not Edit question.');
        setquestionMessage(editQues?.StatusDesc);
      }
      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getVideoTask.methodType
        )
        .then(response => {
          let videoTaskData = response.data.Response;
          setInterviewTaskSections(videoTaskData?.GetProjectTaskQuestions);
        });
      const timeout = setTimeout(() => {
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_EDIT_QUESTION' });
      }, 3000);
    }
  }, [editQues]);

  const toastDisplayed = useRef(false);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (deleteSec?.StatusCode && !toastDisplayed.current) {
      const message = deleteSec?.StatusDesc;

      if (deleteSec?.StatusCode === '-1') {
        toast.error(<>{message}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
        setIsPopup(true);
      } else if (deleteSec?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{message}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
        setIsPopup(true);
      }

      toastDisplayed.current = true;

      setTimeout(() => {
        setIsPopup(false);
        dispatch({ type: 'CLEAR_DELETE_SECTION' });
        toastDisplayed.current = false;
      }, 4500);

      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getVideoTask.methodType
        )
        .then(response => {
          let videoTaskData = response.data.Response;
          setInterviewTaskSections(videoTaskData?.GetProjectTaskQuestions);
        });
    }
  }, [deleteSec]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (deleteQues?.StatusCode && !toastDisplayed.current) {
      const message = deleteQues?.StatusDesc;

      if (deleteQues?.StatusCode === '-1') {
        toast.error(<>{message}</>, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        });
        setIsPopup(true);
      } else if (deleteQues?.StatusCode === '1') {
        toast.success(
          <div style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: 'var(--primaryColor)', marginRight: '8px', fontSize: '1.5em' }}
            />
            <span>{message}</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            transition: Bounce
          }
        );
        setIsPopup(true);
      }

      toastDisplayed.current = true;

      setTimeout(() => {
        setIsPopup(false);
        dispatch({ type: 'CLEAR_DELETE_QUESTION' });
        toastDisplayed.current = false;
      }, 4500);

      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getVideoTask.methodType
        )
        .then(response => {
          let videoTaskData = response.data.Response;
          setInterviewTaskSections(videoTaskData?.GetProjectTaskQuestions);
        });
      const timeout = setTimeout(() => {
        setIsPopup(false);
        setquestionMessage(null);
        dispatch({ type: 'CLEAR_DELETE_QUESTION' });
        // getCriteriaBudgetQuestion();
      }, 3000);
    }
  }, [deleteQues]);

  useEffect(() => {
    setInterviewTaskSections(videoTaskList);
    setOpenSections(new Set(videoTaskList.map(s => s.questionPaperSection_ID)));
  }, [videoTaskList]);

  useEffect(() => {
    setTimeout(() => {
      setisNewSection(false);
    }, 3000);
  }, [isNewSection]);

  const SortableSection = React.memo(({ section, isOpen, toggleOpen, isDragging, sIndex }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
      id: section.questionPaperSection_ID
    });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      border: isDragging ? '4px solid #83A600' : ''
    };

    return (
      <div ref={setNodeRef} style={style}>
        {/* Child Questions (Do Not Trigger Toggle) */}
        <React.Fragment key={section.questionPaperSection_ID}>
          <CustomDivider />
          <div className="row">
            <div className="col-md-8">
              {isPopupVisible && deletesectionmessage && sIndex === 0 && (
                <center>
                  <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                    <div>{deletesectionmessage}</div>
                  </div>
                </center>
              )}
              <CustomButton
                variant="outlined"
                buttonColor="var(--greyBlue)"
                className=""
                //onClick={(e) => handleDeleteSection(sIndex)}
                onClick={e => {
                  setPopup(!popup), setIndex(sIndex);
                }}
                style={{
                  float: 'right',
                  color: 'var(--greyBlue)',
                  backgroundColor: 'white',
                  fontSize: '15px'
                }}
              >
                <Icon className="material-icons-round" style={{ color: 'var(--greyBlue)', marginTop: '1.5px' }}>
                  delete
                </Icon>
                Delete Section
                <CustomPopup
                  onClose={closeHandler}
                  showed={popup}
                  heading="Confirm delete"
                  secondheading="Are you sure you want to delete this Section?"
                  secondheading2="Once deleted, it cant be retrieved."
                >
                  <CustomButtont
                    fontSize="14px"
                    fontWeight="300"
                    color="black"
                    size="medium"
                    style={{ justifyContent: 'space-between', marginRight: '20px' }}
                    onClick={() => {
                      handleDeleteSection(index);
                    }}
                  >
                    Delete
                  </CustomButtont>
                </CustomPopup>
              </CustomButton>
              <div className="section-wrapper ">
                <div className="row" style={{ cursor: 'pointer' }}>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <button
                        ref={setActivatorNodeRef}
                        {...listeners}
                        {...attributes}
                        style={{
                          cursor: 'grab',
                          padding: 0,
                          margin: 0,
                          border: 0
                        }}
                      >
                        <PiDotsSixVerticalBold />
                      </button>
                      <Heading
                        type="5"
                        className="mt-2"
                        color="var(--seaBlue)"
                        onClick={() => toggleOpen(section.questionPaperSection_ID)}
                      >
                        Section {+sIndex + +1}
                        {isOpen ? '▼' : '▶'}
                      </Heading>
                    </div>
                  </div>
                  {interviewTaskSections[sIndex].sectionName == '' &&
                  interviewTaskSections[sIndex].sectionDescription == '' ? (
                    <div className="col-md-9">
                      <TextArea
                        showCharacters={false}
                        fontSize="1.125rem"
                        value={section.sectionName}
                        placeholder="Add a section name"
                        width="250px"
                        style={{
                          color: 'var(--deepBlue)'
                        }}
                        onChange={e => {
                          let tempInterviewTaskSections = interviewTaskSections;
                          tempInterviewTaskSections[sIndex].sectionName = e.target.value;
                          setInterviewTaskSections(tempInterviewTaskSections);
                        }}
                      />
                      {sectionNameError && (
                        <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                      )}
                      <TextArea
                        fontSize="0.75rem"
                        showCharacters={false}
                        value={section.sectionDescription}
                        placeholder="Add section description"
                        className="mt-1"
                        style={{
                          color: 'var(--deepBlue)',
                          fontWeight: '500'
                        }}
                        onChange={e => {
                          let tempInterviewTaskSections = interviewTaskSections;
                          tempInterviewTaskSections[sIndex].sectionDescription = e.target.value;
                          setInterviewTaskSections(tempInterviewTaskSections);
                        }}
                      />

                      {showSaveButton && (
                        // code for section button and popup
                        <>
                          <CustomButton
                            variant="outlined"
                            buttonColor="var(--sapGreen)"
                            className="mt-3"
                            onClick={e => {
                              e.stopPropagation();
                              if (!section.sectionName.trim()) {
                                setSectionNameError(true);
                              } else {
                                setSectionNameError(false);
                                storeSection(sIndex, 'sectionDescription', e.target.value);
                                setisNewSection(true);
                                setAddSectionDisbale(false);
                                setShowSaveButton(false);
                              }
                            }}
                          >
                            <Icon>save</Icon>
                            Save Section
                          </CustomButton>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-9">
                      <TextArea
                        showCharacters={false}
                        fontSize="1.125rem"
                        value={section.sectionName}
                        placeholder="Add a section name"
                        width="250px"
                        style={{
                          color: 'var(--deepBlue)'
                        }}
                        onChange={e => {
                          let tempInterviewTaskSections = interviewTaskSections;
                          tempInterviewTaskSections[sIndex].sectionName = e.target.value;
                          setInterviewTaskSections(tempInterviewTaskSections);
                          seteditSectionIndex(section.questionPaperSection_ID);
                        }}
                      />
                      {editSectionIndex === section.questionPaperSection_ID && sectionNameError && (
                        <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                      )}
                      <TextArea
                        fontSize="0.75rem"
                        showCharacters={false}
                        value={section.sectionDescription}
                        className="mt-1"
                        style={{
                          color: 'var(--deepBlue)',
                          fontWeight: '500'
                        }}
                        onChange={e => {
                          let tempInterviewTaskSections = interviewTaskSections;
                          tempInterviewTaskSections[sIndex].sectionDescription = e.target.value;
                          setInterviewTaskSections(tempInterviewTaskSections);
                          seteditSectionIndex(section.questionPaperSection_ID);
                        }}
                      />
                      {editSectionIndex === section.questionPaperSection_ID && (
                        <>
                          <CustomButton
                            variant="outlined"
                            buttonColor="var(--sapGreen)"
                            className="mt-3"
                            onClick={e => {
                              e.stopPropagation();

                              if (!section.sectionName.trim()) {
                                setSectionNameError(true);
                              } else {
                                setSectionNameError(false);
                                updateSection(sIndex, 'sectionName', section.sectionName);
                                updateSection(sIndex, 'sectionDescription', section.sectionDescription);
                                setShowSaveButton(false);
                              }
                            }}
                          >
                            <Icon>save</Icon>
                            Save Section
                          </CustomButton>
                        </>
                      )}
                    </div>
                  )}
                  {editSectionIndex === section.questionPaperSection_ID && sectionmessage && (
                    <center>
                      <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                        <div>{sectionmessage}</div>
                      </div>
                    </center>
                  )}
                </div>
                {isOpen && !isDragging && (
                  <>
                    <div
                      className="questions-list mt-3"
                      // style={{ borderLeft: '3px solid var(--greyBlue) !important' }}
                    >
                      <SortableContext
                        items={section.questionName_array.map(q => q.question_ID).filter(id => id !== null)}
                      >
                        <React.Fragment key={section.questionPaperSection_ID}>
                          <div className="questions-list mt-3">
                            {section?.questionName_array?.map((question, qIndex) => (
                              <SortableQuestion
                                key={question.question_ID}
                                question={question}
                                qIndex={qIndex}
                                section={section}
                                sIndex={sIndex}
                              />
                            ))}
                          </div>
                        </React.Fragment>
                      </SortableContext>
                    </div>

                    {newQuestionIndex == sIndex && questionmessage && (
                      <center>
                        <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                          <div>{questionmessage}</div>
                        </div>
                      </center>
                    )}
                    <CustomButton
                      variant="outlined"
                      buttonColor="var(--sapGreen)"
                      className="mt-5"
                      onClick={e => createQuestion(sIndex)}
                      disabled={section.isNew || addQuestionDisable}
                    >
                      <Icon>add</Icon>
                      Add Question
                    </CustomButton>

                    {isPopupVisible && deletesectionmessage && deleteSectionIndex == index + 1 && (
                      <center>
                        <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                          <div>{deletesectionmessage}</div>
                        </div>
                      </center>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  });

  const SortableQuestion = React.memo(({ question, qIndex, section, sIndex }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
      id: question.question_ID,
      animateLayoutChanges: () => false
    });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,

      background: 'white'
    };

    return (
      <>
        <div ref={setNodeRef} style={style}>
          <div
            key={question.question_ID}
            index={qIndex}
            onPointerDown={e => {
              e.stopPropagation(); // Prevent drag from interfering
              e.preventDefault(); // Fix double-click issue
            }}
            style={{ position: 'relative' }}
          >
            <button
              ref={setActivatorNodeRef}
              {...listeners}
              {...attributes}
              style={{
                cursor: 'grab',
                padding: 0,
                margin: 0,
                border: 0,
                position: 'absolute',
                zIndex: 100,
                top: '50%'
              }}
            >
              <PiDotsSixVerticalBold />
            </button>
            <DesignQuestion
              key={qIndex}
              sectionIndex={sIndex}
              questionIndex={qIndex}
              questionArrayLength={section?.questionName_array?.length}
              setSwitchValue={setSwitchValue}
              switchvalue={switchvalue}
              sectionId={section.questionPaperSection_ID}
              question={question}
              questionTypes={questionTypes}
              screenerTypeOption={screenerTypeOption}
              ValueTypeChoices={ValueTypeChoices}
              masterTags={masterTags}
              enableEdit={editQuestionId == question?.question_ID}
              setEditQuestionId={setEditQuestionId}
              handleChangeQuestionType={handleChangeQuestionType}
              handleDuplicateQuestion={handleDuplicateQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              handleProjectTaskTab={handleProjectTaskTab}
              isInterview={task.taskType !== 'Form'}
              setEditQuestionIndex={setEditQuestionIndex}
              setNewQuestionIndex={setNewQuestionIndex}
              setDeleteQuestionIndex={setDeleteQuestionIndex}
              setQuestionIndex={setQuestionIndex}
              setAddQuestionDisable={setAddQuestionDisable}
              questionmessage={questionmessage}
              popupColor={popupColor}
              showCheckbox={true}
              handleEditQuestion={handleEditQuestion}
              handleSetEditing={handleSetEditing}
              setInterviewTaskSections={setInterviewTaskSections}
              interviewTaskSections={interviewTaskSections}
              task={task}
            />

            {questionmessage && (
              <center>
                <div
                  style={{
                    color: popupColor,
                    fontWeight: 'bold',
                    textAlign: 'left'
                  }}
                >
                  <div>{questionmessage}</div>
                </div>
              </center>
            )}
          </div>
        </div>
      </>
    );
  });

  const createSection = () => {
    setIsAddingNewSection(true);
    let tempInterviewTaskSections = interviewTaskSections;
    tempInterviewTaskSections = [
      ...tempInterviewTaskSections,
      { sectionName: '', sectionDescription: '', questionName_array: [], isNew: true }
    ];

    setInterviewTaskSections(tempInterviewTaskSections);
    setShowSaveButton(true);
    setAddSectionDisbale(true);
    setSectionNameError(false);
    setSectionSavedStatus(prevStatus => [...prevStatus, false]);
  };

  const handleDragStart = event => {
    const { active } = event;
    setDraggingItem(active.id);

    // Collapse all sections ONLY if a SECTION is being dragged
    if (isSectionBeingDragged(active.id)) {
      setOpenSections(new Set());
    }
  };

  const moveQuestionWithinSection = (sections, sectionId, sourceIndex, destinationIndex) => {
    const updatedSections = [...sections];
    const section = updatedSections.find(s => s.questionPaperSection_ID === sectionId);

    if (!section) return sections;

    const [movedQuestion] = section.questionName_array.splice(sourceIndex, 1);
    section.questionName_array.splice(destinationIndex, 0, movedQuestion);

    return updatedSections;
  };

  const moveQuestionAcrossSections = (
    sections,
    sourceSectionId,
    destinationSectionId,
    sourceIndex,
    destinationIndex
  ) => {
    const updatedSections = [...sections];

    const sourceSection = updatedSections.find(s => s.questionPaperSection_ID === sourceSectionId);
    const destinationSection = updatedSections.find(s => s.questionPaperSection_ID === destinationSectionId);

    if (!sourceSection || !destinationSection) return sections;

    // Remove question from source section
    const [movedQuestion] = sourceSection.questionName_array.splice(sourceIndex, 1);

    // Add question to destination section
    destinationSection.questionName_array.splice(destinationIndex, 0, movedQuestion);

    return updatedSections;
  };

  const moveSection = (sections, activeId, overId) => {
    const sectionIndex = sections.findIndex(s => s.questionPaperSection_ID === activeId);
    const newSectionIndex = sections.findIndex(s => s.questionPaperSection_ID === overId);

    if (sectionIndex !== -1 && newSectionIndex !== -1) {
      return arrayMove(sections, sectionIndex, newSectionIndex);
    }

    return sections;
  };

  const handleDragEnd = event => {
    setDraggingItem(null);
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setInterviewTaskSections(prevSections => {
      let updatedSections = [...prevSections];

      // Check if the dragged item is a section
      const isSectionDrag = prevSections.some(s => s.questionPaperSection_ID === active.id);

      if (isSectionDrag) {
        // Moving sections
        updatedSections = moveSection(updatedSections, active.id, over.id);

        // API call for section drag and drop
        dispatch({
          type: 'DRAG_SECTION',
          payload: {
            user_ID: loggedUser.userId,
            sections: updatedSections.map((section, index) => ({
              questionPaperSection_ID: section.questionPaperSection_ID.toString(),
              sectionOrder: (index + 1).toString()
            }))
          }
        });
      } else {
        // Find the source and destination sections
        let sourceSection = updatedSections.find(s => s.questionName_array.some(q => q.question_ID === active.id));

        let destinationSection = updatedSections.find(s => s.questionName_array.some(q => q.question_ID === over.id));

        if (!sourceSection || !destinationSection) return updatedSections;

        const sourceIndex = sourceSection.questionName_array.findIndex(q => q.question_ID === active.id);
        const destinationIndex = destinationSection.questionName_array.findIndex(q => q.question_ID === over.id);

        if (sourceSection === destinationSection) {
          // Moving within the same section
          updatedSections = moveQuestionWithinSection(
            updatedSections,
            sourceSection.questionPaperSection_ID,
            sourceIndex,
            destinationIndex
          );

          dispatch({
            type: 'DRAG_QUESTION',
            payload: {
              user_ID: loggedUser.userId,
              questions: updatedSections
                .find(s => s.questionPaperSection_ID === sourceSection.questionPaperSection_ID)
                ?.questionName_array.map((q, index) => ({
                  question_ID: q.question_ID,
                  questionOrder: index + 1
                }))
            }
          });
        } else {
          // Moving across sections
          updatedSections = moveQuestionAcrossSections(
            updatedSections,
            sourceSection.questionPaperSection_ID,
            destinationSection.questionPaperSection_ID,
            sourceIndex,
            destinationIndex
          );

          dispatch({
            type: 'DRAG_QUESTION_ACROSS_SECTIONS',
            payload: {
              user_ID: loggedUser.userId,
              questionsPaperSections: [
                {
                  section_ID: sourceSection.questionPaperSection_ID,
                  questions: updatedSections
                    .find(s => s.questionPaperSection_ID === sourceSection.questionPaperSection_ID)
                    ?.questionName_array.map((q, index) => ({
                      question_ID: q.question_ID,
                      questionOrder: index + 1
                    }))
                },
                {
                  section_ID: destinationSection.questionPaperSection_ID,
                  questions: updatedSections
                    .find(s => s.questionPaperSection_ID === destinationSection.questionPaperSection_ID)
                    ?.questionName_array.map((q, index) => ({
                      question_ID: q.question_ID,
                      questionOrder: index + 1
                    }))
                }
              ]
            }
          });
        }
      }

      return updatedSections;
    });

    setOpenSections(new Set(interviewTaskSections.map(s => s.questionPaperSection_ID)));
  };

  const storeSection = (sectionIndex, _field, _value) => {
    setIsAddingNewSection(false);
    let tempInterviewTaskSections = interviewTaskSections;
    tempInterviewTaskSections[sectionIndex] = {
      ...tempInterviewTaskSections[sectionIndex],
      _field: _value,
      isNew: _field != 'sectionDescription'
    };

    setInterviewTaskSections(tempInterviewTaskSections);
    if (_field == 'sectionName') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }
    if (_field == 'sectionDescription') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }

    const section = interviewTaskSections[sectionIndex];
    const data = {
      user_ID: loggedUser.userId,
      projectsTask_ID: interviewTask.projectTask_ID,
      project_ID: projectId,
      projectScreener_ID: null,
      parentType: 'task',
      sectionName: section.sectionName,
      sectionDescription: section.sectionDescription,
      sectionOrder: sectionIndex
    };
    if (data.sectionName != '' || data.sectionDescription != '') {
      dispatch({ type: 'ADD_SECTION', payload: data });
      maverickPortalhttp
        .makeRequest(
          `${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${projectTask_ID}`,
          maverickPortalApiEndPoint.getVideoTask.methodType
        )
        .then(response => {
          let videoTaskData = response.data.Response;
          setInterviewTaskSections(videoTaskData?.GetProjectTaskQuestions);
        });
    }
    const updatedStatus = [...sectionSavedStatus];
    updatedStatus[sectionIndex] = true; // Mark the section at index as saved
    setSectionSavedStatus(updatedStatus);
    setShowSaveButton(false);
  };

  const updateSection = (sectionIndex, _field, _value) => {
    if (_field == 'sectionName') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }
    if (_field == 'sectionDescription') {
      if (_value == '') {
        setMessage(true);
      } else {
        setMessage(false);
      }
    }
    let tempInterviewTaskSections = interviewTaskSections;
    tempInterviewTaskSections[sectionIndex] = { ...tempInterviewTaskSections[sectionIndex], _field: _value };

    setInterviewTaskSections(tempInterviewTaskSections);

    const data = {
      user_ID: loggedUser.userId,
      QuestionPaperSection_ID: tempInterviewTaskSections[sectionIndex].questionPaperSection_ID,
      sectionName: _field == 'sectionName' ? _value : tempInterviewTaskSections[sectionIndex].sectionName,
      sectionDescription:
        _field == 'sectionDescription' ? _value : tempInterviewTaskSections[sectionIndex].sectionDescription
    };
    if (data.sectionName != '') {
      dispatch({ type: 'EDIT_SECTION', payload: data });
    }
    setShowSaveButton(true);
  };

  const createQuestion = sectionIndex => {
    setIsEditing(true);
    let tempInterviewTaskSections = interviewTaskSections.slice();

    !tempInterviewTaskSections[sectionIndex].hasOwnProperty('questionName_array') &&
      (tempInterviewTaskSections[sectionIndex] = {
        ...tempInterviewTaskSections[sectionIndex],
        questionName_array: []
      });

    let tempQuestionArray = tempInterviewTaskSections[sectionIndex].questionName_array;
    tempQuestionArray.push({
      question_ID: null,
      questionName: null,
      questionDescription: null,
      isRequired: switchvalue,
      isEdit: true,
      questionType_ID: 9,
      oldQuestionType_ID: 9,
      savedOption: null,
      buttonName: null,
      tags: [],
      ValueTypeChoices: [],
      choiceName_array: [],
      characterLimit: 500,
      maxFileSizeInMB: 10,
      numberOfFiles: 1
    });

    tempInterviewTaskSections[sectionIndex].questionName_array = tempQuestionArray;
    setNewQuestionIndex(sectionIndex);
    setAddQuestionDisable(true);
    setEditQuestionIndex(null);
    setDeleteQuestionIndex(null);
    setInterviewTaskSections(tempInterviewTaskSections);
  };
  const closeHandler = e => {
    setPopup(e);
  };

  const handleChangeQuestionType = (sectionIndex, questionIndex, questionTypeId, question) => {
    let tempInterviewTaskSections = interviewTaskSections.slice();

    question = {
      ...question,
      questionType_ID: questionTypeId,
      oldQuestionType_ID: question.oldQuestionType_ID,
      isEdit: true
    };

    tempInterviewTaskSections[sectionIndex].questionName_array[questionIndex] = question;
    setInterviewTaskSections(tempInterviewTaskSections);
  };

  const handleDuplicateQuestion = (sectionIndex, questionIndex, setCheckBoxRequired) => {
    let tempInterviewTaskSections = interviewTaskSections.slice();
    let question = tempInterviewTaskSections[sectionIndex]?.questionName_array[questionIndex];
    question = { ...question, isEdit: true, question_ID: null };

    // tempInterviewTaskSections[sectionIndex].questionName_array.push(question);
    // Insert the new question at the specified questionIndex
    tempInterviewTaskSections[sectionIndex].questionName_array.splice(questionIndex + 1, 0, question);

    setInterviewTaskSections(tempInterviewTaskSections);
    setIsEditing(true);
    setCheckBoxRequired(question.isRequired);
  };

  const handleEditQuestion = (sectionIndex, questionIndex) => {
    let tempFormTaskSections = interviewTaskSections.slice();
    let question = tempFormTaskSections[sectionIndex]?.questionName_array[questionIndex];
    question = { ...question, isEdit: true };

    tempFormTaskSections[sectionIndex].questionName_array[questionIndex] = question;
    setInterviewTaskSections(tempFormTaskSections);
    setIsEditing(true);
  };

  const handleDeleteQuestion = (sectionIndex, questionIndex) => {
    let tempInterviewTaskSections = interviewTaskSections.slice();
    let question = tempInterviewTaskSections[sectionIndex]?.questionName_array[questionIndex];

    // Remove the question from the array
    tempInterviewTaskSections[sectionIndex].questionName_array.splice(questionIndex, 1);
    setInterviewTaskSections(tempInterviewTaskSections);
    setAddQuestionDisable(false);

    // Dispatch only if question_ID is not null
    if (question?.question_ID) {
      setQuestionIndex(questionIndex);
      setDeleteQuestionIndex(sectionIndex);
      setNewQuestionIndex(null);
      let payload = {
        user_ID: loggedUser.userId,
        questions_ID: question.question_ID
      };

      dispatch({
        type: 'REQUEST_DELETE_QUESITON',
        formData: payload
      });
    }
  };

  const handleDeleteSection = sectionIndex => {
    setDeleteSectionIndex(sectionIndex);

    let tempInterviewTaskSections = interviewTaskSections.slice();
    let section = tempInterviewTaskSections[sectionIndex];

    let payload = {
      user_ID: loggedUser.userId,
      QuestionPaperSection_ID: section.questionPaperSection_ID
    };

    tempInterviewTaskSections.splice(sectionIndex, 1);
    setInterviewTaskSections(tempInterviewTaskSections);
    dispatch({
      type: 'REQUEST_DELETE_SECTION',
      formData: payload
    });
    setAddSectionDisbale(false);
    setPopup(false);
  };
  const handleProjectTaskTab = () => {
    handleTaskTab(task.taskType, task.projectTask_ID, task.IsVisited);
  };
  const handleProjectTaskNext = () => {
    handleTaskTab(nextTask.taskType, nextTask.projectTask_ID, nextTask.IsVisited);
  };

  return (
    <>
      <section className="form-notification-section ">
        <div className="row py-2">
          <div className="col-md-6">
            <Heading color={'var(--deepBlue)'} fontSize="1.125rem" fontWeight="800">
              {interviewTask.taskName}
            </Heading>

            <TextArea
              showCharacters={false}
              fontSize="0.75rem"
              fontWeight="500"
              placeholder="Add section description"
              value={interviewTask.taskDescription}
              className="mt-1"
            />
          </div>
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          // disabled={isEditingQuestion}
        >
          {draggingItem !== null || isEditing === false ? (
            <SortableContext
              items={interviewTaskSections?.map(s => s.questionPaperSection_ID).filter(id => id !== null)}
            >
              {interviewTaskSections?.map((section, sIndex) => (
                <SortableSection
                  key={section.questionPaperSection_ID}
                  section={section}
                  isOpen={openSections.has(section.questionPaperSection_ID)}
                  toggleOpen={toggleOpen}
                  isDragging={draggingItem === section.questionPaperSection_ID}
                  sIndex={sIndex}
                />
              ))}
            </SortableContext>
          ) : (
            interviewTaskSections?.map((section, sIndex) => {
              return (
                <React.Fragment key={section.questionPaperSection_ID}>
                  <CustomDivider />
                  <div className="row">
                    <div className="col-md-8">
                      {isPopupVisible && deletesectionmessage && sIndex === 0 && (
                        <center>
                          <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                            <div>{deletesectionmessage}</div>
                          </div>
                        </center>
                      )}
                      <CustomButton
                        variant="outlined"
                        buttonColor="var(--greyBlue)"
                        className=""
                        //onClick={(e) => handleDeleteSection(sIndex)}
                        onClick={e => {
                          setPopup(!popup), setIndex(sIndex);
                        }}
                        style={{
                          float: 'right',
                          color: 'var(--greyBlue)',
                          backgroundColor: 'white',
                          fontSize: '15px'
                        }}
                      >
                        <Icon className="material-icons-round" style={{ color: 'var(--greyBlue)', marginTop: '1.5px' }}>
                          delete
                        </Icon>
                        Delete Section
                        <CustomPopup
                          onClose={closeHandler}
                          showed={popup}
                          heading="Confirm delete"
                          secondheading="Are you sure you want to delete this Section?"
                          secondheading2="Once deleted, it cant be retrieved."
                        >
                          <CustomButtont
                            fontSize="14px"
                            fontWeight="300"
                            color="black"
                            size="medium"
                            style={{ justifyContent: 'space-between', marginRight: '20px' }}
                            onClick={() => {
                              handleDeleteSection(index);
                            }}
                          >
                            Delete
                          </CustomButtont>
                        </CustomPopup>
                      </CustomButton>
                      <div className="section-wrapper ">
                        <div className="row">
                          <div className="col-md-3">
                            <Heading type="5" className="mt-2" color="var(--seaBlue)">
                              Section {+sIndex + +1}
                            </Heading>
                          </div>
                          {interviewTaskSections[sIndex].sectionName == '' &&
                          interviewTaskSections[sIndex].sectionDescription == '' ? (
                            <div className="col-md-9">
                              <TextArea
                                showCharacters={false}
                                fontSize="1.125rem"
                                value={section.sectionName}
                                placeholder="Add a section name"
                                width="250px"
                                style={{
                                  color: 'var(--deepBlue)'
                                }}
                                onChange={e => {
                                  let tempInterviewTaskSections = interviewTaskSections;
                                  tempInterviewTaskSections[sIndex].sectionName = e.target.value;
                                  setInterviewTaskSections(tempInterviewTaskSections);
                                }}
                              />
                              {sectionNameError && (
                                <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                              )}
                              <TextArea
                                fontSize="0.75rem"
                                showCharacters={false}
                                value={section.sectionDescription}
                                placeholder="Add section description"
                                className="mt-1"
                                style={{
                                  color: 'var(--deepBlue)',
                                  fontWeight: '500'
                                }}
                                onChange={e => {
                                  let tempInterviewTaskSections = interviewTaskSections;
                                  tempInterviewTaskSections[sIndex].sectionDescription = e.target.value;
                                  setInterviewTaskSections(tempInterviewTaskSections);
                                }}
                              />

                              {showSaveButton && (
                                // code for section button and popup
                                <>
                                  <CustomButton
                                    variant="outlined"
                                    buttonColor="var(--sapGreen)"
                                    className="mt-3"
                                    onClick={e => {
                                      e.stopPropagation();
                                      if (!section.sectionName.trim()) {
                                        setSectionNameError(true);
                                      } else {
                                        setSectionNameError(false);
                                        storeSection(sIndex, 'sectionDescription', e.target.value);
                                        setisNewSection(true);
                                        setAddSectionDisbale(false);
                                        setShowSaveButton(false);
                                      }
                                    }}
                                  >
                                    <Icon>save</Icon>
                                    Save Section
                                  </CustomButton>
                                </>
                              )}
                            </div>
                          ) : (
                            <div className="col-md-9">
                              <TextArea
                                showCharacters={false}
                                fontSize="1.125rem"
                                value={section.sectionName}
                                placeholder="Add a section name"
                                width="250px"
                                style={{
                                  color: 'var(--deepBlue)'
                                }}
                                onChange={e => {
                                  let tempInterviewTaskSections = interviewTaskSections;
                                  tempInterviewTaskSections[sIndex].sectionName = e.target.value;
                                  setInterviewTaskSections(tempInterviewTaskSections);
                                  seteditSectionIndex(section.questionPaperSection_ID);
                                }}
                              />
                              {editSectionIndex === section.questionPaperSection_ID && sectionNameError && (
                                <p style={{ color: 'red', fontSize: '11px' }}>Please fill in the section name.</p>
                              )}
                              <TextArea
                                fontSize="0.75rem"
                                showCharacters={false}
                                value={section.sectionDescription}
                                className="mt-1"
                                style={{
                                  color: 'var(--deepBlue)',
                                  fontWeight: '500'
                                }}
                                onChange={e => {
                                  let tempInterviewTaskSections = interviewTaskSections;
                                  tempInterviewTaskSections[sIndex].sectionDescription = e.target.value;
                                  setInterviewTaskSections(tempInterviewTaskSections);
                                  seteditSectionIndex(section.questionPaperSection_ID);
                                }}
                              />
                              {editSectionIndex === section.questionPaperSection_ID && (
                                <>
                                  <CustomButton
                                    variant="outlined"
                                    buttonColor="var(--sapGreen)"
                                    className="mt-3"
                                    onClick={e => {
                                      e.stopPropagation();

                                      if (!section.sectionName.trim()) {
                                        setSectionNameError(true);
                                      } else {
                                        setSectionNameError(false);
                                        updateSection(sIndex, 'sectionName', section.sectionName);
                                        updateSection(sIndex, 'sectionDescription', section.sectionDescription);
                                        setShowSaveButton(false);
                                      }
                                    }}
                                  >
                                    <Icon>save</Icon>
                                    Save Section
                                  </CustomButton>
                                </>
                              )}
                            </div>
                          )}
                          {editSectionIndex === section.questionPaperSection_ID && sectionmessage && (
                            <center>
                              <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                <div>{sectionmessage}</div>
                              </div>
                            </center>
                          )}
                        </div>
                        <div
                          className="questions-list mt-3"
                          // style={{ borderLeft: '3px solid var(--greyBlue) !important' }}
                        >
                          <React.Fragment key={section.questionPaperSection_ID}>
                            <div className="questions-list mt-3">
                              {section?.questionName_array?.map((question, qIndex) => (
                                <>
                                  <DesignQuestion
                                    key={qIndex}
                                    sectionIndex={sIndex}
                                    questionIndex={qIndex}
                                    questionArrayLength={section?.questionName_array?.length}
                                    setSwitchValue={setSwitchValue}
                                    switchvalue={switchvalue}
                                    sectionId={section.questionPaperSection_ID}
                                    question={question}
                                    questionTypes={questionTypes}
                                    screenerTypeOption={screenerTypeOption}
                                    ValueTypeChoices={ValueTypeChoices}
                                    masterTags={masterTags}
                                    enableEdit={editQuestionId == question?.question_ID}
                                    setEditQuestionId={setEditQuestionId}
                                    handleChangeQuestionType={handleChangeQuestionType}
                                    handleDuplicateQuestion={handleDuplicateQuestion}
                                    handleDeleteQuestion={handleDeleteQuestion}
                                    handleProjectTaskTab={handleProjectTaskTab}
                                    isInterview={task.taskType !== 'Form'}
                                    setEditQuestionIndex={setEditQuestionIndex}
                                    setNewQuestionIndex={setNewQuestionIndex}
                                    setDeleteQuestionIndex={setDeleteQuestionIndex}
                                    setQuestionIndex={setQuestionIndex}
                                    setAddQuestionDisable={setAddQuestionDisable}
                                    questionmessage={questionmessage}
                                    popupColor={popupColor}
                                    showCheckbox={true}
                                    handleEditQuestion={handleEditQuestion}
                                    handleSetEditing={handleSetEditing}
                                    setInterviewTaskSections={setInterviewTaskSections}
                                    interviewTaskSections={interviewTaskSections}
                                    task={task}
                                  />
                                  {/* {(editQuestionIndex) == sIndex && questionmessage && (
                                      <center>
                                        <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                                          <div>{questionmessage}</div>
                                        </div>
                                      </center>
                                    )} */}

                                  {deleteQuestionIndex === sIndex && QuestionIndex === qIndex && questionmessage && (
                                    <center>
                                      <div
                                        style={{
                                          color: popupColor,
                                          fontWeight: 'bold',
                                          textAlign: 'left'
                                        }}
                                      >
                                        <div>{questionmessage}</div>
                                      </div>
                                    </center>
                                  )}
                                </>
                              ))}
                            </div>
                          </React.Fragment>
                        </div>

                        {newQuestionIndex == sIndex && questionmessage && (
                          <center>
                            <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                              <div>{questionmessage}</div>
                            </div>
                          </center>
                        )}
                        <CustomButton
                          variant="outlined"
                          buttonColor="var(--sapGreen)"
                          className="mt-5"
                          onClick={e => createQuestion(sIndex)}
                          disabled={section.isNew || addQuestionDisable}
                        >
                          <Icon>add</Icon>
                          Add Question
                        </CustomButton>

                        {isPopupVisible && deletesectionmessage && deleteSectionIndex == index + 1 && (
                          <center>
                            <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
                              <div>{deletesectionmessage}</div>
                            </div>
                          </center>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
        </DndContext>

        <CustomDivider />
        <div>
          <CustomButton
            variant="outlined"
            buttonColor="var(--sapGreen)"
            className="mt-3"
            onClick={e => createSection()}
            disabled={addSectionDisable}
          >
            <Icon>add</Icon>
            Add New Section
          </CustomButton>
        </div>
        {isNewSection && sectionmessage && (
          <center>
            <div style={{ color: popupColor, fontWeight: 'bold', textAlign: 'left' }}>
              <div>{sectionmessage}</div>
            </div>
          </center>
        )}

        <div className="d-flex flex-column align-items-center justify-content-center mt-5">
          <Heading type="4">Well done!</Heading>
          <Heading type="5">Your progress so far has been saved</Heading>
          <div className="d-flex ">
            <Link to={`/project/${projectId}/invite-recruit`}>
              <Button size="mid" className="mt-4 mr-2" buttonColor="var(--sapGreen)">
                Recruit Respondents
              </Button>
            </Link>

            <Button size="mid" className="mt-4 ml-2" buttonColor="var(--sapGreen)" onClick={handleProjectTaskNext}>
              Design {nextTask.taskType != 'Video' ? nextTask.taskType : 'Interview'}
            </Button>
            <Link to={{ pathname: '/project/' + projectId + '/field' }} style={{ marginLeft: '15px' }}>
              <Button size="mid" className="mt-4 mr-2" buttonColor="var(--sapGreen)">
                Go To Field
              </Button>
            </Link>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
}
