import {useState, useRef,useEffect} from 'react'
import {
  select,
  scaleBand,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  event,
  map,
  InternSet,
  range,
  schemeTableau10,
  scaleOrdinal
} from "d3";

export default function VerticalStack(props){

    const svgRef = useRef();
    const wrapperRef = useRef();
    const canvasRef = useRef(null)
    
    const colors = schemeTableau10
    const [dkeys,setDkeys] = useState([])
    const [dcolors,setDcolors] = useState({})

    useEffect(()=>{
      const svg = select(svgRef.current);
      const { width, height } = wrapperRef.current.getBoundingClientRect();
      const margin = { top: 20, right: 20, bottom: 100, left: 50 }; // Increased bottom margin
      const chartHeight = height - margin.bottom; // Adjust chart height for legends
      
      // Get canvas reference first
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      
      // Process data for multi-bar graph
      let categories = [...new Set(props.data.map(d => d.cn1))];
      let subgroups = [...new Set(props.data.map(d => d[props.secondary]))];
      
      // Create processed data structure
      let processedData = categories.map(cat => {
          let obj = { category: cat };
          subgroups.forEach(sub => {
              const matchingItem = props.data.find(d => 
                  d.cn1 === cat && d[props.secondary] === sub
              );
              obj[sub] = matchingItem ? matchingItem.anscnt : 0;
          });
          return obj;
      });

      setDkeys(subgroups);

      // Set up scales
      const yScale = scaleLinear()
          .domain([0, max(processedData, d => max(subgroups, key => d[key]))])
          .range([chartHeight, margin.top]);

      const xScale = scaleBand()
          .domain(categories)
          .range([margin.left, width - margin.right])
          .padding(0.2);

      // Create color scale
      const color = scaleOrdinal()
          .domain(subgroups)
          .range(colors.slice(0, subgroups.length));

      let cd = {};
      subgroups.forEach((key) => {
          cd[key] = color(key);
      });
      setDcolors(cd);

      // Set up inner scale for grouped bars
      const xSubScale = scaleBand()
          .domain(subgroups)
          .range([0, xScale.bandwidth()])
          .padding(0.05);

      // Clear previous content
      svg.selectAll('.content > *').remove();

      // Update axes
      svg
          .select(".x-axis")
          .attr("transform", `translate(0, ${chartHeight})`)
          .call(axisBottom(xScale));

      svg
          .select(".y-axis")
          .attr("transform", `translate(${margin.left}, 0)`)
          .call(axisLeft(yScale));

      // Create groups for each category
      const categoryGroups = svg
          .select(".content")
          .selectAll(".category")
          .data(processedData)
          .join("g")
          .attr("class", "category")
          .attr("transform", d => `translate(${xScale(d.category)}, 0)`);

      // Create bars
      categoryGroups.selectAll("rect")
          .data(d => subgroups.map(key => ({
              key,
              value: d[key]
          })))
          .join("rect")
          .attr("x", d => xSubScale(d.key))
          .attr("y", d => yScale(d.value))
          .attr("width", xSubScale.bandwidth())
          .attr("height", d => chartHeight - yScale(d.value))
          .attr("fill", d => cd[d.key]);

      // Add value labels
      categoryGroups.selectAll(".value-label")
          .data(d => subgroups.map(key => ({
              key,
              value: d[key]
          })))
          .join("text")
          .attr("class", "value-label")
          .text(d => d.value || '')
          .attr("x", d => xSubScale(d.key) + xSubScale.bandwidth() / 2)
          .attr("y", d => yScale(d.value) - 5)
          .attr("text-anchor", "middle")
          .attr("font-family", "Noto Sans")
          .attr("font-size", "11px")
          .attr("fill", "black");

      // Add "Contribution" text
      svg
          .select(".content")
          .append("text")
          .attr("x", margin.left)
          .attr("y", chartHeight + 40)
          .attr("font-family", "Noto Sans")
          .attr("font-size", "12px")
          .text("Contribution");

      // Add legends
      const legendGroup = svg
          .select(".content")
          .append("g")
          .attr("class", "legend")
          .attr("transform", `translate(${margin.left}, ${chartHeight + 50})`);

      const legendsPerRow = Math.floor((width - margin.left - margin.right) / 120);
      
      const legends = legendGroup
          .selectAll(".legend-item")
          .data(subgroups)
          .join("g")
          .attr("class", "legend-item")
          .attr("transform", (d, i) => {
              const row = Math.floor(i / legendsPerRow);
              const col = i % legendsPerRow;
              return `translate(${col * 120}, ${row * 20})`;
          });

      // Add colored rectangles for legends
      legends
          .append("rect")
          .attr("width", 16)
          .attr("height", 16)
          .attr("fill", d => cd[d]);

      // Add legend text
      legends
          .append("text")
          .attr("x", 24)
          .attr("y", 12)
          .attr("font-family", "Noto Sans")
          .attr("font-size", "12px")
          .text(d => d);

      let doctype = '<?xml version="1.0" standalone="no"?>'
      + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

      let source = (new XMLSerializer()).serializeToString(window.document.getElementById('svg_'+props.qid));

      // Set canvas dimensions before creating image
      const totalHeight = height + 100;
      canvas.width = width;
      canvas.height = totalHeight;

      let img = new Image();
      img.src = 'data:image/svg+xml;base64,'+window.btoa(doctype + source)

      img.onload = function(){
      // Now that the image has loaded, put the image into a canvas element.
      context.clearRect(0, 0, width, totalHeight);
      context.drawImage(img, 0, 0,width,totalHeight);

      if(props.onReady != undefined){
          props.onReady(props.index, 2, canvas.toDataURL("image/png"), props.question)
        }
      }

    },[])

    return (
        <>
            <div
                ref={wrapperRef}
                style={{ width: "100%", height: "400px", marginBottom: "2rem" }}
            >
                <svg ref={svgRef} style={{ width: "100%", height: "100%" }} id={'svg_'+props.qid}>
                    <g className="x-axis" />
                    <g className="y-axis" />
                    <g className="content" />
                </svg>
                <canvas ref={canvasRef} style={{display:'none'}}/>
            </div>
        </>
    );
}